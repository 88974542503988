import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  createTheme,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  AppBar,
  Tabs,
  Tab,
  TablePagination,
  AccordionDetails,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
  Select,
  FormControl,
  MenuItem,
  CircularProgress,
  withStyles,
  Tooltip,
  Paper
} from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Chart from 'react-apexcharts';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import CreateDealButton from "../../postcreation/src/CreateDealButton.web";
import Pushnotifications from "../../pushnotifications/src/Pushnotifications.web";
import HamburgerMenu from "../../customisableuserprofiles/src/HamburgerMenu.web";
import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";
import Joyride, { TooltipRenderProps } from 'react-joyride';
import { responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import Chat from "../../chat/src/Chat.web";
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordion from '@material-ui/core/Accordion';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {

    h1: {
      fontSize: 36,
      [`@media (max-width:1920px)`]: {
        fontSize: 20
      },

    }, h2: {
      fontSize: 36
    }, h3: {
      fontSize: 26,
      [`@media (max-width:1920px)`]: {
        fontSize: 22
      }
    },
    h4: {
      fontSize: 28,
      [`@media (max-width:1920px)`]: {
        fontSize: 18
      },
    },
    h5: {
      fontSize: 24,
      [`@media (max-width:1920px)`]: {
        fontSize: 20, // Font size for 13-inch laptops or smaller screens
      },
    },
    h6: {
      fontSize: 20,
      [`@media (max-width:1920px)`]: {
        fontSize: 18
      },

    },
    subtitle1: {
      fontSize: 18,
      [`@media (max-width:1440px)`]: {
        fontSize: 16
      },
    },
    subtitle2: {
      fontSize: 18,
      [`@media (max-width:1440px)`]: {
        fontSize: 14
      },
    },
    body1: {
      fontSize: 16,
      [`@media (max-width:1440px)`]: {
        fontSize: 14
      },
    },
    body2: {
      fontSize: 14,
      [`@media (max-width:1440px)`]: {
        fontSize: 12,
      },
    },
    caption: {
      fontSize: 16,
      [`@media (max-width:1440px)`]: {
        fontSize: 14
      },
    }

  }
});
let applytheme = responsiveFontSizes(theme);


const ToolTip = withStyles({
  tooltip: {
    padding: '16px',
    radius: '8px',
    background: '#FFFFFF',
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    color: '#4B4C4B',
    fontSize: '15px'
  },
})(Tooltip);

const RightSideAccordition = withStyles((theme) => ({
  root: {
    background: "#fffff",
    padding: "25px 20px",
    borderRadius: "24px !important",
    border: '1px solid #F0FAFA',
    boxShadow: "0px 0px 1px 0px #8989895C",
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      padding: "25px 20px",

    },
    [theme.breakpoints.up(1920)]: {
      padding: "32px"
    },

  },
  expanded: {},
}))(MuiAccordion);


const RightSideAccordionSummary = withStyles({
  root: {
    alignItems: 'flex-start',
    padding: "0px !important",
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },
  content: {
    margin: '0px !important',
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
  expandIcon: {
    padding: '0px 12px !important',
    fontSize: "1.5rem",
    transition: "transform 0.3s ease-in-out", // Smooth animation
    "&$expanded": {
      transform: "rotate(180deg)", // Rotate when expanded
    },
  },
})(MuiAccordionSummary);
// Customizable Area End

import BlockController, {
  configJSON,
  Props,
  TableRows,
} from "./HomeDashboardController";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  truncateName = (name: string) => {
    return (
      <ToolTip title={name} placement="top-end">
        <Typography style={{
          fontFamily: 'Gotham',
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "300px",
          display: "inline-block",
          fontSize: '12px'
        }}>{name}</Typography>
      </ToolTip>
    )
  }
  getService = (dealService: string | undefined) => {
    if (dealService === '-') return dealService;
    if (dealService) {
      const match = dealService.match(/"([^"]+)"/);
      const key = match ? match[1] : "";
      return key.charAt(0).toUpperCase() + key.slice(1);
    }
  }

  transformService = (dealService: string | undefined) => {
    if (dealService === '-') return dealService;
    if (dealService) {
      const uppercase = dealService.charAt(0).toUpperCase() + dealService.slice(1);
      return uppercase.replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase());
    }
  }

  ChartData = [
    { label: 'In Progress', count: 462, percentage: '50%', color: '#8fc9f7' },
    { label: 'Issues Sent to Dealer', count: 126, percentage: '15%', color: '#ffd700' },
    { label: 'Resolved, In Dealer Review', count: 168, percentage: '20%', color: '#d3d3d3' },
    { label: 'Dealer Flags Incomplete', count: 42, percentage: '5%', color: '#f08080' },
    { label: 'Completed', count: 42, percentage: '5%', color: '#8fc9f7' },
  ];


  TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TableDealerOne = () => {
    return (
      <TableContainer component={Box} style={{ overflowX: 'auto' }}>
        <Box style={{ overflowX: 'auto', width: '100%', }}>
          <Table aria-label="sticky table" stickyHeader style={{ overflowX: 'auto' }}>
            <TableHead>
              <TableRow>
                <TabCellHeader style={{ borderLeft: 'none' }}> Deal ID</TabCellHeader>
                <TabCellHeader> Customer's Last Name </TabCellHeader>
                <TabCellHeader>Dealership </TabCellHeader>
                <TabCellHeader> States</TabCellHeader>
                <TabCellHeader>Date Created </TabCellHeader>
                <TabCellHeader style={{ borderRight: 'none' }} > Service</TabCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>

              {this.state.tableDataOngoingDealer.map((deal, index) => (
                <StyledTableRow className="tablehover" key={index}>
                  <TabCells style={{ borderLeft: 'none' }} data-test-id='deal-id' onClick={() => { this.navigateToDealDashboard(deal.id) }}>{deal.id}</TabCells>
                  <TabCells> {this.truncateName(deal.customerLastName)} </TabCells>
                  <TabCells> {deal.dealership} </TabCells>
                  <TabCells> {deal.states} </TabCells>

                  <TabCells> {deal.dateCreated}</TabCells>
                  <TabCells style={{ borderRight: 'none' }}>{this.getService(deal.service)} </TabCells>
                </StyledTableRow>
              ))
              }
              {this.renderNoDeals(this.state.tableDataOngoingDealer.length)}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    )
  }

  TableDealerOneTabtwo = () => {
    const filteredDeals = this.state.tableDataPendingDealer.filter((deal: TableRows) => deal.status !== 'withdrawn').slice(0, 6)
    return (
      <TableContainer component={Box} style={{
        overflowX: 'auto'
      }}>
        <Box style={{ overflowX: 'auto', width: '100%', }}>
          <Table aria-label="sticky table" stickyHeader style={{ overflowX: 'auto' }}>
            <TableHead>
              <TableRow>
                < TabCellHeader style={{ borderLeft: 'none' }}> Deal ID</TabCellHeader>
                <TabCellHeader> Customer's Last Name </TabCellHeader>
                < TabCellHeader>Dealership </TabCellHeader>
                <TabCellHeader> States</TabCellHeader>
                < TabCellHeader>Date Sent </TabCellHeader>
                <TabCellHeader >Service</TabCellHeader>
                <TabCellHeader >Service Provider</TabCellHeader>
                <TabCellHeader style={{ borderRight: 'none', textAlign: 'center' }} >Actions</TabCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDeals.map((deal: TableRows, index: number) => {
                const isSameDealId = index > 0 && filteredDeals[index - 1].id === deal.id;
                return (
                  <StyledTableRow className="tablehover" key={index}>
                    {!isSameDealId || filteredDeals[index - 1].status === 'withdrawn' ? (
                      <>
                        <TabCells style={{ borderLeft: 'none', cursor: 'pointer' }} data-test-id="deal-id" onClick={() => this.navigateToDealDashboard(deal.id)}>
                          #{deal.id}
                        </TabCells>
                        <TabCells>{this.truncateName(deal.customerLastName)}</TabCells>
                        <TabCells>{deal.dealership}</TabCells>
                        <TabCells>{deal.states}</TabCells>
                        <TabCells>{deal.dateSent}</TabCells>
                      </>
                    ) : (
                      <>
                        <TabCells style={{ borderLeft: 'none', cursor: 'pointer', visibility: 'hidden' }}></TabCells>
                        <TabCells style={{ borderLeft: 'none', cursor: 'pointer', visibility: 'hidden' }}></TabCells>
                        <TabCells style={{ borderLeft: 'none', cursor: 'pointer', visibility: 'hidden' }}></TabCells>
                        <TabCells style={{ borderLeft: 'none', cursor: 'pointer', visibility: 'hidden' }}></TabCells>
                        <TabCells style={{ borderLeft: 'none', cursor: 'pointer', visibility: 'hidden' }}></TabCells>
                      </>
                    )}
                    <TabCells>{this.transformService(deal.service)}</TabCells>
                    <TabCells>{deal.service_provider}</TabCells>

                    <TabCells>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Button data-test-id="withdraw" onClick={() => this.withdrawHandler(deal.service_id!)} disabled={this.checkDisablitiy(deal.status, 'withdraw')} variant="text"><Typography style={{ textTransform: 'none', fontSize: '14px', fontWeight: 700, fontFamily: 'Roboto', color: this.checkDisablitiy(deal.status, 'withdraw') ? '#C5CBC9' : '#013D4F' }}>Withdraw</Typography></Button>
                        <Button data-test-id="hirenew" onClick={() => this.hirenewHandler(deal.states, deal.service_id!)} disabled={this.checkDisablitiy(deal.status, 'hirenew')} variant="text"><Typography style={{ textTransform: 'none', color: this.checkDisablitiy(deal.status, 'hirenew') ? '#C5CBC9' : '#4FC8EC', fontSize: '14px', fontWeight: 700, fontFamily: 'Roboto', }}>Hire New</Typography></Button>
                      </Box>
                    </TabCells>
                  </StyledTableRow>
                );
              })}
              {this.renderNoDeals(filteredDeals.length)}

            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    )
  }

  TableServiceProviderOne = () => {
    return (
      this.state.totaldeals > 0 ?
        <TableContainer component={Box} style={{ overflowX: 'auto', width: '100%', }}>
          <Box style={{ overflowX: 'auto', width: '100%' }}>
            <Table aria-label="sticky table" stickyHeader style={{ overflowX: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TabCellHeader style={{ borderLeft: 'none', paddingLeft: '20px' }}> Dealership</TabCellHeader>
                  <TabCellHeader> States </TabCellHeader>
                  <TabCellHeader>Service </TabCellHeader>
                  <TabCellHeader>Payout </TabCellHeader>
                  <TabCellHeader> Date Received</TabCellHeader>
                  <TabCellHeader style={{ borderRight: 'none' }}> Actions</TabCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>

                {this.state.tablePendingDataSp.map((deal, index) => (
                  <StyledTableRow className="tablehover" key={index} >
                    <TabCells style={{ borderLeft: 'none', paddingLeft: '20px' }}> {deal.dealership} </TabCells>
                    <TabCells> {deal.states} </TabCells>
                    <TabCells> {deal.service}  </TabCells>
                    <TabCells> {deal.payout}</TabCells>
                    <TabCells> {deal.dateReceived} </TabCells>
                    <TabCells style={{ borderRight: 'none' }}>
                      <Button data-test-id='acceptdeal' onClick={() => this.acceptOrRejectdeal(deal.selected_service_provider_id, 'accepted')}><Typography style={{ fontSize: '14px', color: '#26BBB3', fontWeight: 700, fontFamily: 'Roboto', textTransform: 'none' }}>Accept</Typography></Button>
                      <Button data-test-id='rejectdeal' onClick={() => this.acceptOrRejectdeal(deal.selected_service_provider_id, 'rejected')}><Typography style={{ fontSize: '14px', fontWeight: 700, fontFamily: 'Roboto', textTransform: 'none', color: '#C43937', }}>Reject</Typography></Button>
                    </TabCells>
                  </StyledTableRow>
                ))}

              </TableBody>
            </Table>
          </Box>
        </TableContainer>
        :
        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '40%' }}>
          <Typography variant="h6" style={{ fontFamily: 'Gotham', color: '#C5CBC9', fontWeight: 300 }}>No deals added yet</Typography>
        </Box>
    )
  }

  TableDealerTwo = () => {
    return (
      <>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <Box className="tableHeadingLayout">
            <Typography variant="h4" className="tableHeadingText">Drafts</Typography>
            <Typography variant="body1" className="sectionSubHeader">Pick back up where you left off on creating these deals</Typography>
          </Box>

          <Button variant="outlined" style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid #013D4F', height: '44px', borderRadius: '8px' }} onClick={() => { this.gotoAlldeals(); localStorage.setItem('dealType', 'draft') }}>
            <Typography variant="body2" className="viewAllButton" >View All</Typography>
            <ArrowForwardIosIcon style={{ color: '#013D4F', height: '14px' }} />
          </Button>
        </Box>
        {this.state.loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"

          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Box} style={{ width: '100%', overflowX: 'auto', }} >
            <Box style={{ width: '100%', overflowX: 'auto' }}>
              <Table stickyHeader aria-label="sticky table" style={{ overflowX: 'auto', }}>
                <TableHead>
                  <TableRow>
                    <TabCellHeader style={{ borderLeft: 'none', }}> Deal ID </TabCellHeader>
                    <TabCellHeader > Customer's Last Name </TabCellHeader>
                    <TabCellHeader > Dealership</TabCellHeader >
                    <TabCellHeader>States </TabCellHeader >
                    <TabCellHeader> Date Created </TabCellHeader>
                    <TabCellHeader style={{ borderRight: 'none' }}>Actions</TabCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {this.state.tableDataDraftDealer.map((deal, index) => (
                    <StyledTableRow key={index} className="tablehover" >
                      <TabCells style={{ borderLeft: 'none', cursor: 'pointer' }} data-test-id='deal-id' onClick={() => { this.navigateToDealDashboard(deal.id) }}>{deal.id}</TabCells>
                      <TabCells> {this.truncateName(deal.customerLastName)} </TabCells>
                      <TabCells >{deal.dealership}</TabCells>
                      <TabCells> {deal.states}</TabCells>
                      <TabCells >{deal.dateCreated}</TabCells >
                      <TabCells style={{ borderRight: 'none' }}>
                        <Button data-test-id="continuedealer" onClick={() => this.handleContinueDealer(deal.id)}>
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ fontFamily: 'Roboto', color: '#4FC8EC', fontSize: '16px', fontWeight: 700, textTransform: 'none' }}>Continue </Typography>
                            <span><ArrowForwardIosIcon style={{ height: '14px', color: '#4FC8EC' }} /></span>
                          </Box>
                        </Button>
                      </TabCells>
                    </StyledTableRow>
                  ))
                  }
                </TableBody>
              </Table>
              {this.renderNoDeals(this.state.tableDataDraftDealer.length)}
            </Box>
          </TableContainer>
        )}
      </>
    )
  }


  SpTableTwo = () => {
    return (
      this.state.totaldeals > 0 ?
        <TableContainer component={Box} style={{ marginTop:'20px', overflowX: 'auto', width: '100%' }} >
          <Box style={{ width: '100%', overflowX: 'auto' }}>
            <Table stickyHeader aria-label="sticky table" style={{ overflowX: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TabCellHeader style={{ borderLeft: 'none' }}> Deal ID</TabCellHeader>
                  <TabCellHeader>Customer's Last Name</TabCellHeader>
                  <TabCellHeader>Dealership</TabCellHeader>
                  <TabCellHeader>States </TabCellHeader>
                  <TabCellHeader>Date Received</TabCellHeader>
                  <TabCellHeader style={{ borderRight: 'none' }}>Service</TabCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tableDataAcceptedSp.map((deal, index) => (
                  <StyledTableRow key={index} className="tablehover">
                    <TabCells style={{ borderLeft: 'none', cursor: 'pointer' }} data-test-id='deal-id' onClick={() => { this.navigateToDealDashboard(deal.id) }}>{deal.id}</TabCells>
                    <TabCells> {this.truncateName(deal.customerLastName)} </TabCells>
                    <TabCells> {deal.dealership}</TabCells>
                    <TabCells>{deal.states}</TabCells>
                    <TabCells> {deal.dateCreated}</TabCells>
                    <TabCells style={{ borderRight: 'none' }}> {deal.service}</TabCells>
                  </StyledTableRow>
                ))}
                  {this.renderNoDeals(this.state.tableDataAcceptedSp.length)}

              </TableBody>
            </Table>
          </Box>
        </TableContainer>
        :
        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '40%' }}>
          <Typography variant="h6" style={{ fontFamily: 'Gotham', color: '#C5CBC9', fontWeight: 300, }}>No deals added yet</Typography>
        </Box>
    )
  }

  TableServiceProviderTwo = () => {
    return (
      <>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box className="tableHeadingLayout">
            <Typography variant="h4" className="tableHeadingText">Recent Deals</Typography>
            <Typography variant="body1" className="sectionSubHeader">Deals you've recently worked on</Typography>
          </Box>

          <Button variant="outlined" style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid #013D4F', height: '44px', borderRadius: '8px', display: this.state.totaldeals > 0 ? 'flex' : 'none' }} onClick={this.gotoAlldeals}>
            <Typography variant="body2" className="viewAllButton" >View All Deals</Typography>
            <ArrowForwardIosIcon style={{ color: '#013D4F', height: '14px', }} />
          </Button>
        </Box>

        {this.state.loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center">
            <CircularProgress />
          </Box>
        ) : this.SpTableTwo()
        }
      </>
    )
  }

  TableDealerThree = () => {
    return (
      <>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box style={{ width: '100%' }}>
            <Typography variant="h4" className="tableHeadingText">Review Service Providers </Typography>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
              <Typography variant="body1" className="sectionSubHeader">Let us know how it was working with these Service Providers</Typography>
              <TablePagination
                component="div"
                data-test-id="pagination"
                count={this.state.totalReviewTableDeal}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                rowsPerPage={this.state.rowsPerPage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                rowsPerPageOptions={[]}
                labelDisplayedRows={({ from, to, count }) => `${this.state.page + 1} of ${Math.ceil(count / this.state.rowsPerPage)}`}
                style={{ color: '#4B4C4B', fontFamily: 'Roboto', fontSize: '14px', fontWeight: 500 }}
              />
            </Box>
          </Box>
        </Box>
        {this.state.loadingReview ? (
          <Box display="flex" justifyContent="center" alignItems="center" >
            <CircularProgress />
          </Box>
        ) :
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TabCellHeader style={{ borderLeft: 'none' }}>Deal ID</TabCellHeader>
                  <TabCellHeader>Customer's Last Name</TabCellHeader>
                  <TabCellHeader>Service Provider</TabCellHeader>
                  <TabCellHeader>Service</TabCellHeader>
                  <TabCellHeader>Date Completed</TabCellHeader>
                  <TabCellHeader style={{ borderRight: 'none' }}>Actions</TabCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.ThirdTableDataDealer.map((row) => (
                  <StyledTableRow key={row.id} >
                    <TabCells style={{ borderLeft: 'none', cursor: 'pointer' }} data-test-id='ThirdTableDataDealer' onClick={() => { this.navigateToDealDashboard(row.id) }}>{row.id} </TabCells>
                    <TabCells> {this.truncateName(row.customerLastName)} </TabCells>
                    <TabCells >{row.provider}</TabCells>
                    <TabCells>{row.service}</TabCells>
                    <TabCells >{row.date}</TabCells>
                    <TabCells style={{ borderRight: 'none' }}>
                      <Button data-test-id="reviewhandle" onClick={() => this.handleReview(row.id)}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body1" style={{ fontFamily: 'Roboto', color: '#4FC8EC', fontWeight: 700, textTransform: 'none' }}>Review Now </Typography>
                          <span><ArrowForwardIosIcon style={{ color: '#4FC8EC', height: '14px', }} /></span>
                        </Box>
                      </Button>
                    </TabCells>
                  </StyledTableRow>
                ))
                }
              </TableBody>
            </Table>
            {this.renderNoDeals(this.state.ThirdTableDataDealer.length)}

          </TableContainer>
        }
      </>
    )
  }

  TableServiceProviderThree = () => {
    return (
      <>
        <Box style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between' }}>
          <Box style={{ width: '100%' }}>
            <Typography variant="h4" className="tableHeadingText">Review Dealers</Typography>
            <Typography variant="body1" className="sectionSubHeader">Let us know how it was working with these Dealers</Typography>
          </Box>
        </Box>
        {this.state.loadingReview ? (
          <Box display="flex" justifyContent="center" alignItems="center" >
            <CircularProgress />
          </Box>
        ) :
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TabCellHeader style={{ borderLeft: 'none' }}>Deal ID</TabCellHeader>
                  <TabCellHeader>Customer's Last Name</TabCellHeader>
                  <TabCellHeader>Dealer</TabCellHeader>
                  <TabCellHeader>Service</TabCellHeader>
                  <TabCellHeader>Date Completed</TabCellHeader>
                  <TabCellHeader style={{ borderRight: 'none' }}> Actions</TabCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.ThirdTableDataSp
                  .map((row) => (
                    <StyledTableRow key={row.id}>
                      <TabCells style={{ borderLeft: 'none', cursor: 'pointer' }} data-test-id='ThirdTableDataSpdealID' onClick={() => { this.navigateToDealDashboard(row.id) }}>{row.id}</TabCells>
                      <TabCells> {this.truncateName(row.customerLastName)} </TabCells>
                      <TabCells>{row.provider}</TabCells>
                      <TabCells>{row.service}</TabCells>
                      <TabCells>{row.date}</TabCells>
                      <TabCells style={{ borderRight: 'none' }}>
                        <Button data-test-id="reviewhandlesp" onClick={() => this.handleReview(row.id)}>
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" style={{ fontFamily: 'Roboto', color: '#4FC8EC', fontWeight: 700, textTransform: 'none' }}>Review Now </Typography>
                            <span><ArrowForwardIosIcon style={{ height: '14px', color: '#4FC8EC' }} /></span>
                          </Box>
                        </Button>
                      </TabCells>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </>
    )
  }

  ChartListOne = (value: number) => {
    const { deleted, in_progress, issue_sent_to_dealer, resolved_in_dealer_review, dealer_flags_incomplete, completed } = this.state.piChartData
    let pieChartData;
    if (value === 0) {
      pieChartData = [
        { label: 'In Progress', count: in_progress.count, percentage: in_progress.percentage, color: '#013D4F', background: "#E6F9FF" },
        { label: 'Issues Sent to Dealer', count: issue_sent_to_dealer?.count, percentage: issue_sent_to_dealer?.percentage, color: '#FCCD0F', background: "#FFFAE6" },
        { label: 'Resolved, In Dealer Review', count: resolved_in_dealer_review?.count, percentage: resolved_in_dealer_review?.percentage, color: '#4B4C4B', background: "#F2F3F2" },
        { label: 'Dealer Flags Incomplete', count: dealer_flags_incomplete?.count, percentage: dealer_flags_incomplete?.percentage, color: '#F05E22', background: "#FDEDE7" },
        { label: 'Completed', count: completed.count, percentage: completed.percentage, color: '#26BBB3', background: "#E6F9FF" },
      ];
    } else {
      pieChartData = [
        { label: 'In Progress', count: in_progress.count, percentage: in_progress.percentage, color: '#013D4F', background: "#E6F9FF" },
        { label: 'Completed', count: completed.count, percentage: completed.percentage, color: '#26BBB3', background: "#E6F9FF" },
        { label: 'Deleted', count: deleted?.count, percentage: deleted?.percentage, color: '#f08080', background: "#FEE2E2" },
      ];
    }



    return (
      <List style={{ width: '100%' }}>
        {pieChartData.map((item, index) => (
          <ListItem
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'white',
              marginBottom: '8px',
              borderRadius: '8px',
            }}
          >
            <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Box>
                <Typography variant="body2" style={{ color: 'rgba(197, 203, 201, 1)', fontFamily: 'Roboto', fontWeight: '500' }}>{item.label}</Typography>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h5" style={{ color: 'rgba(75, 76, 75, 1)', fontFamily: 'Roboto', fontWeight: '500', }}>{item.count}</Typography>
                <Box style={{ backgroundColor: item.background, borderRadius: '26px', width: '54px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="body2" style={{ color: item.color, textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500' }}>
                    {item.percentage}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    )
  }

  ReviewsOne = () => {
    return (
      <>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box style={{ backgroundColor: 'rgba(240, 250, 250, 1)', height: '25px', display: 'flex', alignItems: 'center' }}>
              <ThumbUpAltOutlinedIcon style={{ height: '14px', color: 'rgba(75, 76, 75, 1)' }} /> <span style={{ fontSize: '12px', color: 'rgba(75, 76, 75, 1)' }}>{this.state.likes}</span>
              <ThumbDownAltOutlinedIcon style={{ height: '14px', color: 'rgba(75, 76, 75, 1)', marginLeft: '10px' }} /><span style={{ fontSize: '12px', color: 'rgba(75, 76, 75, 1)' }}>{this.state.dislikes}</span>
            </Box>
            <Typography style={{ marginLeft: '10px', fontSize: '12px', fontWeight: 500, fontFamily: 'Roboto', color: 'rgba(75, 76, 75, 1)', textDecoration: 'underline', textDecorationStyle: 'dashed' }}>{this.state.totalReviews} Reviews</Typography>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center'}}>
            <ThumbUpAltOutlinedIcon style={{ height: '14px', color: 'rgba(75, 76, 75, 1)' }} />
            <Typography variant="body2" className="sectionSubHeader500" style={{ whiteSpace: 'nowrap' }}>{this.getPositivePercentage(this.state.likes, this.state.totalReviews)}% Positive</Typography>
          </Box>
        </Box>

        <List>
          {this.state.reviewsReceived.map((review) => (
            <ListItem key={review.id} alignItems="flex-start" style={{ borderBottom: '1px solid #f0f0f0', padding: '20px 0', }}>
              <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', }}>
                <Box>
                  <Box style={{ display: 'flex' }}>
                    <ListItemAvatar>
                      <Avatar variant="square" alt={review.name} src={review.avatar} style={{ width: '50px', borderRadius: '8px', height: '50px', }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box display="flex" alignItems="center" justifyContent="space-between" >
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="subtitle2" className="reviewName">{review.name}</Typography>
                            <span style={{ backgroundColor: 'rgba(240, 250, 250, 1)', borderRadius: '5px' }}>
                              {review.rating ? <ThumbUpAltOutlinedIcon style={{ color: 'rgba(75, 76, 75, 1)', height: '15px', }} /> : <ThumbDownAltOutlinedIcon style={{ color: 'rgba(75, 76, 75, 1)', height: '15px', }} />}
                            </span>
                          </Box>

                          <Typography variant="body2" className="documentText500">
                            {review.date}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <>
                          <Typography variant="body1" className="documentText">
                            {review.company}
                          </Typography>
                        </>
                      }
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography variant="caption" style={{ lineHeight: "28px" }} className="sectionSubHeader">
                    {review.text}
                  </Typography>
                </Box>
              </Box>
            </ListItem>
          ))
          }
        </List>
      </>
    )
  }

  ReviewsTwo = () => {
    return (
      <>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box style={{ display: 'flex', alignItems: 'center'}}>
            <Box style={{ backgroundColor: 'rgba(240, 250, 250, 1)', height: '25px', display: 'flex', alignItems: 'center' }}>
              <ThumbUpAltOutlinedIcon style={{ height: '14px', color: 'rgba(75, 76, 75, 1)' }} /> <span style={{ fontSize: '12px', color: 'rgba(75, 76, 75, 1)' }}>{this.state.likesGiven}</span>
              <ThumbDownAltOutlinedIcon style={{ height: '14px', color: 'rgba(75, 76, 75, 1)', marginLeft: '10px' }} /><span style={{ fontSize: '12px', color: 'rgba(75, 76, 75, 1)' }}>{this.state.dislikesGiven}</span>
            </Box>
            <Typography style={{ marginLeft: '10px', fontSize: '12px', fontWeight: 500, fontFamily: 'Roboto', color: 'rgba(75, 76, 75, 1)', textDecoration: 'underline', textDecorationStyle: 'dashed' }}>{this.state.totalReviewsGiven} Reviews</Typography>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center'}}>
            <ThumbUpAltOutlinedIcon style={{ height: '14px', color: 'rgba(75, 76, 75, 1)' }} />
            <Typography variant="body2" className="sectionSubHeader500" style={{ whiteSpace: 'nowrap' }} >{this.getPositivePercentage(this.state.likesGiven, this.state.totalReviewsGiven)}% Positive</Typography>
          </Box>
        </Box>

        <List>
          {this.state.reviewsGiven.map((review) => (
            <ListItem key={review.id} alignItems="flex-start" style={{ padding: '20px 0', borderBottom: '1px solid #f0f0f0' }}>
              <Box style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <Box>
                  <Box style={{ display: 'flex' }}>
                    <ListItemAvatar>
                      <Avatar variant="square" alt={review.name} src={review.avatar} style={{ width: '50px', height: '50px', borderRadius: '8px' }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="subtitle2" className="reviewName">{review.name}</Typography>
                            <span style={{ backgroundColor: 'rgba(240, 250, 250, 1)', borderRadius: '5px' }}>
                              {review.rating ? <ThumbUpAltOutlinedIcon style={{ height: '15px', color: 'rgba(75, 76, 75, 1)' }} /> : <ThumbDownAltOutlinedIcon style={{ height: '15px', color: 'rgba(75, 76, 75, 1)' }} />}
                            </span>
                          </Box>

                          <Typography variant="body1" className="documentText">
                            {review.date}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <>
                          <Typography variant="body1" style={{ color: 'rgba(197, 203, 201, 1)', fontFamily: 'Roboto', fontSize: '16px', fontWeight: 500 }}>
                            {review.company}
                          </Typography>
                        </>
                      }
                    />
                  </Box>


                </Box>

                <Box>
                  <Typography variant="caption" style={{ lineHeight: "28px" }} className="sectionSubHeader">
                    {review.text}
                  </Typography>
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </>
    )
  }


  HeaderDealSelect = () => {
    return (
      <FormControl
        variant="outlined"
        style={{ width: '100%', maxWidth: '200px', padding: '0', marginRight: '15px' }}
      >
        <style>
          {`
        .MuiSelect-outlined.MuiSelect-outlined{
          padding:5px;
        }
        `}
        </style>
        <CustomSelect
          className="selecttest"
          data-test-id="selectoption"
          labelId="contactus-reason-label"
          id="contactus-reason"
          value={this.state.dealer || ""}
          displayEmpty
          onChange={this.handleSelectUserDeal}
          IconComponent={ExpandMoreIcon}
          disabled={this.state.optionForDealer.length <= 1}
          style={{
            borderRadius: '12px',
            paddingLeft: '8px',
            minHeight: '45px',
            minWidth: '200px'
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                maxHeight: '300px',
                overflowY: 'auto',
                borderRadius: '8px',
              },
            },
          }}
          renderValue={(selected) => {
            const selectedDealer = this.state.optionForDealer.find(
              (option) => option.dealership_name === selected
            );
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={this.findavatarsrc(selectedDealer)}
                  style={{ marginRight: '8px', height: '35px', width: '35px' }}
                />
                <Typography style={{
                  color: 'rgba(1, 61, 79, 1)', fontSize: '14px', fontWeight: 500, fontFamily: 'Roboto',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '110px',
                }}>
                  {this.finddealername(selectedDealer)}
                </Typography>

              </div>
            );
          }}
        >
          {this.state.optionForDealer.map((option, index) => (
            <MenuItem key={index} data-test-id={`menu-item-${index}`} value={option.dealership_name} style={{ height: '60px', backgroundColor: this.state.dealer === option.dealership_name ? '#E8F8FD' : 'transparent', maxWidth: '190px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={option.dealership_logo}
                  style={{ marginRight: '8px' }}
                />
                <span style={{ color: 'rgba(1, 61, 79, 1)', fontSize: '16px', fontWeight: 300 }}>
                  {option.dealership_name}
                </span>
              </div>
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    )
  }

  headerr = () => {
    return (
      <Box sx={webStyles.header}>
        <Box sx={webStyles.headerLeftSide}>
          <HamburgerMenu navigation={this.props.navigation} id={""} data-test-id="hamburgerMenuTestId" />
          {this.HeaderDealSelect()}
        </Box>


        <Box sx={webStyles.headerRightSide}>
          <Box className="step-3" style={{ display: (this.state.role === 'dealer_admin' || this.state.role === 'dealer') ? 'block' : 'none' }}>
            <CreateDealButton fullWidth={false} data-test-id="mainbtnn" navigation={this.props.navigation} dealerships={this.state.dealerships} savedDeals={this.state.saveddeals}>Create Deal</CreateDealButton>
          </Box>
          <Box>
            <Chat navigation={this.props.navigation} id={""} role={this.state.role} />
          </Box>
          <Box
            data-test-id=""
            sx={webStyles.notificationsButton}
          >
            <Pushnotifications navigation={this.props.navigation} id="" />
          </Box>

          <ProfileDropdown navigation={this.props.navigation} id={""} data-test-id="profildDropdownTestId" />

        </Box>
      </Box>
    )
  }

  cards = () => {
    if(this.state.loading){
      return <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100"
        >
          <CircularProgress />
        </Box>
    }
    if (this.state.role === 'dealer_admin' || this.state.role === 'dealer') {
      return (
        <Grid
          container
          style={{
            marginTop: '20px',
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            width: '100%',
            gap: '8.25px'
          }}
          className="layoutBox"
        >
          {[
            {
              title: 'ONGOING DEALS',
              value: this.state.homepage_ongoing_deals,
            },
            {
              title: 'IN PROGRESS DEALS',
              value: this.state.homepage_inprogress_deals,
            },
            {
              title: 'NO PROVIDER ASSIGNED',
              value: this.state.homepage_no_proivder_assinged_deals,
            },
            {
              title: 'NEEDS YOUR REVIEW',
              value: this.state.homepage_in_dealer_review_deals,
            },
            {
              title: 'ISSUES SENT TO DEALER',
              value: this.state.homepage_issue_sent_deals,
            },
          ].map((item, index) => (
            <Grid
              item
              key={index}
              style={webStyles.cardDeals}
              className="cardBox"
            >
              <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                <StyledTypo >{item.title}</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
              </Box>
              <StyledTypoDeals>{item.value}</StyledTypoDeals>
            </Grid>
          ))}
        </Grid>

      )
    }
    else {
      const {totalAcceptedToday} = this.state;
      return (
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          marginTop="20px"
          data-test-id="spcardbox"
        >
          <Box
            flex="1 1 150px"
            margin="5px"
            padding="15px"
            alignContent="center"
            border="1px solid rgba(240, 250, 250, 1)"
            borderRadius="8px"
            boxSizing="border-box"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <StyledTypo variant="h6">ACCEPTED DEALS</StyledTypo>
              <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <StyledTypoDesc variant="body1">{this.state.homepage_accepted_deals}</StyledTypoDesc>
              <Box
                style={{
                  backgroundColor: '#E6F9FF',
                  borderRadius: '16px',
                  height: '26px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '4px 7px'
                }}
              >
                <Typography style={{ color: '#013D4F', fontWeight: 500, fontSize: '12px' }}>
                  {totalAcceptedToday} New Deals
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            flex="1 1 150px"
            margin="5px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            padding="15px"
            border="1px solid rgba(240, 250, 250, 1)"
            borderRadius="8px"
            boxSizing="border-box"
            alignContent="center"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <StyledTypo variant="h6">IN PROGRESS DEALS</StyledTypo>
              <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc variant="body1">{this.state.homepage_inprogress_deals}</StyledTypoDesc>
          </Box>

          <Box
            flex="1 1 150px"
            margin="5px"
            padding="15px"
            border="1px solid rgba(240, 250, 250, 1)"
            alignContent="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            borderRadius="8px"
            boxSizing="border-box"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <StyledTypo variant="h6">PENDING DEALS</StyledTypo>
              <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc variant="body1">{this.state.homepage_pending_deals}</StyledTypoDesc>
          </Box>

          <Box
            flex="1 1 150px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            margin="5px"
            padding="15px"
            alignContent="center"
            border="1px solid rgba(240, 250, 250, 1)"
            borderRadius="8px"
            boxSizing="border-box"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" >
              <StyledTypo variant="h6">DEALER FLAGS INCOMPLETE</StyledTypo>
              <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc variant="body1">{this.state.homepage_dealer_flags_incomplete_deals}</StyledTypoDesc>
          </Box>
        </Box>
      )
    }
  }

  dealeraccordianOne = () => {
    const {receivedIssues, issueFilterKey, role} = this.state;
    const filterReceivedIssues = receivedIssues.filter((issue)=>
      issue.deal_id.toString().includes(issueFilterKey) || 
      issue.customer_last_name.includes(issueFilterKey) ||
      issue.service_provider_name.includes(issueFilterKey) ||
      issue.file_name.includes(issueFilterKey) || 
      issue.vin.includes(issueFilterKey)
    );
    return (
      <RightSideAccordition>
        <RightSideAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ boxShadow: 'none' }}
        >
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Box style={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
              <Typography variant="h4" className="tableHeadingText">{this.getHeaderOfIssueAccordian()}</Typography>
              <InfoOutlinedIcon className="iconSize" />
            </Box>

            <Typography variant="caption" className="sectionSubHeader">{this.getSubHeaderOfIssueAccordian()}</Typography>
          </Box>

        </RightSideAccordionSummary>
        <AccordionDetails style={{ maxHeight: '450px', overflowY: 'auto', padding: '0px' }}>
          <Box style={{ width: '100%' }}>
            {(this.state.role === 'dealer_admin' || this.state.role === 'dealer') &&
              <TextField
                data-test-id="search-issue-input"
                value={this.state.issueFilterKey}
                onChange={(e) => this.setState({issueFilterKey: e.target.value})}
                variant="outlined"
                placeholder="Search for deal ID, customer name, provider, or last 6 of VIN..."
                fullWidth
                size="small"
                className="placeHolder"
                style={{ margin: '20px 0px', width: '100%', backgroundColor: 'rgba(241, 244, 243, 0.5)', borderRadius: '12px' }}
                InputProps={{
                  classes: { input: 'placeHolder' },
                  startAdornment: <InputAdornment position="start" style={{ paddingLeft: '10px' }}><SearchIcon style={{ color: 'rgba(152, 154, 152, 1)', height: '20px' }} /></InputAdornment>,
                  style: { borderRadius: '8px', paddingLeft: '5px', height: '52px' },
                }}
              />
            }
            <List>
              {filterReceivedIssues.sort((a, b) => a.days_ago - b.days_ago).map((issue, index) => (
                <div key={index}>
                  <ListItem alignItems="flex-start" style={{paddingLeft: 0}}>
                    <ListItemText
                      primary={
                        <Box>
                          <Typography variant="body2" className="documentText">#{issue.deal_id} - {issue.customer_last_name}</Typography>
                          <Typography variant="subtitle2" className="dealStatusDocument">{issue.file_name}</Typography>
                        </Box>
                      }
                      secondary={
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                          <Typography variant="body2" className="documentText">
                            {issue.days_ago === 0 ? "Today" : `${issue.days_ago} days ago`}
                          </Typography>
                          <Typography variant="body2" className="documentText">{role.includes('dealer') ? issue.service_provider_name : issue.dealership_name}</Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  {index < filterReceivedIssues.length - 1 && <Divider component="li" />}
                </div>
              ))}
            </List>
          </Box>
        </AccordionDetails>
      </RightSideAccordition>
    )
  }

  dealeraccordianTwo = () => {
    return (<RightSideAccordition>
      <RightSideAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ boxShadow: 'none' }}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Typography variant="h4" className="tableHeadingText">Deals by Status</Typography>
          <Typography variant="caption" className="sectionSubHeader" >Number of deals in each status</Typography>
        </Box>

      </RightSideAccordionSummary>
      <AccordionDetails style={{ maxHeight: '600px', overflowY: 'auto', padding: "0px" }}>
        <Box style={{ width: '100%' }}>
          <style>
            {`
           .apexcharts-datalabel-value{
            font-size:36px;
            font-weight:500;
            font-family:Roboto;
            margin-top:15px;
            color:rgba(75, 76, 75, 1);
          }
          `}
          </style>
          <Box style={{ padding: '10%', marginTop: '-7%' }}>
            <Chart data-test-id="ApexCharts" options={this.state.options} series={this.state.series} type="donut" />
          </Box>
          {(this.state.role === 'dealer_admin' || this.state.role === 'dealer') ?
            <>
              <AppBar position="static" color="default" style={{ backgroundColor: 'white', boxShadow: 'none', marginTop: '20px', maxWidth: '390px' }}>
                <Tabs
                  value={this.state.chartTabValue}
                  data-test-id="tabs1"
                  onChange={this.handleChangeChartTab}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                  variant="scrollable"
                  aria-label="full width tabs example"
                  classes={{
                    indicator: 'customIndicator',
                  }}
                >
                  <Tab label="Service Provider Hired" {...this.a11yProps(0)} classes={{ root: 'customTab' }} className="tabText" style={{ fontWeight: '600', color: this.getTabColor(this.state.chartTabValue === 0, '#013D4F', '#C5CBC9') }} />
                  <Tab label="No Service Provider" {...this.a11yProps(1)} classes={{ root: 'customTab' }} className="tabText" style={{ color: this.getTabColor(this.state.chartTabValue === 1, '#013D4F', '#C5CBC9') }} />
                </Tabs>
              </AppBar>
              <style>
                {`
          .customTab {
            color: rgba(1, 61, 79, 1); 
            font-size: 16px;
            font-weight:500;
            font-family:Roboto;
            text-transform:none;
            min-width: auto;
          }

          .Mui-selected.customTab {
            color: rgba(1, 61, 79, 1); 
          }

          .customIndicator {
            background-color:rgba(1, 61, 79, 1);
          }
        `}
              </style>
              <this.TabPanel value={this.state.chartTabValue} index={0}>
                {this.ChartListOne(0)}
              </this.TabPanel>
              <this.TabPanel data-test-id="charttab" value={this.state.chartTabValue} index={1}>
                {this.ChartListOne(1)}
              </this.TabPanel>
            </>
            : this.ChartListOne(0)}
        </Box>
      </AccordionDetails>
    </RightSideAccordition>)
  }

  getTableonedealercondition = () => {
    if (this.state.totaldeals > 0) {
      return (
        <>
          <AppBar position="static" color="default" style={{ marginTop: "25px", zIndex: 0, backgroundColor: 'white', boxShadow: 'none' }}>
            <Tabs
              value={this.state.tabvalue}
              data-test-id="tabs"
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              classes={{
                indicator: 'customIndicator',
              }}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="full width tabs example"
            >
              <Tab label="Ongoing" className="tabText"  {...this.a11yProps(0)} classes={{ root: 'customTab' }} style={{ paddingBottom: '25px', fontWeight: 600, color: this.getTabColor(this.state.tabvalue === 0, '#013D4F', '#C5CBC9') }} />
              <Tab label="Pending Hire" className="tabText" {...this.a11yProps(1)} classes={{ root: 'customTab' }} style={{ paddingBottom: '25px', fontWeight: 600, color: this.getTabColor(this.state.tabvalue === 1, '#013D4F', '#C5CBC9'), marginLeft: '20px' }} />
            </Tabs>
          </AppBar>
          <this.TabPanel value={this.state.tabvalue} index={0}>
            {this.TableDealerOne()}
          </this.TabPanel>
          <this.TabPanel data-test-id="trackingDetailsTab" value={this.state.tabvalue} index={1}>
            {this.TableDealerOneTabtwo()}
          </this.TabPanel>
        </>
      )
    }
    else {
      return (
        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '40%',marginTop:'40px' }}>
          <Typography variant="h6" style={{ fontFamily: 'Gotham', color: '#C5CBC9', fontWeight: 300 }}>No deals created yet</Typography>
          <Box style={{ width: '67%', marginTop: '10px' }}>
            <CreateDealButton fullWidth={true} data-test-id="mainbtnn" navigation={this.props.navigation} dealerships={this.state.dealerships} savedDeals={this.state.saveddeals}>Create Your First Deal Now</CreateDealButton>
          </Box>
        </Box>
      )
    }
  }

  tabelOneSection = () => {
    if (this.state.role === 'dealer_admin' || this.state.role === 'dealer') {
      return (
        <>
          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box className="tableHeadingLayout">
              <Typography variant="h4" className="tableHeadingText">Recent Deals</Typography>
              <Typography variant="body1" className="sectionSubHeader">Deals you've recently worked on</Typography>
            </Box>

            <Button data-test-id="viewlall" variant="outlined" style={{
              border: '1px solid #013D4F', borderRadius: '8px', height: '44px',
              justifyContent: 'center',
              alignItems: 'center',
              display: this.state.totaldeals > 0 ? 'flex' : 'none'
            }} onClick={this.gotoAlldeals}>
              <Typography variant="body2" className="viewAllButton" >View All Deals</Typography>
              <ArrowForwardIosIcon style={{ color: '#013D4F', height: '14px' }} />
            </Button>
          </Box>

          {this.state.loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100"
            >
              <CircularProgress />
            </Box>
          ) : this.getTableonedealercondition()
          }
        </>
      )
    }
    else {
      return (
        <>
          <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Box>
              <Typography variant="h4" style={{ color: '#013D4F', fontWeight: 400, fontFamily: 'Gotham' }}>Pending<span className="pendingdealsp" ></span> Deals</Typography>
              <Typography variant="body1" style={{ color: '#4B4C4B', fontFamily: 'Roboto', fontWeight: 400, maxWidth: '800px' }}>You have the following incoming deal requests. You may accept or reject this deal. Please bear in mind that other providers may be able to accept this deal.</Typography>
            </Box>

            <Button variant="outlined" style={{ border: '1px solid #013D4F', borderRadius: '8px', height: '44px', marginTop: '10px', minWidth: '180px', display: this.state.totaldeals > 0 ? 'flex' : 'none' }} onClick={() => { this.gotoAlldeals(); localStorage.setItem('dealType', 'pending') }}>
              <Typography variant="body1" style={{ color: '#013D4F', fontFamily: 'Roboto', textTransform: 'none', fontWeight: 700 }}>View All Pending</Typography>
              <ArrowForwardIosIcon style={{ color: '#013D4F', height: '14px' }} />
            </Button>
          </Box>
          {this.state.loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center">
              <CircularProgress />
            </Box>
          ) :
            this.TableServiceProviderOne()
          }
        </>
      )
    }
  }

  noreview = () => {
    return (
      <Typography variant="subtitle2" style={{ textAlign: 'center', color: '#C5CBC9', fontFamily: 'Gotham', marginTop: '30px' }}>No reviews added yet</Typography>
    )
  }

  tabelTwoSection = () => {
    if (this.state.role === 'dealer_admin' || this.state.role === 'dealer') {
      return this.TableDealerTwo()
    }
    else {
      return this.TableServiceProviderTwo()
    }
  }

  tabelThreeSection = () => {
    if (this.state.role === 'dealer_admin' || this.state.role === 'dealer') {
      return this.TableDealerThree()
    }
    else {
      return this.TableServiceProviderThree()
    }
  }

  selectAcordianOne = () => {
    if (this.state.role === 'dealer_admin' || this.state.role === 'dealer') {
      return this.dealeraccordianOne()
    }
    else {
      return this.dealeraccordianTwo()
    }
  }

  selectAcordianTwo = () => {
    if (this.state.role === 'dealer_admin' || this.state.role === 'dealer') {
      return this.dealeraccordianTwo()
    }
    else {
      return this.dealeraccordianOne()
    }
  }

  CustomTooltip = (props: TooltipRenderProps) => {
    const {
      closeProps,
      index,
      primaryProps,
      skipProps,
      step,
      tooltipProps,
      size,
    } = props;

    return (
      <div
        className="tooltip__body"
        {...tooltipProps}
        style={{
          backgroundColor: "#000",
          color: "#fff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
          maxWidth: "260px",
          minWidth: '210px',
          position: "relative",
          fontSize: "14px",
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-15px' }}>
          <Typography style={{ color: '#FFFFFF', fontFamily: 'Roboto', fontWeight: 500, fontSize: '14px' }}>{this.state.steps[index].heading}</Typography>

          <Box style={{ display: 'flex', alignItems: 'center' }}>

            <Box style={{ marginRight: '-10px', display: index === 5 ? 'none' : 'block' }}>
              <Typography style={{ color: '#C5CBC9', fontFamily: 'Roboto', fontWeight: 400, fontSize: '10px' }}>{`${index + 1} of ${size - 1}`}</Typography>
            </Box>

            <Button
              className="tooltip__close"
              {...closeProps}
              style={{
                background: "none",
                border: "none",
                color: "#C5CBC9",
                fontSize: '25px',
                cursor: "pointer",
                marginRight: '-20px'
              }}
            >
              &times;
            </Button>
          </Box>
        </Box>

        <Box style={{ marginBottom: "8px" }}>
          <Typography style={{ color: '#C5CBC9', fontFamily: 'Roboto', fontWeight: 500, fontSize: '12px' }}>{step.content}</Typography>
        </Box>

        <Box style={{ display: index === 5 ? 'none' : 'block' }}>
          <Box style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#191A19",
            margin: "8px 0",
          }} />

          <Box style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <Button
              variant="outlined"
              className="tooltip__button"
              {...skipProps}
              style={{
                border: '1px solid white',
                color: "white",
                borderRadius: "8px",
                padding: "8px 16px",
                cursor: "pointer",
                fontSize: "12px",
                fontFamily: 'Roboto',
                fontWeight: 500,
                textTransform: 'none',
                display: index === 4 ? 'none' : 'flex'
              }}
            >
              {index === 0 ? 'Skip' : 'End Tour'}
            </Button>

            <Button
              className="tooltip__button tooltip__button--primary"
              {...primaryProps}
              style={{
                backgroundColor: "#fff",
                color: "#000",
                borderRadius: "8px",
                padding: "8px 16px",
                border: "none",
                cursor: "pointer",
                fontSize: "12px",
                fontFamily: 'Roboto',
                textTransform: 'none',
                fontWeight: 500,
                width: index === 4 ? '100%' : 'auto',
                minWidth: '90px'
              }}
            >
              {this.getbuttontext(index)}
            </Button>
          </Box>
        </Box>
      </div>
    );
  };

  renderNoDeals = (dealsLength: number) => {
    return (
      <>
        {dealsLength === 0 && <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center" style={{height:'100px'}}>
            <Box>
              <Typography variant="h3" style={{fontWeight:500}}>{configJSON.noDeals}</Typography>
            </Box>

          </Grid>

        </Grid>}
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <CustomBox height={'100vh'} style={{ paddingLeft: '120px', width: 'calc(100vw - 125px)' }}>
          <Box>{this.headerr()}</Box>
          <Box>
            {
              !this.state.loading &&
              <>
                <Joyride
                  data-test-id="joyride"
                  steps={this.state.steps}
                  continuous={true}
                  scrollToFirstStep={true}
                  showProgress={true}
                  hideBackButton={true}
                  showSkipButton={true}
                  callback={this.handleJoyrideCallback}
                  disableOverlayClose={true}
                  run={!this.state.isTourOpen}
                  locale={{
                    last: "End Tour",
                    next: "Next",
                  }}
                  floaterProps={{
                    styles: {
                      arrow: {
                        spread: 10,
                        length: 10,
                      }
                    }
                  }}
                  tooltipComponent={this.CustomTooltip}
                  styles={{
                    options: {
                      backgroundColor: "#000",
                      textColor: "#fff",
                      zIndex: 1000,
                      arrowColor: "#000",
                      overlayColor: "transparent",
                    },
                    spotlight: {
                      backgroundColor: "transparent",
                      border: "none",
                    },
                    beacon: {
                      backgroundColor: "#E8F8FD",
                      borderRadius: "50%",
                      border: '2px solid #E8F8FD'
                    },
                    beaconInner: {
                      backgroundColor: "#E8F8FD",
                      borderRadius: "50%",
                      margin: "auto",
                      border: '2px solid #013D4F'
                    },
                    beaconOuter: {
                      backgroundColor: "#E8F8FD",
                      borderRadius: "50%",
                      margin: "auto",
                      border: '2px solid #013D4F'
                    },
                    buttonNext: {
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "8px",
                      padding: "8px 16px",
                    },
                    buttonBack: {
                      display: 'none'
                    },
                    buttonSkip: {
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "8px",
                      padding: "8px 16px",
                    },
                    buttonClose: {
                      color: "#fff",
                    },
                    tooltipContainer: {
                      padding: "16px",
                      backgroundColor: "#000",
                      color: "#fff",
                      borderRadius: "8px",
                      textAlign: "left",
                    },
                    tooltipContent: {
                      padding: "0 8px",
                    },
                  }}
                />
              </>
            }
          </Box>

          <Grid container spacing={2} className="pageLayout">
            <Grid item xs={12} style={{ margin: "28px 0px" }}>
              <Typography variant="h1" style={{ fontFamily: 'Gotham', color: '#4B4C4B', fontWeight: 400 }}>
                Welcome, {this.state.fullname}! <span>&#128075;</span>
              </Typography>

            </Grid>
            <Grid item xs={12} sm={8} className="left side">
              <Grid container style={{ gap: "20px" }}>
                <Grid item className="tableSection">
                  <Box display={'flex'} flexDirection={'column'} style={{ gap: "5px" }}>
                    <Typography variant="h4" className="sectionHeadingText">Deal Stats</Typography>
                    <Typography variant="caption" className="sectionSubHeader">{this.getSubHeader()}</Typography>
                  </Box>
                  {this.cards()}
                </Grid>
                <Grid item className="tableSection">
                  {this.tabelOneSection()}
                </Grid>

                <Grid item className="tableSection">
                  {this.tabelTwoSection()}
                </Grid>

                <Grid item className="tableSection">
                  {this.tabelThreeSection()}
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} className="rightside">
              <Grid container direction="column" style={{ gap: '20px' }}>
               {this.state.role ? <>
                <Grid item>
                  {this.selectAcordianOne()}
                </Grid>

                <Grid item>
                  {this.selectAcordianTwo()}
                </Grid>

                <style>
                  {`
                    .customTabb {
                      color: rgba(1, 61, 79, 1); 
                      font-size: 16px;
                      font-family:Roboto;
                      font-weight:500;
                      text-transform:none;
                      min-width: auto;
                    }

                    .Mui-selected.customTabb {
                      color: rgba(1, 61, 79, 1); 
                    }

                    .customIndicatorr {
                      background-color:rgba(1, 61, 79, 1);
                    }
                  `}
                </style>
                <Grid item >
                  <RightSideAccordition >
                    <RightSideAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ boxShadow: 'none' }}
                    >
                      <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Typography variant="h4" className="sectionHeadingText">Reviews</Typography>
                        <Typography variant="body1" className="sectionSubHeader">Feedback from your deals</Typography>
                      </Box>
                    </RightSideAccordionSummary>
                    <AccordionDetails style={{ maxHeight: '420px', overflowY: 'auto', padding: "0px", paddingRight: '10px' }}>
                      <Box style={{ width: '100%' }}>
                        <AppBar position="static" color="default" style={{ backgroundColor: 'white', boxShadow: 'none', marginTop: '20px', maxWidth: '300px', zIndex: 0 }}>
                          <Tabs
                            value={this.state.reviewTab}
                            onChange={this.handleReviewTab}
                            indicatorColor="primary"
                            textColor="primary"
                            data-test-id="review1"
                            classes={{
                              indicator: 'customIndicatorr',
                            }}
                            aria-label="full width tabs example"
                          >
                            <Tab label="Received" {...this.a11yProps(0)} className="tabText" classes={{ root: 'customTabb' }} style={{ color: this.getTabColor(this.state.reviewTab === 0, '#013D4F', '#C5CBC9') }} />
                            <Tab label="Given" {...this.a11yProps(1)} className="tabText" classes={{ root: 'customTabb' }} style={{ color: this.getTabColor(this.state.reviewTab === 1, '#013D4F', '#C5CBC9') }} />
                          </Tabs>
                        </AppBar>
                        <this.TabPanel value={this.state.reviewTab} index={0}>
                          {(this.state.totalReviews !== null && this.state.totalReviews > 0) ? this.ReviewsOne() : this.noreview()}
                        </this.TabPanel>
                        <this.TabPanel value={this.state.reviewTab} data-test-id="reviewtab" index={1}>
                          {(this.state.totalReviewsGiven !== null && this.state.totalReviewsGiven > 0) ? this.ReviewsTwo() : this.noreview()}
                        </this.TabPanel>
                      </Box>
                    </AccordionDetails>
                  </RightSideAccordition>
                </Grid>
                </> :  <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            data-test-id='loader1'

          >
            <CircularProgress />
          </Box>}
              </Grid>

            </Grid>
          </Grid>
        </CustomBox>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomBox = styled(Box)(({ theme }) => ({

  '& .sectionHeadingText': {
    fontFamily: 'Gotham',
    color: '#013D4F',
    fontWeight: 400,
    lineHeight: '28px'
  },
  '& .sectionSubHeader': {
    fontFamily: 'Roboto',
    color: '#4B4C4B',
    fontWeight: 400,
    lineHeight: '18px'
  }, '& .sectionSubHeader500': {
    fontFamily: 'Roboto',
    color: '#4B4C4B',
    fontWeight: 500,
    lineHeight: '18px'
  },
  '& .tableSection': {
    border: '1px solid #F0FAFA',
    padding: '20px',
    borderRadius: '24px',
    width: '100%',
    boxShadow: "0px 0px 1px 0px #8989895C"
  },
  '& .tableHeadingText': {
    fontFamily: 'Gotham',
    color: '#013D4F',
    fontWeight: 400,
    lineHeight: '19.14px',
    [theme.breakpoints.up(1920)]: {
      lineHeight: '26.8px',

    },
  },
  '& .viewAllButton': {
    color: '#013D4F',
    fontFamily: 'Roboto',
    textTransform: 'none',
    fontWeight: 700,
  },
  '& .tableHeadingLayout': {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column'
  },
  '& .iconSize': {
    width: 20,
    height: 20,
    color: "#013D4F",
    [theme.breakpoints.up(1920)]: {
      width: 20,
      height: 20,


    },
  },
  "& .dealStatusDocument": {
    fontWeight: 500,
    color: 'rgba(75, 76, 75, 1)',
    fontFamily: 'Roboto',
    marginTop: '5px',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  "& .documentText": {
    fontFamily: 'Roboto',
    color: '#C5CBC9',
    fontWeight: 400,
    lineHeight: '18px'
  },
  "& .documentText500": {
    fontFamily: 'Roboto',
    color: '#C5CBC9',
    fontWeight: 500,
    lineHeight: '18px'
  },
  "& .placeHolder": {
    fontFamily: "Roboto",
    color: '#989A98',
    opacity: 1,
    fontSize: '10px',
    [theme.breakpoints.up(1920)]: {
      fontSize: '12px'
    },
  },
  '& .tabText': {
    fontSize: '11px',
    [theme.breakpoints.up(1920)]: {
      fontSize: '16px'
    },
  },
  "& .reviewName": {
    fontWeight: 500,
    color: '#4B4C4B',
    fontFamily: 'Roboto',
    marginTop: '5px',
    width: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  "& .cardBox": {
    [theme.breakpoints.up(1600)]: {
      width: '20%',
    }
  },
  "& .layoutBox": {
    [theme.breakpoints.up(1600)]: {
      justifyContent: 'space-between'
    }
  },
  "& .pageLayout": {
    padding: '0px 27px',
    minWidth: '500px',
    [theme.breakpoints.up(1920)]: {
      padding: '0px 32px'
    }
  }



}));

const CustomSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(240, 250, 250, 1)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(240, 250, 250, 1)',
  },
})

const StyledTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: '#F0FAFA',
  },
  '&>td': {
    textWrap: "nowrap",
  },
  '&>td>button': {
    textWrap: "nowrap",
  }
});

const TabCellHeader = styled(TableCell)({
  fontFamily: 'Roboto',
  color: '#C5CBC9',
  border: '1px solid #F0FAFA', fontWeight: 700, fontSize: '14px',
  backgroundColor: 'white',
  whiteSpace: 'nowrap',
  overflow: 'visible',
  textOverflow: 'clip'
})

const TabCells = styled(TableCell)(({ theme }) => ({
  border: '1px solid #F0FAFA', fontFamily: 'Gotham',
  color: '#191A19', fontWeight: 400,
  fontSize: '12px',
  [theme.breakpoints.up(1920)]: {
    fontSize: '16px'

  }
}))

const StyledTypo = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto',
  fontSize: '12px',
  color: '#989A98',
  fontWeight: 500,
  [theme.breakpoints.up(1920)]: {
    fontSize: '14px',
  },
}));
const StyledTypoDeals = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto',
  fontSize: '18px',
  color: '#4B4C4B',
  fontWeight: 500,
  [theme.breakpoints.up(1920)]: {
    fontSize: '36px',
  },
}));
const StyledTypoDesc = styled(Typography)({
  fontFamily: 'Roboto', color: '#4B4C4B', fontWeight: 500,
  fontSize: '18px',
  [theme.breakpoints.up(1920)]: {
    fontSize: '36px',
  }
})

const MenuItemStyled = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'rgba(240, 250, 250, 1)',
  },
  '&:hover': {
    backgroundColor: 'rgba(240, 250, 250, 1)',
  },

})

interface Styles {
  [key: string]: any;
}
const webStyles: Styles = {
  cardDeals: {
    border: '1px solid #F0FAFA',
    padding: '10px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    gap: '15px'
  },
  container: {
    flexDirection: "column",
    height: "100%",
    display: "flex",
    width: "100%",
  },
  header: {
    flexWrap: "nowrap",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #F0FAFA",
    padding: "12px 16px 12px 32px",
  },

  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    border: "none",
    padding: "unset",
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    gap: "0px",
    alignItems: "center",
  },

  logo: { width: "52px" },

  notificationsButton: {

    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",

    height: "24px",
    width: "24px",
    cursor: "pointer",
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",

    gap: "24px",
    alignItems: "center",
  },

  userAvatar: {

    height: "40px",
    width: "40px",
    borderRadius: "50%"
  },




  buttonText: {
    fontFamily: "Roboto, sans-serif",

    fontSize: "14px",
    color: "#013D4F",
    fontWeight: 500,
  },

  content: {

    marginLeft: "120px",
    width: "100%",
  },

  main: {
    display: "flex",

    position: "relative",
    flexWrap: "nowrap",
  },

}
// Customizable Area End