import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';

export interface Task {
  id: string;
  accountId: string;
  title: string;
  status: string;
  priority: string;
  dealID: string;
  created_at: string;
  updated_at: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  isDealCompletedOrDeleted: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  taskListOption: number,
  tasks: Task[],
  isAddingTask: boolean,
  taskEditting: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DealDashboardTaskListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTaskDataId : string = '';
  apiCreateTaskId: string = '';
  apiEditTaskId: string = '';
  apiDeleteTaskId: string = '';

  getTasksData = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetTaskDataId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_tasks/tasks?deal_id=' + localStorage.getItem("createdDealId")
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  handleChangeTaskListOption = (newValue: number) => {
    this.setState({ taskListOption: newValue });
  }

  handleChangeAddTaskState = () => {
    this.setState({ isAddingTask: !this.state.isAddingTask })
  }

  handleAddTask = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const inputValue = event.target.value;
    
      const webHeader = {
        'Content-Type': 'application/json',
        token: localStorage.getItem('authToken'),
      };
      const webRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCreateTaskId = webRequestMessage.messageId;
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_tasks/tasks'
      );
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(webHeader)
      );
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          task: {
            title: inputValue,
            deal_id : localStorage.getItem("createdDealId")
          },
        })
      );
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
      runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
      this.handleChangeAddTaskState();
      return true;
    }
  }

  updateTask = (entry: [string, any], id: string) => {
    const [key, value] = entry;

    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEditTaskId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_tasks/tasks/' + id
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        task: {
          [key]: value
        },
      })
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }
  handleEditTask = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.setState({
        taskEditting : ""
      })
      this.updateTask(["title", event.target.value], this.state.taskEditting)
      return true;
    }
    const index = this.state.tasks.findIndex(item => item.id === this.state.taskEditting);
    const updatedTasks = [...this.state.tasks];
    updatedTasks[index] = { ...updatedTasks[index], title: event.target.value };
    this.setState({ tasks: updatedTasks });
  }

  changeTaskStatus = (id: string) => {
    const index = this.state.tasks.findIndex(item => item.id === id);
    const newStatus = this.state.tasks[index].status === "complete" ? "in_progress" : "complete";
    this.updateTask(["status", newStatus], id);
  }

  editTask = (id: string) => {
    this.setState({
      taskEditting : id
    })
  };

  deleteTask = (id: string) => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteTaskId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_tasks/tasks/' + id
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  handleMoveTaskDown = (id: string) =>{
    const {tasks} = this.state;
    const newPriority = parseInt(tasks.find(task => task.id === id)!.priority) + 1;
    this.updateTask(["priority", newPriority], id);

  }

  handleMoveTaskUp = (id: string)=>{
    const {tasks} = this.state;
    const newPriority = parseInt(tasks.find(task => task.id === id)!.priority) - 1;
    this.updateTask(["priority", newPriority], id);
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getTasksData();
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiGetTaskDataId) {
            this.setState({
              tasks: webResponseJson.data ? webResponseJson.data.map((item: any) => item.attributes) : []
            });
          }
        if (webApiRequestCallId === this.apiEditTaskId || 
          webApiRequestCallId === this.apiDeleteTaskId || 
          webApiRequestCallId === this.apiCreateTaskId){
            this.getTasksData();
          }
      }
    }
    // Customizable Area End
  }
}