import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";


// Customizable Area Start
export type NavObject = TypeNav
export interface TypeNav {
  addListener: Function
  goBack: Function,
  getParam: Function,
  navigate: Function
}

export interface ToggleButtonGroupState {
  selectedButtons: string[];
}

interface ResponseJSON {
  responseJSON: {
    data: {
      id: string;
      type: string;
      attributes: DealAttributes;
    };
  };
}

interface DealAttributes {
  id: number;
  updated_at: string;
  application_type: string;
  registration_type: string;
  vehicle_vin: string;
  vehicle_type: string;
  vehicle_year: string;
  vehicle_weight_empty: string;
  vehicle_weight_gross: string;
  sale_type: string;
  sold_as: string;
  msrp: string;
  tsp: string;
  seller_zip: string;
  owner_first_name: string;
  owner_last_name: string;
  purchaser_zip: string;
  first_owner: string | null;
  second_owner: string | null;
  make: string | null;
  odometer: string | null;
  body_type: string | null;
  is_draft: boolean;
  deal_documents: any[];
  date_created: string;
  deal_status: string;
  from_state: string;
  to_state: string;
  dealership_detail: DealershipDetail;
  account: Account;
  deal_documents_ids: any[];
  fees_data: FeesData;
  service_provider: string;
  service: string;
  date_accepted: string;
  date_sent: string | null;
  pending_tab_status: string;
  date_cancelled: string | null;
  date_completed: string | null;
}

interface DealershipDetail {
  data: {
    id: string;
    type: string;
    attributes: DealershipAttributes;
  };
}

interface DealershipAttributes {
  dealership_name: string;
  business_credentials: string;
  deal_type: string[];
  created_at: string;
  updated_at: string;
  dealership_users: number;
  addresses: AddressList;
  bank_information: BankInformationList;
  dealership_logo: string | null;
  w9_forms: any[];
}

interface AddressList {
  data: Address[];
}

interface Address {
  id: string;
  type: string;
  attributes: AddressAttributes;
}

interface AddressAttributes {
  address: string;
  address_type: string;
  country: string;
  city: string;
  state: string;
  zipcode: string;
  zipcode_ext: string;
}

interface BankInformationList {
  data: BankInformation[];
}

interface BankInformation {
  id: string;
  type: string;
  attributes: BankAttributes;
}

interface BankAttributes {
  bank_name: string;
  account_number: string;
  bank_routing_number: string;
  branch_name: string;
  name: string;
  phone_number: string;
  job_title: string;
  created_at: string;
  updated_at: string;
  agree_e_sign: boolean;
  authirization_signature: string | null;
  voided_check: string | null;
  full_signature: string | null;
  initial_signature: string | null;
  address: {
    data: Address;
  };
}

interface Account {
  data: {
    id: string;
    type: string;
    attributes: AccountAttributes;
  };
}

interface AccountAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name: string;
  role: string;
  profile_picture: string | null;
  group_created_on: string;
  is_dealership_pending: boolean;
  dealership_data: string | null;
  solo_dealership_id: string | null;
  is_any_dealership: boolean;
  solo_dealership_data: string | null;
  assigned_dealership_ids: string | null;
}

interface FeesData {
  id: number;
  fee_type: string;
  fees: Record<string, any>;
  previous_fees: Record<string, any>;
  fee_amount: string;
  status: string;
  accepted_at: string;
  rejected_at: string | null;
  comment: string | null;
  select_service_provider_id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
  deal_id: number;
  previous_fee_amount: string;
  total_fee: string;
  previous_total_fee: string;
  agree_terms: boolean;
  rejection_subject: string | null;
  rejection_reason: string | null;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: NavObject;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}


interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  fullName: string;
  loginemail: string;
  name: string;
  template_dis: string;
  phoneNumber: string;
  loginpassword: string;
  showPassword: boolean;
  errors: {
    [key: string]: string;
  };
  registrationSuccess: boolean;
  isModalOpen: boolean,
  isModelFuntionCall: boolean,
  isEditBtn: boolean,
  getUserData: [],
  getShowUserData: {
    attributes: {
      comment: string; id: number, name: string, description: string, status: string,
    };
  },
  getUserDataArchive: [],
  isArchiveModalOpen: boolean
  getorganizationsDetails: [],

  //create Quote
  itemName: string,
  itemPrice: string,
  itemQuantity: string,
  discount: string,
  description: string,
  //PUT for Achive
  serialIdforArchive: string,
  //Level 1
  leveOneModal: boolean;
  selectedRowData: {},
  statusForlevelOne: string;
  selectedStatus: string;
  userRole: string;
  userRolelevelOne: string;
  anchorEl: null,
  selectedOption: string,
  isEditModalOpen: boolean,
  isShowModalOpen: boolean,
  isStatusModalOpen: boolean,
  editClickDataLeveZero: [],
  itemNameEdit: string,
  itemPriceEdit: string,
  itemQuantityEdit: string,
  discountEdit: string,
  descriptionEdit: string,
  serialIdEdit: number;
  openDialog: boolean;
  total_page: number;
  currentPage: number;
  itemsPerPage: number;
  statusBtn: boolean;
  status: string;
  initials: string,
  profilePhoto: string | null,
  termsAndConditionCheck: boolean,
  submittedConfFees: boolean,
  submitReview: boolean,
  rejectedReview: boolean,
  issueResolved: boolean,
  likeButton: boolean | string,
  showTextArea: boolean,
  dealerReviewText: string,
  selectedItems: string[],
  tellUsWhySubject: string,
  tellUsWhyDetails: string,
  id: number | null,
  spName: string,
  lastName: string,
  fromState: string,
  toState: string,
  application: string,
  service: string,
  vehicleType: string,
  prevTotalFee: string,
  totalFee: string,
  prevRegFee: string,
  regFee: string,
  dealId: string,
  serviceFee: string,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ApiData {
  header?: Record<string, string>;
  contentType?: string;
  method: string;
  endPoint: string;
  body?: LoginPayload | TemplateData | Payload | TemplateIdData | FormData// Adjust the type of body as needed
  type?: string;
}

interface LoginPayload {
  data: {
    email: string;
    password: string;
  };
}

interface TemplateData {
  template: {
    name: string;
    description: string;
  };
}

interface Payload {
  template: {
    name: string;
    description: string;
    kpis_attributes: {
      [key: number]: {
        name: string;
        description: string;
        point: number;
      };
    };


  };
}

interface ChangeEvent {
  target: {
    name: string;
    value: string;
  };
}


interface TemplateIdData {
  status: string;
  comment: string;
}

interface ChangeEvent {
  target: {
    name: string;
    value: string;
  };
}

export interface EventList {
  id: number,
  attributes: {
    name: string,
    description: string,
    status: string,
    comment: string,

  }
}



export default class MultilevelApprovalController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  AttendancDetailsApi: string = "";
  AttendancePostApi: string = "";
  AttendancDetailsArchivedApi: string = "";
  templatesApi: string = "";
  ArchivedDataPutApi: string = "";
  UpdateApiPutApi: string = "";
  StatusDataPutApi: string = "";
  loginPostDeatilsApi: string = "";
  editUpdateLevelZeroApi: string = "";
  //Level 1
  getListApi: string = "";
  getShowListApi: string = "";
  levelOneTestingApi: string = "";
  getCompleteDealCallId: string = "";
  postDealerReviewCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      fullName: "",
      loginemail: "",
      name: "",
      template_dis: "",
      phoneNumber: "",
      loginpassword: "",
      showPassword: false,
      errors: {},
      registrationSuccess: false,
      isModalOpen: false,
      isModelFuntionCall: false,
      isEditBtn: false,
      getUserData: [],
      getShowUserData: {
        attributes: {
          id: 0,
          name: '',
          description: '',
          status: '',
          comment: ''
        }
      },
      getUserDataArchive: [],
      isArchiveModalOpen: false,
      getorganizationsDetails: [],
      itemName: '',
      itemPrice: '',
      itemQuantity: '',
      discount: '',
      description: '',
      //Put for Archive data
      serialIdforArchive: '',
      //Level 1
      leveOneModal: false,
      selectedRowData: '',
      statusForlevelOne: '',
      selectedStatus: 'under_review',
      userRole: '',
      userRolelevelOne: '',
      anchorEl: null,
      selectedOption: "",
      isEditModalOpen: false,
      isStatusModalOpen: false,
      isShowModalOpen: false,
      editClickDataLeveZero: [],
      itemNameEdit: '',
      itemPriceEdit: '',
      itemQuantityEdit: '',

      discountEdit: '',
      descriptionEdit: '',
      serialIdEdit: 0,
      openDialog: false,
      total_page: 0,
      itemsPerPage: 5,
      currentPage: 1,
      statusBtn: false,
      status: 'pending',
      initials: '',
      profilePhoto: null,
      termsAndConditionCheck: false,
      submittedConfFees: false,
      submitReview: false,
      rejectedReview: false,
      issueResolved: false,
      likeButton: "",
      showTextArea: false,
      dealerReviewText: "",
      selectedItems: [],
      tellUsWhySubject: "",
      tellUsWhyDetails: "",
      id: null,
      spName: "",
      lastName: "",
      fromState: "",
      toState: "",
      application: "",
      service: "",
      vehicleType: "",
      prevTotalFee: "",
      totalFee: "",
      prevRegFee: "",
      regFee: "",
      dealId: "",
      serviceFee: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }



  // Customizable Area Start

  handleTermsAndCondition = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    this.setState({
      termsAndConditionCheck: isChecked
    });
  }

  setSubmittedTrue = () => {
    this.setState({
      submittedConfFees: true,
    })
  }

  setSubmitReview = () => {
    this.postDealerReviewRequest()
    this.setState({
      submittedConfFees: false,
      submitReview: true,
    })
  }

  navigateToDashboard = () => {
    const inviteUserTxtData = new Message(getName(MessageEnum.NavigationMessage));
    inviteUserTxtData.addData(getName(MessageEnum.NavigationTargetMessage),
      "Dashboard");
    inviteUserTxtData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { view: "HOME" });
    inviteUserTxtData.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage("MergeEngineUtilities", inviteUserTxtData);
  }

  navigateToDealDashboard = () => {
    const inviteUserTxtData = new Message(getName(MessageEnum.NavigationMessage));
    inviteUserTxtData.addData(getName(MessageEnum.NavigationTargetMessage), "ProjectTemplatesDealDashboard");
    inviteUserTxtData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(inviteUserTxtData);
    localStorage.setItem('createdDealId', localStorage.getItem('completeDealId')!);
  }

  setIssueResolved = () => {
    this.setState({
      rejectedReview: false,
      issueResolved: true,
    })
  }

  setRejectedReview = () => {
    this.setState({
      rejectedReview: true,
    })
  }

  cancelRejectedReview = () => {
    this.setState({
      rejectedReview: false,
    })
  }

  handleTellUsWhySubject = (event: any) => {
    this.setState({
      tellUsWhySubject: event.target.value
    })
  }

  handleTellUsWhyDetails = (event: any) => {
    this.setState({
      tellUsWhyDetails: event.target.value
    })
  }

  handleTellUsWhySentCond = () => {
    return this.state.tellUsWhySubject.length > 0 && this.state.tellUsWhyDetails.length > 0
  }

  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({
      likeButton: value === "true",
      showTextArea: true,
    });
  };

  handleDealerReviewTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    this.setState({
      dealerReviewText: value,
    })
  }

  handleButtonClick = (item: string) => {
    this.setState((prevState: Readonly<S>) => {
      const { selectedItems } = prevState;
      if (selectedItems.includes(item)) {
        return { selectedItems: selectedItems.filter((i) => i !== item) };
      } else {
        return { selectedItems: [...selectedItems, item] };
      }
    });
  };

  async componentDidMount() {
    const reviewValue= await getStorageData('reviewType')
    if(reviewValue==="delaerShipReview"){
      this.setState({ submittedConfFees: true })
    }
    this.getContactInfo();
  }

  async componentDidUpdate() {
    console.log(this.state.dealerReviewText, "like")
  }

  async componentWillUnmount() {
    removeStorageData('reviewType')
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      if (sessionData) {
        setStorageData("reviewType","delaerShipReview")
          this.setState({ submittedConfFees: sessionData.value === "delaerShipReview" })
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (apiCallId === this.getCompleteDealCallId) {
        const serviceString = responseJson.data.attributes.service;
        const regex = /\"([^\"]+)\"=>\"([^\"]+)\"/;
        const match = serviceString.match(regex);
        const serviceValue = match ? match[1] : "";
        const serviceFee = match ? match[2] : "";
        this.setState({
          id: responseJson.data.attributes.id,
          spName: responseJson.data.attributes.service_provider.data.attributes.business_name,
          lastName: responseJson.data.attributes.owner_last_name,
          fromState: responseJson.data.attributes.from_state,
          toState: responseJson.data.attributes.to_state,
          application: responseJson.data.attributes.application_type,
          service: serviceValue,
          vehicleType: responseJson.data.attributes.vehicle_type,
          prevTotalFee: responseJson.data.attributes.fees_data.previous_total_fee,
          totalFee: responseJson.data.attributes.fees_data.total_fee,
          prevRegFee: responseJson.data.attributes.fees_data.previous_fee_amount,
          regFee: responseJson.data.attributes.fees_data.fee_amount,
          dealId: responseJson.data.attributes.id,
          serviceFee: serviceFee,
        })
      }
    }
  }

  navigateToTermsConditions = () => {
    const termsConditionsText = new Message(getName(MessageEnum.NavigationMessage));
    termsConditionsText.addData(getName(MessageEnum.NavigationTargetMessage), "TermsConditions");
    termsConditionsText.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(termsConditionsText);
  }

  getContactInfo = async () => {
    let tokenn = await getStorageData("authToken");
    const Webheader = { "token": tokenn };
    const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCompleteDealCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.completeTheDealEndPoint + localStorage.getItem('completeDealId'));
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(Webheader));
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  postDealerReviewRequest = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let tokenn = await getStorageData("authToken");
    const rrrr = {
      rating: this.state.likeButton,
      categories: this.state.selectedItems.join(", "),
      feedback: this.state.dealerReviewText,
      deal_id: this.state.id,
    }
    const myData = {
      service_provider_review: rrrr,
      service_provider_information_id: 399,
    };
    console.log(this.state.id, "service_provider_information_id");

    const headers = {
      "Content-Type": "application/json",
      "token": tokenn
    };

    this.postDealerReviewCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postDealerReviewEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(myData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}

