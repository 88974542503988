import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Avatar,
  InputAdornment,
  IconButton,
  Modal
} from "@material-ui/core";


import OtpInput from 'react-otp-input';
import { createTheme, styled } from "@material-ui/core/styles";
import { grouppng } from '../../email-account-login/src/assets'
import { vitulogoimage, googleimageicon, banner, usaflag, filledCheckbox, emptyCheckbox } from '../../email-account-registration/src/assets'

import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { isError } from '../../../components/src/Loader.web'
import { GoogleLogin } from 'react-google-login';
import CloseIcon from '@material-ui/icons/Close';
import { StyledTextField } from "../../forgot-password/src/ResetPassword.web";
// Customizable Area End



import EmailAccountRegistrationController, {
  Props,
  configJSON,
} from "./EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});




export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  GoogleHeading = styled(Box)({
    fontWeight: 300,
    color: '#013D4F',
    fontSize: '30px',
    marginBottom: '12px'
  })

  NewBoxx = styled(Box)({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    alignContent: 'start',
    marginBottom: '12px',
    marginTop: '15px',
    '& .agreeText': {
      fontWeight: 500,
      fontSize: '12px',
      color: '#4B4C4B',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      [`@media (min-width:1920px)`]: {
        fontSize: '16px',
      },
    },
    '& .termsText': {
      margin: 0,
      padding: 0,
      fontWeight: 500,
      fontSize: '12px',
      color: '#013D4F',
      textTransform: 'none',
      fontFamily: 'Roboto',
      minWidth: 0,
      [`@media (min-width:1920px)`]: {
        fontSize: '16px',
      },
    }
  })

  GoogleBox = styled(Box)({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    alignContent: 'start',
    marginBottom: '16px',
    marginTop: '14px'
  })

  ValidatePhone = styled(Button)({
    "@media (max-width:469px)": {
      height: '49px',
      paddingTop: '2px',
      marginRight: '5px'
    },
    "@media (min-width:470px)": {
      marginRight: '15px'
    }
  })

  FormControlGoogle = styled(FormControlLabel)({
    "@media (max-width:469px)": {
      marginLeft: '-11px'
    },
    "@media (min-width:470px)": {
      marginLeft: '20px'
    }
  })

  BoxStyled = styled(Grid)({
    "@media (max-width:590px)": {
      width: '450px'
    },
    "@media (min-width:591px) and (max-width:600px)": {
      width: '500px'
    },
    "@media (min-width:601px) and (max-width:959px)": {
      width: '600px'
    },
    "@media (min-width:960px) and (max-width:1100px)": {
      width: '500px'
    },
    "@media (min-width:1101px)": {
      width: '519px'
    },
    '& .inputLoginBox': {
      height: '44px',
      marginBottom: "12px",
      [`@media (min-width:1920px)`]: {
        height: '56px',
      },
      padding: '10px, 24px, 10px, 24px',
      borderRadius: '8px'
    },
  })


  SecondGrid = styled(Grid)({
    "@media (max-width:959px)": {
      display: 'none',
    },
    "@media (min-width:960px)": {
      display: 'block'
    }
  })


  ButtonDbb = styled(Button)({
    '&:disabled': {

      color: '#75D3F0',
      height: '50px',
      backgroundColor: '#F0FAFA',
    },
    '&:enabled': {

      color: 'white',
      backgroundColor: '#4FC8EC',
      height: '50px',
    },

  });

  ButtonGoogle = styled(Button)({
    "@media (max-width:599px)": {
      width: '345px'
    },
    "@media (min-width:600px) and (max-width:959px)": {
      width: '400px'
    },
    "@media (min-width:960px) and (max-width:1100px)": {
      width: '450px'
    },
    "@media (min-width:1101px)": {
      width: '519px'
    }
  })

  Buttondb = styled(Button)({
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 700,
    width: '100%',
    height: 44,
    [`@media (min-width:1920px)`]: {
      height: '56px',
      fontSize: '16px',
    },
    "@media (max-width:599px)": {
      width: '345px'
    },
    "@media (min-width:600px) and (max-width:959px)": {
      width: '400px'
    },
    "@media (min-width:960px) and (max-width:1100px)": {
      width: '450px'
    },
    "@media (min-width:1101px)": {
      width: '519px'
    },
    '&:disabled': {
      color: '#75D3F0',
      backgroundColor: '#F0FAFA', height: '50px',
    },
    '&:enabled': {
      color: 'white',
      backgroundColor: '#4FC8EC', height: '50px',
    },

  });

  ButtondbGoogle = styled(Button)({
    width: '100%',
    minWidth: '250px',
    '&:disabled': {
      color: '#75D3F0',
      backgroundColor: '#F0FAFA',
      height: '50px',
    },
    '&:enabled': {
      color: 'white',
      backgroundColor: '#4FC8EC', height: '50px',
    },
  });

  ScrollViewSection = styled(Grid)({
    opacity: 1,
    '& .inputLoginBox': {
      height: '44px',
      marginBottom: "12px",
      [`@media (min-width:1920px)`]: {
        height: '56px',
      },
      padding: '10px, 24px, 10px, 24px',
      borderRadius: '8px'
    },
    '& .inputLabelIcon': {
      textAlign: 'start',
      marginLeft: '10px',
      padding: '5px',
      height: '18px',
      width: '18px',
      [`@media (min-width:1920px)`]: {
        height: '1em',
        width: '1em',
      },
    },
    '& .passwordVisibilityIcon': {
      width: 18,
      height: 18,
      [`@media (min-width:1920px)`]: {
        width: '1em',
        height: '1em',
      },
    },
  })

  RadioBox = styled(Box)({
    "@media (max-width:960px)": {
      flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginRight: '172px', marginTop: '20px', marginBottom: '20px'
    },
    "@media (min-width:720px)": {

    }
  })

  DealerBox = styled(Box)({
    "@media (max-width:960px)": {
      flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '300px'
    },
    "@media (min-width:720px)": {

    },
    '& .checkBoxText': {
      color: '#4B4C4B',
      fontWeight: 500,
      fontSize: '12px',
      fontFamily: 'Roboto',
      [`@media (min-width:1920px)`]: {
        fontSize: '16px',
      },
    }
  })

  DetailBox = styled(Box)({
    "@media (max-width:500px)": {
      flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '250px'
    },
    "@media (min-width:501px) and (max-width:959px)": {
      flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '300px'
    },
    "@media (min-width:960px) and (max-width:1100px)": {
      flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '300px'
    },
    "@media (min-width:1101px)": {

    },
  })

  BpIcon = styled('span')({
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    boxShadow: 'inset 0 0 0 1px gray, inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#fff',
  });

  BpCheckedIcon = styled(this.BpIcon)({
    backgroundColor: '#4FC8EC',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: 'inset 0 0 0 2px #4FC8EC, inset 0 -2px 0 rgba(16,22,26,.1)',
    '&::before': {
      display: 'block',
      width: '20px',
      height: '20px',
      backgroundImage: 'radial-gradient(#FFFFFF,#FFFFFF 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#4FC8EC',
    },
  });

  NewBox = styled(Box)({
    "@media (max-width:599px)": {
      width: '345px'
    },
    "@media (min-width:599px) and (max-width:959px)": {
      width: '400px'
    },
    "@media (min-width:960px) and (max-width:1100px)": {
      width: '345px'
    },
    "@media (min-width:1101px)": {
      width: '515px'
    },
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .logintivitu': {
      fontWeight: 500,
      fontSize: '12px',
      color: '#013D4F',
      margin: '12px 0px',
      textTransform: 'none',
      fontFamily: 'Roboto',
      [`@media (min-width:1920px)`]: {
        fontSize: '16px',
      },
    }
  })

  StyledModalBox = styled(Box)({
    maxWidth: '512px',
    border: '1px solid #FFFFFF',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
    backgroundColor: '#FFFFFF'
  })

  errorBox = () => {
    if (this.state.photoErrorMessage) {
      return (
        <Typography style={{ color: '#C43937', marginTop: '7px', marginLeft: '4px', fontSize: '12px' }}>
          {this.state.photoErrorMessage}
        </Typography>
      );
    } else {
      return null;
    }
  };

  googleErrorBox = (value: string, property: string) => {
    return (<Typography style={{ color: '#DC2626', fontSize: '12px', marginTop: '2px', marginBottom: '7px', textAlign: property == 'left' ? 'left' : 'center' }}>{value ? value : null}</Typography>)
  }

  successMessageBox = () => {
    return (
      (this.state.phoneConfirmed && !this.state.gotGphone) && (
        <Box>
          <Typography style={{ fontSize: '12px', color: '#26BBB3', marginTop: '10px', fontFamily: 'Roboto' }}>
            Phone number verified.
          </Typography>
        </Box>
      )
    );
  };


  googleSignupModal = () => {
    return (

      <Modal
        open={this.state.openModal}
        onClose={this.handleCloseGoogle}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <this.StyledModalBox>
          <Box display={'flex'} justifyContent={'end'} style={{ width: '104%' }}>
            <Button data-test-id="closebtn" onClick={this.handleCloseGoogle}><CloseIcon /></Button>
          </Box>

          <Box>

            <Typography style={{ color: '#013D4F', fontSize: '30px', fontWeight: 300, fontFamily: 'Gotham light' }}>{this.getGoogleHeader()}</Typography>
            <Typography className="gothamText" style={{ fontWeight: 300, color: '#4B4C4B', fontSize: '18px' }}>
              {this.getGoogleSubHeader()}
              <span style={{ color: '#013D4F', fontFamily: 'Roboto', fontWeight: 500 }}>{this.getPhonedisability() && this.formatPhoneNumber(this.state.googlePhone)}</span>
            </Typography>
          </Box>

          {(!this.state.gotGphone) &&
            <Box >
              <Box style={{ display: this.getDisplay(!this.state.otpSent || this.state.phoneConfirmed), marginTop: '24px', position: "relative", flexDirection: 'row', alignItems: 'center', border: this.isErrorborder(true, this.state.receivedGooglePhoneError, '1px solid #C43937', '1px solid #C5CBC9'), minHeight: '56px', padding: '10px, 24px, 10px, 24px', borderRadius: '8px', justifyContent: 'space-between' }}>
                <Box position="end" style={{ cursor: "pointer", display: "flex", alignItems: "center", position: 'absolute', top: 3, left: 21 }}>
                  <img src={usaflag} style={{ height: "14px", width: "18px" }} />
                  <p style={{ marginLeft: "8px", fontSize: "16px", color: this.handleColor(this.state.googlePhone) }}>+</p>
                  <Typography style={{ fontSize: "16px", color: this.handleColor(this.state.googlePhone), marginTop: '1px' }}>1</Typography>
                  <p style={{ marginLeft: "8px", marginRight: "10px", color: this.handleColor(this.state.googlePhone), fontSize: "16px" }}>|</p>
                </Box>
                <TextField
                  data-test-id="phoneGoogle"
                  type="text"
                  placeholder="Mobile Number"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      border: 'none',
                      height: '',
                      paddingTop: '6px'
                    },
                  }}
                  inputProps={{
                    maxLength: 10,
                    style: { borderRadius: "8px", marginLeft: '90px' }
                  }}
                  InputLabelProps={{
                    style: { marginLeft: '80px', color: 'gray' }
                  }}
                  onChange={this.handlePhoneChangeGoogle}
                  value={this.state.googlePhone}

                  disabled={this.checkDisability(Boolean(!this.state.receivedGooglePhoneError), this.state.otpSent)}
                />

                <this.ValidatePhone data-test-id="validateGoogle" disabled={this.state.googlePhone.length < 10} onClick={this.handlePhoneVlidate} variant="outlined" style={{ border: `1px solid ${this.getColor(this.state.googlePhone.length < 10, 'gray', '#013D4F')}`, width: '150px', borderRadius: '8px', paddingLeft: '10px', paddingRight: '10px', display: this.getDisbalitiofButton((Boolean(!this.state.receivedGooglePhoneError) && this.state.otpSent)) }}>
                  <Typography style={{ color: this.getColor(this.state.googlePhone.length < 10, 'gray', '#013D4F'), fontSize: '16px', fontWeight: 700, fontFamily: 'Roboto', textTransform: 'none' }}>Validate Phone</Typography>
                </this.ValidatePhone>
              </Box>

              <Box style={{ width: '100%', display: (!this.state.otpSent) ? 'flex' : 'none', justifyContent: 'space-between' }}>
                <Box>
                  {this.googleErrorBox(this.state.receivedGooglePhoneError, 'left')}
                </Box>
              </Box>

              {this.checkDisability(this.state.openOtp, !this.state.phoneConfirmed) &&
                <Box>
                  <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', minWidth: '300px' }}>
                    <OtpInput
                      data-test-id="otpGoogle"
                      value={this.state.googleOtp}
                      onChange={this.handleChange}
                      numInputs={4}
                      inputType="tel"
                      inputStyle={{
                        border: `0.2px solid ${this.isErrorborder(true, this.state.receivedGoogleOtpError, '#C43937', '#C5CBC9')}`,
                        height: '65px',
                        width: '65px',
                        fontSize: '30px',
                        borderRadius: '8px',
                        fontWeight: 300,
                        color: '#4B4C4B',
                        textAlign: 'center',
                        margin: '8px'
                      }}
                      renderInput={(props) => <input {...props} />}
                    />
                  </Box>
                  {this.googleErrorBox(this.state.receivedGoogleOtpError, 'center')}

                  <this.ButtonDbb data-test-id="verifyGoogleOtp" onClick={this.verifyOtp} disabled={this.state.googleOtp.length < 4} fullWidth variant="contained" style={{ boxShadow: 'none' }}>
                    <Typography style={{ fontSize: '16px', textTransform: 'none', color: this.getColor(this.state.googleOtp.length < 4, '#75D3F0', 'white'), fontFamily: 'Roboto', fontWeight: 700 }}>
                      Verify
                    </Typography>
                  </this.ButtonDbb>

                  <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
                    <Button onClick={this.handleCloseGoogle}>
                      <Typography style={{ fontSize: '16px', textTransform: 'none', color: '#013D4F', fontFamily: 'Roboto', fontWeight: 500 }}>
                        Not you?</Typography>
                    </Button>

                    <Box display="flex" alignItems="center">
                      <Typography style={{ fontWeight: 500, fontFamily: 'Roboto', color: '#4B4C4B', padding: '0px', fontSize: '16px', textTransform: 'none' }}>Didn't receive OTP? </Typography>
                      <Button variant="text" disabled={this.state.timer > 0 || this.state.count === 2} onClick={this.resendOtp} data-test-id="resendotp">
                        <Typography data-test-id="timerDisplay" style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: 500, color: (this.state.timer > 0 || this.state.count === 2) ? '#4B4C4B' : '#013D4F', textTransform: 'none' }}>Resend <span style={{ display: this.state.timer > 0 ? '' : 'none' }}>in {this.formatTime(this.state.timer)}</span></Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              }
            </Box>
          }

          {this.successMessageBox()}

          <Box style={{ display: this.getDisplaytwo(!this.state.otpSent || this.state.phoneConfirmed) }}>

            <this.DealerBox style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', justifyContent: 'start', width: '100%' }} >
              <FormControl component="fieldset">
                <RadioGroup value={this.state.googleRoleId} data-test-id="radioGoogle" row aria-label="typeofuser" name="typeofuser" onChange={(event) => { this.handleRadioGoogle(event) }}>
                  <FormControlLabel
                    value="1"
                    control={<Radio
                      color="default"
                      disableRipple
                      checkedIcon={<this.BpCheckedIcon />}
                      icon={<this.BpIcon />}
                      {...this.props}
                    />}
                    label={<Typography component={'p'} className="checkBoxText">Dealer</Typography>} />
                  <this.FormControlGoogle
                    value="2"
                    control={<Radio
                      disableRipple
                      color="default"
                      checkedIcon={<this.BpCheckedIcon />}
                      icon={<this.BpIcon />}
                      {...this.props}
                    />}
                    label={<Typography component={'p'} className="checkBoxText">Service Provider</Typography>} />
                </RadioGroup>

              </FormControl>
            </this.DealerBox>

            <this.GoogleBox>
              <Checkbox
                style={{ paddingLeft: '0px' }}
                checked={this.state.checkedGoogle}
                onChange={(event) => { this.handleCheckboxGoogle(event) }}
                data-test-id="checkboxGoogle"
                inputProps={{ 'aria-label': 'controlled' }}
                icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
                checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
              />
              <Typography style={{ fontWeight: 500, fontSize: '16px', color: '#4B4C4B', fontFamily: 'Roboto' }}>I agree to the
                <Button onClick={this.handleTerms} data-test-id="termstest" variant="text" style={{ fontWeight: 500, fontFamily: 'Roboto', fontSize: '16px', color: '#013D4F', textTransform: 'none' }}>Terms and Conditions</Button>
              </Typography>

            </this.GoogleBox>


            <this.ButtondbGoogle data-test-id="submitgoogledetail" disabled={this.checkGoogleButtonDisability()} variant="contained" onClick={this.handleGoogleSignUpSubmit} style={{ height: '56px' }}>
              <Typography style={{ fontWeight: 700, fontSize: '16px', textTransform: 'none' }}>Confirm Details</Typography>
            </this.ButtondbGoogle>

          </Box>

        </this.StyledModalBox>
      </Modal>
    )
  }


  passwordCreationPage = () => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '16%' }}>
        <Box style={{ maxWidth: '600px', padding: '15px' }}>
          <Box style={{ display: "flex", flexDirection: 'column', alignItems: 'start', width: '100%', marginBottom: '30px' }}>
            <Box style={{ fontWeight: 300, color: '#013D4F', fontSize: '30px', fontFamily: 'Gotham light' }}>Create Password</Box>
            <Typography style={{ marginTop: '10px', fontWeight: 300, fontFamily: 'Gotham light', color: '#4B4C4B', fontSize: '18px' }}>Now setup your password.</Typography>
          </Box>

          <Box style={{ width: '100%' }}>
            <Formik
              initialValues={{ password1: '', password2: '' }}
              validationSchema={this.validationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ errors, touched, handleChange, handleBlur, values }) => (
                <Form >
                  <Box style={{ width: '100%' }}>
                    {isError(touched.password2, errors.password2, 'password2')}
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${this.isErrorborder(touched.password2, errors.password2, '#C43937', '#C5CBC9')}` }} className="inputLoginBox">
                      <LockOutlinedIcon style={{ color: this.isErrorborder(true, values.password1, '#4B4C4B', '#C5CBC9') }} className="inputLabelIcon" />
                      <Field
                        className="password login_test_password"
                        name="password1"
                        data-test-id="passwordtest"
                        type={this.state.showPassword1 ? 'text' : 'password'}
                        placeholder="Password"
                        as={StyledTextField}
                        InputProps={{
                          disableUnderline: true,
                          style: { border: 'none', height: '', marginTop: '5px' },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton

                                data-test-id="eyebutton"
                                className="password_button_type"
                                aria-label="toggle password visibility"
                                onClick={() => this.handleShowPass('password1')}
                                edge="end"
                                style={{ marginRight: '10px', color: values.password1 ? '#4B4C4B' : '#C5CBC9' }}
                              >
                                {this.state.showPassword1 ? <VisibilityOutlinedIcon className="passwordVisibilityIcon" /> : <VisibilityOffOutlinedIcon className="passwordVisibilityIcon" />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        InputLabelProps={{ shrink: false }}
                        inputProps={{ maxLength: 15 }}
                        value={values.password1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box style={{ marginTop: '16px', width: '100%' }} >
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: this.isErrorborder(touched.password2, errors.password2, '1px solid #C43937', '1px solid #C5CBC9') }} className="inputLoginBox">
                      <LockOutlinedIcon style={{ color: this.isErrorborder(true, values.password1, '#4B4C4B', '#C5CBC9') }} className="inputLabelIcon" />
                      <Field
                        className="password login_test_password2"
                        name="password2"
                        data-test-id="passwordtest"
                        type={this.state.showPassword2 ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        as={StyledTextField}
                        InputProps={{
                          disableUnderline: true,
                          style: { border: 'none', height: '', marginTop: '5px' },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                className="password_button_type2"
                                data-test-id="eyebutton"
                                aria-label="toggle password visibility"
                                onClick={() => this.handleShowPass('password2')}
                                edge="end"
                                style={{ marginRight: '10px', color: values.password2 ? '#4B4C4B' : '#C5CBC9' }}
                              >
                                {this.state.showPassword2 ? <VisibilityOutlinedIcon className="passwordVisibilityIcon" /> : <VisibilityOffOutlinedIcon className="passwordVisibilityIcon" />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        InputLabelProps={{ shrink: false }}
                        inputProps={{ maxLength: 15 }}
                        value={values.password2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </Box>

                  </Box>

                  <Box className="errorcheckbox" display={"flex"} flexDirection={"column"} style={{ marginTop: '15px' }}>
                    <Typography style={{ fontSize: '14px', fontWeight: 400, color: '#013D4F' }}>Password should have</Typography>
                    <Grid container spacing={1} style={{ marginTop: '15px' }}>
                      {this.passwordCriteria.map((criteria, index) => (
                        <Grid item xs={6} key={index}>
                          <Grid container alignItems="center">
                            <Grid item>
                              {criteria.test(values.password1) && (
                                <CheckCircleIcon style={{ color: '#26BBB3', height: '16px', width: '16px' }} />
                              )}
                              {!criteria.test(values.password1) && values.password1 && (
                                <CancelIcon style={{ color: '#C43937', height: '16px', width: '16px' }} />
                              )}
                              {!values.password1 && (
                                <RadioButtonUncheckedIcon style={{ height: '16px', width: '16px', color: '#4B4C4B' }} />
                              )}
                            </Grid>
                            <Grid item>
                              <Typography style={{ fontSize: '12px', color: '#4B4C4B', fontWeight: 400, marginLeft: '5px', marginTop: '2px' }}>{criteria.label}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>

                  <Box display={'flex'} flexDirection={'column'} style={{ width: '100%', marginTop: '30px' }}>
                    <this.Buttondb type="submit" disabled={!values.password1 || !values.password2 || Boolean(errors.password1) || Boolean(errors.password2)} variant="contained">
                      Enter Vitu Marketplace                    </this.Buttondb>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    )
  }

  errorBoxForBe = (value: string) => {
    if (value) {
      return (
        <Box style={{ marginBottom: '20px', backgroundColor: '#FEE2E2', height: '46px', width: '380px', borderLeft: '4px solid #C43937', borderRadius: '8px' }}>
          <Typography style={{ marginLeft: '10px', fontSize: '14px', color: "#DC2626", paddingTop: '15px', fontWeight: 500 }} >
            {this.state.googleError}
          </Typography>
        </Box>
      )
    }
    return null
  }
  // Customizable Area End

  render() {

    return (

      // Customizable Area Start
      <Grid container spacing={0} style={{ minHeight: '912px', overflowX: 'hidden' }}>
        <this.ScrollViewSection className="hero" item xs={12} sm={12} md={8} lg={8}>
          <Box >

            <img src={vitulogoimage} style={{ width: '91.27px', height: '42px', marginLeft: '20px', marginTop: '20px' }} />

          </Box>
          {
            this.state.isVerified ? this.passwordCreationPage() :
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ overflow: 'scroll' }}
              >
                <Box style={{ marginTop: '25px', marginLeft: '20px' }}>
                  <Box style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'start', width: '100%', marginBottom: '40px' }}>
                    <Box className="gothamLightText" style={{ fontWeight: 300, color: '#013D4F', fontSize: '30px', marginBottom: '12px' }}>{configJSON.welcome}</Box>
                    <Typography className="gothamText" style={{ fontWeight: 300, color: '#4B4C4B', fontSize: '18px' }}>{configJSON.descr}</Typography>
                  </Box>

                  {this.errorBoxForBe(this.state.googleError)}

                  <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item>
                      <Box style={{ height: '98px', width: '98px', backgroundColor: '#F0FAFA', borderRadius: '24px', textAlign: 'center', backgroundImage: `url(${banner})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {this.state.profilePhoto ?
                          <Avatar className="avatar" variant="square" src={this.state.profilePhoto} style={{ width: '100%', height: '100%', borderRadius: '24px' }} />
                          : (
                            <>
                              {this.state.initials ?
                                (
                                  <Avatar
                                    variant="square"
                                    style={{ width: '80px', height: '80px', backgroundColor: 'rgb(240, 250, 250)', borderRadius: '24px', color: '#013D4F', fontSize: '32px', fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                  >
                                    {this.state.initials}
                                  </Avatar>
                                ) :
                                null
                              }
                            </>
                          )}
                      </Box>
                    </Grid>
                    <Grid item style={{ marginLeft: '20px', width: '400px' }}>
                      <Box>
                        {this.state.profilePhoto ?
                          (<Box>
                            <Button component="label" variant="outlined" style={{ color: '#013D4F', marginTop: '10px', border: '1px solid #013D4F', borderRadius: '8px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>{configJSON.reupload}</Typography>
                              <input
                                type="file"
                                hidden
                                onChange={(event) => { this.handlePhotoChange(event) }}
                              />
                            </Button>

                            <Button data-test-id="delete-picture-button" onClick={this.handleDeletePicture} variant="outlined" style={{ color: '#C43937', border: '1px solid #C43937', borderRadius: '8px', marginTop: '10px', marginLeft: '10px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>{configJSON.removephoto}</Typography></Button>
                          </Box>)
                          : (
                            <Box>
                              <Button component="label" variant="outlined" style={{ color: '#013D4F', border: '1px solid #013D4F', borderRadius: '8px', marginTop: '10px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>{configJSON.uploadphoto}</Typography>
                                <input
                                  type="file"
                                  hidden
                                  onChange={(event) => this.handlePhotoChange(event)}
                                />
                              </Button>
                            </Box>)
                        }
                        {this.errorBox()}
                      </Box>
                      <this.DetailBox >
                        <Typography style={{ fontSize: '12px', color: '#C5CBC9', marginTop: '20px' }} color="secondary">{configJSON.photodetail}</Typography>
                      </this.DetailBox>
                    </Grid>
                  </Grid>

                  <this.BoxStyled item style={{ marginTop: '20px' }} xs={8} md={12}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: '1px solid #C5CBC9', }} className="inputLoginBox">
                      <PersonOutlineOutlinedIcon style={{ textAlign: 'start', padding: '5px', color: this.handleColor(this.state.firstName), marginLeft: '15px' }} />
                      <StyledTextField
                        placeholder="Full Name"
                        data-test-id="inputText"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            border: 'none',
                            width: '415px',
                            paddingTop: '5px'
                          },
                          inputProps: {
                            pattern: "[a-zA-Z ]*",
                            title: "Please enter only letters",
                          },
                        }}
                        InputLabelProps={{
                          shrink: false,
                        }}
                        onChange={(event) => this.handleChangeEmail(event)}
                        value={this.state.firstName}
                      />
                    </Box>
                  </this.BoxStyled>

                  <this.BoxStyled item xs={8} md={12}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: this.isErrorborder(true, this.state.receivedErrorEmail, '1px solid #C43937', '1px solid #C5CBC9') }} className="inputLoginBox">
                      <EmailOutlinedIcon style={{ marginLeft: '15px', textAlign: 'start', padding: '5px', color: this.handleColor(this.state.email) }} />
                      <StyledTextField
                        type="email"
                        placeholder="Email"
                        data-test-id="inputemail"

                        InputProps={{

                          disableUnderline: true,

                          style: {
                            border: 'none',
                            width: '415px',
                            height: '',
                            paddingTop: '4px'
                          },
                        }}
                        InputLabelProps={{
                          shrink: false,
                        }}
                        onChange={(event) => this.handleEmailEvent(event)}
                        onKeyDown={(event) => {
                          if (event.key === ' ') {
                            event.preventDefault();
                          }
                        }}
                        value={this.state.email}
                      />
                    </Box>
                    {this.state.receivedErrorEmail && <Box style={{ fontSize: '12px', marginTop: '5px', marginLeft: '5px', fontFamily: 'Roboto', fontWeight: 400 }} color={"#DC2626"}>{this.state.receivedErrorEmail}</Box>}
                  </this.BoxStyled>


                  <this.BoxStyled item xs={8} md={12}>

                    <Box style={{ display: 'flex', marginTop: '16px', position: "relative", flexDirection: 'row', alignItems: 'center', border: this.isErrorborder(true, this.state.receivedErrorPhone, '1px solid #C43937', '1px solid #C5CBC9') }} className="inputLoginBox">
                      <Box position="end" style={{ cursor: "pointer", display: "flex", alignItems: "center", position: 'absolute', left: 20 }}>
                        <img src={usaflag} style={{ height: "14px", width: "18px" }} />
                        <p style={{ marginLeft: "8px", fontSize: "16px", color: this.handleColor(this.state.phone) }}>+</p>
                        <Typography style={{ fontSize: "16px", color: this.handleColor(this.state.phone), marginTop: '1px' }}>1</Typography>
                        <p style={{ marginLeft: "12px", marginRight: "10px", color: this.handleColor(this.state.phone), fontSize: "16px" }}>|</p>
                      </Box>
                      <StyledTextField
                        data-test-id="inputmobile"
                        type="text"
                        placeholder="Mobile Number"
                        InputProps={{
                          disableUnderline: true,

                          style: {
                            border: 'none',
                            width: '415px',
                            height: '',
                            paddingTop: '6px'
                          },
                        }}
                        inputProps={{
                          maxLength: 10,
                          style: { borderRadius: "8px", marginLeft: '100px' }
                        }}
                        InputLabelProps={{
                          style: { marginLeft: '80px', color: 'gray' }
                        }}
                        onChange={this.handlePhoneChange}
                        value={this.state.phone}
                      />
                    </Box>
                    {this.state.receivedErrorPhone && <Box style={{ fontSize: '12px', marginLeft: '5px', marginTop: '5px', fontFamily: 'Roboto', fontWeight: 400 }} color={"#DC2626"}>{this.state.receivedErrorPhone}</Box>}
                  </this.BoxStyled>



                  <Box style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', justifyContent: 'flex-start', alignItems: 'start' }} >


                    <this.DealerBox style={{ display: 'flex', flexDirection: 'row' }} ml={3}>
                      <FormControl component="fieldset">
                        <RadioGroup value={this.state.roleId} data-test-id="radiotest" row aria-label="typeofuser" name="typeofuser" onChange={(event) => { this.handleRadio(event) }}>
                          <FormControlLabel value="1"
                            control={<Radio
                              disableRipple
                              color="default"
                              checkedIcon={<this.BpCheckedIcon />}
                              icon={<this.BpIcon />}
                              {...this.props}
                            />} label={<Typography component={"p"} className="checkBoxText" >Dealer</Typography>} />
                          <FormControlLabel style={{ marginLeft: '20px' }} value="2"
                            control={<Radio
                              color="default"
                              disableRipple
                              checkedIcon={<this.BpCheckedIcon />}
                              icon={<this.BpIcon />}
                              {...this.props}
                            />} label={<Typography component={'p'} className="checkBoxText">Service Provider</Typography>} />
                        </RadioGroup>
                      </FormControl>
                    </this.DealerBox>



                    <this.NewBoxx >
                      <Checkbox
                        style={{ paddingLeft: '0px', marginLeft: '0px' }}
                        checked={this.state.checked}
                        onChange={(event) => { this.handleCheckbox(event) }}
                        data-test-id="checkbox"
                        inputProps={{ 'aria-label': 'controlled' }}
                        icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
                        checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
                      />
                      <Typography component={'p'} className="agreeText"

                      >I agree to the
                        <Button data-test-id="termstest" onClick={this.handleTerms} variant="text"
                          className="termsText"
                        >Terms and Conditions</Button>
                      </Typography>

                    </this.NewBoxx>


                    <this.Buttondb data-test-id="btnSignUp" disabled={!this.state.roleId || !this.state.firstName || !this.state.email || !this.state.phone || !this.state.checked || this.state.phone.length < 10} onClick={this.createAccountWeb} variant="contained">
                      {configJSON.signuptovitu}
                    </this.Buttondb>

                    <GoogleLogin
                      data-test-id="Gloginbutton"
                      clientId="441584058688-05ov470a7n99j7884oatccmqk4q5f2r8.apps.googleusercontent.com"
                      render={renderProps => (
                        <this.ButtonGoogle className="googleloginbutton" onClick={renderProps.onClick} disabled={renderProps.disabled} style={{ height: '56px', marginTop: '15px', border: '1px solid #F0FAFA', display: 'flex', alignItems: 'center' }}>
                          <img src={googleimageicon} alt="icon" />
                          <Typography style={{ textTransform: 'none', marginTop: '5px', marginLeft: '10px', fontSize: '16px', fontWeight: 500, color: '#013D4F', fontFamily: 'Roboto' }}>{configJSON.signupwithgoogle}</Typography>
                        </this.ButtonGoogle>
                      )}
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      cookiePolicy={'single_host_origin'}
                    />

                    <this.NewBox>
                      <Typography style={{ fontWeight: 400, fontSize: '16px', color: '#4B4C4B' }}>{configJSON.alreadyaccount}</Typography>
                      <Button data-test-id="gotoemailaccregistrationtest" variant="text" onClick={this.gotoEmailAccountRegistration} style={{ marginBottom: '20px' }}>
                        <Typography component={'p'} className="logintivitu">{configJSON.logintovitu}</Typography>
                      </Button>
                    </this.NewBox>
                  </Box>

                </Box>

                {this.googleSignupModal()}

              </Grid>
          }
        </this.ScrollViewSection>

        <this.SecondGrid item xs={12} sm={12} md={4} lg={4} style={{ position: 'fixed', height: '100vh', right: '0', width: 'inherit' }}>
          <img src={grouppng} style={{ height: '100%', width: '100%' }} />
        </this.SecondGrid>
      </Grid>
      // Customizable Area End
    );
  }
}
