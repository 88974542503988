import React from "react";
// Customizable Area Start
import {
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  styled
} from '@material-ui/core';
import { grouppng, vitulogoimg } from '../../email-account-login/src/assets'
import OtpInput from 'react-otp-input';
// Customizable Area End
import OTPInputAuthController, {
  Props,
} from "./OTPInputAuthController";

export default class OTPInputAuthWeb extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box className='otpCont'></Box>

        <OtpGrid container style={{ minHeight: '100vh', overflowX: 'hidden' }}>
          <Grid item xs={12} sm={8} md={8} lg={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>

              <Box >
                <img src={vitulogoimg} style={{ width: '91.27px', height: '42px', marginLeft: '20px', marginTop: '20px' }} />
              </Box>
              {!this.state.confirmation ?
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className="mainBox"
                >
                  <Box style={{ width: '70%', maxWidth: '540px' }}>
                    <Grid item >
                      <Box style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'start', width: '100%' }}>
                        <Typography className="gothamText otpHeadingText" >Enter OTP</Typography>
                        <Typography className="gothamText otpSubtitleText">Enter the 4-digit code sent to your phone <span style={{ color: '#013D4F', fontWeight: 500 }}>{this.formatPhoneNumber(this.state.receivedMob)}</span></Typography>
                      </Box>
                    </Grid>

                    <OtpGrid container justifyContent="center" spacing={2} style={{ marginTop: '15px' }}>
                      <OtpInput
                        value={this.state.otp}
                        onChange={this.handleChange}
                        numInputs={4}
                        inputType="tel"
                        data-test-id="input1"
                        inputStyle={this.state.errorMsg ? "errorBox" : "otpBox"}
                        renderInput={(props) => <input {...props} />}
                      />
                    </OtpGrid>
                    <Typography style={{ color: '#DC2626', fontSize: '12px', textAlign: 'center', marginTop: '10px' }} data-test-id="errorMessage" >{this.state.errorMsg ? this.state.errorMsg : null}</Typography>

                    <Grid item style={{ marginTop: '30px', paddingLeft: '0px', paddingRight: '0px' }}>
                      <Buttondb data-test-id="btnSignUp" variant="contained" disabled={this.state.otp.length < 4} onClick={() => this.submitWebOtp()}>
                        Verify
                      </Buttondb>
                    </Grid>


                    <Grid item style={{ marginTop: '0px', padding: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button href={this.state.isComeFromInviteUser ? this.state.inviteuserurl : ""} data-test-id="backtest" variant="text" onClick={this.handleBack}>
                        <Typography component={'p'} className="notYouText">Not You?</Typography>
                      </Button>
                      <Box display="flex" alignItems="center" marginTop={'5px'}>
                        <Typography component={'p'} className="receiveOtp">Didn't receive OTP? </Typography>
                        <Button data-test-id="gotoemailaccregistrationtest" variant="text" disabled={this.state.timer > 0} onClick={this.resendOtpp}>
                          <Typography data-test-id="timerDisplay" className="resendText" style={{ color: this.state.timer > 0 ? '#4B4C4B' : '#013D4F', textTransform: 'none' }}>Resend <span style={{ display: this.state.timer > 0 ? '' : 'none' }}>in {this.formatTime(this.state.timer)}</span></Typography>
                        </Button>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
                :
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className="mainBox"
                >
                  <Box style={{ width: '70%', maxWidth: '600px' }}>
                    <Grid item >
                      <PhoneSuccessBox>
                        <Typography data-test-id="confirmationtest" style={{ fontWeight: 300, color: '#013D4F', fontSize: '30px', marginBottom: '12px', fontFamily: 'Gotham light' }}>Phone Number Verified</Typography>
                        <Typography style={{ fontWeight: 300, color: '#4B4C4B', fontSize: '18px', fontFamily: 'Gotham' }}>Your phone number has been successfully linked to your account. </Typography>
                      </PhoneSuccessBox>
                    </Grid>
                    <Grid item style={{ marginTop: '30px' }}>
                      <Buttondb className="hello" data-test-id="continueandsignup" variant="contained" onClick={this.state.isComeFromInviteUser ? this.handleNavigatetoInviteuser : this.handleNavigate}>
                        Continue & Setup Password
                      </Buttondb>
                    </Grid>
                  </Box>
                </Grid>}

            </Box>

            {this.state.isComeFromInviteUser && <Box style={{ width: '110%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginLeft: '25px', marginBottom: '10px' }}>
              <Button disabled={true} variant="text">
                <Typography style={{ textTransform: 'none', color: '#C5CBC9', fontWeight: 500, fontSize: '14px' }}>Copyright © 2024 Vitu</Typography>
              </Button>
              <Box style={{ display: 'flex' }}>
                <Button variant="text">
                </Button>

                <Button variant="text">
                </Button>
              </Box>
            </Box>}

          </Grid>

          <ImageStyledd item xs={12} sm={4} md={4} lg={4} style={{ position: 'fixed', height: '100vh', right: '0', width: 'inherit' }}>
            <img src={grouppng} height="100%" width='100%' />
          </ImageStyledd>
        </OtpGrid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Buttondb = styled(Button)({
  boxShadow: 'none',
  width: '100%',
  fontWeight: 700,
  fontSize: '12px',
  height: 44,
  textTransform: 'none',
  [`@media (min-width:1920px)`]: {
    fontSize: '16px',
    height: 56
  },
  '&:disabled': {
    color: '#75D3F0',
    backgroundColor: '#F0FAFA',
    '@media (max-width: 600px)': {
      width: "220px",
    },
    '@media (max-width: 700px)': {
      width: "300px",
    },
  },
  '&:enabled': {
    color: 'white',
    backgroundColor: '#4FC8EC',
    '@media (max-width: 600px)': {
      width: "220px",
    },
    '@media (max-width: 700px)': {
      width: "300px",
    },
  },

});

const ImageStyledd = styled(Grid)({
  textAlign: 'end',
  "@media (max-width:600px)": {
    display: 'none'
  },
  "@media (min-width:601px)": {
    display: 'block'
  }
})

const PhoneSuccessBox = styled(Box)({
  display: "flex",
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'start',
  width: '100%'
})

const OtpGrid = styled(Grid)({
  '& .mainBox': {
    marginTop: '100px',
    [`@media (min-width:1920px)`]: {
      marginTop: '185px',
    }
  },
  '& .errorBox': {
    border: '1px solid #C43937',
    height: '50px',
    width: '50px !important',
    fontSize: '20px',
    margin: '6px',
    borderRadius: '8px',
    fontWeight: 300,
    color: '#4B4C4B',
    [`@media (min-width:1920px)`]: {
      width: '65px !important',
      height: '65px',
      fontSize: '30px',
    },
  },
  '& .otpBox': {
    border: '0.2px solid #C5CBC9',
    height: '50px',
    width: '50px !important',
    fontSize: '20px',
    margin: '6px',
    borderRadius: '8px',
    fontWeight: 300,
    color: '#4B4C4B',
    [`@media (min-width:1920px)`]: {
      width: '65px !important',
      height: '65px',
      fontSize: '30px',
    },
  },
  '& .otpHeadingText': {
    fontWeight: '300',
    color: '#013D4F',
    fontSize: '24px',
    marginBottom: '12px',
    [`@media (min-width:1920px)`]: {
      fontSize: "30px",
    }
  },
  '& .otpSubtitleText': {
    fontWeight: '300',
    color: '#4B4C4B',
    fontSize: '14px',
    [`@media (min-width:1920px)`]: {
      fontSize: "18px",
    }
  },
  '& .notYouText': {
    marginTop: '5px',
    textTransform: 'none',
    fontWeight: 510,
    fontSize: '12px',
    color: '#013D4F',
    [`@media (min-width:1920px)`]: {
      fontSize: "16px",
    }
  },
  '& .resendText': {
    fontWeight: 510, padding: '0px', fontSize: '12px',
    [`@media (min-width:1920px)`]: {
      fontSize: "16px",
    }
  },
  '& .receiveOtp': {
    fontWeight: 510,
    padding: '0px',
    fontSize: '12px',
    textTransform: 'none',
    color: '#4B4C4B',
    [`@media (min-width:1920px)`]: {
      fontSize: "16px",
    }
  }
})
// Customizable Area End