import React from "react";
// Customizable Area Start
import { Avatar, Box, Button, Divider, Menu, MenuItem, Modal, Typography, createTheme, styled } from "@material-ui/core";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { MailOutlineOutlined } from "@material-ui/icons";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const MenuItemBox = styled(Box)(({ theme }) => ({
  '& .menuItemText': {
    fontWeight: 400,
    fontSize: '14px',
    color: '#4B4C4B',
    fontFamily: 'Roboto',
    [theme.breakpoints.up(1920)]: {
      fontSize: '16px',

    }
  },
  '& .menuItemIcon': {
    width: '20px',
    height: '20px',
    color: '#013D4F',
    [theme.breakpoints.up(1920)]: {
      width: '1em',
      height: '1em'

    }
  },
  '& .nameText': {
    fontSize: '16px',
    fontWeight: 400,
    color: '#4B4C4B',
    fontFamily: 'Roboto',
    [theme.breakpoints.up(1920)]: {
      fontSize: '20px',
    }
  },
  '& .descriptionText': {
    [theme.breakpoints.up(1920)]: {
      fontSize: '16px',
    },
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Roboto',
    color: '#C5CBC9',
  },
  '& .profileAvatar': {
    height: '45px',
    width: '45px',
    color: '#013D4F',
    backgroundColor: 'rgb(240, 250, 250)',
    [theme.breakpoints.up(1920)]: {
      height: '57px',
      width: '57px',
    },
  }

}))
// Customizable Area End

import ProfileDropdownController, {
  Props,
} from "./ProfileDropdownController";
import { subscription } from "./assets";

export default class ProfileDropdown extends ProfileDropdownController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  logoutModal = () => {
    return (
      <Modal
        open={this.state.isOpenLogoutModal}
        onClose={this.toggleLogoutModalNew}
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledModalBox >
          <Box className="boxHeading" >
            <Button onClick={this.toggleLogoutModalNew} data-test-id="closebtn"><CloseIcon /></Button>
          </Box>

          <Box>
            <Box>
              <Typography component={"h6"} className="logoutText">Log Out</Typography>
            </Box>
            <Typography component={'p'} className="subtitleText">Are you sure you want to log out?</Typography>
          </Box>

          <Divider style={{ height: '2px', width: '113%', marginLeft: '-33px', backgroundColor: '#F0FAFA', marginTop: '30px' }} />

          <Box className="buttonLayout" style={{ display: 'flex', marginTop: '20px', justifyContent: 'end', }}>
            <Button className="button" data-test-id="cancellogout" variant="outlined" onClick={this.toggleLogoutModalNew} style={{ border: '1px solid #013D4F', marginRight: '15px', }}>
              <Typography className="buttonTextPopup" style={{ color: '#013D4F' }}>
                Cancel
              </Typography>
            </Button>
            <Button className="button" data-test-id="finallogout" variant="contained" onClick={this.gotoLogout} style={{ backgroundColor: '#4FC8EC', borderRadius: '8px' }}>
              <Typography className="buttonTextPopup" style={{ color: 'white' }}>
                Log Out
              </Typography>
            </Button>
          </Box>
        </StyledModalBox>
      </Modal>
    )
  }

  profileMenuDropdownn = () => {
    return (
      <Menu
        id="simple-menu"
        data-test-id="profileMenuDropdown"
        anchorEl={this.state.anchorEl}
        open={Boolean(this.state.anchorEl)}
        keepMounted
        onClose={this.handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            padding: '10px 0',
            borderRadius: 8,
            width: '270px',
          },
        }}
      >

        <MenuItemBox display="flex" alignItems="center" pt={0} p={2}>
          <Avatar className="profileAvatar"

            src={this.state.profilePhoto ? this.state.profilePhoto : ''}>
            {this.state.initials}
          </Avatar>

          <Box ml={2}>
            <Typography component={'h6'} className="nameText">{this.state.fullName}</Typography>
            <Typography component={'p'} className="descriptionText">{this.state.description}</Typography>
          </Box>
        </MenuItemBox>

        <MenuItemBox style={{ gap: '15px', marginTop: '7px' }}>
          <MenuItem data-test-id="editprofile" onClick={this.gotoEditProfile} style={{ gap: '15px' }} >
            <PersonOutlineOutlinedIcon className="menuItemIcon" />
            <Typography component={'p'} className="menuItemText">Edit Profile</Typography>
          </MenuItem>

          {
            (this.state.role === ('dealer_admin') || this.state.role === ('service_provider_admin')) &&
            (
              <MenuItem data-test-id="editprofile" onClick={this.gotoEditProfile} style={{ marginTop: '15px', gap: '15px' }} >
                <img src={subscription} className="menuItemIcon" />
                <Typography component={'p'} className="menuItemText">My Subscription Plan</Typography>
              </MenuItem>
            )
          }
          <MenuItem onClick={this.gotoContactUs} data-test-id="contactusprofile" style={{ marginTop: '15px', gap: '15px' }} >
            <MailOutlineOutlined className="menuItemIcon" />
            <Typography component={'p'} className="menuItemText" >Contact Us</Typography>
          </MenuItem>

          <Divider style={{ marginTop: '10px', marginLeft: '20px', width: '230px', background: 'rgba(240, 250, 250, 1)' }} />

          <MenuItem data-test-id="logoutprofile" onClick={this.toggleLogoutModalNew} style={{ gap: '15px', }}>
            <ExitToAppOutlinedIcon style={{ transform: 'rotateY(180deg)' }} className="menuItemIcon" />
            <Typography component={'p'} className="menuItemText">Log Out</Typography>
          </MenuItem>
        </MenuItemBox>
      </Menu>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Avatar
          className="avatar"
          variant="square"
          onClick={(event) => this.handleOpenMenu(event)}
          src={this.state.profilePhoto ? this.state.profilePhoto : ''}
          style={{
            width: "102%",
            height: "102%",
            backgroundColor: 'rgb(240, 250, 250)',
            borderRadius: "24px",
            color: '#013D4F'
          }}
        >
          {this.state.initials}
        </Avatar>
        {this.profileMenuDropdownn()}
        {this.logoutModal()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledModalBox = styled(Box)({
  width: '512px',
  border: '1px solid #FFFFFF',
  boxShadow: theme.shadows[5],
  backgroundColor: '#FFFFFF',
  padding: theme.spacing(2, 4, 3),
  borderRadius: '8px',
  [theme.breakpoints.down(600)]: {
    width: '200px',
  },
  
  '& .button': {
    height: 44,
    '& .buttonTextPopup': {
      fontSize: '12px',
      fontWeight: 700,
      textTransform: 'none',
      fontFamily: 'Roboto',
      [theme.breakpoints.up(1920)]: {
        fontSize: '116px',

      },
    },
    borderRadius: 8,
    width: '156px',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
    [theme.breakpoints.up(1920)]: {
      height: 56
    },

  },
  '& .boxHeading': {
    display: 'flex',
    width: '545px',
    justifyContent: 'end',
    [theme.breakpoints.down(600)]: {
      width: '200px',
    },
  },
  '& .logoutText': {
    color: '#013D4F',
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    marginBottom: '12px',
    [theme.breakpoints.up(1920)]: {
      fontSize: 24
    },
  },
  '& .buttonLayout': {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      gap: '10px',
      justifyContent: 'center'
    },
  },
  
  '& .subtitleText': {
    [theme.breakpoints.up(1920)]: {
      fontSize: 18
    },
    fontSize: '14px',
    color: '#4B4C4B',
    fontWeight: 300,
    fontFamily: 'Gotham light'
  }

})
// Customizable Area End
