import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { ICreateServiceProviderDetails, ServiceableLocations } from "./ServiceProviderProfilesController";
import { State } from 'country-state-city';

interface AccountAttributes {
  id: number;
  first_name: string;
  serviceable_locations: ServiceableLocations
}

export interface ProviderDetails {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

export interface FormValues {
  transfer_id: string;
  date: string;
  shopkeeperName: string;
  opening_cash_date: string;
  station: string;
  opening_cash: string;
  petty_cash: string;
  cash_from_orders: string;
  cash_in: string;
  cash_out: string;
  amount: string;
  variance: string;
  driver_account_id: string;
  store_id: string;
  none: string;
}

export interface StoreManagementDropdown {
  id: string;
  type: string;
  attributes: {
    id: number;
    store_name: string;
    name_translation: string;
  };
}

export interface LocationObject {
  id: string;
  type: string;
  attributes: object;
}

export interface SpecificLocation {
  [key: string]: any;
  stateName: string;
  uploadBusinessLicense?: File | null;
  businessLicense: string;
  uploadDealerLicense?: File | null;
  dealerLicense: string;
  uploadDMVContract?: File | null;
  DMVContract: string;
  uploadRegistrationServiceLicense?: File | null;
  registrationServiceLicense: string;
  uploadBondingRegistration?: File | null;
  bondingRegistration: string;
  isFullService: boolean;
  isOnSite: boolean;
  isRunner: boolean;
  isWhiteGlove: boolean;
  runnerPrice: string;
  whiteGlovePrice: string;
  onSitePrice: string;
  fullServicePrice: string;
  isEditServiceable: boolean;
  id: string;
};


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCancel: Function;
  userName: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  providerData: ProviderDetails;
  servicableLocations: [];
  specificLocation: SpecificLocation;
  loading: boolean;
  snackbar: {
    open: boolean,
    severity: "error" | "success" | "info" | "warning",
    message: string
  };
  idFromParam: string;
  formCompleted: boolean;
  uploadBusinessLicense?: File | null;
  uploadDealerLicense?: File | null;
  uploadDMVContract?: File | null;
  uploadRegistrationServiceLicense?: File | null;
  uploadBondingRegistration?: File | null;
  isChanged: boolean;
  initialState: null | any;
  isOpenBackToDashboardModal: boolean;
  locations: SpecificLocation[]
  label: string
  serviceProviderProDetails: ICreateServiceProviderDetails;
  pageLoader: boolean
  removedFiles: string[]


  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditServiceLocationController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getProvidesDetailsCallId: string = "";
  deleteServiceableLocationId: string = "";
  editLocationCallId: string = "";
  getProviderStateDetailsCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pageLoader: false,
      providerData: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          first_name: "",
          serviceable_locations: {
            data: {}
          }
        }
      },
      servicableLocations: [],
      loading: false,
      snackbar: {
        open: false,
        severity: 'info',
        message: ''
      },
      idFromParam: '',
      specificLocation: {
        stateName: "",
        uploadBusinessLicense: null,
        businessLicense: "",
        dealerLicense: "",
        DMVContract: "",
        registrationServiceLicense: "",
        bondingRegistration: "",
        isFullService: false,
        isOnSite: false,
        isRunner: false,
        isWhiteGlove: false,
        runnerPrice: "",
        whiteGlovePrice: "",
        onSitePrice: "",
        fullServicePrice: "",
        isEditServiceable: false,
        id: ""
      },
      formCompleted: false,
      uploadBusinessLicense: null,
      uploadDealerLicense: null,
      uploadDMVContract: null,
      uploadRegistrationServiceLicense: null,
      uploadBondingRegistration: null,
      isChanged: false,
      initialState: null,
      isOpenBackToDashboardModal: false,
      locations: [],
      label: "State",
      serviceProviderProDetails: {
        sp_business_name: "",
        sp_address: "",
        sp_street_address: "",
        sp_city: "",
        sp_state: "",
        sp_zipCode: "",
        sp_zipCodeEXT: "",
        sp_shipping_address: "",
        sp_shipping_street_address: "",
        sp_shipping_city: "",
        sp_shipping_state: "",
        sp_shipping_zipCode: "",
        sp_shipping_zipCodeEXT: "",
        profilePhoto: null,
        initials: null,
        business_credentials: "",
        newCarOrUsedCar: "",
        checkedBoxes: [],
        test: [],
        testCities: [],
        testShippingCities: [],
        stateISOCode: "",
        filePhoto: null,
      },
      removedFiles: []

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    switch (apiRequestCallId) {
      case this.getProvidesDetailsCallId:
       
        if (responseJson.data) {
          const locationData = responseJson.data;
          const { attributes } = locationData;

          // Call the method to get the files
          this.getFileFromLink(
            attributes.business_license,
            attributes.dealer_license,
            attributes.dmv_contract,
            attributes.registration_service_license,
            attributes.insurance_requirement
          );

          // Construct the specificLocations object
          const specificLocations: SpecificLocation = {
            // ...this.blankObject,
            id: locationData.id,
            stateName: attributes.provider_state,
            businessLicense: attributes.business_license,
            dealerLicense: attributes.dealer_license,
            DMVContract: attributes.dmv_contract,
            registrationServiceLicense: attributes.registration_service_license,
            bondingRegistration: attributes.insurance_requirement,
            isRunner: Boolean(attributes.provider_services.runner),
            runnerPrice: attributes.provider_services.runner || "",
            isOnSite: Boolean(attributes.provider_services.on_site),
            onSitePrice: attributes.provider_services.on_site || "",
            isFullService: Boolean(attributes.provider_services.full_service),
            fullServicePrice: attributes.provider_services.full_service || "",
            isWhiteGlove: Boolean(attributes.provider_services.white_glove),
            whiteGlovePrice: attributes.provider_services.white_glove || "",
            isEditServiceable: false
          };

          // Simplify the initial state setup
          const initialState = {
            specificLocation: specificLocations,
            uploadBusinessLicense: this.state.uploadBusinessLicense,
            uploadDealerLicense: this.state.uploadDealerLicense,
            uploadDMVContract: this.state.uploadDMVContract,
            uploadRegistrationServiceLicense: this.state.uploadRegistrationServiceLicense,
            uploadBondingRegistration: this.state.uploadBondingRegistration,
          };

          // Set state
          this.setState({
            servicableLocations: locationData,
            specificLocation: specificLocations,
            initialState: JSON.parse(JSON.stringify(initialState)),
            pageLoader:false
          });
        }

        break;

      case this.editLocationCallId:
        if (responseJson.data && !responseJson.errors) {
          setTimeout(() => {
            this.setState({ isChanged: false, pageLoader: false }, () => {
              this.handleNavigation('MyTeam');
            });
          }, 1500);
        }
        break;

      case this.deleteServiceableLocationId:
        if (responseJson && !responseJson.errors) {
          setTimeout(() => {
            this.setState({ pageLoader: false }, () => {
              this.handleNavigation('MyTeam');
            });
          }, 1500);
        }
        break;

      case this.getProviderStateDetailsCallId:
        if (responseJson && !responseJson.errors) {
          this.setState({
            pageLoader: false,
            providerData: responseJson.data,
          },()=>{
            this.setState((prevState: S) => ({
              serviceProviderProDetails: {
                ...prevState.serviceProviderProDetails,
                test: State.getStatesOfCountry('US')
                  .filter((state) => 
                    !this.state.providerData.attributes.serviceable_locations.data
                      ?.some((loc: { attributes: { provider_state: string } }) => loc.attributes.provider_state === state.name)
                  ) // Filter only serviceable locations
                  .map((state) => ({
                    value: state.name,
                    displayValue: `${state.name}`,
                    stateISOCode: state.isoCode,
                  })),
              },
            }));
          });
        }
        break;

      default:
        break;
    }

    // Customizable Area End
  }

  // Customizable Area Start



  async componentDidMount() {
    super.componentDidMount();
    const locationId = this.props.navigation.getParam('navigationBarTitleText');
    this.setState({ idFromParam: locationId })
    if (locationId === "new") {
      this.getProviderDetailsSelectedState();
    } else {
      this.getProviderDetailsSelectedState();
      this.getProviderDetails();

    }
  }

  setIsChanged = () => {
    this.setState({ isChanged: true });
  };

  toggleDashboardModalNewSp = () => {
    this.setState({
      isOpenBackToDashboardModal: !this.state.isOpenBackToDashboardModal
    }
    )
  }

  getProviderDetails = async () => {
    let headers = {
      token: await getStorageData('authToken'),
      "Content-Type": configJSON.searchApiContentType,
    };
    this.setState({ pageLoader: true })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.spServicebleLocationApiEndPoint}/${this.state.idFromParam}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    this.getProvidesDetailsCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProviderDetailsSelectedState = async () => {
    let headers = {
      token: await getStorageData('authToken'),
      "Content-Type": configJSON.searchApiContentType,
    };
    this.setState({ pageLoader: true })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProviderStateDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProviderDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  };

  blankObject = {
    stateName: "",
    businessLicense: "",
    dealerLicense: "",
    DMVContract: "",
    registrationServiceLicense: "",
    bondingRegistration: "",
    isFullService: false,
    isOnSite: false,
    isRunner: false,
    isWhiteGlove: false,
    runnerPrice: "",
    whiteGlovePrice: "",
    onSitePrice: "",
    fullServicePrice: "",
    isEditServiceable: false,
    id: "",
    uploadBusinessLicense: null,
    uploadDealerLicense: null,
    uploadDMVContract: null,
    uploadRegistrationServiceLicense: null,
    uploadBondingRegistration: null
  }

  removeDocumentFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, docType: string): void => {
    event.stopPropagation();
  
    const licenseMapping: Record<string, string> = {
      businessLicense: "business_license",
      dealerLicense: "dealer_license",
      DMVContract: "dmv_contract",
      registrationServiceLicense: "registration_service_license",
      bondingRegistration: "bonding_registration",
    };
  
   
    const removedKey = licenseMapping[docType];

    this.setState((prevState) => {
      const updatedRemovedFiles = prevState.removedFiles.includes(removedKey)
        ? prevState.removedFiles
        : [...prevState.removedFiles, removedKey];
      const updatedSpecificLocation = {
        ...prevState.specificLocation,
        [docType]: "",
      };
       const uploadKey = `upload${docType.charAt(0).toUpperCase() + docType.slice(1)}` as keyof typeof prevState;

       return {
         ...prevState,
         isChanged: true,
         removedFiles: updatedRemovedFiles,
         specificLocation: updatedSpecificLocation,
         [uploadKey]: null,
       };
     }, this.setIsChanged);
   };


  onUploadDocument = (acceptedFiles: File[], type: string): void => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileKey = `upload${type.charAt(0).toUpperCase() + type.slice(1)}`;
      const dataKey = type;

      this.setState((prevState) => ({
        ...prevState,
        [fileKey]: file,
      }));

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;

        this.setState((prevState) => ({
          isChanged: true,
          specificLocation: {
            ...prevState.specificLocation,
            [dataKey]: result,
          },
        }));
      };
      this.createServicableLocationFormData()
    }
  };

  handleRunnerCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (this.state.specificLocation.runnerPrice !== '' && this.state.initialState.specificLocation.runnerPrice) {
      this.setState({ isChanged: true })
    }
    else { this.setState({ isChanged: false }) }
    this.setState((prevState) => ({
      specificLocation: {
        ...prevState.specificLocation,
        isRunner: isChecked,
        runnerPrice: isChecked ? prevState.specificLocation.runnerPrice : '',
      },
    }));
  };

  handleFullServiceCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (this.state.specificLocation.fullServicePrice !== '' && this.state.initialState.specificLocation.fullServicePrice) {
      this.setState({ isChanged: true })
    }
    else { this.setState({ isChanged: false }) }
    this.setState((prevState) => ({
      specificLocation: {
        ...prevState.specificLocation,
        isFullService: isChecked,
        fullServicePrice: isChecked ? prevState.specificLocation.fullServicePrice : '',
      },
    }));
  };

  handleWhiteGloveCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (this.state.specificLocation.whiteGlovePrice !== '' && this.state.initialState.specificLocation.whiteGlovePrice) {
      this.setState({ isChanged: true })
    }
    else { this.setState({ isChanged: false }) }
    this.setState((prevState) => ({
      specificLocation: {
        ...prevState.specificLocation,
        isWhiteGlove: isChecked,
        whiteGlovePrice: isChecked ? prevState.specificLocation.whiteGlovePrice : '',

      },
    }));
  };

  handleOnSiteCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (this.state.specificLocation.onSitePrice !== '' && this.state.initialState.specificLocation.onSitePrice) {
      this.setState({ isChanged: true })
    }
    else { this.setState({ isChanged: false }) }
    this.setState((prevState) => ({
      specificLocation: {
        ...prevState.specificLocation,
        isOnSite: isChecked,
        onSitePrice: isChecked ? prevState.specificLocation.onSitePrice : '',
      },
    }));
  };

  handlePriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    priceKey: 'runnerPrice' | 'fullServicePrice' | 'whiteGlovePrice' | 'onSitePrice'
  ) => {
    const { value } = event.target;
    const numericValue = value.replace(/[^0-9.]/g, '');
    const dotCount = (numericValue.match(/\./g) || []).length;
    const formattedValue = numericValue ? `$${numericValue}` : '';
    const isValidValue =
      formattedValue === '' ||
      (formattedValue.startsWith('$') &&
        /^(\$\d*(\.\d*)?)?$/.test(formattedValue));

    if (dotCount <= 1 && isValidValue) {
      this.setState((prevState) => ({
        isChanged: value !== "$" && value !== '',
        specificLocation: {
          ...prevState.specificLocation,
          [priceKey]: formattedValue,
        },
      }));
    }
  };

  handleRunnerOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.handlePriceChange(event, 'runnerPrice');
  };

  handleFullServiceOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.handlePriceChange(event, 'fullServicePrice');
  };

  handleWhiteGloveOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.handlePriceChange(event, 'whiteGlovePrice');
  };

  handleOnSiteOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.handlePriceChange(event, 'onSitePrice');
  };


  createServicableLocationFormData = () => {
    const isAddNewStateEnabled = this.isAddNewStateButton();
    const { initialState, specificLocation, uploadDMVContract, uploadBondingRegistration, uploadBusinessLicense, uploadDealerLicense, uploadRegistrationServiceLicense } = this.state;

    const currentState = JSON.stringify({
      specificLocation,
      uploadBusinessLicense,
      uploadDealerLicense,
      uploadDMVContract,
      uploadRegistrationServiceLicense,
      uploadBondingRegistration,
    });

    const isChanges = currentState !== JSON.stringify(initialState);

    const serviceableLocationsData = [
      specificLocation.stateName,
      specificLocation.fullServicePrice,
      specificLocation.whiteGlovePrice,
      specificLocation.onSitePrice,
      specificLocation.runnerPrice
    ];
    const isFormFilled = serviceableLocationsData.some(Boolean);
    this.setState({ isChanged: isChanges })

    if (isFormFilled || isAddNewStateEnabled) {
      this.setState({ formCompleted: true });
    } else {
      this.setState({ formCompleted: false });
    }
  };

  createServiceableLocations = async (props?: any) => {
    const { providerData } = this.state;

    const formData = new FormData();
    this.setState({pageLoader:true})
    type SpecificLocationKey = "businessLicense" | "dealerLicense" | "DMVContract" | "registrationServiceLicense" | "bondingRegistration";

    {
      this.state.locations.forEach((item, index) => {
        const fieldMapping: { fieldName: string; specificKey: SpecificLocationKey; uploadKey: File | null | undefined }[] = [
          { fieldName: "business_license", specificKey: "businessLicense", uploadKey: item.uploadBusinessLicense },
          { fieldName: "dealer_license", specificKey: "dealerLicense", uploadKey: item.uploadDealerLicense },
          { fieldName: "dmv_contract", specificKey: "DMVContract", uploadKey: item.uploadDMVContract },
          { fieldName: "registration_service_license", specificKey: "registrationServiceLicense", uploadKey: item.uploadRegistrationServiceLicense },
          { fieldName: "insurance_requirement", specificKey: "bondingRegistration", uploadKey: item.uploadBondingRegistration },
        ];
        formData.append(`serviceable_locations[${index}][service_provider_information_id]`, providerData?.id || "");
        formData.append(`serviceable_locations[${index}][provider_state]`, item.stateName);
        if (item.isRunner) {
          formData.append(`serviceable_locations[${index}][provider_services][runner]`, item.runnerPrice);
        }
        if (item.isFullService) {

          formData.append(`serviceable_locations[${index}][provider_services][full_service]`, item.fullServicePrice);
        }
        if (item.isWhiteGlove) {

          formData.append(`serviceable_locations[${index}][provider_services][white_glove]`, item.whiteGlovePrice);
        }
        if (item.isOnSite) {
          formData.append(`serviceable_locations[${index}][provider_services][on_site]`, item.onSitePrice);
        }
        fieldMapping.forEach(({ fieldName, specificKey, uploadKey }) => {
          const specificValue = item?.[specificKey];
          if (!specificValue && !uploadKey) {
            return;
          }
          formData.append(`serviceable_locations[${index}][${fieldName}]`,uploadKey || "");

        });
      })
    };

    const authToken = await getStorageData('authToken');
    const SpWebheader = { "token": authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editLocationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.spServicebleLocationApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(SpWebheader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editServiceableLocations = async (props?: any) => {
    const {
      idFromParam, specificLocation, providerData, uploadBusinessLicense, uploadDealerLicense, uploadDMVContract, uploadBondingRegistration, uploadRegistrationServiceLicense
    } = this.state;

    const formData = new FormData();
    this.setState({ pageLoader: true })
    type SpecificLocationKey = "businessLicense" | "dealerLicense" | "DMVContract" | "registrationServiceLicense" | "bondingRegistration";

    const fieldMapping: { fieldName: string; specificKey: SpecificLocationKey; uploadKey: File | null | undefined }[] = [
      { fieldName: "business_license", specificKey: "businessLicense", uploadKey: uploadBusinessLicense },
      { fieldName: "dealer_license", specificKey: "dealerLicense", uploadKey: uploadDealerLicense },
      { fieldName: "dmv_contract", specificKey: "DMVContract", uploadKey: uploadDMVContract },
      { fieldName: "registration_service_license", specificKey: "registrationServiceLicense", uploadKey: uploadRegistrationServiceLicense },
      { fieldName: "insurance_requirement", specificKey: "bondingRegistration", uploadKey: uploadBondingRegistration },
    ];

    formData.append(`serviceable_locations[0][service_provider_information_id]`, providerData?.id || "");

    if (idFromParam !== 'new') {
      formData.append(`serviceable_locations[0][id]`, idFromParam);
    }
    formData.append(`serviceable_locations[0][provider_state]`, specificLocation.stateName);
    if (specificLocation.isRunner) {
      formData.append(`serviceable_locations[0][provider_services][runner]`, specificLocation.runnerPrice);
    }
    if (specificLocation.isFullService) {

      formData.append(`serviceable_locations[0][provider_services][full_service]`, specificLocation.fullServicePrice);
    }
    if (specificLocation.isWhiteGlove) {

      formData.append(`serviceable_locations[0][provider_services][white_glove]`, specificLocation.whiteGlovePrice);
    }
    if (specificLocation.isOnSite) {
      formData.append(`serviceable_locations[0][provider_services][on_site]`, specificLocation.onSitePrice);

    }

    fieldMapping.forEach(({ fieldName, specificKey, uploadKey }) => {
      const specificValue = specificLocation?.[specificKey];
      if (!specificValue && !uploadKey) {
        return;
      }
      formData.append(`serviceable_locations[${0}][${fieldName}]`,uploadKey || "");

    });

    if (this.state.removedFiles.length > 0) {
      formData.append(`serviceable_locations[0][remove_files]`, JSON.stringify(this.state.removedFiles));
    }
    const authToken = await getStorageData('authToken');
    const SpWebheader = { "token": authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editLocationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.spServicebleLocationApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(SpWebheader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBlobFromFile = async (url: string) => {
    let response = await fetch(url);
    let blob = await response.blob();
    return blob;
  };

  getFileNameFromUrl = (url: string): string => {
    if (!url) return "";
    const parts = url.split('/');
    const lastSegment = parts.pop();
    if (!lastSegment) return "";
    const fileName = lastSegment.split('?')[0];
    return decodeURIComponent(fileName);
  };

  getFileType = (url: string): string => {
    const extension = url.split('.').pop()?.toLowerCase() || '';
    const mimeTypes: { [key: string]: string } = {
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'png': 'image/png',
      'pdf': 'application/pdf',
    };
    return mimeTypes[extension] || 'application/octet-stream';
  };


  getFileFromLink = ( businessLicense: string, dealerLicense: string,
    DMVContract: string, registrationServiceLicense: string, bondingRegistration: string,) => {
    if (businessLicense) {
      this.getBlobFromFile(businessLicense).then((blob) => {
        const file = new File([blob], this.getFileNameFromUrl(businessLicense), { type: this.getFileType(businessLicense) });
        this.setState({ uploadBusinessLicense: file });
      });
    }
    if (dealerLicense) {
      this.getBlobFromFile(dealerLicense).then((blob) => {
        const file = new File([blob], this.getFileNameFromUrl(dealerLicense), { type: this.getFileType(dealerLicense) });
        this.setState({ uploadDealerLicense: file });
      });
    }
    if (DMVContract) {
      this.getBlobFromFile(DMVContract).then((blob) => {
        const file = new File([blob], this.getFileNameFromUrl(DMVContract), { type: this.getFileType(DMVContract) });
        this.setState({ uploadDMVContract: file });
      });
    }
    if (registrationServiceLicense) {
      this.getBlobFromFile(registrationServiceLicense).then((blob) => {
        const file = new File([blob], this.getFileNameFromUrl(registrationServiceLicense), { type: this.getFileType(registrationServiceLicense) });
        this.setState({ uploadRegistrationServiceLicense: file });
      });
    }
    if (bondingRegistration) {
      this.getBlobFromFile(bondingRegistration).then((blob) => {
        const file = new File([blob], this.getFileNameFromUrl(bondingRegistration), { type: this.getFileType(bondingRegistration) });
        this.setState({ uploadBondingRegistration: file });
      });
    }
  };

  handleDeleteState = async () => {
    const authToken = await getStorageData('authToken');
    const Webheader = { "token": authToken };
    this.setState({ pageLoader: true })
    const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteServiceableLocationId = WebrequestMessage.messageId;
    WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_profile/serviceable_locations/" + this.state.idFromParam);
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(Webheader));
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'DELETE');
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  isAddNewStateButton = () => {
    return (!!this.state.specificLocation.stateName && ((this.state.specificLocation.isFullService || this.state.specificLocation.isOnSite || this.state.specificLocation.isWhiteGlove || this.state.specificLocation.isRunner) && (this.state.specificLocation.isFullService ? !!this.state.specificLocation.fullServicePrice : true) && (this.state.specificLocation.isOnSite ? !!this.state.specificLocation.onSitePrice : true) && (this.state.specificLocation.isWhiteGlove ? !!this.state.specificLocation.whiteGlovePrice : true) && (this.state.specificLocation.isRunner ? !!this.state.specificLocation.runnerPrice : true)))
  };

  handleNavigation = (pathname: string, param?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), pathname);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    param && message.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
    runEngine.sendMessage(message.id, message)
    localStorage.setItem('isChanged', JSON.stringify(false))
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.isChanged !== this.state.isChanged) {
      localStorage.setItem("isChanged", JSON.stringify(this.state.isChanged))
    }

  }

  getIcon = (fileExist: string) => { return fileExist ? <CheckCircleIcon style={{ height: "16px", color: "#26BBB3" }} /> : <WarningIcon style={{ height: "16px", color: "#F05E22" }} /> }

  handleFocus = () => {
    this.setState({ label: 'State' });
  };

  addAnotherStateButtonClick = () => {
    this.setState((prevState) => {
      const { specificLocation, uploadBondingRegistration, uploadDealerLicense, uploadBusinessLicense, uploadDMVContract, uploadRegistrationServiceLicense } = prevState;

      // Combine active state data with the uploadBondingRegistration
      const newLocation = {
        ...specificLocation,
        uploadBondingRegistration,
        uploadDealerLicense,
        uploadBusinessLicense,
        uploadDMVContract, uploadRegistrationServiceLicense
      };

      return {
        locations: [...prevState.locations, newLocation],
        specificLocation: this.blankObject, // Reset specificLocation
        uploadBondingRegistration: null, // Reset upload states
        uploadDealerLicense: null,
        uploadBusinessLicense: null,
        uploadDMVContract: null,
        uploadRegistrationServiceLicense: null,
      };
    });
  };


  getServicibleLocationStateName = () => {
    const serviceableLocations = this.state.locations
    const availableStates = this.state.serviceProviderProDetails.test;

    const filteredStates = availableStates.filter(({ value }) =>
      !serviceableLocations.some(
        (serviceLocation: { stateName: string; }) => serviceLocation.stateName === value
      )
    );

    return filteredStates.map(({ displayValue, stateISOCode }) => ({
      label: displayValue,
      stateISOCode,
    }));
  };

  // Customizable Area End
} 