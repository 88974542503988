// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Menu,
  MenuItem,
  styled,
} from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import MultipleDealership from "./MultipleDealership.web";
import SavedDeal from "./SavedDeal.web";
import { Close } from "@material-ui/icons";

export interface Props {
  dealerships: any;
  savedDeals: any;
  navigation: any;
  fullWidth: boolean;
}
const DealButton = styled(Button)({
  display: "flex",
  minWidth: '110px',
  maxHeight: "44px",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
  borderRadius: "8px",
  cursor: "pointer",
  backgroundColor: "#4FC8EC",
  color: "#FFF",
  fontFamily: "Roboto, sans-serif",
  fontSize: "12px",
  fontWeight: 700,
  textTransform: 'none',
  [`@media (min-width:1920px)`]: {
    fontSize: "16px",
  },
  "&:hover": {
    backgroundColor: "#4FC8EC",
  },

});
const webStyles = {
  inviteUserButton: {
    display: "flex",
    height: "44px",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "#4FC8EC",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    whiteSpace: "nowrap",
    padding: "10px 16px",
  }
};

interface S {
  anchorEl: HTMLElement | null;
  CreateDealStatus: string;
  open: boolean;
  menuWidth: number | null;
}

interface SS { }

export class CreateDealButton extends BlockComponent<Props, S, SS>
  implements IBlock {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
      CreateDealStatus: "",
      open: false,
      menuWidth: null,
    };
  }

  navigateToCreateDeal = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CreateDealForm");
    this.send(msg);
  };

  handleClick = (event: any) => {
    const { dealerships, savedDeals } = this.props;
    if (dealerships?.length === 1 && savedDeals?.length === 0) {
      localStorage.setItem("dInfoId", dealerships[0].id);
      this.navigateToCreateDeal();
      return;
    }
    this.setState({ anchorEl: event.currentTarget, menuWidth: event.currentTarget.clientWidth, });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleStartFromScratchClick() {
    const { dealerships } = this.props;
    if (dealerships?.length === 1) {
      localStorage.setItem("dInfoId", dealerships[0].id);
      this.navigateToCreateDeal();
      return;
    }
    this.handleClickOpen();
  }

  ExtendedMenuItem = styled(MenuItem)(({ theme }) => ({
    "&:hover": {
      backgroundColor: "#F0FAFA"
    }
  }));

  StyledMenu = styled(Menu)(({ theme }) => ({
    "& > div:nth-child(3)": {
      borderRadius: "8px",
      // marginTop: "45px",

      "& > .MuiList-padding": {
        padding: 0,

        "& > .MuiMenuItem-root": {
          height: '44px',
          fontSize: '12px',
          [`@media (min-width:1920px)`]: {
            height: "56px",
          fontSize: '14px',
          },
        }
      }
    }
  }));

  BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1)
    },
    "& .MuiPaper-root": {
      maxWidth: "1230px",
      height: "714px",
      borderRadius: "24px",
      padding: "24px"
    }
  }));

  render() {
    return (
      <>
        <DealButton
          fullWidth={this.props.fullWidth}
          data-test-id="create-deal-btn"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
        // className={"inviteUserButton"}
        >
          {this.props.children}
        </DealButton>
        <this.StyledMenu
          id="simple-menu"
          data-test-id="create-deal-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.setState({ anchorEl: null });
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          MenuListProps={{
            style: {
              minWidth: this.state.menuWidth ?? undefined,
            }
          }}
        >
          <this.ExtendedMenuItem
            data-test-id="start-from-scratch"
            onClick={() => {
              this.setState({ CreateDealStatus: "Start From Scratch" });
              this.handleStartFromScratchClick();
              this.setState({ anchorEl: null });
            }}
          >
            Start from Scratch
          </this.ExtendedMenuItem>
          <this.ExtendedMenuItem
            data-test-id="saved-deal"
            onClick={() => {
              this.setState({ CreateDealStatus: "Saved Deal" });
              this.handleClickOpen();
              this.setState({ anchorEl: null });
            }}
          >
            Saved Deals
          </this.ExtendedMenuItem>
        </this.StyledMenu>
        <this.BootstrapDialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center"
            }}
          >
            <Close
              style={{ height: "32px", width: "32px" }}
              color="inherit"
              onClick={this.handleClose}
            />
          </Box>
          <DialogContent>
            {this.state.CreateDealStatus == "Start From Scratch" ? (
              <MultipleDealership
                checkHeader="true"
                handleModal={this.handleClose}
                navigation={this.props.navigation}
              />
            ) : null}
            {this.state.CreateDealStatus == "Saved Deal" ? (
              <SavedDeal
                checkHeader="true"
                handleModal={this.handleClose}
                navigation={this.props.navigation}
                showSearchBar={true}
              />
            ) : null}
          </DialogContent>
          <DialogActions></DialogActions>
        </this.BootstrapDialog>
      </>
    );
  }
}

export default CreateDealButton;
// Customizable Area End
