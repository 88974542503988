import React from "react";
// Customizable Area Start
import ConfirmFeesDealerController, {
  Props,
} from "./ConfirmFeesDealerController.web";
import {
  Box,
  Avatar,
  withStyles,
  createStyles,
  Button,
  Badge,
  ThemeProvider,
  createTheme,
  Typography,
  Card,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { vitu, notifications, menu } from "./assets";
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";
import Pushnotifications from "../../pushnotifications/src/Pushnotifications.web";


import clsx from "clsx";
const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: '100%',
    height: '100%',
    overflow: 'scroll'
  },

  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 32px",
    borderBottom: "1px solid #F0FAFA"
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "48px"
  },

  menuLogo: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px"
  },

  hamburgerButton: {
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
    minWidth: "0",

    "& > img": {
      width: "100%",
      height: "100%"
    }
  },

  logo: {
    width: "52px",

    "& > img": {
      width: "100%"
    }
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px"
  },

  notificationIcon: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",

    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(-40%, 30%)"
    }
  },

  avatarContainer: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",

    "& > img": {
      width: "100%"
    }
  },

  avatar: {
    width: "100%",
    height: "100%",
    borderRadius: "24px"
  },

  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    height: '100%'
  },

  formFee: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },

  formFeeHeader: {
    width: '900px',
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },

  cardContainer: {
    boxShadow: "0px 0px 4px 0px #8989895C",
    borderRadius: "10px",
    minWidth: "810px",
    padding: "30px 45px 30px 45px",
  },

  cardPanelContainer: {
    gap: '35px',
    display: "flex",
    justifyContent: "space-between",
  },

  cardPanel: {
    boxShadow: "none",
    overflow: 'visible'
  },

  progressMenu: {
    padding: "6px 2px 6px 2px",
    borderRadius: "26px"
  },

  inProgress: {
    color: "#346472",
    border: "1px solid #346472"
  },

  feeDetailsContainers: {
    padding: "40px 70px",
    gap: "40px",
    width: "760px",
    borderRadius: "10px",
    border: "1px solid #F1F4F3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px"
  },

  feeDetailsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },

  editFeeBtn: {
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    border: "1px solid #4C4D4C",
    "& span": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "left",
      textTransform: "initial"
    }
  },

  feeDetailsFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingTop: "23px",
    borderTop: "1px solid #F1F4F3"
  },

  rejectBtn: {
    width: '183px',
    borderRadius: "8px",
    padding: '16px 0px',
    border: "1px solid #4C4D4C",
    height: 44,
    "& span": {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "initial",
      [`@media (min-width:1920px)`]: {
        fontSize: 16,
      },
    },
    [`@media (min-width:1920px)`]: {
      height: 56,
    },
  },
  acceptBtn: {
    width: '355px',
    padding: '16px 0px',
    borderRadius: "8px",
    background: "#4FC8EC",
    height: 44,
    "& span": {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#FFFFFF",
      textTransform: "initial",
      [`@media (min-width:1920px)`]: {
        fontSize: 16,
      },
    },
    "&:hover": {
      backgroundColor: "#4FC8EC",
      color: "#FFFFFF"
    },
    [`@media (min-width:1920px)`]: {
      height: 56,
    },
  },
  totalFee: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px"
  },
  totalFeeLabel: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#4B4C4B",
    [`@media (min-width:1920px)`]: {
      fontSize: 18,
    },
  },
  totalFeeValue: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    color: "#013D4F",
    [`@media (min-width:1920px)`]: {
      fontSize: 28,
    },
  },
  disabledBorder: {
    borderColor: "#C5CBC9"
  },

  dialog: {
    height: 'auto',
    '& > div:nth-child(3) > div:nth-child(1)': {
      borderRadius: '8px',
      boxShadow: 'none',
    },
  },

  rejectDialog: {
    '& > div:nth-child(3) > div:nth-child(1)': {
      height: '45%'
    },
  },

  acceptDialog: {
    '& > div:nth-child(3) > div:nth-child(1)': {
      height: 'auto'
    },
  },

  dialogHeader: {
    padding: '18px 16px 8px 40px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '544px',
    height: '32px',
    borderBottom: '1px solid rgba(241, 244, 243, 1)',
  },

  dialogTitle: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '32px',
    textAlign: 'left',
    color: '#013D4F',
    [`@media (min-width:1920px)`]: {
      fontSize: 24,
    },
  },

  dialogSubTitle: {
    textWrap: 'wrap',
    fontSize: 14,
    fontWeight: 400,
    marginTop: '16px',
    [`@media (min-width:1920px)`]: {
      fontSize: 18,
    },
  },

  dialogContent: {
    padding: '25px 40px',
    flex: 'inherit'
  },

  dialogActions: {
    textTransform: 'initial',
    padding: '8px 35px',
    borderTop: '1px solid rgba(242, 243, 242, 1)',
    gap: '16px',

    '& > button': {
      width: '100%',
      height: '44px',
      padding: '16px',
      gap: '8px',
      borderRadius: '8px',
      opacity: '0px',
      margin: '8px 0px !important',
      [`@media (min-width:1920px)`]: {
        height: '56px',

      },
    },

    '& > button > span': {
      fontFamily: 'Roboto',
      fontWeight: 700,
      lineHeight: '24px',
      textAlign: 'left',
      textTransform: 'initial',
      fontSize: 12,
      [`@media (min-width:1920px)`]: {
        fontSize: 16,
      },

    },
  },

  disagreeBtn: {
    border: '1px solid rgba(76, 77, 76, 1)',

    '& > span': {
      color: 'rgba(76, 77, 76, 1)',
    },
  },

  agreeBtn: {
    background: '#4FC8EC',
    '&:hover': {
      background: '#4FC8EC',
    },

    '&.Mui-disabled': {
      background: '#E8F8FD',
      '& > span': {
        color: '#94A3B8',
      },
    },

    '& > span': {
      color: '#FFFFFF',
    },
  },

  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },

  changeReason: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#C5CBC9",
    margin: 0,
    display: "block"
  },
  oldPrice: {
    marginRight: "4px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    color: "#C5CBC9",
    textDecoration: "line-through"
  },
});

export const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "24px",
      fontWeight: 300,
      lineHeight: "40px",
      color: "#013D4F",
      letterSpacing: 0,
      [`@media (min-width:1920px)`]: {
        fontSize: 30,
      },
    },
    h2: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: "26px",
      color: "#4B4C4B",
      letterSpacing: 0,
      [`@media (min-width:1920px)`]: {
        fontSize: 18,
      },
    },
    h3: {
      fontFamily: "Gotham, sans-serif",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "26px",
      color: "#4B4C4B",
      letterSpacing: 0,
      [`@media (min-width:1920px)`]: {
        fontSize: 20
      },

    },
    h4: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "26px",
      color: "#4B4C4B",
      letterSpacing: 0,
      [`@media (min-width:1920px)`]: {
        fontSize: 20
      },
    },
    body1: {
      fontFamily: "Roboto",
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "31px",
      color: "#939F9B",
      textWrap: 'nowrap',
      [`@media (min-width:1920px)`]: {
        fontSize: 14
      },
    },
    body2: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "31px",
      textAlign: "left",
      textWrap: 'nowrap',
      color: '#4B4B4B',
      [`@media (min-width:1920px)`]: {
        fontSize: 20
      },
    }
  }
});
export class ConfirmFeesDealer extends ConfirmFeesDealerController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    const { classes } = this.props;
    const { dealInfo, status } = this.state;

    let cardPanelInfos = {
      ['ID: ' + dealInfo.id]: dealInfo.serviceProviderName,
      "CUSTOMER'S LAST NAME": dealInfo.customerLastName,
      STATES: dealInfo.fromState + " > " + dealInfo.toState,
      APPLICATION: dealInfo.application,
      'SERVICES': dealInfo.service,
      'VEHICLE TYPE': dealInfo.vehicleType
    } as { [key: string]: string };

    const renderModal = () => {
      return (
        <Dialog
          data-test-id="accept-reject-fee-dialog"
          open={status !== ''}
          keepMounted
          onClose={this.cancelHandler}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            style: {
              height: 'auto'
            },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          className={clsx(classes.dialog, {
            [classes.rejectDialog]: status === 'reject',
            [classes.acceptDialog]: status === 'accept',
          })}
        >
          <Box className={classes.dialogHeader}>
            <ClearIcon
              data-test-id="close-icon"
              onClick={this.cancelHandler}
            />
          </Box>
          <DialogContent className={classes.dialogContent}>
            <Box className={classes.dialogTitle}>
              {status === 'reject'
                ? 'Are you sure you want to reject this fee?'
                : 'Are you sure you want to accept this fee?'}
            </Box>
            <Typography variant="body2" className={classes.dialogSubTitle}>
              {status === 'reject'
                ? 'Once rejected, you can either hire a new service provider or continue this deal without a service provider.'
                : `Once accepted, we will hold an amount of $${dealInfo.registrationFee} until you confirm the work has been completed.`}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogActions} style={{ display: status === 'reject' ? 'block' : 'flex' }}>
            {status === 'reject' && <>
              <Button
                data-test-id="reject-btn1"
                className={classes.agreeBtn}
                onClick={this.rejectAndHireNewSPHandler}
              >
                Reject & Hire A New Service Provider
              </Button>
              <Button
                data-test-id="reject-btn2"
                className={classes.disagreeBtn}
                onClick={this.rejectAndContinueDealHandler}
              >
                Reject & Continue Deal With No Service Provider
              </Button>
              <Button
                data-test-id="reject-btn3"
                onClick={this.cancelHandler}
              >
                Cancel
              </Button>
            </>}

            {status === 'accept' && <>
              <Button
                data-test-id="cancel-btn"
                className={classes.disagreeBtn}
                onClick={this.cancelHandler}
                style={{ width: '120px' }}
              >
                Cancel
              </Button>
              <Button
                data-test-id="submit-btn"
                className={classes.agreeBtn}
                onClick={this.acceptAndHoldFeesHandler}
                style={{ width: '291px' }}
              >
                Accept & Hold Fees
              </Button>
            </>}

          </DialogActions>
        </Dialog>
      )
    }

    return <ThemeProvider theme={theme}>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Box className={classes.headerLeftSide}>
            <Box className={classes.menuLogo}>
              <Button className={classes.hamburgerButton}>
                <img src={menu} alt="menu button" />
              </Button>

              <Box className={classes.logo}>
                <img src={vitu} alt="vitu logo" />
              </Box>
            </Box>
          </Box>

          <Box className={classes.headerRightSide}>
            <Box
              data-test-id="notifications"
              className={classes.notificationIcon}
            >
              <Pushnotifications navigation={undefined} id={""} />
            </Box>

            <Box className={classes.avatarContainer}>
              <ProfileDropdown
                navigation={this.props.navigation}
                id={""}
                data-test-id="profileDropdownTestId" />
            </Box>
          </Box>
        </Box>
        <Box className={classes.main}>
          <Box className={classes.formFee}>
            <Box className={classes.formFeeHeader}>
              <Typography variant="h1">Confirm Fees</Typography>
              <Box sx={{ marginTop: "12px", marginBottom: "60px" }}>
                <Typography variant="h2">
                  Here are the fees requested by the Service Provider to complete this deal. Upon your acceptance, we will hold this amount until you agree the Service Provider has completed the work.
                </Typography>
              </Box>
            </Box>
            <Box className={classes.cardContainer}>
              <Box className={classes.cardPanelContainer}>
                {Object.entries(cardPanelInfos).map(([key, value]) => (
                  <Card className={classes.cardPanel} key={key}>
                    <Typography variant="body1">{key}</Typography>
                    <Typography variant="body2">{value}</Typography>
                  </Card>
                ))}
              </Box>
              <Box style={{ display: 'flex', backgroundColor: '#F0FAFA', borderRadius: '5px', width: '88px', height: '25px', justifyContent: 'space-around', alignItems: 'center', padding: '0px 10px', marginTop: '4px' }}>
                <ThumbUpAltOutlinedIcon style={{ color: '#4B4C4B', width: '15px', height: '15px' }} />
                <Box style={{ display: 'flex' }}>
                  <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight: 500 }}>86</Typography>
                  <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight: 500 }}>%</Typography>
                </Box>
                <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight: 500 }}>Positive</Typography>
              </Box>
            </Box>
            <Box className={classes.feeDetailsContainers}>
              <Box className={classes.feeDetailsHeader}>
                <Typography variant="h1">Registration Fee</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box>
                  <Typography variant="h3">Registration Fee</Typography>
                  {dealInfo && (
                    <span className={classes.changeReason}>
                      *Change because: "{dealInfo.reason}"
                    </span>
                  )}
                </Box>
                <Box>
                  <Typography variant="h4">
                    {dealInfo.oldRegistrationFee && dealInfo.oldRegistrationFee !== dealInfo.registrationFee && (
                      <span className={classes.oldPrice}>${dealInfo.oldRegistrationFee}</span>
                    )}
                    ${dealInfo.registrationFee}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Button className={classes.rejectBtn} onClick={this.rejectHandler}>Reject</Button>
                <Button className={classes.acceptBtn} onClick={this.acceptHandler}>Accept & Hold Fees</Button>
                <Box className={classes.totalFee}>
                  <Typography className={classes.totalFeeLabel}>
                    Total Fee
                  </Typography>
                  <Typography className={classes.totalFeeValue}>
                    ${dealInfo.registrationFee}
                  </Typography>
                  {(dealInfo.oldRegistrationFee && (dealInfo.oldRegistrationFee !== dealInfo.registrationFee)) && 
                    <Typography style={{textDecoration: 'line-through', fontSize: '20px'}}>${dealInfo.oldRegistrationFee}</Typography>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {renderModal()}
    </ThemeProvider>
  }
}

export default withStyles(styles)(ConfirmFeesDealer);
// Customizable Area End
