import React from "react";

// Customizable Area Start
import {
    Box, Grid, IconButton, Typography, withStyles,
    TextField, Button,
    styled
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
// Customizable Area End

import SendToNotesController, { configJSON, Props } from "./SendToNotesController.web";

// Customizable Area Start
const StyledTextField = withStyles({
    root: {
        "& .MuiInputBase-root": {
            fontFamily: "Roboto",
            color: "#013D4F",
            backgroundColor: "#ffffff",
            fontSize: 14,
            fontWeight: 400,
            height: 56
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #C5CBC9",
            borderRadius: 8
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #CBD5E1",
            borderRadius: 8
        },
        "& .MuiOutlinedInput-root:hover": {
            borderRadius: 8
        },
        "& .MuiSvgIcon-root": {
            display: 'none'
        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: 0
        },
        "& .MuiFormHelperText-root": {
            marginTop: 5,
            fontSize: 14
        }
    },
    "& .MuiInputBase-input::placeholder": {
        color: "#4B4C4B",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px"

    }
})(TextField);
const StyledTextFieldXInformatation = withStyles({
    root: {
        "& .MuiInputBase-root": {
            fontFamily: "Roboto",
            color: "#013D4F",
            backgroundColor: "#ffffff",
            fontSize: 14,
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #C5CBC9",
            borderRadius: 8
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #CBD5E1",
            borderRadius: 8
        },
        "& .MuiOutlinedInput-root:hover": {
            borderRadius: 8
        },
        "& .MuiSvgIcon-root": {
            display: 'none'
        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: 0
        },
        "& .MuiFormHelperText-root": {
            marginTop: 5,
            fontSize: 14
        }
    },
    "& .MuiInputBase-input::placeholder": {
        color: "#4B4C4B",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px"

    }
})(TextField);


const StyledSendToNotesBox = styled(Box)(({ theme }) => ({
    position: 'fixed',
    right: 10,
    width: '400px',
    color: '#fff',
    zIndex: 99,
    bottom: '0px',
    boxShadow: "0px 8px 32px 0px #0000000F",
    "& .cancelButton": {
        color: "#4B4C4B",
        fontSize: 12,
        lineHeight: "24px",
        fontWeight: 700,
        width: "80%",
        textTransform: 'none' as const,
        height: '40px',
        borderRadius: 8,
        [theme.breakpoints.up(1920)]: {
            fontSize: 16,
            height: '56px',
        },
    },
    "& .sendButton": {
        color: "#fff",
        background: "#4FC8EC",
        fontSize: 12,
        lineHeight: "24px",
        fontWeight: 700,
        width: "80%",
        textTransform: 'none' as const,
        height: '40px',
        borderRadius: 8,
        [theme.breakpoints.up(1920)]: {
            fontSize: 16,
            height: '56px',
        },
    },
    "& .headingText": {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "20px",
        color: '#013D4F',
        [theme.breakpoints.up(1920)]: {
            fontSize: 20,
        }
    },
    "& .subHeadingText": {
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "20px",
        color: '#013D4F',
        [theme.breakpoints.up(1920)]: {
            fontSize: 14,
        },
    },

}))

// Customizable Area End

export default class SendToNotes extends SendToNotesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    render() {
        // Customizable Area Start
        const { state, props } = this;
        const { notesFloatingWindow, missingForm } = state;
        const { selectedFile } = props
        return (
            <>
                {notesFloatingWindow && <StyledSendToNotesBox >
                    <Box>
                        <Box style={webStyles.chatHeadingLayout}>
                            <Box display={'flex'} flexDirection={'row'} style={{ gap: '12px' }}>
                                <Box>
                                    <ErrorOutlineOutlinedIcon style={webStyles.warningIcon} />
                                </Box>
                                <Box>
                                    <Box display={'flex'} flexDirection={'column'} style={{ gap: '2px' }}>
                                        <Typography component={'h6'} data-test-id='heading_text' className={"headingText"}>{configJSON.raiseIssue}</Typography>
                                        <Typography component={'h6'} className={"subHeadingText"}>{selectedFile.filename}</Typography>

                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item >
                                            {this.state.minimizeChats ? <IconButton
                                                onClick={this.handleMinimizeWindow}
                                                data-test-id='expand_more'
                                            >
                                                <ExpandMoreIcon style={webStyles.iconButton} />
                                            </IconButton> :
                                                <IconButton
                                                    data-test-id='expand_less'

                                                    onClick={this.handleMinimizeWindow}
                                                >
                                                    <ExpandLessIcon style={webStyles.iconButton} />
                                                </IconButton>}
                                        </Grid>
                                        <Grid item >
                                            <IconButton onClick={this.handleCloseNoteFloating}>
                                                <ClearIcon style={webStyles.iconButton} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        {this.state.minimizeChats && <Box style={webStyles.chatContentLayout as React.CSSProperties}>
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: "29px"
                            }}>
                                <Box>
                                    <StyledTextField
                                        onChange={this.handleChangeNotesForm}
                                        variant="outlined"
                                        value={missingForm.information}
                                        name="information"
                                        data-test-id="missingInput"
                                        fullWidth
                                        placeholder={configJSON.placeHolders.missingPlaceHolder}
                                        error={Boolean(this.state.missingFormErrors.informationError)}
                                        helperText={this.state.missingFormErrors.informationError}

                                    />
                                </Box>
                                <Box>
                                    <StyledTextFieldXInformatation
                                        onChange={this.handleChangeNotesForm}
                                        name="xInformation"
                                        value={missingForm.xInformation}
                                        variant="outlined"
                                        data-test-id="missingXInput"
                                        multiline
                                        minRows={6}
                                        maxRows={7}
                                        fullWidth
                                        placeholder={configJSON.placeHolders.missingXPlaceHolder}
                                        error={Boolean(this.state.missingFormErrors.xInformationError)}
                                        helperText={this.state.missingFormErrors.xInformationError}

                                    />
                                </Box>
                            </Box>
                            <Box style={webStyles.buttonPosition}>
                                <Box style={webStyles.buttonLayout}>
                                    <Button variant="contained"
                                        onClick={this.validateForm}
                                        data-test-id="sendButton"
                                        className={"sendButton"}>
                                        {configJSON.noteButton.send}
                                    </Button>

                                </Box>
                                <Box style={webStyles.buttonLayout}>
                                    <Button variant="outlined"
                                        onClick={this.handleCloseNoteFloating}
                                        className={"cancelButton"}>
                                        {configJSON.noteButton.cancel}
                                    </Button>

                                </Box>

                            </Box>

                        </Box>}

                    </Box>
                </StyledSendToNotesBox>}

            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyles = {

    chatHeadingLayout: {
        backgroundColor: "#F0FAFA",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '10px 10px 0px 0px',
    },
    iconButton: {
        color: '#013D4F'
    },
    chatContentLayout: {
        background: '#fff',
        padding: "35px"
    },

    buttonLayout: {
        width: '100%',
        textAlign: 'center' as const
    },
    buttonPosition: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: "21px",
        marginTop: "48px"

    },
    warningIcon: {
        color: "#013D4F",
        width: 20,
        height: 20
    }
}
// Customizable Area End
