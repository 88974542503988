import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
export interface GetUserResponseSuccess {
  data: Data;
}
export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  is_on_board_complete: boolean;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name?: null;
  role: string;
  profile_picture?: null;
  group_created_on?: null;
  is_deal_pending: boolean;
  deal_data?: null;
  solo_dealership_id: number;
}

export interface ReceivedIssue{
  id: number;
  file_name: string;
  deal_id: number;
  days_ago: number;
  dealership_name: string;
  service_provider_name: string;
  customer_last_name: string;
  vin: string;
}

export interface ResponseDataBE {
  homepage_ongoing_deals: number,
  homepage_inprogress_deals: number,
  homepage_no_proivder_assinged_deals: number,
  homepage_in_dealer_review_deals: number,
  homepage_issue_sent_deals: number,
  total_deals: number;
  ongoing_total?: number,
  pending_hire_total?: number,
  deleted_total?: number,
  draft_total?: number,
  completed_total?: number,

  ongoing: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  pending_hire: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  deleted: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  completed: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  draft: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
}
export interface OngoingOrPendingHireOrDeletedOrCompletedOrDraft {
  data?: (DataEntity)[] | null;
}
export interface DataEntity {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  id: number;
  updated_at: string;
  application_type: string;
  registration_type: string;
  vehicle_vin: string;
  vehicle_type: string;
  vehicle_year: string;
  vehicle_weight_empty: string;
  vehicle_weight_gross: string;
  sale_type: string;
  sold_as: string;
  msrp: string;
  tsp: string;
  seller_zip: string;
  owner_first_name: string;
  owner_last_name: string;
  purchaser_zip: string;
  first_owner?: null;
  second_owner?: null;
  make?: null;
  odometer?: null;
  body_type?: null;
  is_draft: boolean;
  deal_documents?: (null)[] | null;
  date_created: string;
  deal_status: string;
  from_state: string;
  to_state: string;
  dealership_detail: DealershipDetail;
  account: Account;
  deal_documents_ids?: (null)[] | null;
  fees_data?: null;
  service_provider?: null;
  service?: null;
  date_accepted?: null;
}
export interface DealershipDetail {
  data: Dataa;
}
export interface Dataa {
  id: string;
  type: string;
  attributes: Attributes1;
}
export interface Attributes1 {
  dealership_name: string;
  business_credentials: string;
  deal_type?: (string)[] | null;
  created_at: string;
  updated_at: string;
  dealership_users: number;
  addresses: Addresses;
  bank_information: BankInformation;
  dealership_logo?: null;
  w9_forms?: (null)[] | null;
}
export interface Addresses {
  data?: (DataEntity1)[] | null;
}
export interface DataEntity1 {
  id: string;
  type: string;
  attributes: Attributes2;
}
export interface Attributes2 {
  address: string;
  address_type: string;
  country: string;
  city: string;
  state: string;
  zipcode: string;
  zipcode_ext: string;
}
export interface BankInformation {
  data?: (null)[] | null;
}
export interface Account {
  data: Data1;
}
export interface Data1 {
  id: string;
  type: string;
  attributes: Attributes3;
}
export interface Attributes3 {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name: string;
  role: string;
  profile_picture?: null;
  group_created_on: string;
  is_dealership_pending: boolean;
  dealership_data?: null;
  solo_dealership_id?: null;
  is_any_dealership: boolean;
  solo_dealership_data?: null;
  assigned_dealership_ids?: null;
}

interface ResponseError {
  errors: [
    { message: string }
  ]
}

export interface TableRows {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated?: string;
  dateAccepted?: string;
  service?: string;
  dateSent?: string;
  dateReceived?: string;
  payout?: string;
  selected_service_provider_id?: any;
  status: string;
  service_id?: string;
  service_provider?: string;
}

export interface ResponseBESp {
  homepage_accepted_deals: number,
  homepage_inprogress_deals: number,
  homepage_pending_deals: number,
  homepage_dealer_flags_incomplete_deals:number,
  homepage_in_dealer_review_deals: number,
  accepted_total?: number,
  rejected_or_expired_total?: number,
  pending_total?: number,
  deleted_total?: number,
  total_deals: number;
  total_accepted_today: number;
  accepted: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  rejected_or_expired: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  deleted: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  pending: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
}

interface ThirdTableDataDealer {
  id: string;
  customerLastName: string;
  provider: string;
  service: string;
  date: string;
}


interface DealerReviewPendingDeal {
  id: number;
  owner_last_name: string;
  date_completed: string;
  service_provider?: string | null;
  dealer?: string | null;
  service: string | null;
}

interface DealSummary {
  id: string;
  type: string;
  attributes: DealerReviewPendingDeal;
}

interface DealerReviewPendingDealResponse {
  total_deals: number;
  dealer_review_pending_deal: {
    data: DealSummary[];
  };
  dealer_review_received: DealerReviewReceived;
  dealer_review_given: DealerReviewGiven;
}

interface SpReviewDealResponse {
  total_deals: number;
  service_provider_review_pending_deal: {
    data: DealSummary[];
  };
  service_provider_review_received: DealerReviewReceived;
  service_provider_review_given: DealerReviewGiven;
}

interface DealerReviewReceived {
  reviews: {
    data: any[];
  };
  total_reviews_count: number;
  likes: number;
  dislikes: number;
}

interface DealerReviewGiven {
  reviews: {
    data: any[];
  };
  total_reviews_count: number;
  likes: number;
  dislikes: number;
}

interface Reviews {
  id: number;
  name: string;
  company: string;
  avatar: string;
  date: string;
  text: string;
  rating: boolean;
}

interface Dealership {
  id: number;
  dealership_name: string;
  dealership_logo?: string;
  state_name?: string;
  provider_name?: string;
  provider_logo?: string;
}

export interface IPieChart {
  [x: string]: any;
  total_deals: number
  in_progress: InProgress
  issue_sent_to_dealer: IssueSentToDealer
  resolved_in_dealer_review: ResolvedInDealerReview
  completed: Completed
  dealer_flags_incomplete: DealerFlagsIncomplete
}

export interface InProgress {
  count: number
  percentage: string
}

export interface IssueSentToDealer {
  count: number
  percentage: string
}

export interface ResolvedInDealerReview {
  count: number
  percentage: string
}

export interface Completed {
  count: number
  percentage: string
}

export interface DealerFlagsIncomplete {
  count: number
  percentage: string
}


// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabvalue: number,
  page: number,
  rowsPerPage: number,

  chartTabValue: number,
  dealer: string,
  reviewTab: number,
  role: string;


  homepage_ongoing_deals: number,
  homepage_inprogress_deals: number,
  homepage_no_proivder_assinged_deals: number,
  homepage_in_dealer_review_deals: number,
  homepage_issue_sent_deals: number,
  homepage_accepted_deals: number,
  homepage_pending_deals: number,
  homepage_dealer_flags_incomplete_deals:number,

  totaldeals: number;
  totalAcceptedToday: number;
  tableDataOngoingDealer: TableRows[],
  tableDataPendingDealer: TableRows[],
  tableDataDraftDealer: TableRows[],
  tableDataAcceptedSp: TableRows[],
  tablePendingDataSp: TableRows[],
  loading: boolean,
  accepted_total: number | undefined,
  pending_total: number | undefined,
  ongoing_total: number | undefined,
  completed_total: number | undefined,
  ThirdTableDataDealer: ThirdTableDataDealer[],
  reviewsReceived: Reviews[],
  reviewsGiven: Reviews[],
  totalReviews: number | null,
  likes: number | null,
  dislikes: number | null,
  totalReviewsGiven: number | null,
  likesGiven: number | null,
  dislikesGiven: number | null,
  ThirdTableDataSp: ThirdTableDataDealer[],
  totalReviewTableDeal: number,
  loadingReview: boolean,

  optionForDealer: Dealership[],
  selecteDealerId: number | null,
  fullname: string,
  draft_total: number | undefined,

  saveddeals: any,
  dealerships: any,
  steps: any,
  isTourOpen: any,
  tourMode: string,
  series: any;
  options: any;
  piChartData:IPieChart;

  receivedIssues: ReceivedIssue[];
  issueFilterKey: string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HomeDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  withDrawCallId: string = "";
  apiGetSavedDealsId: string = "";
  apiGetDealershipsId: string = "";
  changeNameApiCallId: string = "";
  getuserDataApiCallId: string = "";
  getDealDataApiCallId: string = "";
  sendContactUsApiCallId: string = "";
  getReviewDataApiCallId: string = "";
  setOnBoardingApiCallId: string = "";
  apiCallIdGetPieChartData: string = ""
  getDealershipListApiCallId: string = "";
  acceptOrRejectdealApiCallId: string = "";
  getReceivedIssuesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      tabvalue: 0,
      page: 0,
      rowsPerPage: 5,
      chartTabValue: 0,
      dealer: '',
      reviewTab: 0,
      role: '',

      homepage_ongoing_deals: 0,
      homepage_inprogress_deals: 0,
      homepage_no_proivder_assinged_deals: 0,
      homepage_in_dealer_review_deals: 0,
      homepage_issue_sent_deals: 0,
      homepage_accepted_deals: 0,
      homepage_pending_deals: 0,
      homepage_dealer_flags_incomplete_deals:0,

      totaldeals: 0,
      totalAcceptedToday: 0,
      tableDataOngoingDealer: [],
      tableDataPendingDealer: [],
      tableDataDraftDealer: [],
      tableDataAcceptedSp: [],
      tablePendingDataSp: [],
      loading: true,
      accepted_total: 0,
      pending_total: 0,
      ongoing_total: 0,
      completed_total: 0,
      ThirdTableDataDealer: [],
      reviewsReceived: [],
      reviewsGiven: [],
      totalReviews: 0,
      likes: 0,
      dislikes: 0,

      totalReviewsGiven: 0,
      likesGiven: 0,
      dislikesGiven: 0,
      ThirdTableDataSp: [],
      totalReviewTableDeal: 0,
      loadingReview: true,

      optionForDealer: [],
      selecteDealerId: null,
      fullname: '',
      draft_total: 0,
      saveddeals: [],
      dealerships: [],

      steps: [
        {
          target: '[data-test-id="logovitu"]',
          content: "Welcome to Vitu Marketplace! Whether you're a service provider or a dealer, you're now part of our community. Let's start with a quick walkthrough to help you make the most of our platform.",
          placement: "bottom-start",
          key: 'step1',
          offset: 15,
          heading: 'Welcome to Vitu!',
          disableBeacon: true,
        },
        {
          target: '[data-test-id="homeButtonTestId"]',
          content: "Keep track of deals, statistics, and all ongoing activity right here on your homepage.",
          placement: "right-start",
          key: 'step2',
          heading: 'Home Dashboard',
          offset: 15,
          disableBeacon: true,
        },
        {
          target: '[data-test-id="allDealsButtonTestId"]',
          content: "Deep dive into the status of your deals and find all their pertinent information here.",
          heading: 'All Deals',
          placement: "right-start",
          offset: 15,
          key: 'step3',
          disableBeacon: true,
        },
        {
          target: '[data-test-id="myTeamsButtonTestId"]',
          content: "Use this tab to view and manage your teams and users.",
          offset: 15,
          heading: 'My Teams',
          placement: "right-start",
          key: 'step4',
          disableBeacon: true,
        },
        {
          target: '.step-3',
          content: "Are you ready start? Create a deal now!",
          heading: 'Create a Deal',
          offset: 10,
          placement: "bottom-end",
          key: 'step5',
          disableBeacon: true,
        },
        {
          target: '.avatar',
          content: "You can restart the tour anytime from your profile.",
          heading: 'Tour Ended',
          offset: 10,
          placement: "bottom-end",
          key: 'step6',
          disableBeacon: true,
        },
      ],
      isTourOpen: true,
      tourMode: "",
      series: [],
      options: {
        plotOptions: {
          pie: {
            donut: {
              size: '75%',
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Deals',
                  fontSize: '14px',
                  color: 'rgba(197, 203, 201, 1)',
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  formatter: () => '840',
                },
              },
            },
          },
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      piChartData:{
        total_deals: 0,
        in_progress:{
          count: 0,
          percentage: ""
        },
        issue_sent_to_dealer: {
          count: 0,
          percentage: ""
        },
        resolved_in_dealer_review: {
          count: 0,
          percentage: ""
        },
        completed: {
          count: 0,
          percentage: ""
        },
        dealer_flags_incomplete: {
          count: 0,
          percentage: ""
        },
        deleted: {
          count: 0,
          percentage: ""
        }
      },
      receivedIssues: [],
      issueFilterKey: ''
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const productId = message.getData(getName(MessageEnum.SessionResponseData));
      if (productId?.loading) {
        this.setState({ loading: true, tourMode: "tour" })
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getuserDataApiCallId:
            this.handleUserData(responseJson)
            break;
          case this.getDealDataApiCallId:
            this.handleDealdataFromBE(responseJson)
            break;
          case this.getReviewDataApiCallId:
            this.handleReviewData(responseJson)
            break;
          case this.getDealershipListApiCallId:
            this.handleDealerhipList(responseJson)
            break;
          case this.apiGetDealershipsId:
            this.handlegetdealership(responseJson)
            break;
          case this.apiGetSavedDealsId:
            this.handledaveddeals(responseJson)
            break;
          case this.acceptOrRejectdealApiCallId:
            this.handleacceptorrejectdeal(responseJson)
            break;
          case this.withDrawCallId:
            console.log(responseJson);
            break;
          case this.apiCallIdGetPieChartData:
            this.handleApiResponcePiechat(responseJson);
            break;
          case this.getReceivedIssuesApiCallId:
            this.setState({
              receivedIssues: responseJson.errors ? [] : responseJson
            });
            break;
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  navigateToDealDashboard = (id: string) => {
    localStorage.setItem("createdDealId", id);
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectTemplatesDealDashboard"
    );
    this.send(msg);
  }

  handleacceptorrejectdeal = (responseJson: any) => {
    if (responseJson.data.message) {
      this.getDealsFromBe(0, 5, null, this.state.selecteDealerId)
    }
  }

  handlegetdealership = (responseJson: any) => {
    this.setState({ dealerships: responseJson.dealerships.data ? responseJson.dealerships.data : [] })
  }
  handledaveddeals = (responseJson: any) => {
    this.setState({ saveddeals: responseJson.data ? responseJson.data : [] });
  }

  async componentDidMount() {
    this.getUserDataFromBe();
    this.getDealershipsData()
    this.getSavedDealsData()
    this.handleChangeChartTab({} as React.ChangeEvent<{}>, 0);
    if(localStorage.getItem("resetOnboardingGuide") === 'true'){
      this.setState({
        tourMode: 'tour'
      })
    }
  }

  getReceivedIssues = async () => {
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReceivedIssuesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.role.includes('dealer') ? 
      `bx_block_profile/dealer_informations/received_issues?id=${this.state.selecteDealerId}` :
      `bx_block_profile/service_provider_informations/issues_sent?location_id=${this.state.selecteDealerId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDealershipList = async () => {
    const authtok = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": authtok
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealershipListApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_profile/dealer_informations/user_associated_dealerships'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  handleContinueDealer = (identificationnumber: any) => {
    setStorageData('dId', identificationnumber)
    const continuedeal = new Message(getName(MessageEnum.NavigationMessage));
    continuedeal.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    continuedeal.addData(getName(MessageEnum.NavigationTargetMessage), 'CreateDealForm')
    this.send(continuedeal);
  }

  handleReview = (reviewid: any) => {
    setStorageData('reviewid', reviewid)
    const reviewpage = new Message(getName(MessageEnum.NavigationMessage));
    reviewpage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage) );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { value: 'delaerShipReview' })
    reviewpage.addData(getName(MessageEnum.NavigationTargetMessage), 'MultilevelApproval')
    reviewpage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(reviewpage);
  }

  setOnBoarding = async () => {
    removeStorageData("resetOnboardingGuide");

    const authtok = await getStorageData('authToken')
    const Webheader = {
      "token": authtok
    };

    const formData = new FormData()
    formData.append("is_on_board_complete", 'true')

    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setOnBoardingApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/account_block/accounts/update_onboard_user'
    );

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  handleDealerhipList = (responseJson: Dealership[] & { errors: [] }) => {
    if (!responseJson.errors) {
      const isDealerRole = this.state.role === 'dealer' || this.state.role === 'dealer_admin';
      const updatedOptions = this.mapResponseData(responseJson, isDealerRole);

      this.setState({
        optionForDealer: updatedOptions,
        dealer: updatedOptions[0].dealership_name || '',
        selecteDealerId: updatedOptions[0].id || 0
      }, ()=>{this.getReceivedIssues()});

      const dealerId = isDealerRole ? updatedOptions[0].id : null;
      const serviceProviderId = isDealerRole ? '' : updatedOptions[0].id || '';
      this.getDealsFromBe(0, 5, dealerId, serviceProviderId);
    }
    else {
      this.getDealsFromBe(0, 5, null, '');
    }
  }

  mapResponseData(dataa: Dealership[] & { errors: [] }, isDealer: boolean) {
    return dataa.map((item) => ({
      id: item.id || 0,
      dealership_name: isDealer ? (item.dealership_name || 'NaN') : (item.provider_name || 'NaN'),
      dealership_logo: isDealer ? (item.dealership_logo || '') : (item.provider_logo || ''),
    }));
  }

  finddealername = (selectedDealer: Dealership | undefined) => {
    if (selectedDealer) return selectedDealer.dealership_name;
    else if (this.state.optionForDealer.length === 0) return '';
    else return this.state.optionForDealer[0].dealership_name;
  }

  findavatarsrc = (selectedDealer: Dealership | undefined) => {
    if (selectedDealer) return selectedDealer.dealership_logo;
    else if (this.state.optionForDealer.length === 0) return '';
    else return this.state.optionForDealer[0].dealership_logo;
  }

  handleDealdataFromBE = (responseJson: ResponseDataBE & ResponseBESp & ResponseError) => {
    this.setState({ loading: false })
    if (responseJson.errors) {
      this.setState({ totaldeals: 0, loading: false })
    }
    else if (this.checkRole()) {
      this.setOngoingdata(responseJson)
      this.setPendingData(responseJson)
      this.setDraftdata(responseJson)
      this.setState({
        loading: false,
        homepage_ongoing_deals: responseJson.homepage_ongoing_deals,
        homepage_inprogress_deals: responseJson.homepage_inprogress_deals,
        homepage_no_proivder_assinged_deals: responseJson.homepage_no_proivder_assinged_deals,
        homepage_in_dealer_review_deals: responseJson.homepage_in_dealer_review_deals,
        homepage_issue_sent_deals: responseJson.homepage_issue_sent_deals,
        completed_total: responseJson.completed_total,
        ongoing_total: responseJson.ongoing_total,
        pending_total: responseJson.pending_hire_total,
        totaldeals: responseJson.total_deals,
        draft_total: responseJson.draft_total
      })
    } else if (this.state.role === ('service_provider') || this.state.role === ('service_provider_admin')) {
      this.setAcceptedSpData(responseJson)
      this.setPendingDataSp(responseJson)
      this.setState({
        loading: false,
        homepage_accepted_deals: responseJson.homepage_accepted_deals,
        homepage_inprogress_deals: responseJson.homepage_inprogress_deals,
        homepage_pending_deals: responseJson.homepage_pending_deals,
        homepage_dealer_flags_incomplete_deals:responseJson.homepage_dealer_flags_incomplete_deals,
        homepage_in_dealer_review_deals: responseJson.homepage_in_dealer_review_deals,
        accepted_total: responseJson.accepted_total,
        pending_total: responseJson.pending_total,
        totaldeals: responseJson.total_deals,
        totalAcceptedToday: responseJson.total_accepted_today
      })
    }
  }
  gettabletwodisplay = () => {
    if (this.state.role === 'dealer' || this.state.role === 'dealer_admin') {
      return (this.state.draft_total != null && this.state.draft_total > 0) ? 'block' : 'none'
    }
    else {
      return 'block'
    }
  }

  handleReviewData = (responseJson: DealerReviewPendingDealResponse & SpReviewDealResponse) => {
    this.setState({ totalReviewTableDeal: responseJson.total_deals ? responseJson.total_deals : 0, loadingReview: false })
    if (this.state.role === 'dealer' || this.state.role === 'dealer_admin') {
      this.setTableThreeDataDealer(responseJson)

      const reviews = this.extractReviews(responseJson, 'received', 'dealer');
      const reviewsGiven = this.extractReviews(responseJson, 'given', 'dealer')
      if (reviews && reviewsGiven) {
        this.setState({ reviewsReceived: reviews, reviewsGiven: reviewsGiven })
      }


      const totalReviews = responseJson.dealer_review_received.total_reviews_count;
      const likes = responseJson.dealer_review_received.likes;
      const dislikes = responseJson.dealer_review_received.dislikes

      const totalReviewsGiven = responseJson.dealer_review_given.total_reviews_count;
      const likesGiven = responseJson.dealer_review_given.likes;
      const dislikesGiven = responseJson.dealer_review_given.dislikes
      this.setState({ totalReviews, likes, dislikes, totalReviewsGiven, likesGiven, dislikesGiven })
    }
    else if (this.state.role === 'service_provider' || this.state.role === 'service_provider_admin') {
      this.setTableThreeDataSp(responseJson)
      const reviews = this.extractReviews(responseJson, 'received', 'sp');
      const reviewsGiven = this.extractReviews(responseJson, 'given', 'sp')
      if (reviews && reviewsGiven) {
        this.setState({ reviewsReceived: reviews, reviewsGiven: reviewsGiven })
      }


      const totalReviews = responseJson.service_provider_review_received.total_reviews_count;
      const likes = responseJson.service_provider_review_received.likes;
      const dislikes = responseJson.service_provider_review_received.dislikes

      const totalReviewsGiven = responseJson.service_provider_review_given.total_reviews_count;
      const likesGiven = responseJson.service_provider_review_given.likes;
      const dislikesGiven = responseJson.service_provider_review_given.dislikes
      this.setState({ totalReviews, likes, dislikes, totalReviewsGiven, likesGiven, dislikesGiven })
    }
  }

  getPositivePercentage = (value1: number | null, value2: number | null) => {
    if (value1 && value2) {
      return (
        ((value1 / value2) * 100).toFixed(2)
      )
    }
    return 0;
  }

  setOngoingdata = (responseJson: ResponseDataBE) => {
    if (responseJson.ongoing.data) {
      const transformedData = responseJson.ongoing.data.slice(0, 5).map((deal: any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name,
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(),
        dateAccepted: deal.attributes.date_accepted ? new Date(deal.attributes.date_accepted).toLocaleDateString() : "-",
        service: deal.attributes.service || "-",
        status: deal.attributes.status,
      }));
      this.setState({ tableDataOngoingDealer: transformedData })
    }
  }

  setPendingData = (responseJson: ResponseDataBE) => {
    const pendingHireData = responseJson.pending_hire.data;
    const tableDataPendingDealer: TableRows[] = [];
    if (pendingHireData) {
      pendingHireData.forEach((hireData: any) => {
        const hiredServiceProviderData = hireData.attributes.hired_service_provider_data;
        hiredServiceProviderData.forEach((service: any) => {
          const transformedPendingdata = {
            id: hireData.attributes.id,
            customerLastName: hireData.attributes.owner_last_name,
            dealership: hireData.attributes.dealership_detail.data.attributes.dealership_name,
            states: `${hireData.attributes.from_state} > ${hireData.attributes.to_state}`,
            dateSent: new Date(hireData.attributes.date_created).toLocaleDateString(),
            status: service.status,
            service: service.service_name,
            service_id: service.id,
            service_provider: service.service_provider_name
          };
          tableDataPendingDealer.push(transformedPendingdata)
        });
      })
      this.setState({ tableDataPendingDealer: tableDataPendingDealer })
    }
  };

  setDraftdata = (responseJson: ResponseDataBE) => {
    if (responseJson.draft.data) {
      const transformedDraftddata = responseJson.draft.data.slice(0, 5).map((deal: any) => ({
        id: deal.attributes.id,
        customerLastName: deal.attributes.owner_last_name,
        dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(),
        status: deal.attributes.status,
      }));
      this.setState({ tableDataDraftDealer: transformedDraftddata })
    }
  }

  setAcceptedSpData = (responseJson: ResponseBESp) => {
    if (responseJson.accepted.data) {
      const transformedacceptedData = responseJson.accepted.data.slice(0, 5).map((deal: any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateReceived: new Date(deal.attributes.date_received).toLocaleDateString(),
        dateAccepted: deal.attributes.date_accepted ? new Date(deal.attributes.date_accepted).toLocaleDateString() : "-",
        service: this.parseServiceStringValue(deal.attributes.service),
        status: deal.attributes.status,
      }));
      this.setState({ tableDataAcceptedSp: transformedacceptedData })
    }
  }

  isToday(dateString: string) {
    const inputDate = new Date(dateString);
    const today = new Date();
  
    return (
      inputDate.getFullYear() === today.getFullYear() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getDate() === today.getDate()
    );
  }

  setPendingDataSp = (responseJson: ResponseBESp) => {
    if (responseJson.pending.data) {
      const transformedPendingSpData = responseJson.pending.data.slice(0, 5).map((deal: any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateReceived: new Date(deal.attributes.date_received).toLocaleDateString(),
        service: this.parseServiceStringValue(deal.attributes.service) || "-",
        payout: deal.attributes.fees_data?.payout || "N/A",
        status: 'Available',
        selected_service_provider_id: deal.attributes.selected_service_provider_id
      }));
      this.setState({ tablePendingDataSp: transformedPendingSpData })
    }
  }

  acceptOrRejectdeal = async (spId: any, action: string) => {
    const authtokn = await getStorageData('authToken')
    const Webheader = {
      "token": authtokn
    };
    const formData = new FormData()
    formData.append("status", action)

    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptOrRejectdealApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/select_service_providers/update_deal_status/${spId}`
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  getDealsFromBe = async (page: number, rowperpage: number, dealershipid: number | null, serviceable_location_id: number | string | null) => {
    serviceable_location_id = serviceable_location_id === null ? '' : serviceable_location_id;

    const authtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": authtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealDataApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/deals?page=${page + 1}&per_page=${rowperpage}&dealer_information_id=${dealershipid}`
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  checkRole = () => {
    return (this.state.role === 'dealer' || this.state.role === 'dealer_admin')
  }

  parseServiceStringValue = (serviceString: string): string => {
    let parsedValue = "-";
    if (serviceString) {
      const validJsonString = serviceString.replace(/=>/g, ":");
      if (validJsonString.startsWith("{") && validJsonString.endsWith("}")) {
        const serviceObject = JSON.parse(validJsonString);
        const firstKey = Object.keys(serviceObject)[0];
        parsedValue = firstKey || "-";
      }
    }
    return parsedValue;
  };

  gotoAlldeals = () => {
    const routetogo = new Message(getName(MessageEnum.NavigationMessage));
    routetogo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    routetogo.addData(getName(MessageEnum.NavigationTargetMessage), 'AllDeals')
    this.send(routetogo);
  }

  getTabColor = (condition: boolean, color1: string, color2: string) => {
    return (condition ? color1 : color2)
  }

  handleUserData = async (responseJson: GetUserResponseSuccess) => {
    if (responseJson.data.attributes) {
      this.setState({ role: responseJson.data.attributes.role, isTourOpen: this.state.tourMode === "tour" ? false : responseJson.data.attributes.is_on_board_complete, fullname: responseJson.data.attributes.full_name.split(" ")[0] },
        () => { this.getreviewData(0); this.getDealershipList(); this.loadOnboardingGuide() })
    }
  }
  loadOnboardingGuide = () => {
    if (this.state.role === 'service_provider' || this.state.role === 'service_provider_admin') {
      const updatedSteps = [...this.state.steps];
      updatedSteps[4] = {
        ...updatedSteps[4],
        content: "Requests from Dealers to hire your services will appear here and as a notification for you to accept.",
        heading: "Pending Deals",
        target: '.pendingdealsp',
        placement: 'top-center',
        offset: 25
      };
      this.setState({ steps: updatedSteps });
    }
  }

  getSubHeader = () => {
    if (this.state.role === 'dealer_admin' || this.state.role === 'dealer') {
      return 'Quick statistics on deals in your dealership'
    } else {
      return 'Quick statistics on deals in your account'
    }
  }

  getHeaderOfIssueAccordian = () => {
    if (this.state.role === 'dealer_admin' || this.state.role === 'dealer') {
      return `Attention Required (${this.state.receivedIssues.length})`
    } else {
      return 'Issue Sent to Dealer'
    }
  }

  getSubHeaderOfIssueAccordian = () => {
    if (this.state.role === 'dealer_admin' || this.state.role === 'dealer') {
      return 'Below are the documents that require your attention'
    } else {
      return 'These documents require Dealer attention'
    }
  }

  handleJoyrideCallback = (data: any) => {
    const { status, type } = data;
    if (['finished', 'skipped'].includes(status) && ['tour:end', 'skip'].includes(type)) {
      this.setOnBoarding()
    }
  };

  getreviewData = async (page: number) => {
    const newtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReviewDataApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reviews/dealer_summery?page=${page + 1}&per_page=5`
    );
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(Webheader));
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'GET');
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  setTableThreeDataDealer = (responseJson: DealerReviewPendingDealResponse) => {
    if (responseJson.dealer_review_pending_deal.data) {
      const pendingDeals = responseJson.dealer_review_pending_deal.data.map((deal: any) => ({
        id: deal.attributes.id,
        customerLastName: deal.attributes.owner_last_name,
        provider: deal.attributes.service_provider || 'N/A',
        service: this.parseServiceString(deal.attributes.service),
        date: deal.attributes.date_completed
      }));
      this.setState({ ThirdTableDataDealer: pendingDeals });
    }
  }

  setTableThreeDataSp = (responseJson: SpReviewDealResponse) => {
    if (responseJson.service_provider_review_pending_deal.data) {
      const pendingDealssp = responseJson.service_provider_review_pending_deal.data.map((deal: any) => ({
        id: deal.attributes.id,
        customerLastName: deal.attributes.owner_last_name,
        provider: deal.attributes.dealer || 'N/A',
        service: this.parseServiceString(deal.attributes.service),
        date: deal.attributes.date_completed
      }));
      this.setState({ ThirdTableDataSp: pendingDealssp });
    }
  }

  parseServiceString = (serviceString: string): string => {
    let parsedValue = "-";
    if (serviceString) {
      const validJsonString = serviceString.replace(/=>/g, ":");
      if (validJsonString.startsWith("{") && validJsonString.endsWith("}")) {
        const serviceObject = JSON.parse(validJsonString);
        const firstKey = Object.keys(serviceObject)[0];
        parsedValue = firstKey || "NA";
      }
    }
    return parsedValue;
  };

  extractReviews = (apiResponse: DealerReviewPendingDealResponse & SpReviewDealResponse, value: string, role: string) => {
    const { dealer_review_received, dealer_review_given, service_provider_review_given, service_provider_review_received } = apiResponse;
    const receivedlist = role === 'dealer' ? dealer_review_received : service_provider_review_received;
    const givenlist = role === 'dealer' ? dealer_review_given : service_provider_review_given;
    if (value === 'received') {
      return receivedlist.reviews.data.map((review: any) => {
        const {
          id,
          feedback,
          created_at,
          service_name,
          account,
          rating
        } = review.attributes;

        return {
          id: id,
          name: account.full_name,
          company: service_name || "N/A",
          avatar: account.profile_picture,
          date: created_at,
          text: feedback || "No feedback provided",
          rating: rating
        };
      });
    }
    else if (value === 'given') {
      return givenlist.reviews.data.map((review: any) => {
        const {
          id,
          feedback,
          created_at,
          service_name,
          account,
          rating
        } = review.attributes;

        return {
          id: id,
          name: account.full_name,
          company: service_name || "N/A",
          avatar: account.profile_picture,
          date: created_at,
          text: feedback || "No feedback provided",
          rating: rating
        };
      });
    }
  };

  getUserDataFromBe = async () => {
    const newtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserDataApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/get_user'
    );
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(Webheader));
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'GET');
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  getbuttontext = (indexx: number) => {
    if (indexx === 0) return 'Start Tour'
    else if (indexx === 4) return 'Done'
    else return 'Next'
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabvalue: newValue });
  };

  handleChangeChartTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ chartTabValue: newValue },()=>{
      if(newValue===0){
        this.getPieChartData('hired')
      }else{
        this.getPieChartData('notHired')

      }
    }
    );
  };

  handleReviewTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ reviewTab: newValue });
  }

  getDealershipsData = () => {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDealershipsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_profile/dealer_informations/get_dealerships?page=1&per_page=10'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSavedDealsData = () => {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSavedDealsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_posts/deals/get_saved_deals'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSelectUserDeal = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedDealershipName = event.target.value;
    const selectedDealership = this.state.optionForDealer.find(
      (option) => option.dealership_name === selectedDealershipName
    );

    this.setState({
      dealer: selectedDealership ? selectedDealership.dealership_name : '',
      selecteDealerId: selectedDealership ? selectedDealership.id : null,
    }, () => {
      this.checkRole() ? this.getDealsFromBe(0, 5, this.state.selecteDealerId, '') : this.getDealsFromBe(0, 5, null, this.state.selecteDealerId)
      this.getReceivedIssues();
    });
  }

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.setState({ page: newPage },
      () => { this.getreviewData(this.state.page) }
    )
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 })
  };

  hirenewHandler = (states: string, id: string) => {
    this.withdrawHandler(id)
    const fromState = states.split(">")[1].trim();
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ServiceProviderDashboard");
    this.send(msg);
    localStorage.setItem('state_code', fromState);
    localStorage.setItem('createdDealId', id);
  }

  withdrawHandler = async (id: string) => {
    const authToken = await getStorageData('authToken')
    const Webheader = {
      "token": authToken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const formData = new FormData();
    formData.append("status", 'withdrawn');

    this.withDrawCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/select_service_providers/update_deal_status/' + id
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);

    const updatedPendingDeal = this.state.tableDataPendingDealer.filter((deal) => deal.service_id !== id);
    this.setState({
      tableDataPendingDealer: updatedPendingDeal
    })
  }

  checkDisablitiy = (status: string, field: string) => {
    if(status==='rejected' && field==='withdraw'){
      return true
    }
    else  if(status==='rejected' && field==='hirenew'){
        return false
    }
    else  if(status==='expired' && field==='hirenew'){
      return false
    } 
    else  if(status==='pending'){
        return false
    }
    else return true
  }
  getPieChartData = async (value:string) => {
    const authToken = await getStorageData('authToken')
    const Webheader = {
      "token": authToken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let endPoint;
    if(value==='hired'){
      endPoint="bx_block_posts/deals/deal_by_status?filter=hired_service_provider"
    }else{
      endPoint="bx_block_posts/deals/deal_by_status"
    }
    this.apiCallIdGetPieChartData = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }
  handleApiResponcePiechat = (responseJson: IPieChart) => {
    if (responseJson && !responseJson.errors) {
      let hiredSeries;
      let notHiredSeries
    if(this.state.chartTabValue===0){
      hiredSeries=[
        responseJson.in_progress.count,
        responseJson.issue_sent_to_dealer.count,
        responseJson.resolved_in_dealer_review.count,
        responseJson.completed.count,
        responseJson.dealer_flags_incomplete.count,
      ]
      
    }else{
      notHiredSeries=[
        responseJson.in_progress.count,
        responseJson.completed.count,
        responseJson.deleted.count,
      ]
    }
      let hiredColours=['#004c6d', '#fcb900', '#525252', '#00bcd4', '#f06725']
      let NotHiredColours=['#013D4F', '#26BBB3', '#C43937']
      let hiredLabels= [
        "In Progress",
        "Issue Sent to Dealer",
        "Resolved in Dealer Review",
        "Completed",
        "Dealer Flags Incomplete",
      ]
      let notHiredLabels= [
        "In Progress",
        "Completed",
        "Deleted"
      ]


      this.setState({
        piChartData:responseJson,
        series: this.state.chartTabValue==0?hiredSeries:notHiredSeries,
        options: {
          ...this.state.options,
          colors:this.state.chartTabValue==0?hiredColours:NotHiredColours,
          labels:this.state.chartTabValue==0?hiredLabels:notHiredLabels,
          plotOptions: {
            pie: {
              donut: {
                size: '75%',
                labels: {
                  show: true,
                  total: {
                    show: true,
                    label: 'Deals',
                    fontSize: '14px',
                    color: 'rgba(197, 203, 201, 1)',
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    formatter: () => responseJson.total_deals,
                  },
                },
              },
            },
          },
        },
      });
    }
  }

}
// Customizable Area End