import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
import { Document } from "../../projecttemplates/src/ProjectTemplatesDealDashboardController.web";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    closeNoteWindow: (key: boolean) => void
    selectedFile:Document;
    accepted_selected_service_provider_id:string
    reteriewDocumentStatus:(key:boolean)=> void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    notesFloatingWindow: boolean;
    minimizeChats: boolean;
    missingForm: {
        information: string;
        xInformation: string
    };
    missingFormErrors: {
        informationError: string;
        xInformationError: string
    }

    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class SendToNotesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiCallIdCreateRaiseIssue: string = ""

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            notesFloatingWindow: true,
            minimizeChats: true,
            missingForm: {
                information: "",
                xInformation: ""
            },
            missingFormErrors: {
                informationError: "",
                xInformationError: ""
            }

            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();


    }

    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            const token: string = message.getData(
                getName(MessageEnum.SessionResponseToken)
            );
            runEngine.debugLog("TOKEN", token);
        }
        if(apiRequestCallId === this.apiCallIdCreateRaiseIssue){
            if(responseJson&&!responseJson.errors){
                if(responseJson.data?.message==="Issue raised"){
                    this.props.reteriewDocumentStatus(true)
                    this.props.closeNoteWindow(false)
                }
            }
        }
    }

    handleChangeNotesForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target
        this.setState((prevState) => ({
            missingForm: {
                ...prevState.missingForm,
                [name]: value,
            },
            missingFormErrors: {
                ...prevState.missingFormErrors,
                [`${name}Error`]: "",
            },
        }));
    }

    validateForm = () => {
        this.setState((prevState) => {
            const errors: any = {};

            if (!prevState.missingForm.information.trim()) {
                errors.informationError = "Subject canot be empty";
            }

            if (!prevState.missingForm.xInformation.trim()) {
                errors.xInformationError = "Details canot be empty";
            }

            return {
                missingFormErrors: { ...prevState.missingFormErrors, ...errors },
            };
        }, () => {
            this.createRaiseIssue()
        });
    };

    handleCloseNoteFloating = () => {
        this.setState({
            notesFloatingWindow: false
        }, () => {
            this.props.closeNoteWindow(false)
        })
    }
    handleMinimizeWindow = () => {
        this.setState({ minimizeChats: !this.state.minimizeChats })
    }
    createRaiseIssue = async () => {
        const token = await getStorageData('authToken')
        const deal_id=await getStorageData('createdDealId')
        const header = {
            //   "Content-Type": configJSON.apiContentType,
            token,
        };
        const { missingForm } = this.state
        const formData = new FormData()
        formData.append("info_title", missingForm.information)
        formData.append("info_description", missingForm.xInformation)
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdCreateRaiseIssue = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `${configJSON.apiEndPointRaiseIssue}?deal_id=${deal_id}&deal_document_id=${this.props.selectedFile.id}&select_service_provider_id=${this.props.accepted_selected_service_provider_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };





    // Customizable Area End
}
