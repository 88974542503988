import {
  Box,
  createStyles,
  withStyles,
  Typography,
  WithStyles,
  Button,
  Modal,
  createTheme,
  Divider,
  styled,
  Avatar,
} from "@material-ui/core";
import React from "react";
import { HelpOutline, Lock } from "@material-ui/icons";
import { DealInfo, ServiceProvider, User } from "./ProjectTemplatesDealDashboardController.web";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import CloseIcon from '@material-ui/icons/Close';
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
const classes = createStyles({
  hiredSP: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "20px",
    paddingTop: "20px",
  },

  header: {
    display: "flex",
    alignItems: "center",
  },
  row1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  row2: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  hiredDetails: {
    fontFamily: "Gotham",
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "26px",
  },

  endContract: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    display: "flex",
    gap: "5px",
    textWrap: "nowrap",
  },

  chatNow: {
    borderRadius: "8px",
    background: "#013D4F",
    padding: "16px 0px",
    cursor: 'pointer',

    "&:hover": {
      background: "#0F6D8A",
    },

    "& > span": {
      color: "#FFFFFF",
      textTransform: "initial",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export interface Props extends WithStyles {
  serviceProvider: ServiceProvider;
  user: User,
  dealInfo: DealInfo,
  navigation: any,
  id: string,
  chatNowAction: () => void
}

interface S {
  isOpenBackToDashboardModal: boolean,
}

interface SS {
  id: any;
}

export const StyledBasicBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  gap: "20px",
  paddingTop: "20px",
  '& .headingBasic': {
    fontFamily: "Gotham, sans-serif",
    fontSize: 24,
    fontWeight: 400,
    color: "#013D4F",
    [`@media (max-width:1500px)`]: {
      fontSize: 18,
    },

  },
  "& .hiredText": {
    fontSize: "13px",
    fontWeight: 300,
    color: "#4B4C4B",
    [theme.breakpoints.up(1600)]: {
      fontSize: 16,

    }
  },
  "& .contractText": {
    fontSize: "13px",
    fontWeight: 500,
    color: "#4B4C4B",
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up(1600)]: {
      fontSize: 16,

    }
  },
  "& .serviceProviderText": {
    fontSize: "12px",
    fontWeight: 300,
    color: "#4B4C4B",
    [theme.breakpoints.up(1600)]: {
      fontSize: 14,

    }

  },
  "& .SPPrice": {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: "18px",
    },
    "& span": {
      color: "#C5CBC9",
      display: 'inline-block',
      [theme.breakpoints.up(1920)]: {
        fontSize: "14px",
      },
    },
  },
  "& .serviceStatus": {
    padding: "6px 12px 6px 8px",
    gap: "4px",
    borderRadius: "26px",
    background: "#EAFBFA",
    color: "#26BBB3",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textWrap: "nowrap",
    [theme.breakpoints.up(1600)]: {
      fontSize: "14px",
    },
  },
  "& .SPName": {
    fontFamily: "Gotham",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "28px",
    textAlign: "left",
    color: "#013D4F",
    marginLeft: "10px",
    [theme.breakpoints.up(1600)]: {
      fontSize: "28px",
    },
  },
  "& .spNameThreeDots": {
    fontFamily: "Gotham",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "28px",
    textAlign: "left",
    color: "#013D4F",
    marginLeft: "10px",
    overflow: 'hidden',
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [theme.breakpoints.up(1920)]: {
      fontSize: "28px",
    },
  },
  "& .chatNow": {
    borderRadius: "8px",
    background: "#013D4F",
    padding: "0px",
    cursor: 'pointer',
    height: '32px',
    [theme.breakpoints.up(1920)]: {
      height: "56px",
      padding: "16px 0px",
    },

    "&:hover": {
      background: "#0F6D8A",
    },

    "& > span": {
      color: "#FFFFFF",
      textTransform: "initial",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "24px",
      [theme.breakpoints.up(1920)]: {
        fontSize: "16px",
      },
    },
  },
}));

export class ServiceProviderHired extends BlockComponent<Props, S, SS> {
  googleMaps: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      isOpenBackToDashboardModal: false,
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (apiCallId === this.googleMaps) {
        this.navigateToContactUs();
        this.toggleDashboardModalNew();
      }
    }
  }



  navigateToContactUs = () => {
    const contactUsTxtData = new Message(getName(MessageEnum.NavigationMessage));
    contactUsTxtData.addData(getName(MessageEnum.NavigationTargetMessage), "Contactus");
    contactUsTxtData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(contactUsTxtData);
  }

  toggleDashboardModalNew = () => {
    this.setState({
      isOpenBackToDashboardModal: !this.state.isOpenBackToDashboardModal
    })
  }

  backToDashboardModal = () => {
    return (
      <Modal
        open={this.state.isOpenBackToDashboardModal}
        onClose={this.toggleDashboardModalNew}
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          style={{
            width: '512px',
            minWidth: '200px',
            border: '1px solid #FFFFFF',
            boxShadow: theme.shadows[5],
            backgroundColor: '#FFFFFF',
            padding: theme.spacing(2, 4, 3),
            borderRadius: '8px',
          }}>
          <Box display={'flex'} style={{ width: '545px' }} justifyContent={'end'} >
            <Button onClick={this.toggleDashboardModalNew} data-test-id="closebtn"><CloseIcon /></Button>
          </Box>

          <Box style={{ width: '520px', height: '100px' }}>
            <Box><Typography style={{ color: '#013D4F', fontWeight: 500, fontSize: '24px', marginBottom: '12px', fontFamily: 'Roboto' }}>Do you want to end your contract?</Typography></Box>
            <Typography style={{ color: '#4B4C4B', fontSize: '18px', fontWeight: 300, fontFamily: 'Gotham light' }}>Please contact us to provide your reasons for terminating the contract.</Typography>
          </Box>

          <Divider style={{ height: '2px', width: '113%', marginLeft: '-33px', backgroundColor: '#F0FAFA', marginTop: '30px' }} />

          <Box style={{ display: 'flex', marginTop: '20px', justifyContent: 'end', }}>
            <Button data-test-id="cancellogout" variant="outlined" onClick={this.toggleDashboardModalNew} style={{ height: '56px', border: '1px solid #013D4F', width: '120px', marginRight: '15px', borderRadius: '8px' }}>
              <Typography style={{ color: '#013D4F', fontSize: '16px', fontWeight: 700, textTransform: 'none', fontFamily: 'Roboto' }}>
                Cancel
              </Typography>
            </Button>
            <Button data-test-id="finalDashboard" variant="contained" onClick={this.navigateToContactUs} style={{ width: '291px', height: '56px', backgroundColor: '#4FC8EC', borderRadius: '8px' }}>
              <Typography style={{ color: 'white', textTransform: 'none', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 700, }}>
                Contact Us
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  }

  derivedAvatarFromName = (name: string) => {
    return name.split(' ').map(name => name.charAt(0)).join('').slice(0, 2).toUpperCase();
  }

  render(): React.ReactNode {
    const { classes, serviceProvider, user, dealInfo } = this.props;
    const isDealer = user?.role?.includes("dealer");
    return (
      <>
        <StyledBasicBox>
          <Box className={classes.header}>
            <Avatar style={{ height: '40px', width: '40px', color: '#013D4F', backgroundColor: 'rgb(240, 250, 250)', }}
              src={isDealer ? serviceProvider?.serviceProviderLogo : dealInfo?.dealershipLogo}>
              {
                this.derivedAvatarFromName(isDealer ? serviceProvider?.serviceProviderName : dealInfo?.dealershipName)
              }
            </Avatar>
            <Typography className={(serviceProvider?.serviceProviderName.length > 15 || dealInfo?.dealershipName.length > 15) ? "spNameThreeDots" : "SPName"}>
              {isDealer ? serviceProvider?.serviceProviderName : dealInfo?.dealershipName}
            </Typography>
          </Box>
          <Box className={classes.content}>
            <Box className={classes.row1}>
              <Typography className={"SPPrice"}>
                {serviceProvider.selectedService}
                <span>&nbsp;(Vitu Fee 3%)</span>
              </Typography>
              <Box className={"serviceStatus"}>
                <Lock /> In Holding
              </Box>
            </Box>
            <Box className={classes.row2}>
              <Typography className={"hiredText"}>
                {isDealer ? 'They' : 'You'} were hired on {serviceProvider.hiredOn}
              </Typography>
              <Button style={{ textTransform: 'none' }} onClick={this.toggleDashboardModalNew} data-test-id="endContractButton" className="endContractButton">
                <Typography className={"contractText"}>
                  End contract <HelpOutline />
                </Typography>
              </Button>
            </Box>
          </Box>
          <Button className={"chatNow"} onClick={this.props.chatNowAction}>Chat Now</Button>
        </StyledBasicBox>
        {this.backToDashboardModal()}
      </>
    );
  }
}

export default withStyles(classes)(ServiceProviderHired);
