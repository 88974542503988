import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  open: boolean;
  onCloseDrawer: () => void;
}

interface S {
  registrationFee: RegistrationFee;
}

interface SS {}

export interface RegistrationFee {
  fee: string;
  oldFee: string;
  totalFee: string;
  oldTotalFee: string;
  status: string;
  reason: string;
}

export default class ViewDetailsDrawerController extends BlockComponent<
  Props,
  S,
  SS
> {
  getDealInformationId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      registrationFee: {} as RegistrationFee,
    };

    this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.SessionResponseMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getDealInformation = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealInformationId = request.messageId;
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/deals/" + localStorage.getItem("createdDealId")
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(request.id, request);
    return true;
  };

  async componentDidMount() {
    this.getDealInformation();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiId === this.getDealInformationId) {
        this.setState({
          registrationFee: {
            fee: response.data.attributes.fees_data?.fee_amount,
            oldFee: response.data.attributes.fees_data?.previous_fee_amount,
            status: response.data.attributes.fees_data?.status,
            totalFee: response.data.attributes.fees_data?.fee_amount,
            oldTotalFee:
              response.data.attributes.fees_data?.previous_fee_amount,
            reason: response.data.attributes.fees_data?.comment,
          },
        });
      }
    }
  }
}
