// Customizable Area Start
import React, { CSSProperties } from "react";

import { Box, Avatar, Typography, styled } from "@material-ui/core";

import UsersTableController, { Props } from "./UsersTableController.web";
import { UserNormalized } from "./MyTeamController.web";
import { checkmark } from "./assets";
import moment from "moment";

interface Styles {
  [Key: string]: CSSProperties;
}


export default class UsersTable extends UsersTableController {
  constructor(props: Props) {
    super(props);
  }
  renderHeader(){
    const { usersData, isAdmin, selectedServiceableLocation} = this.props;
    return (
      <Box className={isAdmin() ? "tableRow admin" : "tableRow user"}>
        {isAdmin() && <Box />}
        <Box className="thCell">User</Box>
        <Box className="thCell">Email</Box>
        <Box className="thCell">Contact No.</Box>
        {usersData.some((user) => user.dealerships?.split(",").length > 1) &&
          <Box className="thCell">Dealerships</Box>
        }
        <Box className="thCell">Role</Box>
        {usersData.some((user) => user.locations?.length > 0) && !selectedServiceableLocation &&
          <Box className="thCell">States</Box>
        }
        <Box className="thCell">Joined on</Box>
        {isAdmin() && <Box style={{justifyContent: 'flex-end'}} className="thCell">Actions</Box>}
      </Box>
    )
  }
  render() {
    const { usersData, userEmail, handleCheck, handleEditUser, handleDeleteUserPrompt, isAdmin, serviceableLocations, selectedServiceableLocation } = this.props;
    const getStateNameFromId = (id: string) => {
      return serviceableLocations?.find(location => location.id == id)?.provider_state;
    }
    const mapToStatesName = (ids: string[]) => {
      return ids.map(id => getStateNameFromId(id)).join(", ")
    }
    const currentPageData = usersData.slice();    
    return (
      <StyledContainer>
        {this.renderHeader()}
        
        {currentPageData.map((user: UserNormalized, i: number) => (
          <Box
            data-test-id={`user-${i}`}
            key={user.id}
            className={isAdmin() ? "tableRow admin" : "tableRow user"}
            style={{backgroundColor: user.checked ? "#F0FAFA" : "transparent"}}
          >
            {isAdmin() && (<Box className="trCell">
              <CheckboxContainer onClick={() => handleCheck(user.id)} data-test-id={`user-checkbox-${i}`}>
                  <input
                    type="checkbox"
                    checked={user.checked}
                    onChange={()=>{}}
                    className="checkbox"
                  />
                  <span className="checkmark">
                    <img src={checkmark} alt="checkmark" />
                  </span>
                </CheckboxContainer>
              </Box>
            )}
            <Box className="trCell" style={{ gap: "12px" }}>
              <StyledAvatar
                src={user.profilePicture}
                alt={user.name}
                variant="rounded"
                style={{ textTransform: "uppercase", backgroundColor: user.checked ? "#fff" : "#f7fcfc" }}
              >
                {(user.name || "")
                  .split(" ")
                  .map((string) => string[0])
                  .join("")}
              </StyledAvatar>
              <Typography className="name">{user.name}</Typography>
              {userEmail === user.email && <div className="ownAccount">Me</div>}
            </Box>
            <Box className="trCell">
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {user.email}
              </span>
            </Box>
            <Box className="trCell">
              <span
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >{`+${user.contactNumber}`}</span>
            </Box>
            {usersData.some((user) => user.dealerships?.split(",").length > 1) &&
              <Box className="trCell">{user.dealerships}</Box>
            }
            <Box className="trCell" style={{ textTransform: "capitalize" }}>
              {user.dealerRole}
            </Box>
            {usersData.some((user) => user.locations?.length > 0) && !selectedServiceableLocation &&
              <Box className="trCell">{mapToStatesName(user.locations)}</Box>
            }
            <Box className="trCell" sx={{color:user.inviteAccepted ? 'rgb(75, 76, 75)': '#C5CBC9 !important'}}>
              <span>
                {user.inviteAccepted ? 
                moment.utc(user.joinedOn).local().format("MMMM D, YYYY h:mm a") 
                : `Invited ${moment.utc(user.invitedOn).local().format("MMMM D, YYYY")}`}
              </span>
              {!user.inviteAccepted && <span className="resendInvite" onClick={()=> {this.props.resendInvite(user)}}>{user.resentSuccessfully ? 'Resent ✔': 'Resend Invite'}</span>}
            </Box>
            {this.props.isAdmin() && <Box style={{justifyContent: 'flex-end'}} className="trCell actionsCell">
              <Typography data-test-id={`edit-user-${i}`} className="actionButton" onClick={() => handleEditUser(user.id)}>Edit</Typography>
              <Typography data-test-id={`delete-user-${i}`} className="actionButton" onClick={() => handleDeleteUserPrompt(user.id)}>Delete</Typography>
            </Box>}
          </Box>
        ))}
      </StyledContainer>
    );
  }
}

const CheckboxContainer = styled(Box)({
  position: "relative",
  width: "20px",
  height: "20px",
  margin: 0,
  border: "1px solid #C5CBC9",
  borderRadius: "6px",
  cursor: "pointer",
  overflow: "hidden",

  "& .checkmark": {
    position: "absolute",
    left: "0",
    top: "0",
    alignItems: "center",
    display: "none",
    justifyContent: "center",
    height: "20px",
    width: "20px",
    backgroundColor: "#4FC8EC",
  },

  "& .checkbox": {
    appearance: "none",
  },

  "& input:checked + span": {
    display: "flex",
  },
});

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "& .thCell": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    fontSize: "14px",
    fontFamily: "Roboto, sans-serif",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#C5CBC9",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },

  "& .tableRow": {
    display: "grid",
    columnGap: "5px",
    borderBottom: "1px solid #F0FAFA",
    padding: "12px 0 12px 25px",

    "&.admin": {
      gridTemplateColumns: "3% 1fr 2fr 10% 1fr 10% 1.5fr 10%",

      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "3% 1fr 15% 15% 1fr 10% 1.5fr 10%",
      },
    },

    "&.user": {
      gridTemplateColumns: "1fr 1fr 10% 1fr 10% 1.5fr",

      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr 15% 15% 1fr 10% 1.5fr",
      },
    }
  },

  "& .trCell": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    fontWeight: 500,
    color: "#4B4C4B",
    lineHeight: "16px",
    [theme.breakpoints.up(1920)]: {
      fontSize: "14px",
      lineHeight: "22px",
    }
  },

  "& .ownAccount": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 6px",
    borderRadius: "8px",
    backgroundColor: "#013D4F",
    color: "FFF",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
  },

  "& .actionsCell": {
    gap: "24px",

    [theme.breakpoints.down("sm")]: {
      gap: "10px",
    },
  },

  "& .resendInvite":{
    marginLeft: '8px',
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#4FC8EC",
    cursor: 'pointer'
  },

  "& .name": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500
  },

  "& .actionButton": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    cursor: "pointer",
  },
}));

const StyledAvatar = styled(Avatar)(({theme}) => ({
  width: "48px",
  height: "48px",
  borderRadius: "12px",
  color: "#4B4C4B",
}))

// Customizable Area End
