import React from "react";
// Customizable Area Start
import {
  Box,
  withStyles,
  createStyles,
  Dialog,
  DialogContent,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  Tooltip,
} from "@material-ui/core";
import ProjectTemplatesDocumentsCompareController, {
  Props,
} from "./ProjectTemplatesDocumentsCompareController";
import { CheckCircleOutline, Clear, ErrorOutline } from "@material-ui/icons";
import clsx from "clsx";
import { Document } from "../../../../packages/blocks/projecttemplates/src/ProjectTemplatesDealDashboardController.web";

const classes = createStyles({
  container: {},
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
  documentCompareDialog: {
    "& > div:nth-child(3) > div:nth-child(1)": {
      width: "90vw",
      height: "80vh",
      borderRadius: "8px",
      background: "#FFFFFF",
      boxShadow: "none",
      margin: 0,
      padding: "30px",
      display: "block",
      flex: "none",
    },
  },

  documentCompareDialogHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  basicInfoContainer: {
    padding: 30,
    border: "1px solid #E5E6E5",
    borderRadius: 10,
  },

  basicInfoItems: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "30px 60px",
  },

  basicInfoItem: {
    minWidth: 220,
  },

  documentCompareContainer: {
    marginTop: 50,
    display: "flex",
    gap: 30,
  },

  documentContainer: {
    flex: 1,

    "& > div": {
      marginBottom: 30,
      borderRadius: "12px",
      border: "2px dashed #F0FAFA",
      position: "relative",
    },
  },

  documentHeader: {
    padding: 24,
  },

  circularProgress: {
    height: "16px",
    width: "16px",
    marginRight: "10px",
  },

  pdfIframe: {
    minHeight: "800px",
    width: "100%",
    borderRadius: "5px",
    background: "rgba(237, 245, 247, 1)",
  },

  documentContent: {
    borderRadius: "20px",
    border: "1px solid #F1F4F3",
    cursor: "pointer",
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "cener",
    alignItems: "center",
    gap: 30,
  },

  readyButton: {
    flex: 1,
    marginTop: 20,
    background: "rgba(234, 251, 250, 1)",
    color: "rgba(38, 187, 179, 1)",
    textTransform: "initial",
    "&:hover": {
      background: "rgba(234, 251, 250, 1)",
      color: "rgba(38, 187, 179, 1)",
    },
  },

  notReadyButton: {
    flex: 1,
    marginTop: 20,
    background: "rgba(254, 242, 242, 1)",
    color: "rgba(196, 57, 55, 1)",
    textTransform: "initial",
    "&:hover": {
      background: "rgba(254, 242, 242, 1)",
      color: "rgba(196, 57, 55, 1)",
    },
  },

  readyButtonChecked: {
    background: "#26BBB3",
    color: "white",
    "&:hover": {
      background: "#26BBB3",
      color: "white",
    },
  },
  notReadyButtonChecked: {
    background: "#C43937",
    color: "white",
    "&:hover": {
      background: "#C43937",
      color: "white",
    },
  },

  checkcircleoutline: {
    marginRight: "8px",
  },

  errorOutline: {
    marginRight: "8px",
  },

  basicInfoValue: {
    fontWeight: 500,
    color: "#4B4C4B",
  },

  documentSelect: {
    "&::before": {
      content: "none",
    },
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    [`@media (min-width:1920px)`]: {
      fontSize: 24
    },
  },


  documentTitle: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#4B4C4B",
  },

  documentNumberContainer: {
    position: "absolute",
    borderRadius: 44,
    border: "2px dashed #F0FAFA",
    top: 40,
    left: -15,
    width: 24,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  verticalBorder: {
    width: 1,
    minHeight: "100%",
    backgroundColor: "#F0FAFA",
  },

  truncate: {
    whiteSpace: "nowrap",
    overflow: "clip",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    display: "inline-block",
  },

  disabled: {
    background: '#F1F4F3'
  },
});
// Customizable Area End

export class ProjectTemplatesDocumentsCompare extends ProjectTemplatesDocumentsCompareController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderFirstDocument = () => {
    const { firstFile, secondFile } = this.state;
    const {
      classes,
      files,
      fileIcon,
      isSPHired,
      user
    } = this.props;
    const isDealer = user.role?.includes('dealer');
    return "id" in firstFile && (
      <Box className={classes.documentContainer}>
        <Box>
          <Box className={classes.documentNumberContainer}>
            <Typography className={classes.documentTitle}>1</Typography>
          </Box>
          <Box className={classes.documentHeader}>
            <Typography className={classes.documentTitle}>
              First Document
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth={true}
                value={firstFile.id || "null"}
                label="First Document"
                onChange={this.handleSelectFirstFile}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className={classes.documentSelect}
                style={{ color: firstFile.id ? "#013D4F" : "#C5CBC9" }}
              >
                {files.map((file, index) => {
                  return (
                    <MenuItem value={file.id} key={index}>
                      <img
                        src={fileIcon(file)}
                        className={classes.circularProgress}
                      ></img>
                      {file.filename}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box className={classes.documentContent}>
          <iframe
            className={classes.pdfIframe}
            src={firstFile.url}
          ></iframe>
          <Box className={classes.buttonsContainer}>
            <Button
              disabled={isSPHired && isDealer}
              data-test-id="first-file-ready-btn"
              onClick={() => {
                  this.props.changeDocumentStatus(isSPHired ? 'approved' : 'ready', firstFile.id);
                  this.props.changeSelectedDocumentStatus(isSPHired ? 'approved' : 'ready')
                  if(secondFile.id === firstFile.id){
                    this.setState({
                      secondFile: {
                        ...this.state.secondFile,
                        status: isSPHired ? 'approved' : 'ready'
                      }
                    })
                  }
                }
              }
              className={clsx(classes.readyButton, {
                [classes.readyButtonChecked]:
                  firstFile.status === "ready" || firstFile.status === "approved",
                [classes.disabled]:
                  isSPHired && isDealer,
              })}
            >
              {firstFile.status === "ready" || firstFile.status === "approved" && (
                <CheckCircleOutline
                  className={classes.checkcircleoutline}
                />
              )}
              {this.getFirstButtonText(firstFile)}
            </Button>
            <Button
              disabled={isSPHired && isDealer}
              data-test-id="first-file-not-ready-btn"
              onClick={() => {
                  this.props.changeDocumentStatus(isSPHired ? 'issue_raised' : 'not_ready', firstFile.id);
                  this.props.changeSelectedDocumentStatus(isSPHired ? 'issue_raised' : 'not_ready');
                  if(secondFile.id === firstFile.id){
                    this.setState({
                      secondFile: {
                        ...this.state.secondFile,
                        status: isSPHired ? 'issue_raised' : 'not_ready'
                      }
                    })
                  }
                }
              }
              className={clsx(classes.notReadyButton, {
                [classes.notReadyButtonChecked]:
                  firstFile.status === "not_ready" || firstFile.status === "issue_raised",
                [classes.disabled]:
                  isSPHired && isDealer,
              })}
            >
              {firstFile.status === "not_ready" || firstFile.status === "issue_raised" && (
                <ErrorOutline className={classes.errorOutline} />
              )}
              {this.getSecondButtonText(firstFile)}
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  renderSecondDocument = () => {
    const {
      classes,
      files,
      fileIcon,
      isSPHired,
      user
    } = this.props;
    const { secondFile, firstFile } = this.state;
    const isDealer = user.role?.includes('dealer');
    return   <Box className={classes.documentContainer}>
    <Box>
      <Box className={classes.documentNumberContainer}>
        <Typography className={classes.documentTitle}>2</Typography>
      </Box>
      <Box className={classes.documentHeader}>
        <Typography className={classes.documentTitle}>
          Second Document
        </Typography>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth={true}
            value={secondFile.id || "null"}
            placeholder="Select Document"
            label="Second Document"
            onChange={this.handleSelectSecondFile}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            className={classes.documentSelect}
            style={{ color: secondFile.id ? "#013D4F" : "#C5CBC9" }}
          >
            <MenuItem value="null" style={{ display: "none" }}>
              Select Document
            </MenuItem>
            {files.map((file, index) => {
              return (
                <MenuItem value={file.id} key={index}>
                  <img
                    src={fileIcon(file)}
                    className={classes.circularProgress}
                  ></img>
                  {file.filename}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
    <Box className={classes.documentContent}>
      {"url" in secondFile && (
        <>
          <iframe
            className={classes.pdfIframe}
            src={secondFile.url}
          ></iframe>
          <Box className={classes.buttonsContainer}>
            <Button
              disabled={isSPHired && isDealer}
              data-test-id="second-file-ready-btn"
              onClick={() => {
                this.props.changeDocumentStatus(isSPHired ? 'approved' : 'ready', secondFile.id);
                this.setState({secondFile: {...this.state.secondFile, status: isSPHired ? 'approved' : 'ready'}})
                if(secondFile.id === firstFile.id){
                  this.setState({
                    firstFile: {
                      ...this.state.firstFile,
                      status: isSPHired ? 'approved' : 'ready'
                    }
                  })
                }
              }}
              className={clsx(classes.readyButton, {
                [classes.readyButtonChecked]:
                  secondFile.status === "ready" || secondFile.status === "approved",
                [classes.disabled]:
                  isSPHired && isDealer,
              })}
            >
              {secondFile.status === "ready" || secondFile.status === "approved"  && (
                <CheckCircleOutline
                  className={classes.checkcircleoutline}
                />
              )}
              {this.getFirstButtonText(secondFile)}
            </Button>
            <Button
              disabled={isSPHired && isDealer}
              data-test-id="second-file-not-ready-btn"
              onClick={() => {
                this.props.changeDocumentStatus(isSPHired ? 'issue_raised' : 'not_ready', secondFile.id); 
                this.setState({secondFile: {...this.state.secondFile, status: isSPHired ? 'issue_raised' : 'not_ready'}})
                if(secondFile.id === firstFile.id){
                  this.setState({
                    firstFile: {
                      ...this.state.firstFile,
                      status: isSPHired ? 'issue_raised' : 'not_ready'
                    }
                  })
                }
              }}
              className={clsx(classes.notReadyButton, {
                [classes.notReadyButtonChecked]:
                  secondFile.status === "not_ready" || secondFile.status === "issue_raised",
                [classes.disabled]:
                  isSPHired && isDealer,
              })}
            >
              {secondFile.status === "not_ready" || secondFile.status === "issue_raised" && (
                <ErrorOutline className={classes.errorOutline} />
              )}
              {this.getSecondButtonText(secondFile)}
            </Button>
          </Box>
        </>
      )}
    </Box>
  </Box>
  
  }

  getFirstButtonText = (file: Document) => {
    const {isSPHired} = this.props;

    if(isSPHired){
      if(file.status === 'approved'){
        return 'Approved';
      } else {
        return 'Approve';
      }
    } else{
      return 'Ready';
    }
  }

  getSecondButtonText = (file: Document) => {
    const {isSPHired, } = this.props;

    if(isSPHired){
      if(file.status === 'issue_raised'){
        return 'Issue Raised';
      } else {
        return 'Raise an issue';
      }
    } else{
      return 'Not Ready';
    }
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      classes,
      compareDocMode,
      triggerCompareDoc,
      basicInfos,
    } = this.props;
    const ToolTip = withStyles({
      tooltip: {
        padding: '16px',
        radius: '8px',
        background: '#FFFFFF',
        boxShadow:"0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
        color: '#4B4C4B',
        fontSize: '15px'
      },
    })(Tooltip);
    return (
      <Dialog
        data-test-id="compare-doc-dialog"
        fullScreen={true}
        open={compareDocMode}
        keepMounted
        onClose={triggerCompareDoc}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.documentCompareDialog}
      >
        <Box
          className={classes.documentCompareDialogHeader}
          data-test-id="documents-upload-dialog-header"
        >
          <Clear
            className={classes.closeIcon}
            data-test-id="close-btn"
            onClick={triggerCompareDoc}
          />
        </Box>
        <DialogContent
          className={classes.documentCompareDialogContent}
          data-test-id="documents-upload-dialog-content"
        >
          <Box className={classes.basicInfoContainer}>
            <Typography variant="h2" style={{ marginBottom: 40 }}>
              Basic Information
            </Typography>
            <Box className={classes.basicInfoItems}>
              {Object.entries(basicInfos).map(([key, value]) => (
                <Box className={classes.basicInfoItem} key={key}>
                  <Typography
                    className={classes.basicInfoTypo}
                    variant="body1"
                  >
                    {key}
                  </Typography>
                  {key === "OWNER'S NAME" ? <ToolTip title={value} placement="top-end">
                        <Typography className={`${classes.truncate} ${classes.basicInfoValue}`}>{value}</Typography>
                      </ToolTip> : <Typography className={classes.basicInfoValue}>{value}</Typography>}
                </Box>
              ))}
            </Box>
          </Box>
          <Box className={classes.documentCompareContainer}>
            {this.renderFirstDocument()}
            <Box className={classes.verticalBorder}></Box>
            {this.renderSecondDocument()}
          </Box>
        </DialogContent>
      </Dialog>
    );
    // Customizable Area End
  }
}

export default withStyles(classes)(ProjectTemplatesDocumentsCompare);
