import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
export interface FormFeeType {
  "transaction_id": number,
  "current_plan_name": string,
  "current_plan_add_on_cost": number,
  "free_plan_add_on_cost": number,
  "discount_value": number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  formType: string;
  addOnEnum: string;
  payNowAction?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isAgreed: boolean;
  formFee: FormFeeType;
  generateDialog: boolean;
  modalTitle:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiBuyAddOnId: string = "";
  apiPayAddOnId: string = "";
  generateChecklistId : string = "";
  handleChangeAgreeState = (newAgreeState: boolean) => {
    this.setState({ isAgreed: newAgreeState })
  }

  handleCloseDialog=()=>{
    this.setState({generateDialog:false})
  }
  navigateToDealDashboard = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'ProjectTemplatesDealDashboard');
    this.send(msg);
  }
  navigateToTermsAndConditions = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'TermsConditions');
    this.send(msg);
  }

  navigateToAutoForm = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'AutoForm');
    this.send(msg);
  }

  buyAddOn = async() => {
    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiBuyAddOnId = message.messageId;
    const addOn= await getStorageData('addonRequired')

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custombillingapi/forms_and_fees/buy_add_on?add_on_enum=${this.props.addOnEnum ? this.props.addOnEnum : addOn}&deal_id=${localStorage.getItem('createdDealId')}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(message.id, message);
    return true;
  }

  generateCheckList =  async () => {
    const deal_id = localStorage.getItem('createdDealId');
    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.generateChecklistId = message.messageId;
    const addOn = await getStorageData('addonRequired')

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custombillingapi/forms_and_fees/get_add_on?add_on_enum=${addOn}&deal_id=${deal_id}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(message.id, message);
    return true;
  }

  payAddOn = async () => {
    const addOn = await getStorageData('addonRequired')
    const deal_id = localStorage.getItem('createdDealId');
    const add_on_price = this.state.formFee.current_plan_add_on_cost;
    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPayAddOnId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custombillingapi/forms_and_fees/pay_add_on?add_on_enum=${addOn}&deal_id=${deal_id}&add_on_price=${add_on_price}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(message.id, message);
    return true;
  }

  payNowClickHandler = () => {
    const { formFee } = this.state;
    if (!formFee.transaction_id) {
      this.navigateToAutoForm();
    } else {
      this.payAddOn();
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = ({
      isAgreed: false,
      formFee: {} as FormFeeType,
      generateDialog: false,
      modalTitle:""
    })

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const addOnName=await getStorageData('addon')
    this.setState({modalTitle:addOnName})
    this.buyAddOn();
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      if (sessionData) {
        if (sessionData.reterivePopup === 'generate') {
          this.setState({ generateDialog: true })
        }
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response && !response.errors) {
        if (apiId === this.apiBuyAddOnId) {
          this.setState({
            formFee: response
          });
        }
        if (apiId === this.apiPayAddOnId) {
            this.generateCheckList();
        }
        if (apiId === this.generateChecklistId) {
          setTimeout(() => {
            this.setState({ generateDialog: false },()=>{
              this.navigateToDealDashboard()
            })
          }, 500)
        }
      }
    }
    // Customizable Area End
  }
}