// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles, Typography, Grid, IconButton, styled } from "@material-ui/core";
import IssueRaisedController, {
  Props,
} from "./IssueRaisedController.web";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearIcon from '@material-ui/icons/Clear';
import { formattedDate } from "./ProjectTemplatesDocumentsUploadController";
const styles = createStyles({
  chatHeadingLayout: {
    backgroundColor: "#F0FAFA",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '10px 10px 0px 0px',
  },
  iconButton: {
      color: '#013D4F'
  },
  chatContentLayout: {
      background: '#fff',
      padding: "30px"
  },
  warningIcon: {
      color: "#013D4F",
      width: 20,
      height: 20
  },
  textLabel: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "12px",
    color: "#C5CBC9"
  },
  infoBox:{
    display: 'flex',
    flexDirection: 'column',
    gap: "20px",
    borderRadius: "10px",
    borderWidth: "1px",
    padding: "15px",
    border: "1px solid #ECF9F9",
    marginTop: '20px'
  },
  itemBox:{
    display: 'flex',
    flexDirection: 'column',
    gap: "5px",
  },
  title:{
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "16px",
    color: "#013D4F"
  },
  content:{
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
    color: "#4B4C4B"
  }
});

const StyledSendToNotesBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  right: 10,
  width: '30%',
  color: '#fff',
  zIndex: 99,
  bottom: '0px',
  boxShadow: "0px 8px 32px 0px #0000000F",
  "& .cancelButton": {
      color: "#4B4C4B",
      fontSize: 12,
      lineHeight: "24px",
      fontWeight: 700,
      width: "80%",
      textTransform: 'none' as const,
      height: '40px',
      borderRadius: 8,
      [theme.breakpoints.up(1920)]: {
          fontSize: 16,
          height: '56px',
      },
  },
  "& .sendButton": {
      color: "#fff",
      background: "#4FC8EC",
      fontSize: 12,
      lineHeight: "24px",
      fontWeight: 700,
      width: "80%",
      textTransform: 'none' as const,
      height: '40px',
      borderRadius: 8,
      [theme.breakpoints.up(1920)]: {
          fontSize: 16,
          height: '56px',
      },
  },
  "& .headingText": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
      color: '#013D4F',
      [theme.breakpoints.up(1920)]: {
          fontSize: 20,
      }
  },
  "& .subHeadingText": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "20px",
      color: '#013D4F',
      [theme.breakpoints.up(1920)]: {
          fontSize: 14,
      },
  },

}))

export class IssueRaised extends IssueRaisedController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes, issueRaised } = this.props;
    return (
      <StyledSendToNotesBox >
          <Box>
              <Box className={classes.chatHeadingLayout}>
                  <Box display={'flex'} flexDirection={'row'} style={{ gap: '12px' }}>
                      <Box>
                          <ErrorOutlineOutlinedIcon className={classes.warningIcon} />
                      </Box>
                      <Box>
                          <Box display={'flex'} flexDirection={'column'} style={{ gap: '2px' }}>
                              <Typography component={'h6'} data-test-id='heading_text' className={"headingText"}>Issue Raised</Typography>
                              <Typography component={'h6'} className={"subHeadingText"}>{issueRaised.filename}</Typography>
                          </Box>
                      </Box>
                  </Box>
                  <Box>
                      <Grid item xs={12}>
                          <Grid container alignItems="center">
                              <Grid item >
                                  {this.state.minimizeChats ? <IconButton
                                      onClick={this.handleMinimizeWindow}
                                      data-test-id='expand_more'
                                  >
                                      <ExpandMoreIcon className={classes.iconButton} />
                                  </IconButton> :
                                      <IconButton
                                          data-test-id='expand_less'
                                          onClick={this.handleMinimizeWindow}
                                      >
                                          <ExpandLessIcon className={classes.iconButton} />
                                      </IconButton>}
                              </Grid>
                              <Grid item >
                                  <IconButton onClick={this.handleCloseNoteFloating}>
                                      <ClearIcon className={classes.iconButton} />
                                  </IconButton>
                              </Grid>
                          </Grid>
                      </Grid>
                  </Box>
              </Box>
              {!this.state.minimizeChats && 
              <Box className={classes.chatContentLayout}>
                <Typography className={classes.textLabel}>Issue raised by service provider on {formattedDate(issueRaised.issue_raised_at)}</Typography>
                <Box className={classes.infoBox}>
                  <Box className={classes.itemBox}>
                    <Typography className={classes.title}>
                      Subject
                    </Typography>
                    <Typography className={classes.content}>
                      {issueRaised.subject}
                    </Typography>
                  </Box>
                  <Box className={classes.itemBox}>
                    <Typography className={classes.title}>
                      Details
                    </Typography>
                    <Typography className={classes.content}>
                      {issueRaised.details}
                    </Typography>
                  </Box>
                </Box>
              </Box>}

          </Box>
      </StyledSendToNotesBox>
  );
    
  }
}

export default withStyles(styles)(IssueRaised);
// Customizable Area End