import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Grid,
  InputBase,
  Avatar,
  Badge
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    minWidth: "12px",
    height: '12px',
    borderRadius: "50%",
    background: "#C43937"
  },
}))(Badge);

const StyledChatBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  right: 10,
  width: '400px',
  color: '#fff',
  zIndex: 99,
  bottom: '0px',
  boxShadow: "0px 8px 32px 0px #0000000F",
  "& .headingText": {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "28px",
    color: '#ffffff',
    [theme.breakpoints.up(1920)]: {
      fontSize: 24,
    },
  },
  "& .userNameSelected": {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 500,
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
  "& .userName": {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 500,
    color: "#C5CBC9",
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
  "& .dealText": {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: 20,
    },
  },
  "& .dealTextSelected": {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 600,
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: 20,
    },
  },
  "& .messageText": {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 400,
    color: "#939F9B",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: 'hidden',
    width: '280px',
    height: 20,
    [theme.breakpoints.up(1920)]: {
      fontSize: 15,
    },
  },
  "& .messageTextSelected": {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 500,
    color: "#939F9B",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: 'hidden',
    width: '280px',
    height: 20,
    [theme.breakpoints.up(1920)]: {
      fontSize: 15,
    },

  },
  '& .messageChatAvatar': {
    width: '50px',
    height: "50px",
    border: "2px dashed #013D4F",
    backgroundColor: 'white',
    color: "#013D4F",
    fontWeight: 500 as const,
    [theme.breakpoints.up(1920)]: {
      width: '57px',
      height: "57px",
    },
  },
  "& .chatHeadingLayout": {
    backgroundColor: "#013D4F",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
    [theme.breakpoints.up(1920)]: {
      padding: '10px',
    },
    borderRadius: '10px 10px 0px 0px',
  },
  "& .iconButton": {
    color: '#fff',
    width: '18px',
    height: "18px",
    [theme.breakpoints.up(1920)]: {
      width: '24px',
      height: "24px",
    },
  },
}))
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";
import ViewChat from "./ViewChat.web";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderChatMessages = () => {
    return (
      <>
        {this.state.chatList.map((list: IChat) => {
          return (
            <>
              <Grid item xs={12} key={list.id}
                style={{ cursor: 'pointer' }}
                data-test-id="selectedChat"
                onClick={() => this.handleSelectChat(list)}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={2}>
                    {list.userImage ?

                      <Avatar src={list.userImage} className={'messageChatAvatar'} /> : <Avatar
                        className={'messageChatAvatar'}>
                        {list.name
                          .split(' ')
                          .map((word) => word.charAt(0))
                          .join('')
                          .slice(0, 2)
                          .toUpperCase()}
                      </Avatar>}
                  </Grid>
                  <Grid item xs={10}>
                    <Box>
                      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography component={'div'} className={list.unReadMessageCount > 0 ? "userNameSelected" : "userName"}>{list.name}</Typography>
                        <Typography component={'div'} className={list.unReadMessageCount > 0 ? "userNameSelected" : "userName"}>{this.convertformatTime(list.lastMessageDate)}</Typography>

                      </Box>
                      <Box>
                        <Typography component={'div'} className={list.unReadMessageCount > 0 ? "dealTextSelected" : "dealText"}>{list.dealNumber}-{list.dealName}</Typography>
                      </Box>
                      <Box display={"flex"} justifyContent={'space-between'} alignItems={'center'}>
                        <Box >
                          <Typography data-test-id="last_message" component={'div'} className={list.unReadMessageCount > 0 ? "messageTextSelected" : "messageText"}

                          >{list.senderName === this.state.userName ? "You" : list.senderName}:&nbsp;
                            <Typography data-test-id="message_last"  variant="body1" style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: list.lastMessage }} className={list.unReadMessageCount > 0 ? "messageTextSelected" : "messageText"} />
                          </Typography>

                        </Box>
                        {list.unReadMessageCount > 0 && <Box>
                          <Box style={{
                            width: '12px',
                            height: "12px",
                            borderRadius: "50%",
                            background: "#C43937"
                          }} />

                        </Box>}
                      </Box>

                    </Box>

                  </Grid>

                </Grid>

              </Grid>
              <Grid item xs={12} style={{

                borderBottom: "1px solid #F0FAFA",
                margin: "5px 0px"
              }} />
            </>
          )

        })}
      </>
    )
  }

  renderNoChatList = () => {
    return (
      <>
        {this.state.chatList.length === 0 &&
          <>
            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '230px' }}>
                <Grid item xs={12}>
                <Typography component={'div'} align="center" className={"dealTextSelected"}>{configJSON.noChatsFound}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>

        <IconButton onClick={this.openChats} data-test-id="chat_icon">
          {this.state.newMessageStatus ? <StyledBadge color="secondary" badgeContent=" ">
            <ChatBubbleOutlineIcon style={webStyles.chatButton} />
          </StyledBadge> :
            <ChatBubbleOutlineIcon style={webStyles.chatButton} />
          }
        </IconButton>
        {this.state.openChats &&
          <>
            <StyledChatBox >
              <Box>
                <Box className={"chatHeadingLayout"}>
                  <Box>
                    <Typography component={'h6'} className={"headingText"}>{configJSON.chatHeading}</Typography>

                  </Box>
                  <Box>
                    <Grid item xs={12}>
                      <Grid container alignItems="center">
                        <Grid item >
                          {this.state.minimizeChats ? <IconButton onClick={() => this.closeChatsList('list')} data-test-id="minimize_chat">
                            <ExpandMoreIcon className={"iconButton"} />
                          </IconButton> :
                            <IconButton onClick={() => this.openChatsList('list')} data-test-id="maxmize_chat">
                              <ExpandLessIcon className={"iconButton"} />
                            </IconButton>}
                        </Grid>
                        <Grid item >
                          <IconButton onClick={() => this.closeChats("list")} data-test-id="close_chat">
                            <ClearIcon className={"iconButton"} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              {this.state.minimizeChats && <Box style={webStyles.chatContentLayout as React.CSSProperties}>
                <Box display={'flex'} alignItems={'center'}
                  style={{ background: "#F1F4F3" }} height={"52px"} borderRadius={12}
                >
                  <SearchIcon style={webStyles.searchIcon} />
                  <InputBase
                    data-test-id="search_chat"
                    onChange={this.handleChatNameChange}
                    placeholder="Search Chats"
                    inputProps={{ 'aria-label': 'Search Chats' }}
                  />
                </Box>
                <Box style={{
                  marginTop: "20px", overflowY: 'auto',
                  overflowX: 'hidden'
                }}>
                  {this.renderChatMessages()}
                  {this.renderNoChatList()}



                </Box>
              </Box>}
            </StyledChatBox>
            {this.state.chatWindow &&
              <ViewChat navigation={undefined} id={""} chatBoxType={"box"}
                closeWindow={this.closeChats}
                chatDetails={this.state.selectedChat}
                role={this.state.role}
                cardStatus={this.state.openChats}
                retreviewReadMessages={this.retreviewReadMessages}
              />
            }
          </>}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  chatContentLayout: {
    background: '#fff',
    height: '430px',
    overflowY: 'auto',
    padding: "15px 10px"
  },
  chatButton: {
    color: '#013D4F'
  },
  searchIcon: {
    color: "#989A98",
    margin: '0px 10px 0px 25px'
  },
  messageChatAvatar: {
    width: '57px',
    height: "57px",
    border: "2px dashed #013D4F",
    backgroundColor: 'white',
    color: "#013D4F",
    fontWeight: 500 as const
  },
};
// Customizable Area End
