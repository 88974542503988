import React from 'react';
import { Box, Typography, Link, makeStyles } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { closeIcon, fileUpload, pdfFile } from '../../../packages/blocks/customisableuserprofiles/src/assets';


interface LicenseDropzoneProps {
    licenseType: string;
    onDrop: (acceptedFiles: File[], licenseType: string) => void;
    file?: any;
    removeFileData: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, docType: string) => void;
    fileLabel: string;
    fileString: string;
}

const useStyles = makeStyles((theme) => ({
    drag_dropText: {
        fontWeight: 300,
        fontSize: "20px",
        color: "#013D4F",
        textAlign: 'center',
        lineHeight: "32px",
        [theme.breakpoints.up(1920)]: {
            fontSize: "24px",

        }
    },
    lableText: {
        marginTop: "7px",
        fontSize: "12px",
        fontWeight: 500,
        color: "#4B4C4B",
        lineHeight: "22px",
        [theme.breakpoints.up(1920)]: {
            fontSize: "14px",
        }
    },
    subLabelText: {
        marginTop: "10px",
        fontWeight: 500,
        fontSize: "10px",
        color: "#4B4C4B",
        lineHeight: "18px",
        [theme.breakpoints.up(1920)]: {
            fontSize: "12px",
            marginTop: "15px",
        }
    }

}));

const LicenseDropzone: React.FC<LicenseDropzoneProps> = ({ licenseType, onDrop, file, removeFileData, fileLabel, fileString }) => {
    const classes = useStyles();
    return (
        <Dropzone
            data-test-id={licenseType}
            onDrop={(acceptedFiles) => onDrop(acceptedFiles, licenseType)}
            multiple={false}
            accept={{
                'application/pdf': ['.pdf'],
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg'],
            }}
        >
            {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                    {...getRootProps()}
                    style={{
                        height: "196px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        cursor: "pointer",
                        color: "black",
                        border: "1px solid #C5CBC9",
                        borderRadius: "8px",
                        backgroundColor: isDragActive ? "#f0f0f0" : "transparent",
                        width: "100%",
                    }}
                >
                    <input {...getInputProps()} />
                    {file ? (

                        <Box style={{ height: "171px", width: "100%", display: "flex", flexDirection: 'column', position: "relative" }}>
                            <Box style={{ width: "100%", marginTop: "10px" }}>
                                <Typography
                                    style={{
                                        position: "absolute",
                                        top: -22,
                                        left: 29,
                                        padding: "0 8px",
                                        background: "#fff",
                                        fontSize: "12px",
                                    }}
                                >
                                    {fileLabel}
                                </Typography>
                                <div style={{ textAlign: 'right' }}>
                                    <button onClick={(e) => removeFileData(e, licenseType)}
                                        data-test-id="removeFile"
                                        style={{ width: "20px", height: "20px", marginRight: "20px", border: "0px", backgroundColor: 'white' }}>

                                        <img src={closeIcon} style={{ width: "20px", height: "20px" }} />
                                    </button>
                                </div>
                            </Box>
                            <Box style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                {file.type === "application/pdf" ? (
                                    <img src={pdfFile} alt="PDF File" />
                                ) : (
                                    <img src={fileString} alt={fileLabel} style={{ height: "110px", width: "173px" }} />
                                )}
                                <Typography style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", marginTop: "10px" }}>
                                    {file.name}
                                </Typography>
                            </Box>
                        </Box>

                    ) : (
                        <Box component="div" style={{ position: 'relative', width: "100%" }}>
                            <Typography
                                style={{
                                    position: "absolute",
                                    top: -38,
                                    left: 29,
                                    padding: "0 8px",
                                    background: "#fff",
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                    fontWeight: 500,
                                    color: "#4B4C4B"
                                }}
                            >
                                {fileLabel}
                            </Typography>
                            <Box component="div" style={{ gap: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src={fileUpload} alt="File Upload Icon" style={{ marginLeft: "5px" }} />
                                <Typography component={'h6'} className={classes.drag_dropText}>
                                    Drag & Drop
                                </Typography>
                                <Typography component={"p"} className={classes.lableText}>
                                    or click <Link style={{ color: "#4FC8EC" }}>here</Link> to browse your files
                                </Typography>
                                <Typography component={'p'} className={classes.subLabelText}>
                                    (Allowable formats include .pdf, .jpg, & .png)
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </div>
            )}
        </Dropzone>
    )
}

export default LicenseDropzone;
