// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";


import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
}

interface S {
  dealInfo: DealInfo,
  status: string
}

interface SS {
 
}

interface DealInfo{
  id: string,
  serviceProviderName: string,
  positivePercentage: string,
  customerLastName: string,
  fromState: string,
  toState: string,
  application: string,
  service: string,
  vehicleType: string,
  registrationFee: string,
  oldRegistrationFee: string,
  reason: string,
  feeId: string,
}

export default class ConfirmFeesDealerController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDealInformationId: string = '';
  apiAcceptFeeId: string = '';
  apiRejectFeeId: string = '';
  getDealInformation = () =>{
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDealInformationId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/' + localStorage.getItem("confirmFeeDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  mapToDealInfo = (response: any) => {
    const dealInfo : DealInfo = {
      id: response.id,
      serviceProviderName: response.service_provider.data.attributes.business_name,
      positivePercentage: '0',
      customerLastName: response.owner_last_name,
      fromState: response.from_state,
      toState: response.to_state,
      application: response.application_type,
      service: response.service.match(/\"([^\"]+)\"=>\"([^\"]+)\"/)[1].replace(/^\w/, (c: string) => c.toUpperCase()),
      vehicleType: response.vehicle_info_type,
      registrationFee: response.fees_data.fee_amount,
      feeId: response.fees_data.id,
      oldRegistrationFee: response.fees_data.previous_fee_amount,
      reason: response.fees_data.comment
    }

    return dealInfo;
  }

  navigateToScreen = (screen: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    this.send(msg);
  }

  rejectHandler = () =>{
    this.setState({
      status: 'reject'
    })
  }

  acceptHandler = () => {
    this.setState({
      status: 'accept'
    })
  }

  cancelHandler = () => {
    this.setState({
      status: ''
    })
  }

  rejectAndHireNewSPHandler= () => {
    this.callRejectApi();
    localStorage.setItem('createdDealId', this.state.dealInfo.id);
    localStorage.setItem('state_code', this.state.dealInfo.toState);
    this.navigateToScreen('ServiceProviderDashboard');
  }

  rejectAndContinueDealHandler = () => {
    this.callRejectApi();
    localStorage.setItem('createdDealId', this.state.dealInfo.id);
    this.navigateToScreen('ProjectTemplatesDealDashboard');
  }

  acceptAndHoldFeesHandler = () => {
    this.callAcceptApi();
  }

  callRejectApi = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiRejectFeeId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_multilevelapproval/hired_provider_fees/reject/' + this.state.dealInfo.feeId
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  callAcceptApi = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAcceptFeeId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_multilevelapproval/hired_provider_fees/accept/' + this.state.dealInfo.feeId
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.state = {
      dealInfo: {} as DealInfo,
      status: '',
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getDealInformation();
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response && !response.errors) {
        if(apiId === this.apiGetDealInformationId){
          this.setState({
            dealInfo: this.mapToDealInfo(response.data.attributes),
          })
        }
        if(apiId === this.apiAcceptFeeId){
          localStorage.setItem('createdDealId', this.state.dealInfo.id);
          this.navigateToScreen("ProjectTemplatesDealDashboard")
        }
      }
    }
  }
}
// Customizable Area End