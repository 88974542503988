import React from "react";
// Customizable Area Start
import { grouppng, vitulogoimg } from '../../email-account-login/src/assets'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { isError } from '../../../components/src/Loader.web'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton, styled
} from "@material-ui/core";

const Buttondb = styled(Button)({
  height: '44px',
  marginTop: '24px',
  [`@media (min-width:1920px)`]: {
    height: '56px',
  },
  '&:disabled': {
    color: '#75D3F0',
    width: '512px',
    maxWidth: '100%',
    backgroundColor: '#F0FAFA',
    '@media (max-width: 600px)': {
      width: "220px",
    },
    '@media (max-width: 700px)': {
      width: "300px",
    },
  },
  '&:enabled': {
    color: 'white',
    width: '512px',
    backgroundColor: '#4FC8EC',
    marginTop: '24px',
    height: "44px",
    '@media (max-width: 600px)': {
      width: "220px",
    },
    '@media (max-width: 700px)': {
      width: "300px",
    },
  },
  '& .buttonText': {
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 700,
    [`@media (min-width:1920px)`]: {
      fontSize: "16px",
    }
  }

});
// Customizable Area End

import ResetPassword, {
  Props,
} from "./ResetPasswordController";


export default class Block extends ResetPassword {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  EmailInputPage = () => {
    return (
      <Box
        data-test-id="box1"
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"calc(100vh - 180px)"}
      >
        <Box data-test-id="parentbox" width={"90%"} display={"flex"} flexDirection={"column"} className="formLayout">
          <Box display={"flex"} flexDirection={"column"} style={{ rowGap: '0px', width: '100%' }}>
            <Typography className="gothamText mainHeadingText" component={'h4'}>Forgot Password</Typography>
            <Typography className="gothamText mainSubTitleText" component={'p'}>Enter your email to reset your password</Typography>
          </Box>
          {this.state.receivedError &&
            <Box style={{ backgroundColor: '#FEE2E2', height: '46px', width: '380px', borderLeft: '4px solid #C43937', borderRadius: '8px' }}>
              <Typography component={'p'} className="errorText">{this.state.receivedError}</Typography>
            </Box>
          }
          <Formik
            data-test-id="formik"
            initialValues={{ email: '' }}
            validationSchema={this.validationSchema}
            onSubmit={this.handleSubmit}
          >
            {({ errors, touched, handleChange, handleBlur, values }) => (
              <Form>
                <Box style={{ marginTop: '15px' }}>
                  <Box className="emailBox" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${this.isErrorborder(touched.email, errors.email, '#C43937', '#C5CBC9')}` }}>
                    <EmailOutlinedIcon className="emailIcon" style={{ color: this.isErrorborder(true, values.email, '#4B4C4B', '#C5CBC9') }} />
                    <Field
                      name="email"
                      type="email"
                      placeholder="Please enter your email"
                      as={StyledTextField}
                      InputProps={{
                        disableUnderline: true, style: { border: 'none', height: '', marginTop: '2px' },
                        onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
                          if (event.key === ' ') {
                            event.preventDefault();
                          }
                        },
                      }}
                      InputLabelProps={{ shrink: false }}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="email"
                      fullWidth
                    />
                  </Box>
                  <ErrorMessage name="email" component="div" style={{ marginTop: '5px', color: "#DC2626", fontSize: '12px', fontWeight: 500, fontFamily: 'Roboto' }} />
                </Box>

                <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                  <Buttondb data-test-id="loginhere" className="loginhere" type="submit" disabled={!values.email || Boolean(errors.email)} variant="contained" >
                    <Typography component={'p'} className="buttonText">Continue</Typography>
                  </Buttondb>
                </Box>
              </Form>
            )}
          </Formik>

          <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '-15px' }}>
            <Button data-test-id="gotologin" variant="text" onClick={this.goTologin}>
              <Typography className="resetPasswordText" component={'p'}>Remember Password?</Typography>
            </Button>
          </Box>
        </Box>

      </Box>
    )
  }
  EmailSentPage = () => {
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"calc(100vh - 180px)"}
      >
        <Box width={"60%"} display={"flex"} flexDirection={"column"} className="formLayout">
          <Box display={"flex"} flexDirection={"column"} style={{ rowGap: '0px', width: '100%' }}>
            <Typography className="gothamText mainHeadingText" >Email Sent</Typography>
            <Typography className="gothamText mainSubTitleText" component={'p'}>Please check your email at '<span className="emailText">{this.state.email}</span>' for a password recovery link.</Typography>
          </Box>

          <Box style={{ marginTop: '24px', padding: '0px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Button data-test-id="notyou" style={{ paddingLeft: '0px' }} variant="text" onClick={this.handleNotYou}>
              <Typography component={'p'} className="resetPasswordText">Not You?</Typography>
            </Button>

            <Box display="flex" alignItems="center"> 
              <Typography className="resendText" component={'p'} style={{color: '#4B4C4B' }}>Didn't receive the email? </Typography>
              <Button data-test-id="sendemailtobe" variant="text" disabled={!this.state.canResend} onClick={this.sendEmailToBe}>
                <Typography component={'p'} className="resendText" style={{ color: this.state.canResend ? '#013D4F' : '#4B4C4B' }}>
                  Resend <span style={{ display: this.state.canResend ? 'none' : '' }}>in {this.formatTime(this.state.timer)}</span>
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
  ResetPasswordPage = () => {
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"calc(100vh - 180px)"}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '10%', maxWidth: '512px', marginLeft: '100px', marginRight: '100px' }}>
          <Box style={{ display: "flex", flexDirection: 'column', alignItems: 'start', width: '100%', marginBottom: '30px' }}>
            <Box className="gothamText mainHeadingText" >Reset Password!</Box>
            <Typography className="gothamText mainSubTitleText">Set a new passsword for your account</Typography>
          </Box>

          <Box style={{ width: '100%' }}>
            <Formik
              initialValues={{ password1: '', password2: '' }}
              validationSchema={this.validationSchema2}
              onSubmit={this.handleSubmit2}
            >
              {({ errors, touched, handleChange, handleBlur, values }) => (
                <Form >
                  <Box style={{ width: '100%' }}>
                    {isError(touched.password2, errors.password2, 'password2')}
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${this.isErrorborder(touched.password2, errors.password2, '#C43937', '#C5CBC9')}`,}} className="emailBox">
                      <LockOutlinedIcon className="emailIcon" style={{ color: this.isErrorborder(true, values.password1, '#4B4C4B', '#C5CBC9'),}} />
                      <Field
                        className="password login_test_password"
                        name="password1"
                        data-test-id="passwordtest"
                        type={this.state.showPassword1 ? 'text' : 'password'}
                        placeholder="New Password"
                        as={StyledTextField}
                        InputProps={{
                          disableUnderline: true,
                          style: { border: 'none', height: '', marginTop: '5px' },
                          onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
                            if (event.key === ' ') {
                              event.preventDefault();
                            }
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id="eyebutton"
                                className="password_button_type"
                                aria-label="toggle password visibility"
                                onClick={() => this.handleShowPass('password1')}
                                edge="end"
                                style={{ marginRight: '10px', color: values.password1 ? '#4B4C4B' : '#C5CBC9' }}
                              >
                                {this.state.showPassword1 ? <VisibilityOutlinedIcon className="visiblityIcon" /> : <VisibilityOffOutlinedIcon className="visiblityIcon" />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        InputLabelProps={{ shrink: false }}
                        inputProps={{ maxLength: 15 }}
                        value={values.password1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box style={{ marginTop: '16px', width: '100%' }} >
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: this.isErrorborder(touched.password2, errors.password2, '1px solid #C43937', '1px solid #C5CBC9')}} className="emailBox">
                      <LockOutlinedIcon className="emailIcon" style={{ textAlign: 'start', padding: '5px', color: this.isErrorborder(true, values.password1, '#4B4C4B', '#C5CBC9') }} />
                      <Field
                        className="password login_test_password2"
                        name="password2"
                        data-test-id="passwordtest"
                        type={this.state.showPassword2 ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        as={StyledTextField}
                        InputProps={{
                          disableUnderline: true,
                          style: { border: 'none', height: '', marginTop: '5px' },
                          onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
                            if (event.key === ' ') {
                              event.preventDefault();
                            }
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                className="password_button_type2"
                                data-test-id="eyebutton"
                                aria-label="toggle password visibility"
                                onClick={() => this.handleShowPass('password2')}
                                edge="end"
                                style={{ marginRight: '10px', color: values.password2 ? '#4B4C4B' : '#C5CBC9' }}
                              >
                                {this.state.showPassword2 ? <VisibilityOutlinedIcon className="visiblityIcon" /> : <VisibilityOffOutlinedIcon className="visiblityIcon" />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        InputLabelProps={{ shrink: false }}
                        inputProps={{ maxLength: 15 }}
                        value={values.password2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </Box>

                  </Box>

                  <Box className="errorcheckbox" display={"flex"} flexDirection={"column"} style={{ marginTop: '15px' }}>
                    <Typography style={{ fontSize: '14px', fontWeight: 400, color: '#013D4F' }}>Password should have</Typography>
                    <Grid container spacing={1} style={{ marginTop: '15px' }}>
                      {this.passwordCriteria.map((criteria, index) => (
                        <Grid item xs={6} key={index}>
                          <Grid container alignItems="center">
                            <Grid item>
                              {criteria.test(values.password1) && (
                                <CheckCircleIcon style={{ color: '#26BBB3', height: '16px', width: '16px' }} />
                              )}
                              {!criteria.test(values.password1) && values.password1 && (
                                <CancelIcon style={{ color: '#C43937', height: '16px', width: '16px' }} />
                              )}
                              {!values.password1 && (
                                <RadioButtonUncheckedIcon style={{ height: '16px', width: '16px', color: '#4B4C4B' }} />
                              )}
                            </Grid>
                            <Grid item>
                              <Typography style={{ fontSize: '12px', color: '#4B4C4B', fontWeight: 400, marginLeft: '5px', marginTop: '2px' }}>{criteria.label}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>

                  <Box display={'flex'} flexDirection={'column'} style={{ width: '100%', marginTop: '30px' }}>
                    <Buttondb className="resetpassword" type="submit" disabled={!values.password1 || !values.password2 || Boolean(errors.password1) || Boolean(errors.password2)} variant="contained">
                      <Typography className="buttonText" component={'p'}>Reset Password</Typography>
                    </Buttondb>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    )
  }
  SuccessPage = () => {
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"calc(100vh - 180px)"}

      >
        <Box width={"60%"} display={"flex"} flexDirection={"column"} className="formLayout">
          <Box display={"flex"} flexDirection={"column"} style={{ rowGap: '5px', width: '100%', textAlign: 'center' }}>
            <Typography className="gothamText mainHeadingText" component={'h5'}>Congratulations!</Typography>
            <Typography className="gothamText mainSubTitleText" component={'p'}>You have successfully reset your password</Typography>
          </Box>

          <Box display={'flex'} flexDirection={'column'} style={{ width: '100%', marginTop: '30px' }}>
            <Buttondb onClick={this.goTologin} variant="contained">
              <Typography component={'p'} className="buttonText">Back To Log In</Typography>
            </Buttondb>
          </Box>

        </Box>

      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NewBox style={{ display: 'flex', width: '100vw', height: '100vh' }} className="box">
          <Box
            style={{
              width: '66%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              overflowY: 'auto',
              position: 'relative',
              zIndex: 1,
            }}
          >
            <Box>
              <Box style={{ marginTop: '50px', marginLeft: '11%' }}>
                <img src={vitulogoimg} className="vituLogo" />
              </Box>
              {(!this.state.isSent && !this.state.tokenFromBe) && this.EmailInputPage()}
              {(this.state.isSent && !this.state.tokenFromBe) && this.EmailSentPage()}
              {(this.state.tokenFromBe && !this.state.resetPasswordSuccess) && this.ResetPasswordPage()}
              {(this.state.tokenFromBe && this.state.resetPasswordSuccess) && this.SuccessPage()}
            </Box>

            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              marginBottom={'50px'}
              alignItems={"center"}
            >
              <Typography style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500, color: '#4B4C4B' }}>
                Copyright © 2024 Vitu
              </Typography>
              <Button variant="text" onClick={this.goToTerms} data-test-id="tandc">
                <Typography
                  style={{
                    marginRight: '40px',
                    fontSize: '14px',
                    textTransform: 'none',
                    textDecoration: 'underline',
                    fontWeight: 500,
                    color: '#4B4C4B',
                  }}
                >
                  Terms and Conditions
                </Typography>
              </Button>
            </Box>
          </Box>



          <RightImageBox
            style={{
              position: 'fixed',
              right: 0,
              width: '34%',
              height: '100vh',
              overflow: 'hidden',
            }}
          >
            <img
              src={grouppng}
              height="100%"
              width="100%"
              style={{ objectFit: 'fill' }}
            />
          </RightImageBox>
        </NewBox>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  // custom style definitions
};

const NewBox = styled(Box)({
  "@media (max-width:900px)": {
    minHeight: '800px'
  },
  "@media (min-width:1000px)": {
    minHeight: '800px'
  },
  '& .mainHeadingText': {
    fontWeight: '300',
    color: '#013D4F',
    fontSize: '24px',
    [`@media (min-width:1920px)`]: {
      fontSize: "30px",
    }
  },
  '& .mainSubtitleText': {
    fontWeight: '300',
    color: '#4B4C4B',
    fontSize: '14px',
    [`@media (min-width:1920px)`]: {
      fontSize: "18px",
    }
  },
  '& .errorText': {
    color: '#C43937',
    paddingTop: '13px',
    marginLeft: '10px',
    fontSize: '12px',
    fontWeight: 700,
    [`@media (min-width:1920px)`]: {
      fontSize: "14px",
    }
  },
  '& .formLayout': {
    rowGap: '16px',
    marginTop: '100px',
    maxWidth: '512px',
    [`@media (min-width:1920px)`]: {
      marginTop: '200px',
    }
  },
  '& .resetPasswordText': {
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '12px',
    color: '#013D4F',
    [`@media (min-width:1920px)`]: {
      fontSize: '16px',
    },
  },
  '& .emailBox': {
    height: '44px',
    [`@media (min-width:1920px)`]: {
      height: '56px',
    },
    padding: '10px, 24px, 10px, 24px',
    borderRadius: '8px'
  },
  '& .emailIcon': {
    textAlign: 'start',
    marginLeft: '10px',
    padding: '5px',
    height: '18px',
    width: '18px',
    [`@media (min-width:1920px)`]: {
      height: '1em',
      width: '1em',
    },
  },
  '& .vituLogo': {
    height: '38px',
    width: '75px',
    [`@media (min-width:1920px)`]: {
      height: '42px',
      width: '91.27px'
    }
  },
  '& .emailText': {
    color: '#013D4F', fontWeight: 'bold', fontSize: '14px',
    [`@media (min-width:1920px)`]: {
      fontSize: '18px',
    },
  },
  '& .resendText': {
    fontWeight: 510,
    padding: '0px',
    fontSize: '12px',
    textTransform: 'none',
    [`@media (min-width:1920px)`]: {
      fontSize: '16px',
    },
  },
  '& .visiblityIcon':{
    width:18,
    height:18,
    [`@media (min-width:1920px)`]: {
      width:'1em',
      height:'1em',
    },

  }


})

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input::placeholder': {
    color: '#C5CBC9',
    fontSize: '12px',
    opacity: 1,
    fontWeight: 400,
    fontFamily:'Roboto',
    [theme.breakpoints.up(1920)]: {
      fontSize: '14px',
    }
  },
  '& input': {
    [theme.breakpoints.up(1920)]: {
      fontSize: '14px',
    },
    fontFamily:'Roboto',
    fontSize: '12px',
  }
}));

const RightImageBox = styled(Box)({
  "@media (max-width:699px)": {
    display: 'none'
  },
  "@media (min-width:700px)": {
    display: 'block'
  },
})
// Customizable Area End
