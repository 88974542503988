import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from '@material-ui/core';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { DealInfo } from "../../projecttemplates/src/ProjectTemplatesDealDashboardController.web";

interface Account {
  id: string;
  full_name: string;
  role: string;
  profile_picture: string;
}
interface Note {
  id: string;
  note: string;
  note_type: string;
  updated_time: string;
  updated_date: string;
  dealership_name: string;
  is_auto_note: boolean;
  account: Account;
}

export const utils = require('./utils');
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  dealInfo?: DealInfo;
  isSPHired: boolean;
  isDealCompletedOrDeleted: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notes: Note[];
  isAddingNote: boolean;
  isEditingNote: boolean;
  note: Note;
  currentAccountId: string;
  isChanging: boolean;
  selectedFiles?: File[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DealDashboardNoteController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetNoteDataId: string = '';
  apiAddNewNoteId: string = '';
  apiEditNoteId: string = '';
  apiDeleteNoteId: string = '';
  apiGetCurrentAccountId: string = '';

  handleClickOpen = () => {
    this.setState({
      isAddingNote: true,
    });
  };

  handleClose = () => {
    this.setState({
      isAddingNote: false,
      isEditingNote: false,
      isChanging: false,
    });
  };

  handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      note: {
        ...prevState.note,
        note_type:
          prevState.note.note_type === 'internal' ? 'external' : 'internal',
      },
    }));
    this.setState({
      isChanging: true,
    });
  };

  handleContentChange = (content: any) => {
    this.setState((prevState) => ({
      note: {
        ...prevState.note,
        note: content,
      },
    }));
  };

  handleAddNote = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddNewNoteId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_projectnotes/notes'
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    const noteContent = this.state.selectedFiles && this.state.selectedFiles.length > 1 
      ? "Documents uploaded successfully" 
      : this.state.note.note;
    const payload = {
      data: {
        note: noteContent,
        note_type: this.state.note.note_type,
        deal_id: localStorage.getItem("createdDealId")
      },
    };

    console.log("Payload being sent to backend: ", payload);

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    this.setState(prevState => ({
      note: {
        ...prevState.note,
        note: noteContent
      } as Note
    }));

    return true;
  };

  handleEditNote = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEditNoteId = webRequestMessage.messageId;
    
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_projectnotes/notes/' + this.state.note.id
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          note: this.state.note.note,
          note_type: this.state.note.note_type,
        },
      })
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  getDefaultAccount = () => {
    return {
      id: '',
      full_name: 'default',
      role: 'dealer',
      profile_picture: 'default',
    };
  };

  getDefaultNote = () => {
    return {
      id: '' + 0,
      note: '',
      note_type: 'internal',
      updated_time: utils.getCurrentTime(),
      updated_date: utils.getCurrentDate(),
      dealership_name: '',
      is_auto_note: false,
      account: this.getDefaultAccount(),
    };
  };

  deleteNote = (id: string) => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteNoteId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_projectnotes/notes/' + id
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  editNote = (id: string) => {
    const editNote = this.state.notes.find((note) => note.id === id);
    this.setState({
      isEditingNote: true,
      note: editNote || this.getDefaultNote(),
    });
  };

  onSelectChange = (value: string, id: string) => {
    if (value === 'edit') {
      this.editNote(id);
    }
    if (value === 'delete') {
      this.deleteNote(id);
    }
  };

  getNoteData = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetNoteDataId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_projectnotes/notes?deal_id=' + localStorage.getItem("createdDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  mapToNotes = (data: any) => {
    return data.map((item: any) => {
      const { id, note, note_type, updated_time, updated_date, dealership_name, account_id, full_name, profile_picture, role, is_auto_note} =
        item.attributes;

      return {
        id,
        note,
        note_type,
        updated_time,
        updated_date,
        dealership_name,
        is_auto_note,
        account: {
          id: account_id,
          full_name: full_name,
          profile_picture: profile_picture,
          role: role
        }
      };
    });
  };

  getCurrentAccount = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCurrentAccountId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/get_user'
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  updateSelectedFiles = (files: File[]) => {
    this.setState({ selectedFiles: files });
  };

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      notes: [],
      isAddingNote: false,
      isEditingNote: false,
      note: this.getDefaultNote(),
      isChanging: false,
      currentAccountId: '',
      selectedFiles: [],
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getNoteData();
    this.getCurrentAccount();
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if(prevProps.dealInfo !== this.props.dealInfo){
        this.getNoteData();
      }
  }
  dealWithNoteDataResponse(webResponseJson: any){
    if(webResponseJson.data){
      this.setState({
        notes: this.mapToNotes(webResponseJson.data),
      });
    }
    else{
      this.setState({
        notes: [],
      });
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiGetNoteDataId) {
          this.dealWithNoteDataResponse(webResponseJson);
        }

        if (webApiRequestCallId === this.apiAddNewNoteId
          || webApiRequestCallId === this.apiDeleteNoteId
          || webApiRequestCallId === this.apiEditNoteId
        ) {
          this.getNoteData();
        }

        if (webApiRequestCallId === this.apiGetCurrentAccountId) {
          const { id } = webResponseJson.data;
          this.setState({
            currentAccountId: id,
          });
        }
      }
    }
    // Customizable Area End
  }
}
