import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  styled,
  Avatar,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  // Customizable Area End
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Customizable Area Start
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";
import { dislikeButton, dislikeButtonChecked, emptyCheckbox, filledCheckbox, likeButton, likeButtonChecked, logo } from "./assets";
import { notifications } from "../../../blocks/dashboard/src/assets";
import HamburgerMenuFull from "../../customisableuserprofiles/src/HamburgerMenuFull.web";

interface Styles {
  [key: string]: any;
}

// Customizable Area End

import MultilevelApprovalController, {
  Props,
  EventList,
  configJSON,
} from "./MultilevelApprovalController.web";

export default class MultilevelApproval extends MultilevelApprovalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  demoEvent: EventList = {
    id: 1,
    attributes: {
      name: "Annual Tech Conference",
      description: "A conference focusing on the latest in technology and innovation.",
      status: "Scheduled",
      comment: "Registration opens next week."
    }
  };
  unusedComponent = (event: EventList) => {
    return (
      <>
        <Dialog open={false}>
          <IconButton />
          <CloseIcon />
          <Typography>{event}</Typography>
        </Dialog>
      </>
    )
  }
  headerr = () => {
    return (
      <Box sx={webStylesHeader.header}>
        <Box sx={webStylesHeader.headerLeftSide}>
          <HamburgerMenuFull navigation={this.props.navigation} id={""} data-test-id="hamburgerMenuTestId" />

          <Box sx={webStylesHeader.logo} style={{ marginLeft: '4px' }}>
            <img style={{ marginBottom: '5px', height: '24px', width: '52px' }} src={logo} alt="vitu logo" />
          </Box>
        </Box>

        <Box sx={webStylesHeader.headerRightSide}>
          <Box
            data-test-id="notifications"
            sx={webStylesHeader.notificationsButton}
          >
            <img src={notifications} alt="notifications button" />
          </Box>

          <ProfileDropdown navigation={this.props.navigation} id={""} data-test-id="profileDropdownTestId" />
        </Box>
      </Box>
    )
  }

  getBackgroundColor = (item: string, selectedItems: string[]) => {
    return selectedItems.includes(item) ? '#013D4F' : 'transparent';
  };

  getItemWidth = (item: string) => {
    switch (item) {
      case 'Speed':
        return '85px';
      case 'Accuracy':
        return '106px';
      default:
        return '158px';
    }
  };

  renderCompleteDeal = () => {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minWidth: '901px', height: '100%' }}>
        <Box className="mainContent">
          <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: '104px', padding: '0 20px', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Typography component={'h1'} className="gothamLightText completeText">Complete the Deal</Typography>
            <Typography className="gothamText subHeadingDescription">Please confirm to finalize the deal and release the pending fees to holding. Fees will be released to the service provider upon completion of the deal.</Typography>
          </Box>
          <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '140px', alignItems: 'center', border: '1px solid #F1F4F3', borderRadius: '10px', padding: '0 6%', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Box style={{ width: '40%', marginTop: '8px' }}>
              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography component={'h6'} className="idText" style={{ marginBottom: "8px" }}>ID:</Typography>
                <Typography component={'h6'} className="idText">{this.state.dealId}</Typography>
              </Box>
              <Box>
                <Typography component={'p'} className="tableValue">{this.state.spName}</Typography>
              </Box>
              <Box style={{ display: 'flex', backgroundColor: '#F0FAFA', borderRadius: '5px', width: '88px', height: '25px', justifyContent: 'space-around', alignItems: 'center', padding: '0px 10px', marginTop: '4px' }}>
                <ThumbUpAltOutlinedIcon style={{ color: '#4B4C4B', width: '15px', height: '15px' }} />
                <Box style={{ display: 'flex' }}>
                  <Typography component={"p"} className="positiveText">86</Typography>
                  <Typography component={"p"} className="positiveText">%</Typography>
                </Box>
                <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight: 500 }}>Positive</Typography>
              </Box>
              <Typography></Typography>
            </Box>
            <Box style={{ display: 'flex', width: '200%', justifyContent: "space-between", alignItems: 'flex-start', height: '50%' }}>
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography component={'h6'} className="tableHeading">{configJSON.customerLastName}</Typography>
                <Typography component={"p"} className="tableValue">{this.state.lastName}</Typography>
              </Box>
              <Box>
                <Typography component={'h6'} className="tableHeading">STATES</Typography>
                <Typography component={"p"} className="tableValue">{this.state.fromState} {'>'} {this.state.toState}</Typography>
              </Box>
              <Box>
                <Typography component={'h6'} className="tableHeading">APPLICATION</Typography>
                <Typography component={"p"} className="tableValue">{this.state.application}</Typography>
              </Box>
              <Box>
                <Typography component={'h6'} className="tableHeading">SERVICES</Typography>
                <Typography component={"p"} className="tableValue">{this.state.service}</Typography>
              </Box>
              <Box>
                <Typography className="gothamText tableHeadingLigthText" component={'h6'}>VEHICLE TYPE</Typography>
                <Typography component={"p"} className="tableValue">{this.state.vehicleType}</Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box style={{ width: '100%', height: '263px', border: '1px solid #F0FAFA', borderBottom: '1px dashed #F0FAFA', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', borderBottomStyle: 'none', background: 'linear-gradient(to right, #F0FAFA 50%, transparent 50%) bottom repeat-x', backgroundSize: '18px 1px', padding: '0 6%', boxSizing: 'border-box', overflow: 'hidden' }}>
              <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '90%' }}>
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '128px' }}>
                  <Box>
                    <Box>
                      <Typography component={'h5'} className="totalFees">Total Fees To Release</Typography>
                    </Box>
                  </Box>
                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Typography component={'h6'} className="positiveTextValue">Registration Fee</Typography>
                    <Box style={{ display: 'flex' }}>
                      <Box style={{ display: 'flex', color: '#C5CBC9', textDecoration: 'line-through', marginRight: '4px' }}>
                        <Typography component={'p'} className="valueFees">$</Typography>
                        <Typography  component={"p"} className="valueFees">{this.state.prevRegFee}</Typography>
                      </Box>
                      <Box style={{ display: 'flex' }}>
                        <Typography component={'p'} className="valueFees">$</Typography>
                        <Typography  component={'p'} className="valueFees">{this.state.regFee}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Typography component={'h6'} className="positiveTextValue">Service Fee</Typography>
                    <Box style={{ display: 'flex' }}>
                      <Typography component={'p'} className="valueFees">{this.state.serviceFee}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', marginLeft: '-10px' }}>
                  <Checkbox checked={this.state.termsAndConditionCheck} data-test-id="termsAndConditionCheckboxId" color="primary" onChange={this.handleTermsAndCondition} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                  <Typography style={{ color: '#0F172A' }}>I agree to the <span style={{ color: '#013D4F', textDecoration: 'underline', cursor: 'pointer' }} onClick={this.navigateToTermsConditions} data-test-id="termsAndConditionTestId">Terms and Conditions</span></Typography>
                </Box>
              </Box>
              <Box></Box>
            </Box>
            <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', height: '127px', border: '1px solid #F0FAFA', borderTop: '0px dotted #F1F4F3', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', padding: '0 6%', boxSizing: 'border-box', overflow: 'hidden' }}>
              <Box style={{ width: '100%' }}>
                <Button data-test-id="RejectAndTellUsWhyTestId" onClick={this.setRejectedReview} className="mayBeLaterBtn">Reject & Tell Us Why</Button>
              </Box>
              <Box style={{ width: '209.16%', display: 'flex', justifyContent: 'center', marginRight: '30px' }}>
                <Button data-test-id="submitButtonTestId"
                  onClick={this.setSubmittedTrue}
                  disabled={!this.state.termsAndConditionCheck} className="submitBtn"
                  style={{
                    width: '432px',
                    color: `${this.state.termsAndConditionCheck ? '#FFFFFF' : '#A3E2F5'}`,
                    backgroundColor: `${this.state.termsAndConditionCheck ? '#4FC8EC' : '#E8F8FD'}`
                  }}>
                  Confirm & Release Fees</Button>
              </Box>
              <Box style={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <Box>
                  <Typography style={{ fontWeight: 500, fontSize: '12px', color: '#4B4C4B' }}>Total Fee</Typography>
                </Box>
                <Box>
                  <Box style={{ display: 'flex', marginTop: '4px', color: '#013D4F' }}>
                    <Typography component={'h6'} className="priceText">$</Typography>
                    <Typography component={'h6'} className="priceText">{this.state.totalFee}</Typography>
                  </Box>
                  <Box className="strikeOut">
                    <Typography>$</Typography>
                    <Typography>{this.state.prevTotalFee}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ width: '100%', height: '150px', display: 'flex', border: '1px solid #F0FAFA', borderRadius: '10px', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', padding: '0 6%', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Box style={{ height: '54px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Typography component={'h6'} className="attentionText">Attention Please</Typography>
              <Typography component={"p"} className="flagText">This deal will automatically be closed after 15 days. You may go to <span style={{ color: '#4FC8EC' }}>Deal Details</span> to find more information about this deal.</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  renderReviewDealerSide = () => {
    const { selectedItems } = this.state;
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '703px', width: '100%', height: '100%' }}>
        <Box style={{
          margin: "55px 0",
          height: `${this.state.showTextArea ? '663px' : '459px'}`,
          minWidth: '703px',
          width: '730px',
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: 'space-between',
        }}>
          <Box style={{ width: "100.45%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: '104px', padding: '0 10px', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Typography className="gothamLightText heading">Deal completed successfully </Typography>
            <Typography className="gothamText subHeadingDescription">Thank you for choosing Vitu Marketplace! We value your feedback and would love to hear about your experience with your hired service provider.</Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', border: '1px solid #F0FAFA', borderRadius: '24px', width: '701px', height: `${this.state.showTextArea ? '526px' : '322px'}`, justifyContent: 'space-around', alignItems: 'center' }}>
            <Box>
              <Typography variant="h6" className="subTitleReview">How was your experience with {this.state.spName} Services? </Typography>
            </Box>
            <Box style={{ marginBottom: '20px' }} data-test-id="dealerGroup">
              <RadioGroup
                aria-label="dealer-group"
                name="dealerGroup"
                className="radioClass"
                value={this.state.likeButton.toString()}
                onChange={this.handleRadioChange}
                row={true}
                style={{ color: "black", width: '126px', display: 'flex', justifyContent: 'space-between' }}
              >
                <StyledFormControlLabel
                  value="true"
                  data-test-id="radiotest"
                  control={
                    <CustomRadio
                      disableRipple
                      color="default"
                      checkedIcon={<img src={likeButtonChecked} />}
                      icon={<img src={likeButton} />}
                      {...this.props}
                    />} label="Good" />
                <StyledFormControlLabel
                  value="false"
                  control={<CustomRadio
                    disableRipple
                    color="default"
                    checkedIcon={<img src={dislikeButtonChecked} />}
                    icon={<img src={dislikeButton} />}
                    {...this.props}
                  />} label="Bad" />
              </RadioGroup>
            </Box>
            {this.state.showTextArea && (
              <>
                <Box style={{ width: '389px', height: '44px', display: 'flex', justifyContent: 'space-between' }} data-test-id="selectedItemsButton">
                  {['Speed', 'Accuracy', 'Communication'].map((item, index) => (
                    <Button
                      key={index}
                      onClick={() => this.handleButtonClick(item)}
                      className="reviewsItem"
                      style={{
                        width: this.getItemWidth(item),
                        backgroundColor: this.getBackgroundColor(item, selectedItems),
                        border: selectedItems.includes(item) ? 'none' : '1px solid #C5CBC9',
                        color: selectedItems.includes(item) ? '#FFFFFF' : 'black',
                      }}
                    >
                      {item}
                    </Button>
                  ))}
                </Box>
                <Box>
                  <TextField
                    label="Tell us more..."
                    style={{ width: '549px', height: '100px' }}
                    variant="outlined"
                    data-test-id="textBoxDealerReviewTestId"
                    multiline
                    value={this.state.dealerReviewText}
                    onChange={(event) => { this.handleDealerReviewTextChange(event) }}
                    rows={4}
                  ></TextField>
                </Box>
              </>
            )}
            <Box style={{ display: 'flex', width: '549px', justifyContent: 'space-between', alignItems: 'center', height: '56px' }}>
              <Box style={{ width: '100%' }}>
                <Button onClick={this.navigateToDashboard} variant="outlined" className="mayBeLaterBtn"> Maybe Later</Button>
              </Box>
              <Box>
                <Button data-test-id="submitReviewButtonTestId"
                  onClick={this.setSubmitReview}
                  disabled={this.state.selectedItems.length == 0}
                  className="submitBtn"
                  style={{
                    color: `${this.state.selectedItems.length != 0 ? '#FFFFFF' : '#A3E2F5'}`,
                    backgroundColor: `${this.state.selectedItems.length != 0 ? '#4FC8EC' : '#E8F8FD'}`
                  }}>Submit Review</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  renderFeedbackSent = () => {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '703px', width: '100%', height: '100%' }}>
        <Box style={{
          margin: "55px 0",
          height: '287px',
          minWidth: '703px',
          width: '730px',
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: 'space-between',
        }}>
          <Box style={{ width: "100.45%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: '104px', padding: '0 10px', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Typography className="gothamLightText" style={{ fontWeight: 300, fontSize: "36px", color: "#013D4F" }}>Feedback Sent</Typography>
            <Typography className="gothamText" style={{ fontWeight: 400, fontSize: "18px", color: "#4B4C4B", textAlign: 'center' }}>Thank you for providing your feedback. Your input helps us improve our Marketplace services.</Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', borderRadius: '24px', width: '701px', height: '150px', justifyContent: 'space-around', alignItems: 'center' }}>
            <Box style={{ display: 'flex', width: '590px', justifyContent: 'space-between', alignItems: 'center', height: '56px' }}>
              <Box style={{ width: '100%' }}>
                <Button onClick={this.navigateToDealDashboard} style={{ border: '1px solid #4C4D4C', borderRadius: '8px', width: '210px', height: '56px' }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: '#4C4D4C', textTransform: 'none' }}>View Deal Dashboard</Typography></Button>
              </Box>
              <Box>
                <Button onClick={this.navigateToDashboard} data-test-id="backToHomeButtonTestId" style={{ borderRadius: '8px', width: '346px', height: '56px', backgroundColor: '#4FC8EC' }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: '#FFFFFF', textTransform: 'none' }}>Back to Home</Typography></Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  renderRejectedReview = () => {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Box style={{
          margin: "55px 0",
          width: '100%',
          maxWidth: '901px',
          display: "flex",
          height: '800px',
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: 'space-between',
          position: "relative",

        }}>
          <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", minHeight: '104px', padding: '0 20px', boxSizing: 'border-box', overflow: 'hidden', minWidth: '901px' }}>
            <Typography className="gothamLightText completeText">Tell Us Why</Typography>
            <Typography className="gothamText subHeadingDescription">{configJSON.tellUsText}</Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', width: '901px', height: '487px', border: '1px solid #F0FAFA', borderRadius: '10px', }}>
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between', width: '805px', height: '287px' }}>
              <StyledLabelTextField
                label="Subject"
                style={{ width: '805px', height: '56px', paddingLeft: '4px', fontSize: 400, color: 'rgba(75, 76, 75, 1)' }}
                variant="outlined"
                data-test-id="tellUsWhySubjectTestId"
                value={this.state.tellUsWhySubject}
                onChange={(event) => this.handleTellUsWhySubject(event)}
              />
              <StyledTextField
                label="Details of the issue"
                variant="outlined"
                data-test-id="tellUsWhyDetailsTestId"
                multiline
                value={this.state.tellUsWhyDetails}
                onChange={(event) => this.handleTellUsWhyDetails(event)}
              />
            </Box>
            <Box style={{ display: 'flex', width: '781px', justifyContent: 'space-between', alignItems: 'center', height: '56px' }}>
              <Box style={{ width: '100%' }}>
                <Button data-test-id="cancelButtonTestid"
                  onClick={this.cancelRejectedReview} className="mayBeLaterBtn" style={{ width: '380px' }}>Cancel</Button>
              </Box>
              <Box>
                <Button data-test-id="sendButtonTestid" className="submitBtn" onClick={this.setIssueResolved} disabled={!this.handleTellUsWhySentCond} style={{
                  width: '380px',
                  color: `${this.handleTellUsWhySentCond() ? '#ffffff' : '#A3E2F5'}`,
                  backgroundColor: `${this.handleTellUsWhySentCond() ? '#4FC8EC' : '#E8F8FD'}`
                }}>Send</Button>
              </Box>
            </Box>
          </Box>
          <Box style={{ height: '172px', display: 'flex', border: '1px solid #F0FAFA', borderRadius: '10px', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', padding: '0 6%', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Box style={{ height: '76px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Typography component={'h6'} className="attentionText">Attention Please</Typography>
              <Typography component={'p'} className="flagText">{configJSON.flagIncomplete}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  renderIssueReceived = () => {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Box style={{
          margin: "55px 0",
          height: '287px',
          width: '100%',
          maxWidth: '703px',
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: 'space-between',
        }}>
          <Box style={{ width: "701px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: '0 10px', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Typography className="gothamLightText" style={{ fontWeight: 300, fontSize: "36px", color: "#013D4F" }}>Issue Received</Typography>
            <Typography className="gothamText" style={{ fontWeight: 400, fontSize: "18px", color: "#4B4C4B", textAlign: 'center' }}>Thank you for reaching out and providing this information. We will work with your service provider to notify them of the issue and get this resolved.</Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', width: '701px', height: '150px', justifyContent: 'space-around', alignItems: 'center' }}>
            <Box style={{ display: 'flex', width: '590px', justifyContent: 'space-between', alignItems: 'center', height: '56px' }}>
              <Box style={{ width: '100%' }}>
                <Button data-test-id="backToDashboardButtonTestId" onClick={this.navigateToDashboard} style={{ border: '1px solid #4C4D4C', borderRadius: '8px', width: '210px', height: '56px' }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: '#4C4D4C', textTransform: 'none' }}>Back to Home</Typography></Button>
              </Box>
              <Box>
                <Button data-test-id="navigateToDealDashboardButtonTestId" onClick={this.navigateToDealDashboard} style={{ borderRadius: '8px', width: '346px', height: '56px', backgroundColor: '#4FC8EC' }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: '#FFFFFF', textTransform: 'none' }}>Back to Deal Dashboard</Typography></Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Box>
        <Box style={{ position: 'absolute', display: "none" }}>
          {this.unusedComponent(this.demoEvent)}
        </Box>
        <CustomisableUserProfilesStyle>
          {this.headerr()}
          <Box style={{ width: "99.9%", position: "absolute", left: "0", borderTop: "2px solid #F0FAFA", marginTop: "10px" }} />
          {!this.state.submittedConfFees && !this.state.submitReview && !this.state.rejectedReview && !this.state.issueResolved && this.renderCompleteDeal()}
          {this.state.submittedConfFees && this.renderReviewDealerSide()}
          {this.state.submitReview && this.renderFeedbackSent()}
          {this.state.rejectedReview && this.renderRejectedReview()}
          {this.state.issueResolved && this.renderIssueReceived()}
        </CustomisableUserProfilesStyle>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomisableUserProfilesStyle = styled(Box)({
  padding: "24px",
  boxSizing: "border-box",
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
    },
  },
  "& .MuiAutocomplete-root": {
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: "6px",
      height: "56px",
      "& .MuiAutocomplete-input": {
        padding: "0px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "9px",
      },
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '9px',
      },
      '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
        backgroundColor: "#013D4F",
      },
      "& .MuiAutocomplete-popper": {
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option": {
            "&[data-focus='true'], &:hover": {
              backgroundColor: "##013D4F",
            },
            "&[aria-selected='true']": {
              backgroundColor: "#013D4F",
            },
          },
        },
      },
    },
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
    }
  },
  '& label.Mui-focused': {
    color: '#4B4C4B',
    fontWeight: 500
  },
  '& .MuiInputLabel-root': {
    paddingLeft: '6px',
    color: '#C5CBC9',
  },
  '& label.MuiInputLabel-shrink': {
    marginLeft: 0,
    paddingLeft: 0,
    color: '#4B4C4B',
    fontWeight: 500
  },
  "& .MuiFormGroup-root": {
    display: "inline",
  },
  "& .MuiFormControlLabel-label": {
    marginTop: "3.5px"
  },
  "& .mainContent": {
    margin: "55px 0",
    height: '856px',
    width: '901px',
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: 'space-between',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#C43937',
  },
  "& .Mui-checked": {
    color: "#4FC8EC",
  },
  "& .subTitleReview": {
    color: '#013D4F',
    fontWeight: 400,
    fontSize: '14px',
    ["@media (min-width: 1920px)"]: {
      fontSize: '18px',
    },
  },
  '& .mayBeLaterBtn': {
    border: '1px solid #4C4D4C',
    borderRadius: '8px',
    minWidth: '162px',
    height: '44px',
    fontSize: '12px',
    fontWeight: 700,
    color: '#4C4D4C',
    textTransform: 'none',
    ["@media (min-width: 1920px)"]: {
      height: '56px',
      fontSize: '16px',
    },
  },
  '& .heading': {
    fontWeight: 300,
    fontSize: "24px",
    color: "#013D4F",
    ["@media (min-width: 1920px)"]: {
      fontSize: '36px',
    },
  },
  '& .completeText': {
    fontWeight: 300,
    fontSize: "24px",
    color: "#013D4F",
    marginBottom: '10px',
    ["@media (min-width: 1920px)"]: {
      fontSize: '30px',
    },
  },
  '& .subHeadingDescription': {
    marginLeft: '-14px',
    marginRight: '-14px',
    fontWeight: 400,
    fontSize: "14px",
    color: "#4B4C4B",
    textAlign: 'center',
    ["@media (min-width: 1920px)"]: {
      fontSize: '18px',
    },
  },
  '& .submitBtn': {
    borderRadius: '8px',
    width: '371px',
    height: '44px',
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'none',
    ["@media (min-width: 1920px)"]: {
      height: '56px',
      fontSize: '16px',
    },
    ["@media (max-width:600px)"]: {
      width: 200

    }
  },
  '& .reviewsItem': {
    fontSize: '12px',
    fontWeight: 400,
    textTransform: 'none',
    borderRadius: '10px',
    height: '40px',
    ["@media (min-width: 1920px)"]: {
      height: '44px',
      fontSize: '16px',
    },
  },
  '& .flagText': {
    color: '#C5CBC9',
    fontWeight: 400,
    fontSize: '12px',
    ["@media (min-width: 1920px)"]: {
      fontSize: '14px',
    }
  },
  '& .attentionText': {
    fontWeight: 400,
    fontSize: '16px',
    color: '#013D4F',
    ["@media (min-width: 1920px)"]: {
      fontSize: '18px',
    }
  },
  '& .strikeOut': {
    ["@media (min-width: 1920px)"]: {
      fontSize: '20px',
    },
    display: 'flex', marginTop: '-6px',
    textDecoration: 'line-through',
    fontSize: '16px', fontWeight: 400,
    color: '#C5CBC9',
    justifyContent: 'flex-end'
  },
  '& .priceText': {
    ["@media (min-width: 1920px)"]: {
      fontSize: '28px',
    },
    fontSize: '20px',
    fontWeight: 600
  },
  '& .totalFees': {
    color: '#013D4F',
    fontWeight: 500,
    fontSize: '18px',
    ["@media (min-width: 1920px)"]: {
      fontSize: '24px',
    },
  },
  '& .tableHeading': {
    ["@media (min-width: 1920px)"]: {
      fontSize: '14px',
    },
    color: '#939F9B',
    fontWeight: 500,
    fontSize: '12px',
    marginBottom: "8px"
  },
  '& .tableValue': {
    color: '#4B4C4B',
    fontWeight: 500, fontSize: '16px',
    ["@media (min-width: 1920px)"]: {
      fontSize: '18px',
    },
  },
  '& .tableHeadingLigthText': {
    ["@media (min-width: 1920px)"]: {
      fontSize: '14px',
    },
    color: '#4B4C4B',
    fontWeight: 400,
    fontSize: '12px',
    marginBottom: "8px"
  },
  '& .idText': {
    color: '#939F9B',
    fontWeight: 500,
    fontSize: '14px',
    ["@media (min-width: 1920px)"]: {
      fontSize: '16px',
    },
  },
  '& .positiveText': {
    color: '#4B4C4B',
    fontSize: '10px',
    fontWeight: 500,
    ["@media (min-width: 1920px)"]: {
      fontSize: '12px',
    },
  },
  '& .positiveTextValue': {
    color: '#4B4C4B',
    fontSize: '14px',
    fontWeight: 500,
    ["@media (min-width: 1920px)"]: {
      fontSize: '16px',
    },
  },
  '& .valueFees': {
    fontSize: '14px',
    fontWeight: 500,
    ["@media (min-width: 1920px)"]: {
      fontSize: '16px',
    },
  }
});

const webStylesHeader: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    minWidth: '870px',
  },

  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 0px",
    minWidth: '870px',
    "@media (max-width: 375px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "0px",
  },

  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
  },

  logo: {
    width: "52px",
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px",
    "@media (max-width: 375px)": {
      width: "100%",
      justifyContent: "space-between",
      padding: "0 10px",
    },
  },


  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },


}

const StyledFormControlLabel = styled(FormControlLabel)({
  margin: 0,
  flexDirection: 'column',
  '& .MuiFormControlLabel-label': {
    marginTop: '16px',
  },
});

const CustomRadio = styled(Radio)({
  padding: 0,
});

const StyledTextField = styled(TextField)({
  width: '805px',
  '& .MuiOutlinedInput-root': {
    height: '202px',
    alignItems: 'start',
  },
  '& .MuiOutlinedInput-input': {
    padding: '6px',
    color: 'rgba(75, 76, 75, 1)',
    fontSize: '16px',
    fontWeight: 400
  },
});

const StyledLabelTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#4B4C4B',
    fontWeight: 500
  },
  '& .MuiInputLabel-root': {
    paddingLeft: '6px',
    color: '#C5CBC9',
  },
  '& label.MuiInputLabel-shrink': {
    marginLeft: 0,
    paddingLeft: 4,
    color: '#4B4C4B',
    fontWeight: 500
  },
})


// Customizable Area End


