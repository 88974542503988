Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTemplates";
exports.labelBodyText = "ProjectTemplates Body";
exports.projectAPiEndPoint = "bx_block_projecttemplates/project_templates";

exports.btnExampleTitle = "CLICK ME";
exports.documentAddOns = "Document Add-Ons";
exports.prePopulatedFormsAndDocumentChecklist =
  "Pre-Populated Forms + Document Checklist";
exports.blankForms = "Blank Forms + Document Checklist";
exports.documentChecklistOnly = "Document Checklist Only";
exports.createChatRoom="bx_block_chat/chats?deal_id",
exports.openForms="Open Checklist",
exports.openChecklist="Open Checklist",
exports.purchased="Purchased"
// Customizable Area End
