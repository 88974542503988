// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../packages/framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");

export const baseURL = require("../../../framework/src/config.js").baseURL;

export type DynamicFormElement = HTMLElement & {
  dynamicFieldList: any[];
  record: any;
  generalFormSettings: any;
  urlMapping: Record<string, string>;
  highlightInvalidFields: () => void;
  currentFormData: any;
  currentValidationStatus: "VALID" | "INVALID";
};

export interface Props extends WithStyles<any> {}

const url = baseURL
interface S {
  validationStatus: boolean;
  record: any;
  modalVisible: boolean;
  dynamicFieldMap: any;
  reviewResponseMap: any;
  validationEnabled: boolean;
}

interface SS {}

export default class AutoFormController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      validationStatus: false,
      record: null,
      modalVisible: false,
      dynamicFieldMap: null,
      reviewResponseMap: null,
      validationEnabled: false
    };
    this.formContainerRef = { current: null };
    this.formRef = { current: null };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  subScribedMessages = [
    getName(MessageEnum.AccoutLoginSuccess),
    getName(MessageEnum.RestAPIResponceMessage),
  ];

  formContainerRef: React.MutableRefObject<HTMLFormElement | null>;
  formRef: React.MutableRefObject<DynamicFormElement | null>;

  urlOverrides = {
    "/Container/CountiesJson":
      url + "/bx_block_integrationwithapi1/api_integrations/get_counties",
    "/Container/ZipDecoderJson":
      url +
      "/bx_block_integrationwithapi1/api_integrations/get_container_zip_decoder",
    "/Interstate/ValidateAddress":
      url +
      "/bx_block_integrationwithapi1/api_integrations/get_validate_address",
    "/Interstate/MakeByContainsJson":
      url +
      "/bx_block_integrationwithapi1/api_integrations/get_make_by_contain",
    "/Interstate/MoCityTaxExist": url + "/Interstate/MoCityTaxExist",
    "/Interstate/VinDecodeJson": url + "/bx_block_posts/deals/decode_vin",
    "/Interstate/ZipDecoderJson":
      url + "/bx_block_integrationwithapi1/api_integrations/get_zip_decoder",
  };

  apiGetFormFields: string = "";
  apiPostReviewFields: string = "";
  apiPostSubmitFields: string = "";

  updateValidationEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      validationEnabled: event.target.checked
    })
  }
  getFormFields = () => {
    const fromState = localStorage.getItem("fromState") || 'CA';
    const toState = localStorage.getItem("toState") || 'LA';
    const webHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFormFields = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_integrationwithapi1/api_integrations/get_form_fields?from_state=${fromState}&to_state=${toState}&service_type=FormsAndFees`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  reviewFields = () => {
    const webHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostReviewFields = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_integrationwithapi1/api_integrations/review_fields`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    const reviewFieldsData = {
        "review_fields": {
            "serviceType": "FormsAndFees",
            "state": "CA",
            "owners": [
              {
                "lastName": "ARMSTRONG",
                "ownerType": "Individual",
                "driverLicenseStateId": "4",
                "dateOfBirth": "1992-02-01",
                "driverLicenseNumber": "F1234567",
                "firstName": "GARRETT",
              }
          ],
          "seller": {
            "isIndividual": false,
            "dealershipName": "G TEST DEALER",
            "address": {
              "stateCode": "OR",
              "addressLine1": "4204 MATTSON STREET",
              "city": "SALEM",
              "zipCode": "97301",
            }
        },
            "repossession": false,
            "useTypeId": "0",
            "plateCategoryId": "0",
            "noTitle": true,
            "paperlessTitle": false,
            "locationId": 8,
            "isPermanentResident": true,
            "OdometerValidityId": "0",
            "applicationTypeId": "0",
            "saleTypeId": "0",
            "feeExemption": false,
            "leaseBuyout": {
                "isLeaseBuyout": false
            },
           
            "isMemberOfMilitary": false,
            "vin": "4JGBF7BE4BA619928",
            "vehicleTypeId": "0",
            "make": "MERCEDES-BENZ",
            "model": "GL-Class",
            "bodyTypeId": "205",
            "year": 2011,
            "unladenWeight": 5346,
            "fuelTypeId": "0",
            "odometerReading": 100000,
            "odometerReadingInId": "0",
            "caepa": false,
            "purchaseDate": "2024-11-07",
            "soldAsId": "1",
            "sellingPrice": 15000,
            "documentFees": 600,
            "addOns": 0,
            "taxableSellingPrice": 15600,
            "taxesPaidToAnotherState": false,
            "registrationActionTypeId": "0",
            "titleStateId": "37",
            "registrationAddress": {
                "stateCode": "CA",
                "countyCode": "06073",
                "countyName": "San Diego",
                "city": "LA JOLLA",
                "addressLine1": "8342 VIA SONOMA",
                "zipCode": "92037",
                "zipExt": "0818"
            },
            "isUnicorporatedAddress": false,
            "additionalAddressShowMailingAddress": false
        }
    };

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reviewFieldsData)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  onModalSubmit = (formList: any) => {
    this.setState({
      modalVisible: false,
    });

    this.submitFields(formList);
  };

  submitFields = (formList: any) => {
    const submitFieldsData= {
      'submit_fields': {
        'record': {
          ...this.state.record,
          forms: formList
        },
      },
      'deal_id' : localStorage.getItem('createdDealId')
    }
    const webHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostSubmitFields = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_integrationwithapi1/api_integrations/submit_fields`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(submitFieldsData)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  onModalCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  onSubmit = () => {
    if (this.state.validationEnabled && !this.state.validationStatus) {
      this.formRef.current?.highlightInvalidFields();
    }
    else {
      this.setState({
        record: {
          "serviceType": "FormsAndFees",
          "state": "CA",
          "locationId": 8,
          "applicationTypeId": "0",
          "OdometerValidityId": "0",
          "saleTypeId": "0",
          "feeExemption": false,
          "leaseBuyout": {
              "isLeaseBuyout": false
          },
          "repossession": false,
          "useTypeId": "0",
          "plateCategoryId": "0",
          "noTitle": true,
          "paperlessTitle": false,
          "isPermanentResident": true,
          "owners": [
              {
                  "ownerType": "Individual",
                  "lastName": "ARMSTRONG",
                  "firstName": "GARRETT",
                  "dateOfBirth": "1992-02-01",
                  "driverLicenseStateId": "4",
                  "driverLicenseNumber": "F1234567"
              }
          ],
          "isMemberOfMilitary": false,
          "vin": "4JGBF7BE4BA619928",
          "vehicleTypeId": "0",
          "make": "MERCEDES-BENZ",
          "model": "GL-Class",
          "bodyTypeId": "205",
          "year": 2011,
          "unladenWeight": 5346,
          "fuelTypeId": "0",
          "odometerReading": 100000,
          "odometerReadingInId": "0",
          "caepa": false,
          "purchaseDate": "2024-11-07",
          "soldAsId": "1",
          "sellingPrice": 15000,
          "documentFees": 600,
          "addOns": 0,
          "taxableSellingPrice": 15600,
          "taxesPaidToAnotherState": false,
          "seller": {
              "isIndividual": false,
              "dealershipName": "G TEST DEALER",
              "address": {
                  "zipCode": "97301",
                  "stateCode": "OR",
                  "city": "SALEM",
                  "addressLine1": "4204 MATTSON STREET"
              }
          },
          "registrationActionTypeId": "0",
          "titleStateId": "37",
          "registrationAddress": {
              "stateCode": "CA",
              "countyCode": "06073",
              "countyName": "San Diego",
              "city": "LA JOLLA",
              "addressLine1": "8342 VIA SONOMA",
              "zipCode": "92037",
              "zipExt": "0818"
          },
          "isUnicorporatedAddress": false,
          "additionalAddressShowMailingAddress": false
        }
      }, ()=> this.reviewFields());
    }
  };
  async componentDidMount() {
    this.getFormFields();
  }
  handleNavigation=()=>{
    const generatePage = new Message(getName(MessageEnum.NavigationMessage));
    generatePage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage) );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData),{transaction_id:"",reterivePopup:"generate"})
    generatePage.addData(getName(MessageEnum.NavigationTargetMessage), 'OrderSummary')
    generatePage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(generatePage);
  }

  async receive(from: string, message: Message) {
    const apiId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiId === this.apiGetFormFields) {
      const { defaultValuesRecord, fieldList, generalSettings } = response.data;
      this.setState({
        dynamicFieldMap: fieldList,
      });
      const ref = this.formContainerRef;
      const recordChangeHandler = (event: Event) => {
        this.setState({ record: (event as CustomEvent<any>).detail });
      };
      const validationStatusChangeHandler = (event: Event) => {
        const isValid = (event as CustomEvent<any>).detail === "VALID";
        this.setState({ validationStatus: isValid });
      };
      if (ref) {
        const formElement = document.createElement(
          "dynamic-form"
        ) as DynamicFormElement;
        formElement.dynamicFieldList = fieldList;
        formElement.generalFormSettings = generalSettings;
        formElement.record = defaultValuesRecord;
        formElement.urlMapping = this.urlOverrides;

        this.setState({ record: defaultValuesRecord });
        
        formElement.addEventListener("recordChange", recordChangeHandler);
        formElement.addEventListener(
          "validationStatusChange",
          validationStatusChangeHandler
        );

        ref.current!.appendChild(formElement);
        this.formRef.current = formElement;

        return () => {
          this.formRef.current = null;
          formElement.remove();
          formElement.removeEventListener("recordChange", recordChangeHandler);
          formElement.removeEventListener(
            "validationStatusChange",
            validationStatusChangeHandler
          );
        };
      }
    }
    if (apiId === this.apiPostReviewFields) {
     if(response&&!response.errors){
      this.setState(
        {
          reviewResponseMap: response.data,
        },
        () => {
          if(this.state.reviewResponseMap.formList){
            this.setState({
              modalVisible: true,
            });
          }
        }
      );
     }
    }
    if (apiId === this.apiPostSubmitFields){
        this.handleNavigation()
    }
  }
}
// Customizable Area End
