import {
  Box,
  createStyles,
  withStyles,
  Typography,
  WithStyles,
  Button,
  DialogContent,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import React from "react";
import { ThumbUp } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
const classes = createStyles({
  serviceProvider: {
    padding: "20px 0px 20px 0px",
    borderBottom: "1px solid #F0FAFA",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& button": {
      textTransform: "initial",
      fontWeight: 700,
    },
  },

  serviceProviderName: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#4B4C4B",
  },

  serviceProviderType: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: "#4B4C4B",
  },

  positiveContainer: {
    padding: "5px 10px 5px 10px",
    borderRadius: "5px",
    background: "#F0FAFA",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    marginLeft: "8px",
    color: "#4B4C4B",
  },

  serviceProviderStatus: {
    textAlign: "end",
  },

  dialog: {
    "& > div:nth-child(3) > div:nth-child(1)": {
      width: "600px",
      gap: "40px",
      borderRadius: "8px",
      opacity: "0px",
      background: "#FFFFFF",
      boxShadow: "none",
      margin: 0,
      display: "block",
      flex: "none",
    },
  },

  dialogHeader: {
    padding: "24px 16px 24px 40px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "544px",
  },

  dialogTitle: {
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#0F172A",
  },

  dialogContent: {
    padding: "0px 40px",
    marginBottom: "55px",
  },

  dialogContentText: {
    fontFamily: "Gotham",
    fontSize: "18px",
    fontWeight: 300,
    lineHeight: "26px",
    textAlign: "left",
    color: "#0F172A",
    marginTop: "16px",
  },

  dialogActions: {
    textTransform: "initial",
    padding: "24px 35px",
    borderTop: "1px solid rgba(242, 243, 242, 1)",

    "& > button": {
      width: "120px",
      height: "56px",
      padding: "16px",
      gap: "8px",
      borderRadius: "8px",
      opacity: "0px",
    },

    "& > button > span": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "left",
      textTransform: "initial",
    },
  },

  cancelBtn: {
    border: "1px solid rgba(76, 77, 76, 1)",

    "& > span": {
      color: "rgba(76, 77, 76, 1)",
    },
  },

  withdrawBtn: {
    background: "#4FC8EC",
    "&:hover": {
      background: "#4FC8EC",
    },

    "&.Mui-disabled": {
      background: "#E8F8FD",
      "& > span": {
        color: "#94A3B8",
      },
    },

    "& > span": {
      color: "#FFFFFF",
    },
  },

  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
});

export interface Props extends WithStyles {
  serviceProvider: any;
  withdrawDeal: (id: string) => void;
  hireNew: (id: string) => void;
}
export class ServiceProviderHiring extends React.Component<
  Props,
  {
    isWithdraw: boolean;
    selectedServiceProvider: string;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isWithdraw: false,
      selectedServiceProvider: "",
    };
  }

  withDrawHandler = (sp: string) => {
    this.setState({
      isWithdraw: !this.state.isWithdraw,
      selectedServiceProvider: sp,
    });
  };

  cancelWithdrawHandler = () => {
    this.setState({
      isWithdraw: !this.state.isWithdraw,
      selectedServiceProvider: "",
    });
  };

  render(): React.ReactNode {
    const { classes, serviceProvider } = this.props;
    const { isWithdraw, selectedServiceProvider } = this.state;
    const isRejected = serviceProvider.status === 'rejected';
    return (
      <>
        <Box className={classes.serviceProvider}>
          <Box className={classes.serviceProviderInfo}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography className={classes.serviceProviderName}>
                {serviceProvider.name}
              </Typography>
              <Typography className={classes.positiveContainer}>
                <ThumbUp style={{width: '15px', height: '15px'}}/>
                <span style={{ textWrap: "nowrap", fontSize:'12px' }}>
                  {serviceProvider.postivePercent}% Positive
                </span>
              </Typography>
            </Box>
            <Typography className={classes.serviceProviderType}>
              {serviceProvider.service}
            </Typography>
          </Box>
          <Box className={classes.serviceProviderStatus}>
            <Button
              data-test-id="status"
              variant="text"
              style={{ color: isRejected ? "#C43937" : '#C5CBC9', fontWeight: 400 }}
            >
              {isRejected ? 'Rejected' : 'No Response'}
            </Button>
            <Box display={"flex"}>
              <Button
                data-test-id="withdraw-btn"
                variant="text"
                style={{ color: isRejected ? "#C5CBC9": "#013D4F" }}
                disabled={isRejected}
                onClick={() => this.withDrawHandler(serviceProvider.name)}
              >
                Withdraw
              </Button>
              <Button
                data-test-id="hire-new-btn"
                variant="text"
                style={{ color: "#4FC8EC", textWrap: "nowrap" }}
                onClick={() => this.props.hireNew(serviceProvider.id)}
              >
                Hire New
              </Button>
            </Box>
          </Box>
        </Box>
        <Dialog
          data-test-id="withdraw-dialog"
          open={isWithdraw}
          keepMounted
          onClose={this.cancelWithdrawHandler}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          className={classes.dialog}
        >
          <Box className={classes.dialogHeader}>
            <ClearIcon
              data-test-id="close-icon"
              onClick={this.cancelWithdrawHandler}
            />
          </Box>
          <DialogContent className={classes.dialogContent}>
            <Typography className={classes.dialogTitle}>
              Withdraw Invitation
            </Typography>
            <Box className={classes.dialogContentText}>
              {`Are you sure you want to withdraw the deal request to ${selectedServiceProvider}? Your deal will not be deleted.`}
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              data-test-id="cancel-withdraw-btn"
              className={classes.cancelBtn}
              onClick={this.cancelWithdrawHandler}
            >
              Cancel
            </Button>
            <Button
              data-test-id="withdraw-modal-btn"
              className={classes.withdrawBtn}
              onClick={() => {
                this.props.withdrawDeal(serviceProvider.id);
                this.cancelWithdrawHandler();
              }}
            >
              Withdraw
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(classes)(ServiceProviderHiring);
