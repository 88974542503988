// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControl,
  Tooltip,
  IconButton,
  styled,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import TableHeadController from "./TableHeadController.web";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  SearchRounded,
} from "@material-ui/icons";
import { checkmark, group, trash } from "./assets";
import { ContentView, DealershipNormalized, ServiceableLocation } from "./MyTeamController.web";

export default class TableHead extends TableHeadController {
  LeftSideContainer = () => {
    const {
      currentView,
      totalItems = 0,
      checkedItems = 0,
      isAllChecked,
      isAdmin,
      handleCheckAll,
      handleDealershipsModal,
      handleBulkDeleteUserPrompt,
    } = this.props;
    return(
      <Box className="leftSideContainer">
          {isAdmin() &&
            currentView !== ContentView.DEALERSHIPS &&
            currentView !== ContentView.SERVICEABLE_LOCATIONS && (
              <CheckboxContainer onClick={handleCheckAll}>
                <input
                  type="checkbox"
                  checked={isAllChecked}
                  onChange={() => {}}
                  className="checkbox"
                  disabled={!totalItems}
                />
                <span className="checkmark">
                  <img src={checkmark} alt="checkmark" />
                </span>
              </CheckboxContainer>
            )}

          {checkedItems ? (
            <Box className="checkedItemsInfo">
              <Typography
              
                className="itemsQuantity"
                style={{ textTransform: "capitalize" }}
              >
                {`${checkedItems} ${this.findProperItemsName(
                  checkedItems
                )} selected`}
              </Typography>

              {currentView !== ContentView.DEALERSHIPS && (
                <Box className="selectedItemsControls">
                  <StyledTooltip title={`Delete user`}>
                    <IconButton
                      onClick={handleBulkDeleteUserPrompt}
                      style={{ padding: "5px" }}
                    >
                      <img src={trash} alt="delete" />
                    </IconButton>
                  </StyledTooltip>

                  <StyledTooltip title={`Assign to Dealership(s)`}>
                    <IconButton
                      onClick={handleDealershipsModal}
                      style={{ padding: "5px" }}
                    >
                      <img src={group} alt="assign" />
                    </IconButton>
                  </StyledTooltip>
                </Box>
              )}
            </Box>
          ) : (
            <Typography className="delaeshipLengthText"
              style={{ textTransform: "capitalize" }}
            >{`${totalItems} ${this.findProperItemsName(
              totalItems
            )}`}</Typography>
          )}
        </Box>
    )
  }
  renderStates = (serviceableLocations: ServiceableLocation[], states: string[]) => {
    return (
      (
        <DealershipsFormControl>
          <Select
            className="dealershipSelect"
            data-test-id="states-select"
            value={states}
            renderValue={() => {
              if (!states.length) {
                return <span>States</span>;
              }

              return states
                .map(
                  (stateName) =>
                    serviceableLocations.find(
                      (location) =>
                        location.provider_state === stateName
                    )?.provider_state
                )
                .join(", ");
            }}
            onChange={this.handleSetStates}
            disabled={serviceableLocations.length === 1}
            multiple
            displayEmpty
            IconComponent={ExpandMoreRounded}
            MenuProps={{
              getContentAnchorEl: null,
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              PaperProps: {
                style: {
                  width: 250,
                },
              },
            }}
          >
            {serviceableLocations.length === 1 ? (
              <StyledMenuItem value={serviceableLocations[0].id}>
                {serviceableLocations[0].provider_state}
              </StyledMenuItem>
            ) : (
              serviceableLocations.map(
                (location: ServiceableLocation) => (
                  <StyledMenuItem
                    key={location.id}
                    value={location.provider_state}
                  >
                    <ListItemText primary={location.provider_state} />
                    <StyledCheckbox
                      checked={states.includes(location.provider_state)}
                      checkedIcon={
                        <CheckedIcon>
                          <img src={checkmark} alt="checkmark" />
                        </CheckedIcon>
                      }
                      icon={<CheckboxIcon />}
                    />
                  </StyledMenuItem>
                )
              )
            )}
          </Select>
        </DealershipsFormControl>
    ))
  }
  
  renderServices = (currentView: ContentView, services: string[]) => {
    return(
      currentView === ContentView.SERVICEABLE_LOCATIONS && (
        <StyledFormControl style={{ width: "135px" }}>
          <Select
            id="service-select"
            data-test-id="choose-service"
            value={services}
            displayEmpty
            renderValue={
              () => {
                if (!services.length) {
                  return <span>Services</span>;
                }

                return services.join(", ");
              }
            }
            multiple
            onChange={this.handleSetServices}
            MenuProps={{
              getContentAnchorEl: null,
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            }}
            IconComponent={ExpandMoreRounded}
            className="select-component"
          >
            <StyledMenuItem value="runner">
              <ListItemText primary={"Runner"} />
              <StyledCheckbox
                checked={services.includes(
                  "runner"
                )}
                checkedIcon={
                  <CheckedIcon>
                    <img src={checkmark} alt="checkmark" />
                  </CheckedIcon>
                }
                icon={<CheckboxIcon />}
              />
            </StyledMenuItem>
            <StyledMenuItem value="full_service">
              <ListItemText primary={"Full Service"} />
              <StyledCheckbox
                checked={services.includes(
                  "full_service"
                )}
                checkedIcon={
                  <CheckedIcon>
                    <img src={checkmark} alt="checkmark" />
                  </CheckedIcon>
                }
                icon={<CheckboxIcon />}
              />
            </StyledMenuItem>
            <StyledMenuItem value="white_glove">
              <ListItemText primary={"White Glove"} />
              <StyledCheckbox
                checked={services.includes(
                  "white_glove"
                )}
                checkedIcon={
                  <CheckedIcon>
                    <img src={checkmark} alt="checkmark" />
                  </CheckedIcon>
                }
                icon={<CheckboxIcon />}
              />
            </StyledMenuItem>
            <StyledMenuItem value="on_site">
            <ListItemText primary={"On Site"} />
              <StyledCheckbox
                checked={services.includes(
                  "on_site"
                )}
                checkedIcon={
                  <CheckedIcon>
                    <img src={checkmark} alt="checkmark" />
                  </CheckedIcon>
                }
                icon={<CheckboxIcon />}
              />
            </StyledMenuItem>
          </Select>
        </StyledFormControl>
      )
    )
  }

  renderSortBy = (currentView: ContentView) =>{
    const {sort, } = this.props;
    return (
      <StyledFormControl>
        <Select
          className="sort-menu"
          id="sort-select"
          value={sort}
          onChange={this.handleSetSort}
          displayEmpty
          MenuProps={{
            getContentAnchorEl: null,
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          IconComponent={ExpandMoreRounded}
        >
          <StyledMenuItem disabled value="" style={{ display: "none" }}>
            Sort by
          </StyledMenuItem>
          <StyledMenuItem value="A-Z">A to Z</StyledMenuItem>
          <StyledMenuItem value="Z-A">Z to A</StyledMenuItem>
          {(currentView === ContentView.DEALERSHIPS ||
            currentView === ContentView.SERVICEABLE_LOCATIONS) && (
              <StyledMenuItem value="most_users">Most Users</StyledMenuItem>
            )}
          {(currentView === ContentView.DEALERSHIPS ||
            currentView === ContentView.SERVICEABLE_LOCATIONS) && (
              <StyledMenuItem value="recent_joined">
                Recently Created
              </StyledMenuItem>
            )}
          {(currentView === ContentView.SINGLE_DEALERSHIP_USERS ||
            currentView === ContentView.SINGLE_SERVICEABLE_LOCATION || currentView === ContentView.USERS) && (
              <StyledMenuItem value="recent_joined">
                Recently Joined
              </StyledMenuItem>
            )}
        </Select>
      </StyledFormControl>

    )
  }
  RightSideContainer = () => {
    const {
      currentView,
      dealershipsData = [],
      totalItems = 0,
      totalPages,
      itemsPerPage = 10,
      search,
      services,
      roles,
      role,
      page,
      states,
      serviceableLocations,
      dealershipsFilter,
      handleDealershipsFilterSelect,
      handleDealershipsFilterClose
    } = this.props;

    return (
      <Box className="rightSideContainer">
      <Search
        value={search}
        onChange={this.handleSearch}
        placeholder="Start typing to search..."
        InputProps={{
          classes: { input: 'placeHolder' },
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded
                width="12px"
                height="12px"
                style={{ color: "#4b4c4b", fontSize: "15px" }}
              />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />

      {currentView === ContentView.USERS && dealershipsFilter && serviceableLocations && (
          <>
            {dealershipsData.length > 1 && (
              <DealershipsFormControl>
                <Select
                  className="dealershipSelect"
                  value={dealershipsFilter}
                  renderValue={() => {
                    if (!dealershipsFilter.length) {
                      return <span>Choose Dealerships</span>;
                    }

                    return dealershipsFilter
                      .map(
                        (dealershipId) =>
                          dealershipsData.find(
                            (dealershipToFind) =>
                              dealershipToFind.id === dealershipId
                          )?.name
                      )
                      .join(", ");
                  }}
                  onChange={handleDealershipsFilterSelect}
                  onClose={handleDealershipsFilterClose}
                  disabled={dealershipsData.length === 1}
                  multiple
                  displayEmpty
                  IconComponent={ExpandMoreRounded}
                  MenuProps={{
                    getContentAnchorEl: null,
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    PaperProps: {
                      style: {
                        width: 250,
                      },
                    },
                  }}
                >
                  {dealershipsData.length === 1 ? (
                    <StyledMenuItem value={dealershipsData[0].id}>
                      {dealershipsData[0].name}
                    </StyledMenuItem>
                  ) : (
                    dealershipsData.map(
                      (dealership: DealershipNormalized) => (
                        <StyledMenuItem
                          key={dealership.id}
                          value={dealership.id}
                        >
                          <ListItemText primary={dealership.name} />
                          <StyledCheckbox
                            checked={dealershipsFilter.includes(
                              dealership.id
                            )}
                            checkedIcon={
                              <CheckedIcon>
                                <img src={checkmark} alt="checkmark" />
                              </CheckedIcon>
                            }
                            icon={<CheckboxIcon />}
                          />
                        </StyledMenuItem>
                      )
                    )
                  )}
                </Select>
              </DealershipsFormControl>
            )}

            {serviceableLocations.length > 1 && states && this.renderStates(serviceableLocations, states)}
          </>
        )}
      {(currentView === ContentView.SINGLE_DEALERSHIP_USERS || currentView === ContentView.SINGLE_SERVICEABLE_LOCATION ||
        currentView === ContentView.USERS) && (
        <StyledFormControl style={{ width: "135px" }}>
          <Select
            id="role-select"
            data-test-id="choose-role"
            value={role}
            displayEmpty
            onChange={this.handleSetRole}
            MenuProps={{
              getContentAnchorEl: null,
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            }}
            IconComponent={ExpandMoreRounded}
            className="select-component"
          >
            <StyledMenuItem disabled value="" style={{ display: "none" }}>
              Choose Role
            </StyledMenuItem>
            <StyledMenuItem value="all">All</StyledMenuItem>
            {roles?.map((role) => (
              <StyledMenuItem key={role.id} value={role.attributes.name}>
                {role.attributes.label}
              </StyledMenuItem>
            ))}
          </Select>
        </StyledFormControl>
      )}
      
      {services && this.renderServices(currentView, services)}
      
      {this.renderSortBy(currentView)}
      <Box className="navigation">
        <Typography className="pagesText">{`${page} of ${totalPages}`}</Typography>
        <button
          data-test-id="prev-page-button"
          className="paginationButton"
          onClick={this.prevPage}
        >
          <KeyboardArrowLeft
            width={5}
            height={8}
            style={{ color: "#4B4C4B" }}
          />
        </button>
        <button
          data-test-id="next-page-button"
          className="paginationButton"
          onClick={this.nextPage}
        >
          <KeyboardArrowRight
            width={5}
            height={8}
            style={{ color: "#4B4C4B" }}
          />
        </button>
      </Box>
    </Box>
    )
  }
  render() {
    
    return (
      <Container>
        <this.LeftSideContainer data-test-id="left-side-container"/>
        <this.RightSideContainer data-test-id="right-side-container"/>
      
      </Container>
    );
  }
}

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center",
  justifyContent: "space-between",
  height: "55px",
  padding: "0 40px 0 25px",
  borderBottom: "1px solid #F0FAFA",
  [theme.breakpoints.down("sm")]: {
    gap: "10px",
  },

  "& .leftSideContainer": {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    gap: "15px",
  },

  "& .checkedItemsInfo": {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    gap: "24px",

    [theme.breakpoints.down("sm")]: {
      gap: "5px",
    },
  },

  "& .itemsQuantity": {
    textTransform: "capitalize",
    minWidth: "60px",
    fontSize: '12px',
    [theme.breakpoints.up(1920)]: {
      fontSize: '14px'
    },
  },

  "& .selectedItemsControls": {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    gap: "24px",

    "& button": {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },

    "& img": {
      width: "18px",
    },

    [theme.breakpoints.down("sm")]: {
      gap: "5px",
    },
  },

  "& .rightSideContainer": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "5px",
  },

  "& .navigation": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    paddingLeft: "24px",
    gap: "6px",
    borderLeft: "1px solid #F0FAFA",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
    },
  },

  "& .pagesText": {
    minWidth: "33px",
    color: "#4B4C4B",
    fontSize:'12px',
    [theme.breakpoints.up(1920)]: {
      fontSize:'14px'
    },
  },

  "& .paginationButton": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "16px",
    height: "16px",
    border: "none",
    backgroundColor: "inherit",
    cursor: "pointer",
  },

  "& *": {
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  "& .placeHolder": {
    fontFamily: "Roboto",
    color: '#989A98',
    opacity: 1,
    fontSize: '12px',
    [theme.breakpoints.up(1920)]: {
      fontSize: '14px'
    },
  },
  "& .delaeshipLengthText":{
    fontSize: '12px',
    [theme.breakpoints.up(1920)]: {
      fontSize: '14px'
    },
  }
}));
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize:12,
  [theme.breakpoints.up(1920)]:{
    fontSize:'14px'
  },
  "&:hover": {
    background: "#F0FAFA",
  },
  "&:focus": {
    background: "#F0FAFA",
  },
}));

const CheckboxContainer = styled(Box)({
  position: "relative",
  width: "20px",
  height: "20px",
  border: "1px solid #C5CBC9",
  margin: 0,
  borderRadius: "6px",
  cursor: "pointer",
  overflow: "hidden",

  "& .checkbox": {
    appearance: "none",
  },

  "& .checkmark": {
    position: "absolute",
    top: "0",
    left: "0",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "#4FC8EC",
  },

  "& input:checked + span": {
    display: "flex",
  },
});

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#4B4C4B",
    boxShadow: theme.shadows[1],
    fontSize: "12px",
    padding: "16px",
  },
}))(Tooltip);

const Search = styled(TextField)({
  marginRight: "25px",

  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
});

const StyledCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#4FC8EC",
    width: "24px",
    height: "24px",
    borderRadius: "6px",
  },

  "&:hover": {
    backgroundColor: "transparent",
  },
});

const CheckedIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "6px",
  backgroundColor: "#4FC8EC",
});

const CheckboxIcon = styled("div")({
  boxSizing: "border-box",
  width: "24px",
  height: "24px",
  border: "1px solid #C5CBC9",
  borderRadius: "6px",
});

const DealershipsFormControl = styled(FormControl)(({ theme }) => ({
  width: "170px",

  "& .MuiSelect-root": {
    color: "#4B4C4B",
  },

  "& .MuiPaper-root": {
    width: "220px",
  },

  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },

  "& .MuiSelect-select": {
    paddingLeft: "5px",
  },

  "& ul > li": {
    backgroundColor: "#F0FAFA",
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "fit-content",
  marginRight: "15px",

  "& .MuiSelect-root": {
    color: "#4B4C4B",
    fontSize:12,
    [theme.breakpoints.up(1920)]:{
      fontSize:14
    }
  },

  "& .MuiSelect-select": {
    paddingLeft: "5px",
  },

  "& .MuiList-padding": {
    paddingTop: "0",
    paddingBottom: "0",
  },

  "& .Muiselect-icon": {
    [theme.breakpoints.down("sm")]: {
      top: "calc(50% - 6px)",
    },
  },

  "& .MuiInputLabel-shrink": {
    display: "none",
  },

  "& .MuiInputLabel-formControl": {
    transform: "translate(0, 9px) scale(1)",
  },

  "& label + .MuiInput-formControl": {
    marginTop: 0,
  },

  "& .MuiFormLabel-root.Mui-focused": {
    color: "unset",
  },

  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
}));

// Customizable Area End
