import React from "react";
// Customizable Area Start
import { Avatar, Box, Button, Divider, Grid, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Badge from '@material-ui/core/Badge';
import CheckIcon from '@material-ui/icons/Check';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { styled } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// Customizable Area End

import BlockController, {
  Props,
} from "./PushnotificationsController";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  notificationdropdown=()=>{
    return(
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          style={{ maxHeight: '700px'}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
        >
        <Box style={{paddingLeft:'20px', paddingRight:'20px'}}>
            <Box style={{display:'flex', justifyContent:'end'}}>
                <Button onClick={this.markAllasRead} data-test-id="closemodal" size="small" style={{ textTransform: 'none', marginTop:'20px', color:'#4B4C4B', fontSize:'14px', fontWeight:700, fontFamily:'Inter'}}>
                    <CheckIcon style={{marginRight:'5px'}}/> Mark all as read
                </Button>
            </Box>
            <Divider style={{marginTop:'10px'}}/>
         {this.state.notificationsRceived.map((notification, index) => (
            <Box key={index} onClick={() => this.notificationClickHandler(notification)}>
                <MenuItem
                style={{ paddingTop: '15px', maxWidth: '500px', display: 'flex', alignItems: 'start', gap:'10px'}}
                >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar src={notification.profile ? notification.profile : ''} style={{ backgroundColor: notification.avatarColor ?  notification.avatarColor : '#346472', fontFamily:'Roboto', fontSize:'18px', color:'white'}}>
                        {notification.avatarText}
                    </Avatar>
                </Box>

                <Box className="middlecontent"
                    style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '10px',
                    width: '350px',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    }}
                >

                    <Grid container alignItems="center" wrap="wrap">
                      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        {!notification.isRead && (
                          <FiberManualRecordIcon
                            style={{
                              height: '15px',
                              padding: '0px',
                              color: this.getColor(notification.isRead, '#4B4C4B', '#4FC8EC'),
                              marginLeft: '-7px',
                            }}
                          />
                        )}
                        <Typography
                          style={{
                            color: this.getColor(notification.isRead, '#4B4C4B', '#4FC8EC'),
                            fontWeight: 500,
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                          }}
                        >
                          {notification.title}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          style={{
                            marginLeft: '5px',
                            color: '#C5CBC9',
                            fontWeight: 500,
                            fontSize: '14px',
                            fontFamily: 'Roboto',
                          }}
                        >
                          / {notification.ownerLastName}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          style={{
                            marginLeft: '5px',
                            color: '#C5CBC9',
                            fontWeight: 500,
                            fontSize: '14px',
                            fontFamily: 'Roboto',
                          }}
                        >
                          / {notification.id}
                        </Typography>
                      </Grid>
                    </Grid>


                    <Typography style={{ marginBottom: '8px', overflow: 'hidden', textOverflow: 'ellipsis', color: this.getColor(notification.isRead, '#989A98',  '#4B4C4B'), fontSize:'14px', fontWeight:400, fontFamily:'Roboto' }}>
                      <div dangerouslySetInnerHTML={{__html: notification.description}} style={{color: '#4B4C4B'}}></div>
                    </Typography>
                </Box>

                <Box className="threedots" style={{ display: 'flex', alignItems: 'center', flexDirection:'column', justifyContent:'start', width:'35px'}}>
                    <IconButton data-test-id="morevert" onClick={(event)=>this.handleClickMoreVert(event, notification.notificationId)} style={{ marginTop: '5px', color: '#4B4C4B' }}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={this.state.anchorElTwo}
                      keepMounted
                      open={Boolean(this.state.anchorElTwo)}
                      onClose={this.handleCloseMoreVert}
                      style={{boxShadow:'none'}}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      getContentAnchorEl={null}
                    >
                      <MenuItem>
                        <Button data-test-id="markasrad" onClick={()=>this.markasRead(this.state.currentNotificationId)} size="small" style={{ textTransform: 'none', color:'#4B4C4B', fontSize:'14px', fontWeight:700, fontFamily:'Inter'}}>
                          <CheckIcon style={{marginRight:'5px'}}/> Mark as read
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button data-test-id="deletednoti" onClick={()=>this.deleteNotification(this.state.currentNotificationId)} size="small" style={{ textTransform: 'none', color:'#C43937', fontSize:'14px', fontWeight:700, fontFamily:'Inter'}}>
                            <DeleteOutlineIcon style={{marginRight:'5px', color:'#C43937'}}/>Delete
                          </Button>
                      </MenuItem>
                    </Menu>


                        <Typography variant="caption" color="textSecondary" style={{marginTop:'10px', color:'#989A98', fontSize:'14px', fontWeight:400, fontFamily:'Inter'}}>
                            {notification.timestamp}
                        </Typography>
                </Box>
                </MenuItem>
              {index < this.state.notificationsRceived.length - 1 && <Divider />}
            </Box>
          ))}

        </Box>
        </Menu>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <Box>
            <IconButton color="inherit" onClick={(event)=>this.handleClick(event)} data-test-id="notificationclick">
            {this.state.is_all_read ? (
                <NotificationsNoneIcon style={{ color: '#4B4C4B' }} />
              ) : (
                <StyledBadgee badgeContent=''>
                  <NotificationsNoneIcon style={{ color: '#4B4C4B' }} />
                </StyledBadgee>
              )}
            </IconButton>
            {this.notificationdropdown()}
        </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const StyledBadgee = styled(Badge)({
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'scale(0.6)',
      transformOrigin: '100% 100%',
      backgroundColor: '#C43937',
      color: 'red',
      top: '-10px',
      right: '-1px',
    },
  });
// Customizable Area End
