import React from "react";

// Customizable Area Start
import { Box, Button, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { logoImg, closeBtn } from "./assets";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <TermsConditionsStyles>
        <Box
          sx={{
            padding: "24px",
          }}
          className="termsContainer"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img className="logoImg" src={logoImg} alt="logo"></img>
            <Button
              className="closeBtn"
              onClick={this.closeTermsConds}
            ></Button>
          </Box>
          <Box
            sx={{
              maxWidth: "812px",
              margin: "0 auto",
              padding: "60px 0",
            }}
          >
            <Typography className="title gothamText">Terms and Conditions</Typography>
            <Box className="termsConds gothamText"></Box>
          </Box>
        </Box>
        <Box className="shadow"></Box>
      </TermsConditionsStyles>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const TermsConditionsStyles = styled(Box) ({
  "& .closeBtn": {
    width: "32px",
    height: "32px",
    background: `url(${closeBtn})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  "& .logoImg": {
    width: "92px",
    height: "42px",
  },
  "& .title": {
    marginBottom: "32px",
    fontWeight: "300",
    fontSize: "24px",
    color: "#013D4F",
    [`@media (min-width:1920px)`]: {
      fontSize: "30px",
    },
  },
  "& .termsConds": {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#4B4C4B",
    [`@media (min-width:1920px)`]: {
      fontSize: "16px",
    }
  },
  "& .termsConds p": {
    marginBottom: "32px",
  },
  "& .shadow": {
    boxShadow: `0px -100px 100px -50px rgba(255,255,255,0.75) inset;
    -webkit-box-shadow: 0px -100px 100px -50px rgba(255,255,255,0.75) inset;
    -moz-box-shadow: 0px -100px 100px -50px rgba(255,255,255,0.75) inset`,
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "281px",
    zIndex: 1,
  },
});
// Customizable Area End
