import React from "react";

// Customizable Area Start
import { GoogleLogin } from 'react-google-login';
import {
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton, styled
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { grouppng, vitulogoimg, googleimgicon } from '../../email-account-login/src/assets'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { Formik, Form, Field, ErrorMessage } from 'formik';


import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const Buttondb = styled(Button)({
  height: '44px',
  marginTop: '24px',
  [`@media (min-width:1920px)`]: {
    height: '56px',
  },
  '&:disabled': {
    color: '#4FC8EC',
    width: '512px',
    backgroundColor: '#F0FAFA',
    '@media (max-width: 600px)': {
      width: "220px",
    },
    '@media (max-width: 700px)': {
      width: "300px",
    },
  },
  '&:enabled': {
    color: 'white',
    width: '512px',
    backgroundColor: '#4FC8EC',
    '@media (max-width: 600px)': {
      width: "220px",
    },
    '@media (max-width: 700px)': {
      width: "300px",
    },
  },

});

const RightBox = styled(Box)({
  "@media (max-width:699px)": {
    display: 'none'
  },
  "@media (min-width:700px)": {
    display: 'block'
  },
})

const LeftBox = styled(Box)({
  "@media (max-width:699px)": {
    width: '100%'
  },
  "@media (min-width:700px)": {
    width: '66.6%'
  },
  '& .createText': {
    textTransform: 'none',
    fontWeight: 550,
    fontSize: '12px',
    marginTop: '5px',
    color: '#013D4F',
    fontFamily: "Roboto",
    [`@media (min-width:1920px)`]: {
      fontSize: '16px',
    },
  },
  '& .accountYetText': {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: '12px',
    color: '#4B4C4B',
    [`@media (min-width:1920px)`]: {
      fontSize: '16px',
    },
  },
  '& .loginHeadingText': {
    fontWeight: '300',
    color: '#013D4F',
    fontSize: '24px',
    [`@media (min-width:1920px)`]: {
      fontSize: "30px",
    }
  },
  '& .loginSubtitleText': {
    fontWeight: '300',
    color: '#4B4C4B',
    fontSize: '14px',
    [`@media (min-width:1920px)`]: {
      fontSize: "18px",
    }
  },
  '& .loginButtonText': {
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '12px',
    color: '#013D4F',
    [`@media (min-width:1920px)`]: {
      fontSize: '16px',
    },
  },
  '& .passwordIcon': {
    width: 18,
    height: 18,
    [`@media (min-width:1920px)`]: {
      width: '1em',
      height: '1em',
    },
  },
  '& .inputLoginBox': {
    height: '44px',
    [`@media (min-width:1920px)`]: {
      height: '56px',
    },
    padding: '10px, 24px, 10px, 24px',
    borderRadius: '8px'
  },
  '& .inputBoxIcon': {
    textAlign: 'start',
    marginLeft: '10px',
    padding: '5px',
    height: '18px',
    width: '18px',
    [`@media (min-width:1920px)`]: {
      height: '1em',
      width: '1em',
    },
  },
  '& .forgotPassText': {
    cursor: 'pointer',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '12px',
    color: '#013D4F',
    textAlign: 'end',
    marginTop: '15px',
    marginBottom: '16px',
    [`@media (min-width:1920px)`]: {
      fontSize: '16px',
    },
  },
  '& .buttonTextLogin': {
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 700,
    [`@media (min-width:1920px)`]: {
      fontSize: "16px",
    }
  }
})
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";
import { StyledTextField } from "../../forgot-password/src/ResetPassword.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const LoginBox = styled(Box)({
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  maxWidth: '520px',
  marginTop: '80px',
  "@media (max-width:720px)": {
    width: '90%'
  },
})

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start

      <Box style={{ display: 'flex', width: '100vw', height: '100vh' }}>
        <LeftBox>
          <Box style={{ marginTop: '50px', marginLeft: '11%' }}>
            <img src={vitulogoimg} style={{ height: '42px', width: '91.27px' }} />
          </Box>


          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            height={"calc(100vh - 142px)"}
          >
            <LoginBox width={"60%"} display={"flex"} flexDirection={"column"} style={{ rowGap: '16px', maxWidth: '520px', marginTop: '80px' }}>
              <Box display={"flex"} flexDirection={"column"} style={{ rowGap: '0px', width: '100%' }}>
                <Typography className="gothamText loginHeadingText" >Welcome Back!</Typography>
                <Typography className="gothamText loginSubtitleText">Please log in to your account</Typography>
              </Box>
              {this.state.receivedError &&
                <Box style={{ backgroundColor: '#FEE2E2', height: '46px', width: '380px', borderLeft: '4px solid #C43937', borderRadius: '8px' }}>
                  <Typography style={{ color: '#C43937', paddingTop: '13px', marginLeft: '10px', fontSize: '14px', fontWeight: 700 }}>{this.state.receivedError}</Typography>
                </Box>
              }

              <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}
              >
                {({ errors, touched, handleChange, handleBlur, values }) => (
                  <Form>
                    <Box style={{ width: '100%' }}>
                      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${this.isErrorborder(touched.email, errors.email, '#C43937', '#C5CBC9')}` }} className="inputLoginBox">
                        <EmailOutlinedIcon style={{ color: values.email ? '#4B4C4B' : '#C5CBC9' }} className="inputBoxIcon" />
                        <Field
                          name="email"
                          type="email"
                          placeholder="Please enter your email ID"
                          as={StyledTextField}
                          InputProps={{ disableUnderline: true, style: { border: 'none', height: '', marginTop: '5px' } }}
                          InputLabelProps={{ shrink: false }}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="email"
                          fullWidth
                        />
                      </Box>
                      <ErrorMessage name="email" component="div" style={{ marginTop: '5px', color: "#DC2626", fontSize: '12px', fontFamily: 'Roboto', fontWeight: 400 }} />
                    </Box>
                    <Box style={{ marginTop: '16px', width: '100%' }} >
                      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${this.isErrorborder(touched.password, errors.password, '#C43937', '#C5CBC9')}` }} className="inputLoginBox">
                        <LockOutlinedIcon style={{ color: values.password ? '#4B4C4B' : '#C5CBC9' }} className="inputBoxIcon" />
                        <Field
                          className="password"
                          name="password"
                          data-test-id="passwordtest"
                          type={this.state.showPassword ? 'text' : 'password'}
                          placeholder="Please enter your password"
                          as={StyledTextField}
                          InputProps={{
                            disableUnderline: true,
                            style: { border: 'none', height: '', marginTop: '5px' },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  className="password_button_type"
                                  data-test-id="eyebutton"
                                  aria-label="toggle password visibility"
                                  onClick={this.handleShowPass}
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="end"
                                  style={{ marginRight: '10px', color: values.password ? '#4B4C4B' : '#C5CBC9' }}
                                >
                                  {this.state.showPassword ? <VisibilityOutlinedIcon className="passwordIcon" /> : <VisibilityOffOutlinedIcon className="passwordIcon" />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          InputLabelProps={{ shrink: false }}
                          inputProps={{ maxLength: 15 }}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                        />
                      </Box>
                      <ErrorMessage name="password" component="div" style={{ fontSize: '12px', color: "#DC2626", marginTop: '5px', fontFamily: 'Roboto', fontWeight: 400 }} />
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                      <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography data-test-id="forgotpass" onClick={this.navigateToForgotPassword}
                          className="forgotPassText">Forgot Password?</Typography>
                      </Box>

                      <Buttondb data-test-id="loginhere" className="loginhere" type="submit" disabled={!values.email || !values.password || Boolean(errors.email) || Boolean(errors.password)} variant="contained">
                        <Typography component={'p'} className="buttonTextLogin">Login to Vitu</Typography>
                      </Buttondb>
                    </Box>
                  </Form>
                )}
              </Formik>

              <GoogleLogin
                data-test-id="Gloginbutton"
                clientId="441584058688-05ov470a7n99j7884oatccmqk4q5f2r8.apps.googleusercontent.com"
                render={renderProps => (
                  <Button className="googleloginbutton" onClick={renderProps.onClick} disabled={renderProps.disabled} style={{ width: '100%', height: '56px', marginTop: '-10px', display: 'flex', justifyContent: 'center', border: '1px solid #F0FAFA' }}>
                    <img src={googleimgicon} alt="icon" />
                    <Typography style={{ textTransform: 'none', marginTop: '5px', marginLeft: '10px', fontSize: '16px', fontWeight: 600, color: '#013D4F' }}>Login with Google</Typography>
                  </Button>
                )}
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={'single_host_origin'}
              />

              <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                <Typography className="accountYetText" component={'p'}>Don’t have an account yet?</Typography>
                <Button variant="text" onClick={this.goToSignUp} data-test-id="gotosignup">
                  <Typography component={'h6'} className="createText">Create a Vitu account</Typography>
                </Button>
              </Box>
            </LoginBox>


          </Box>
        </LeftBox>


        <RightBox width='33.4%' style={{ position: 'fixed', height: '100vh', right: '0' }}>
          <img src={grouppng} height="100%" width='100%'>
          </img>
        </RightBox>

      </Box>
      // Customizable Area End
    );
  }
}
