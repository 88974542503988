import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { ServiceProvider } from "./ProjectTemplatesDealDashboardController.web";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
    registrationFee: string,
    registrationFeeStatus: string,
    role: string;
    hiredSPInfo: ServiceProvider;
    calculateNowHandler: () => void;
    sendRegistrationFee: (role: string, fee: string) => void;
    isDealCompletedOrDeleted: boolean;
}

interface S {
    isRegistrationFeeSaved: boolean;
    registrationFee: string;
    isViewDetails: boolean;
}

interface SS {
    
}

export default class RegistrationFeeController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
  
  }

  

  async receive(from: string, message: Message) {
   
  }
}