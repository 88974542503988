// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { ServiceableLocation, UserNormalized } from "./MyTeamController.web";

export const webConfigJSON = require("./config.js");

export interface Props {
  userEmail: string
  usersData: UserNormalized[]
  serviceableLocations?: ServiceableLocation[],
  page: number
  itemsPerPage: number
  handleCheck: (itemId: string) => void
  handleEditUser: (userId: string) => void
  setDeletePromptState: () => void
  isAdmin: () => boolean
  handleDeleteUserPrompt: (userId: string) => void
  selectedServiceableLocation: string | undefined,
  resendInvite: (user: UserNormalized) => void
}
interface S {
  users: UserNormalized[]
}
interface SS {}

export default class UsersTableController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);

    this.state = {
      users: []
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const {usersData, page, itemsPerPage, } = this.props;

    if(prevProps.itemsPerPage !== itemsPerPage || prevProps.usersData !== usersData || prevProps.page !== page) {
      this.setCurrentPage()
    }
  }

  setCurrentPage = () => {
    const {page, itemsPerPage} = this.props;
    const currentPageUsers = this.props.usersData.slice(page * itemsPerPage - itemsPerPage, page * itemsPerPage)

    this.setState({users: currentPageUsers})
  }

}
// Customizable Area End