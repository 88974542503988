// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import { WithStyles } from "@material-ui/core";
import { IssueRaised } from "./ProjectTemplatesDocumentsUploadController";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  hideIssueRaised: () => void;
  issueRaised: IssueRaised;
}

interface S {
  minimizeChats: boolean;
}

interface SS {
}

export default class IssueRaisedController extends BlockComponent<
  Props,
  S,
  SS
> {
  handleMinimizeWindow = () => {
    this.setState({ minimizeChats: !this.state.minimizeChats })
  }

  handleCloseNoteFloating = () => {
    this.props.hideIssueRaised();
  }

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      minimizeChats: false
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    console.log("componentDidMount");
  }

  async receive(from: string, message: Message) {
    console.log("receive");
  }
}
// Customizable Area End