// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";


import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
}

interface S {
  dealInfo: DealInfo,
  status: string,
  isEditing: boolean,
  isEditingRegistrationFee: boolean,
  oldComment: string,
  oldFee: string,
}

interface SS {
 
}

interface DealInfo{
  id: string,
  serviceProviderId: string,
  serviceProviderName: string,
  positivePercentage: string,
  customerLastName: string,
  fromState: string,
  toState: string,
  application: string,
  service: string,
  vehicleType: string,
  registrationFee: string,
  registrationFee2: string,
  feeId: string,
  comment: string,
  oldRegistrationFee: string,
}

export default class ConfirmFeesProviderController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDealInformationId: string = '';
  apiSendRegistrationFeeId: string = '';

  getDealInformation = () =>{
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDealInformationId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/' + localStorage.getItem("confirmFeeDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  mapToDealInfo = (response: any) => {
    const dealInfo : DealInfo = {
      id: response.id,
      serviceProviderId: response.accepted_selected_service_provider_id,
      serviceProviderName: response.service_provider.data.attributes.business_name,
      positivePercentage: '0',
      customerLastName: response.owner_last_name,
      fromState: response.from_state,
      toState: response.to_state,
      application: response.application_type,
      service: response.service.match(/\"([^\"]+)\"=>\"([^\"]+)\"/)[1].replace(/^\w/, (c: string) => c.toUpperCase()),
      vehicleType: response.vehicle_info_type,
      registrationFee: response.fees_data.fee_amount,
      registrationFee2: response.fees_data.fee_amount,
      feeId: response.fees_data.id,
      comment: response.fees_data.comment,
      oldRegistrationFee: response.fees_data.previous_fee_amount,
    }

    return dealInfo;
  }

  navigateToScreen = (screen: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    this.send(msg);
  }

  handleChangeEditingStatus = () => {
    this.setState({
      isEditing: !this.state.isEditing
    });
  };


  handleSelectFeeChange = () => {
    this.setState({
      isEditingRegistrationFee: true,
    })
  };

  handleInputReason = (event: any) => {
    this.setState({
      dealInfo: {
        ...this.state.dealInfo,
        comment: event.target.value
      }
    });
  };

  handleChangeReason = (event: any) => {
    if (event.key === "Enter") {
      this.setState({
        dealInfo: {
          ...this.state.dealInfo,
          comment: event.target.value
        },
      });
    }
  };

  handleFeeInputChange = (event: any) => {
    const { value } = event.target;
    const regex = /^\d*(\.\d{0,2})?$/;


    if (regex.test(value)) {
      this.setState({ dealInfo: {...this.state.dealInfo,
        registrationFee: value,
        oldRegistrationFee: this.state.dealInfo.registrationFee2
      } });
    }
  };

  handleFeeSave(event: any) {
    if (event.key === "Enter") {
      this.setState({
        dealInfo: {
          ...this.state.dealInfo,
          registrationFee: event.target.value
        },
      });
    }
  }

  cancelHandler = () => {
    this.setState({
      dealInfo: {
        ...this.state.dealInfo,
        comment: this.state.oldComment,
        registrationFee: this.state.oldFee
      },
      isEditing: false,
      isEditingRegistrationFee: false
    });
  }

  saveHandler = () => {
    this.setState({
      isEditing: false,
      isEditingRegistrationFee: false
    })
  }

  confirmAndSendToDealer = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSendRegistrationFeeId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_multilevelapproval/hired_provider_fees'
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            fee_type: "manual",
            fee_amount: this.state.dealInfo.registrationFee,
            deal_id: localStorage.getItem("confirmFeeDealId"),
            select_service_provider_id: this.state.dealInfo.serviceProviderId,
            comment: this.state.dealInfo.comment
          }
        },
      })
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    
    return true;
  }

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleFeeSave = this.handleFeeSave.bind(this);
    this.handleChangeReason = this.handleChangeReason.bind(this);
    
    this.state = {
      dealInfo: {} as DealInfo,
      status: '',
      isEditing: false,
      isEditingRegistrationFee: false,
      oldComment: '',
      oldFee: ''
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getDealInformation();
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response && !response.errors) {
        if(apiId === this.apiGetDealInformationId){
          this.setState({
            dealInfo: this.mapToDealInfo(response.data.attributes),
            oldComment: response.data.attributes.fees_data.comment,
            oldFee: response.data.attributes.fees_data.fee_amount,
          })
        }
        if(apiId === this.apiSendRegistrationFeeId){
          localStorage.setItem('createdDealId', localStorage.getItem('confirmFeeDealId')!)
          this.navigateToScreen('ProjectTemplatesDealDashboard');
        }
      }
    }
  }
}
// Customizable Area End