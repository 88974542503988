import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Checkbox,
  withStyles,
  createStyles,
  createTheme,
  ThemeProvider,
  styled,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import OrderSummaryController, {
  Props,
  configJSON,
} from "./OrderSummaryController";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

const styles = createStyles({
  premiumPlan: {
    color: "#013D4F",
    fontWeight: 600,
  },
  termsAndConditions: {
    color: "#013D4F",
    textDecoration: "underline",
    cursor: 'pointer'
  },
  checkBox: {
    padding: 0,

    "&.Mui-checked": {
      color: "#4FC8EC",
    },
  },
  borderBox: {
    width: "640px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "24px",
    border: "1px solid #F0FAFA",
  },
  container: {
    // minWidth: "1366px",
    width: '100%'
  }
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontFamily: "Gotham, sans-serif",
      fontWeight: 300,
      lineHeight: "40px",
      color: "#013D4F",
      letterSpacing: 0,

    },
    h2: {
      fontFamily: "Roboto",
      fontWeight: 500,
      lineHeight: "32px",
      color: "#013D4F",
      letterSpacing: 0
    },
    h3: {
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: "24px",
      textAlign: "left",
      color: "#013D4F",
    },
    h4: {
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: "24px",
      textAlign: "left",
      color: "#4B4C4B",
    },

    h5: {
      fontFamily: "Roboto",
      fontWeight: 500,
      lineHeight: "24px",
      textAlign: "left",
      color: "#4B4C4B",
    },

    h6: {
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "left",
      color: "#4B4C4B",
    },
    body1: {
      fontFamily: "Gotham, sans-serif",
      fontWeight: 300,
      lineHeight: "26px",
      textAlign: "center",
      color: "#4B4C4B",
      letterSpacing: 0
    },
    subtitle1: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "left",
      color: "#4B4C4B",
    },
    subtitle2: {
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: "22px",
      textAlign: "left",
      color: "#C5CBC9",
      marginTop: "8px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  header: {
    height: "72px",
    borderBottom: "1px solid #F0FAFA",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  formFee: {
    width: "640px",
    opacity: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  formFeeHeader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  formFeeContent: {
    width: "544px",
    height: "354px",
  },
  formFeeDetails: {
    marginTop: "40px",
    height: "250px",
    gap: "24px",
    borderRadius: "12px",
    opacity: "0px",
  },
  formsInfo: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
  },
  formFeeSalePrice: {
    display: "flex",
  },
  formFeePay: {
    height: "104px",
    gap: "24px",
    borderRadius: "12px",
    opacity: "0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  totalFeeInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  formFeeFooter: {
    padding: "48px",
    width: "544px",
    gap: "0px",
    borderRadius: "24px",
    opacity: "0px",
  },
  termAndConditionCheckBox: {
    margin: "24px 0px",
    height: "48px",
    gap: "10px",
    opacity: "0px",
    display: "flex",
    alignItems: "center",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

export const CustomCheckboxIcon = styled("span")(({ theme }) => ({
  width: "20px",
  height: "20px",
  borderRadius: "6px",
  opacity: "0px",
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
}));

export const CustomCheckedCheckboxIcon = styled(CustomCheckboxIcon)({
  backgroundColor: "#4FC8EC",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

const CustomBoxForm = styled(Box)(({ theme }) => ({

  '& .mainHeading': {
    fontSize: "24px",
    fontFamily: "Gotham, sans-serif",
    fontWeight: 300,
    lineHeight: "40px",
    color: "#013D4F",
    letterSpacing: 0,
    [theme.breakpoints.up(1920)]: {
      fontSize: "30px",
    }
  },
  '& .subHeading': {
    fontSize: "12px",
    fontFamily: "Gotham, sans-serif",
    fontWeight: 300,
    lineHeight: "26px",
    textAlign: "center",
    color: "#4B4C4B",
    letterSpacing: 0,
    [theme.breakpoints.up(1920)]: {
      fontSize: "18px",
    }
  },
  '& .paynowBtnEnabled': {
    width: "377px",
    borderRadius: "8px",
    height: "44px",
    color: "#FFFFFF",
    backgroundColor: "#4FC8EC",
    boxShadow: "none",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    textTransform: "initial",
    [theme.breakpoints.up(1920)]: {
      height: "56px",
      fontSize: "16px",
    },
    "&:hover": {
      backgroundColor: "#4FC8EC",
    }
  },
  '& .paynowBtnDisabled': {
    width: "377px",
    borderRadius: "8px",
    height: "44px",
    color: "#75D3F0 !important",
    backgroundColor: "#E8F8FD !important",
    boxShadow: "none",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    textTransform: "initial",
    [theme.breakpoints.up(1920)]: {
      height: "56px",
      fontSize: "16px",
    }
  },
  '& .totalFee': {
    color: "#013D4F",
    fontWeight: 500,
    textAlign: "end",
    fontSize: "10px",
    [theme.breakpoints.up(1920)]: {
      fontSize: "12px",
    }
  },
  '& .discountText': {
    fontSize: "10px",
    [theme.breakpoints.up(1920)]: {
      fontSize: "12px",
    }
  },
  '& .feesText': {
    fontSize: 18,
    [theme.breakpoints.up(1920)]: {
      fontSize: "24px",
    }
  },
  '& .formsText': {
    fontSize: "14px",
    [theme.breakpoints.up(1920)]: {
      fontSize: "16px",
    }
  },
  '& .attentionHeadingText': {
    fontSize: "14px",
    [theme.breakpoints.up(1920)]: {
      fontSize: "18px",
    }
  },
  '& .attentionText': {
    fontSize: "12px",
    [theme.breakpoints.up(1920)]: {
      fontSize: "14px",
    }
  },
  '& .subHeadingLayout': {
    marginTop: "12px",
    marginBottom: "30px",
    [theme.breakpoints.up(1920)]: {
      marginBottom: "60px",
    }
  },
  '& .main': {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up(1920)]: {
      marginBottom: "111px",
    },
    marginTop: "50px",
  },
  '& .backSection': {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "86px",
    height: "40px",
    padding: "10px 12px 10px 12px",
    gap: "6px",
    borderRadius: "50px",
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  '& .backIcon': {
    width: "16px",
    height: "16px",
    [theme.breakpoints.up(1920)]: {
      width: "20px",
      height: "20px",
    },
  },
  '& .backText': {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
    width: "36px",
    [theme.breakpoints.up(1920)]: {
      fontSize: "16px",

    },
  },
}));

const CustomDailog = styled(Dialog)(({ theme }) => ({
  '& .dailogContent': {
    display: 'flex',
    flexDirection: 'column',
    gap: "16px",
    justifyContent: 'center',
    padding: '60px 40px 32px 40px'
  },
  '& .dialogHeading': {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "20px",
    letterSpacing: "0%",
    color: '#013D4F',
    [theme.breakpoints.up(1920)]: {
      fontSize: "24px",
    }
  },
  '& .dialogDescription': {
    fontFamily: "Gotham",
    fontWeight: 300,
    fontSize: "14px",
    letterSpacing: "0%",
    color: '#0F172A',
    [theme.breakpoints.up(1920)]: {
      fontSize: "18px",
    }
  },
  '& .generateButton': {
    textTransform: 'none',
    background: '#4FC8EC',
    color: "#fff",
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "0%",
    width: "175px",
    height: '44px',
    boxShadow: 'none',
    borderRadius: "8px",
    [theme.breakpoints.up(1920)]: {
      fontSize: "16px",
      height: 56
    },
    [theme.breakpoints.down(600)]: {
      width: "100%",

    }
  },
  '& .dailogAction': {
    maxHeight: '104px',
    padding: '24px'
  }
}))
// Customizable Area End

export class OrderSummary extends OrderSummaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  generateDialog =() => {
    return (
      <>
        <CustomDailog
          open={this.state.generateDialog} maxWidth={'md'}
          onClose={this.handleCloseDialog}
          PaperProps={{
            style: {
              maxWidth: "600px",
              width: '100%',
              maxHeight: "296px",
              borderRadius: '8px',
              boxShadow: "0px 8px 32px 0px #0000000F,0px 4px 8px 0px #00000008,0px 25px 50px 0px #00000017"

            },
          }}
        >
          <DialogContent className={"dailogContent"}>

            <Typography variant="h4" className={'dialogHeading'}>
              {this.state.modalTitle}
            </Typography>
            <Typography variant="subtitle2" className={'dialogDescription'}>
              {configJSON.dialogDescription}
            </Typography>

          </DialogContent>
          <DialogActions className="dailogAction">
            <Button variant="contained" className="generateButton"
            data-test-id='generate_btn'
              onClick={this.payAddOn}
            >{configJSON.dialogButton}</Button>
          </DialogActions>
        </CustomDailog >

      </>
    )
  }


  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, formType, payNowAction } = this.props;
    const { isAgreed, formFee } = this.state;
    const formatMoney = (number: number) => {
      if (number === undefined) return 0;
      return number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    }
    let formItem = "Forms";
    let { formFeeTitle, formFeeThanksLine1, formFeeThanksLine2, attentionDetail } = configJSON;
    if (formType === "blankForm") {
      formFeeTitle = configJSON.blankFormsFee;
      formItem = configJSON.blankFormsFee;
      formFeeThanksLine1 = configJSON.blankFormsFeeThanksLine1;
      formFeeThanksLine2 = configJSON.blankFormsFeeThanksLine2;
      attentionDetail = configJSON.blankFormsFeeAttentionDetail;
    }
    else if (formType === "checklist") {
      formFeeTitle = configJSON.checklistFee;
      formItem = configJSON.checklistFee;
      formFeeThanksLine1 = configJSON.checklistFeeThanksLine1;
      formFeeThanksLine2 = configJSON.checklistFeeThanksLine2;
      attentionDetail = configJSON.checklistFeeAttentionDetail;
    }
    else if (formType === "calculateRegistration") {
      formFeeTitle = configJSON.calculateRegistrationFee;
      formItem = configJSON.calculateRegistrationFee;
      formFeeThanksLine1 = configJSON.calculateRegistrationFeeThanksLine1;
      formFeeThanksLine2 = configJSON.calculateRegistrationFeeThanksLine2;
      attentionDetail = configJSON.calculateRegistrationFeeAttentionDetail;
    }
    return (
      <ThemeProvider theme={theme}>
        <CustomBoxForm >
          <Box sx={webStyle.header}>
            <Box data-test-id="back-button" className={'backSection'} onClick={this.navigateToDealDashboard}>
              <ArrowBackIos className={"backIcon"} />
              <span className={"backText"}>Back</span>
            </Box>
            <Box />
          </Box>
          <Box className={"main"}>
            <Box sx={webStyle.formFee}>
              <Box sx={webStyle.formFeeHeader}>
                <Typography component="h1" className={"mainHeading"}>{formFeeTitle}</Typography>
                <Box className={"subHeadingLayout"}>
                  <Typography variant="body1" className={"subHeading"}>
                    {formFeeThanksLine1}
                  </Typography>
                  <Typography variant="body1" className={"subHeading"}>
                    {formFeeThanksLine2}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.borderBox}>
                <Box sx={webStyle.formFeeContent}>
                  <Box sx={webStyle.formFeeDetails}>
                    <Typography variant="h2" className="feesText">Fee Details</Typography>
                    <Box sx={webStyle.formsInfo}>
                      <Typography variant="h4" className="formsText">{formItem}</Typography>
                      <Box sx={webStyle.formFeeSalePrice}>
                        <Typography variant="h4" className="formsText" style={{ color: "#C5CBC9" }}>
                          <del>${formatMoney(formFee.free_plan_add_on_cost)}</del>
                        </Typography>
                        <Typography variant="h4" className="formsText" style={{ marginLeft: "11px" }}>
                          ${formatMoney(formFee.current_plan_add_on_cost)}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="h6" className="discountText" style={{ marginTop: "8px" }}>
                      {formFee.discount_value}% off due to having a
                      <span className={classes.premiumPlan}> {formFee.current_plan_name}</span>
                    </Typography>

                    <Box sx={webStyle.termAndConditionCheckBox}>
                      <Checkbox
                        icon={<CustomCheckboxIcon />}
                        checkedIcon={<CustomCheckedCheckboxIcon />}
                        data-test-id="checkbox"
                        checked={isAgreed}
                        className={classes.checkBox}
                        onChange={() => this.handleChangeAgreeState(!isAgreed)}
                      />
                      <Typography variant="h5" className="formsText">
                        I agree to the&nbsp;
                        <span data-test-id="termsAndConditions" className={classes.termsAndConditions} onClick={this.navigateToTermsAndConditions}>
                          Terms and Conditions{" "}
                        </span>
                      </Typography>
                    </Box>

                    <Box sx={webStyle.formFeePay}>
                      <Button
                        data-test-id="payNowBtn"
                        variant="contained"
                        disabled={!isAgreed ? true : false}
                        className={
                          isAgreed
                            ? "paynowBtnEnabled"
                            : "paynowBtnDisabled"
                        }
                        onClick={payNowAction || this.payNowClickHandler}
                      >
                        Pay Now
                      </Button>
                      <Box sx={webStyle.totalFeeInfo}>
                        <Typography variant="h6" className={"totalFee"}>
                          Total Fee
                        </Typography>
                        <Typography variant="h2" className="feesText">${formatMoney(formFee.current_plan_add_on_cost)}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.borderBox} sx={{ marginTop: "24px" }}>
                <Box sx={webStyle.formFeeFooter}>
                  <Typography variant="h3" className="attentionHeadingText">{configJSON.attention}</Typography>
                  <Typography variant="subtitle2" className="attentionText">
                    {attentionDetail}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CustomBoxForm>
        {this.generateDialog()}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(OrderSummary);