import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { IChat } from "../../chat/src/ChatController";

export const configJSON = require("./config");
interface Vehicle {
  vehicleVin: string,
  vehicleType: string,
  vehicleYear: string,
  vehicleWeightEmpty: string,
  vehicleWeightGross: string,
  make: string,
  odometer: string,
  bodyType: string,
}

export interface ServiceProvider {
  id: string,
  type: string,
  selectedService: string,
  createdAt: string,
  updatedAt: string,
  serviceProviderName: string,
  serviceProviderLogo: string | undefined,
  hiredOn: string,
}

export interface Service {
  id: string,
  name: string,
  positivePercent: number,
  service: string,
  status: string,
  logo: string,
}

export interface User {
  id: string;
  avatar: string;
  role: string;
}

export interface Document {
  id: string;
  filename: string;
  url: string;
  size?: number;
  type?: string;
  status?: string;
  issueRaised?: boolean;
}

export interface DealInfo {
  dealId: string,
  fromState: string,
  toState: string,
  applicationType: string,
  registrationType: string,
  transactionID: string | null,
  vehicle: Vehicle,
  saleType: string,
  soldAs: string,
  msrp: string,
  tsp: string,
  sellerZip: string,
  purchaserZip: string,
  ownerFirstName: string,
  ownerLastname: string,
  firstOwner: string,
  secondOwner: string,
  dealershipName: string,
  dealershipLogo: string,
  dealDocuments: Document[],
  dateOfSale: string,
  dealStatus: string,
  accepted_selected_service_provider_id: string;
}

interface ChecklistItem {
  id: number;
  deal_id:number;
  checked: boolean;
  description: string;
  information: string;
  created_at: string;
  updated_at: string;
}

// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedStatus: string,
  dealInfo: DealInfo,
  user: User,
  sidebar: boolean,
  isChecklistOpened: boolean,
  hiredSPInfo: ServiceProvider,
  sentServiceProviders: Service[],
  isLoading: boolean,
  chatCreated: boolean,
  chatDetails: IChat,
  registrationFee: string;
  registrationFeeStatus: string;
  sendNotes: boolean;
  isModalShown: boolean;
  isPopulatedFormsPurchased: boolean;
  isFormsCentsPurchased: boolean;
  isFormsChecklistPurchased: boolean;
  buyNowBtnStatus: {
    populatedBlankForms: boolean,
    blankForms: boolean,
    checkListForms: boolean,
    populatedformHandler:boolean,
        blankFormsHandler:boolean,
        checklistFormHandler:boolean
  };
  checkListItems: ChecklistItem[]; 
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTemplatesDealDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDealInformationId: string = '';
  apiGetUserInformationId: string = '';
  apiGetAcceptedSP: string = '';
  apiSendRegistrationFeeId: string = '';
  apiGetSentServices: string = '';
  apiUpdateDealStatus: string = '';
  apiCallIdCreateChatRoom: string = "";
  withDrawDealCallId: string = "";
  apiGetChecklistInformationId: string = "";
  apiUpdateCheckListItem: string = "";

  inProgress = 'In Progress';
  issuesSentToDealer = 'Issues Sent to Dealer';
  resolvedInDealerReview = 'Resolved, In Dealer Review';
  completed = 'Completed';
  dealerFlagsIncomplete = 'Dealer Flags Incomplete';
  deleted = 'Deleted';

  handleChangeSelectedStatus = (newStatus: string) => {
    this.setState({ selectedStatus: newStatus });
    const updatedStatus = newStatus.toLowerCase().replace(/,/g, '').replace(/\s+/g, '_');
    this.updateDealStatus(updatedStatus);
  }

  updateDealStatus = (newStatus: string) => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateDealStatus = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/update_deal_status/' + this.state.dealInfo.dealId + '?status=' + newStatus
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  navigateToScreen = (screen: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    this.send(msg);
  }

  navigateToOrderSummary = () => {
    this.navigateToScreen('OrderSummary');
    setStorageData('addon','Generate Populated Forms and Checklist')
    setStorageData('addonRequired','populated_forms_addon') 
  }

  navigateToAutoForm = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'AutoForm');
    this.send(msg);
  }

  navigateToOrderSummaryBlankFormFee = () => {
    this.navigateToScreen('OrderSummaryBlankFormFee');
    setStorageData('addon','Generate Blank Forms')
    setStorageData('addonRequired','forms_cents_addon') 
  }

  navigateToOrderSummaryChecklistFee = () => {
    this.navigateToScreen('OrderSummaryChecklistFee');
    setStorageData('addon','Generate Checklist')
    setStorageData('addonRequired','forms_checklist_addon') 

  }

  navigateToOrderSummaryCalculateRegistrationFee = () => {
    this.navigateToScreen('OrderSummaryCalculateRegistrationFee');
  }

  navigateToServiceProviderDashboards = () => {
    if (this.state.selectedStatus === 'Completed' || this.state.selectedStatus === 'Deleted') {
      this.setState({
        isModalShown: true
      })
      return;
    }
    localStorage.setItem('state_code', this.state.dealInfo.toState);
    this.navigateToScreen('ServiceProviderDashboard');
  }

  handleCheckToggle = (index: number) => {
    const updatedChecklist = [...this.state.checkListItems]; 
    updatedChecklist[index].checked = !updatedChecklist[index].checked; 
    this.setState({ checkListItems: updatedChecklist });
    this.updateChecklistItem(updatedChecklist[index].checked, String(updatedChecklist[index].id))
  };

  updateChecklistItem = (checkedValue: boolean, itemId: string) => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateCheckListItem = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_integrationwithapi1/checklist_items/${itemId}`

    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(
        { checklist_item: { checked: checkedValue } }
      )
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getSentServices = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSentServices = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_profile/select_service_providers/get_hired_providers?deal_id=' + localStorage.getItem("createdDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  getAcceptedServiceProvider = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAcceptedSP = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/select_service_providers/fetch_accepted_services?deal_id=' + localStorage.getItem("createdDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getDealInformation = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDealInformationId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/' + localStorage.getItem("createdDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getUserInformation = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserInformationId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/get_user'
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getChecklistInfo = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetChecklistInformationId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_integrationwithapi1/checklist_items?deal_id=${localStorage.getItem("createdDealId")}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  mapToDealInfo = (response: any) => {
    const vehicle: Vehicle = {
      vehicleVin: response.vehicle_vin,
      vehicleType: response.vehicle_type,
      vehicleYear: response.vehicle_year,
      vehicleWeightEmpty: response.vehicle_weight_empty,
      vehicleWeightGross: response.vehicle_weight_gross,
      make: response.make,
      odometer: response.odometer,
      bodyType: response.body_type
    }

    const dealInfo: DealInfo = {
      dealId: response.id,
      ownerFirstName: response.owner_first_name,
      ownerLastname: response.owner_last_name,
      fromState: response.from_state,
      transactionID : response.transaction_id,
      toState: response.to_state,
      applicationType: response.application_type,
      registrationType: response.registration_type,
      saleType: response.sale_type,
      soldAs: response.sold_as,
      msrp: response.msrp,
      tsp: response.tsp,
      sellerZip: response.seller_zip,
      purchaserZip: response.purchaser_zip,
      firstOwner: response.first_owner,
      secondOwner: response.second_owner,
      vehicle: vehicle,
      dealershipName: response.dealership_detail.data.attributes.dealership_name,
      dealershipLogo: response.dealership_detail.data.attributes.dealership_logo,
      dealDocuments: response.deal_documents.map((doc: any) => ({ id: doc.id, filename: doc.filename, url: doc.url, status: doc.status, issueRaised: doc.issue_raised })),
      dateOfSale: response.date_created,
      dealStatus: response.deal_status,
      accepted_selected_service_provider_id: response.accepted_selected_service_provider_id
    }

    localStorage.setItem('fromState', response.from_state);
    localStorage.setItem('toState', response.to_state);

    return dealInfo;
  }

  closeDrawerHandler = () => {
    this.setState({ sidebar: false });
  }

  mapToHiredSPInfo = (response: any) => {
    if (response.data) {
      const { id, type } = response.data[0];
      const { selected_services, created_at, updated_at, service_provider_name, service_provider_logo, hired_on } = response.data[0].attributes;
      return {
        id,
        type,
        selectedService: selected_services,
        createdAt: created_at,
        updatedAt: updated_at,
        serviceProviderName: service_provider_name,
        serviceProviderLogo: service_provider_logo,
        hiredOn: hired_on,
      }
    } else {
      return {} as ServiceProvider;
    }
  }

  serviceProvidersData = (response: any) => {
    let sentServices: Service[] = [];
    if (response.data) {
      if(response.data.message !== "Already accepted request is present"){
      response?.data?.filter((data: any) => data.attributes.status !== 'withdrawn').forEach((data: any) => {
        const { id } = data;
        const { status, selected_services, service_provider_name, service_provider_logo, positive_like_percentage } = data.attributes;
        sentServices.push({
          id: id,
          status: status,
          service: selected_services,
          logo: service_provider_logo,
          name: service_provider_name,
          positivePercent: positive_like_percentage
        })
      })
    }
    }
    return sentServices;
  }

  sendRegistrationFee = (role: string, fee: string) => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSendRegistrationFeeId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_multilevelapproval/hired_provider_fees'
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            fee_type: "manual",
            fee_amount: fee,
            deal_id: localStorage.getItem("createdDealId"),
            select_service_provider_id: this.state.dealInfo.accepted_selected_service_provider_id
          }
        },
      })
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    this.setState({
      registrationFee: fee
    })
    return true;
  }

  changeDealToInProgress = () => {
    this.setState({
      isModalShown: false
    })
    this.handleChangeSelectedStatus(this.inProgress);
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    // Customizable Area Start
    this.state = {
      selectedStatus: "In Progress",
      dealInfo: {} as DealInfo,
      user: {} as User,
      sidebar: false,
      isChecklistOpened: false,
      hiredSPInfo: {} as ServiceProvider,
      sentServiceProviders: [],
      isLoading: true,
      chatCreated: false,
      chatDetails: {} as IChat,
      registrationFee: '',
      registrationFeeStatus: '',
      sendNotes: false,
      isModalShown: false,
      isFormsChecklistPurchased: true,
      isFormsCentsPurchased : false,
      isPopulatedFormsPurchased: false,
      buyNowBtnStatus: {
        populatedBlankForms: true,
        blankForms: true,
        checkListForms: true,
        populatedformHandler:false,
        blankFormsHandler:false,
        checklistFormHandler:false
      },
      checkListItems: [],

    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getDealInformation();
    this.getUserInformation();
    this.getSentServices();
    this.getChecklistInfo();
    this.getAcceptedServiceProvider();
    const url = new URL(window.location.toString());
    const sidebar = url.searchParams.get("sidebar");
    if (sidebar) {
      this.setState({ sidebar: sidebar === "true" });
    }
    // Customizable Area End
  }

  // Customizable Area Start


  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.state.dealInfo !== prevState.dealInfo && this.state.dealInfo !== {} as DealInfo) {
      this.setState({
        isLoading: false
      })
    }
  }

  hireNew = async (id: string) => {
    this.withdrawDeal(id);
    this.navigateToServiceProviderDashboards();
  }

  withdrawDeal = async (id: string) => {
    const authtok = await getStorageData('authToken')
    const Webheader = {
      "token": authtok
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const formData = new FormData();
    formData.append("status", 'withdrawn');

    this.withDrawDealCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/select_service_providers/update_deal_status/' + id
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    const updatedServices = this.state.sentServiceProviders.filter((provider: Service) => provider.id !== id);
    this.setState({
      sentServiceProviders: updatedServices
    })

    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  getDealStatus = (status: string) => {
    switch (status) {
      case 'in_progress':
        return this.inProgress;
      case 'issue_sent_to_dealer':
        return this.issuesSentToDealer;
      case 'resolved_in_dealer_review':
        return this.resolvedInDealerReview;
      case 'completed':
        return this.completed;
      case 'dealer_flags_incomplete':
        return this.dealerFlagsIncomplete;
      case 'deleted':
        return this.deleted;
    }
    return '';
  }

  handleChatNow = async () => {
    let deal_id = await getStorageData("createdDealId")
    const webHeader = {
      // 'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const fromData = new FormData()
    fromData.append('name', "hello")

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdCreateChatRoom = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createChatRoom}=${deal_id}`
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), fromData
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  apiResponceCreateChatRoom = (response: any) => {
    if (response && !response.errors) {
      const chatList = response;
      const results = {
        id: chatList.id,
        name: chatList?.service_provider_details?.name || chatList?.dealership_details?.name,
        dealName: chatList.deal_details.customer_last_name,
        dealNumber: chatList.deal_details.deal_id,
        userImage: chatList?.service_provider_details?.logo || chatList?.dealership_details?.logo,
        lastMessage: "",
        lastMessageDate: "",
        senderName: "",
        unReadMessageCount: 0
      }
      this.setState({
        chatCreated: true,
        chatDetails: results
      })
    }

  }

  closeChats = () => {
    this.setState({ chatCreated: false })
  }

  manageReteriewDocumnet = (value: boolean) => {
    this.setState({ sendNotes: value }, () => {
      if (value) this.handleChatNow()
    })

  }

  handleOpenCheckList=()=>{
    this.setState({isChecklistOpened:!this.state.isChecklistOpened})
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response && !response.errors) {
        switch (apiId) {
          case this.apiGetDealInformationId:
            this.setState({
              dealInfo: this.mapToDealInfo(response.data.attributes),
              selectedStatus: this.getDealStatus(response.data.attributes.deal_status),
              registrationFee: response.data.attributes.fees_data?.fee_amount,
              registrationFeeStatus: response.data.attributes.fees_data?.status,
            },()=>{
              this.setState({
                isPopulatedFormsPurchased: response.data.attributes.purchased_add_ons.some((addOn : any) => addOn.add_on_name === "populated_forms_addon"),
                isFormsCentsPurchased: response.data.attributes.purchased_add_ons.some((addOn : any) => addOn.add_on_name === "forms_cents_addon"),
                isFormsChecklistPurchased: response.data.attributes.purchased_add_ons.some((addOn : any) => addOn.add_on_name === "forms_checklist_addon"),
              });
            });
            break;
          case this.apiGetUserInformationId:
            this.setState({
              user: { id: response.data.id, avatar: response.data.attributes.profile_picture, role: response.data.attributes.role }
            })
            break;
          case this.apiGetAcceptedSP:
            this.setState({
              hiredSPInfo: this.mapToHiredSPInfo(response),
            })
            break;
          case this.apiGetSentServices:
            this.setState({
                sentServiceProviders: this.serviceProvidersData(response),
            })
            break;
          case this.apiCallIdCreateChatRoom:
            this.apiResponceCreateChatRoom(response)
            break;
          case this.apiUpdateDealStatus:
            break;
          case this.apiSendRegistrationFeeId:
            console.log(response);
            break;
          case this.withDrawDealCallId:
            console.log(response);
            break;
          case this.apiGetChecklistInformationId:
            this.setState({ checkListItems: response.checklist });
            break;
        }
      }
    }



    // Customizable Area End
  }
}