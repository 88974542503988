Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';
exports.deleteApiMethod = 'DELETE'
exports.patchApiMethod = 'PATCH'


exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";
exports.chatHeading ="Chats";
exports.chatWindow="chat_window"
exports.getAllChats="bx_block_chat/chats"
exports.noChatsFound="No chats found"
exports.noConversationFound="No conversation found"
exports.userChats="bx_b"
exports.sendMessagePlaceHolder="Type your message...";
exports.createMessageEndPoint="bx_block_chat/messages"
exports.editText="Edit"
exports.deleteText="Delete"
exports.getuserEndPoint="account_block/accounts/get_user"
exports.getNewMessagesStatusEndPoint="bx_block_chat/chats/new_messages"
exports.readMessagesEndPoint="bx_block_chat/chats/:chat_id/read_messages"
exports.addtoDocumentsEndPoint="bx_block_chat/messages/add_document_to_deal/:id"
exports.addDocumentsDialog={
  heading:"Add to documents",
  subHeading:"Please confirm you want to upload ",
  subHeadingEnd:" to the documents of this deal.",
  addButton:"Add to documents",
  cancelButton:"Cancel"
}
exports.addDocuments="Add To Documents";
exports.download="Download"
exports.deleteMessageText="You deleted this message"
exports.placeHolders={
  missingPlaceHolder:"Subject",
  missingXPlaceHolder:"Details....",
}
exports.noteButton={
  cancel:"Cancel",
  send:"Send"
}
exports.apiEndPointRaiseIssue="bx_block_posts/deals/raise_issue";
exports.raiseIssue="Raise an issue"

  // Customizable Area End