// Customizable Area Start
import React, { CSSProperties } from "react";
import moment from "moment";
import {
  Avatar,
  Box,
  Typography,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Backdrop,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  ListItemText,
  Button,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";

import MyTeamController, {
  Props,
  ContentView,
  ServiceableLocation,
  DealershipNormalized,
  SingleDealershipInfo,
  ProviderDetails,
  webConfigJSON
} from "./MyTeamController.web";
import TableHead from "./TableHead.web";
import DealershipsTable from "./DealershipsTable.web";
import UsersTable from "./UsersTable.web";
import {
  circleCheckmark,
  dealershipGroup,
  group,
  pencilGrey,
  trash,
  trashGrey,
  invitationSuccess,
  editUserSuccess,
  checkmark,
  editDealership,
  location,
} from "./assets";
import {
  CloseRounded,
  ExpandMoreRounded,
  EditOutlined,
} from "@material-ui/icons";
import { NormalizedUserData } from "./DashboardController.web";

export default class MyTeam extends MyTeamController {
  constructor(props: Props) {
    super(props);
  }

  HeaderRightSide = () => {
    const { userData } = this.props;
    const { showGroupNameEdit, updatedGroupName } = this.state;
    return (
      <Box sx={webStyles.headerRightSide}>
        <Label>Dealer Group</Label>
        {userData?.attributes.isDealerGroup && (
          <>
            <Box sx={webStyles.dealerGroupUpperBox}>
              {showGroupNameEdit ? (
                <>
                  <StyledTextField
                    value={updatedGroupName}
                    onChange={this.setUpdatedGroupName}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                  <Typography
                    style={webStyles.editGroup}
                    onClick={this.updateDealerGroupName}
                    className="buttonText"

                  >
                    Update
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className="nameTxt" component={'p'}>
                    {userData.attributes.dealerGroupName || ""}
                  </Typography>
                  <Typography
                    style={webStyles.editGroup}
                    onClick={this.handleGroupEdit}
                    className="buttonText"
                  >
                    Edit
                  </Typography>
                </>
              )}
            </Box>
            <Box style={webStyles.creationDate}>
              {userData.attributes.groupCreatedOn &&
                `Created on ${moment(
                  new Date(userData.attributes.groupCreatedOn)
                ).format("MMMM D, YYYY")}`}
            </Box>
          </>
        )}
      </Box>
    );
  };

  HeaderRightSideSP = (singleServiceableLocation: ServiceableLocation) => {
    return (
      <Box sx={webStyles.headerRightSide}>
        <Label>Serviceable Location</Label>
        <Box sx={webStyles.dealerGroupUpperBox}>
          <Typography className="nameTxt" component={'p'}>
            {singleServiceableLocation.provider_state}
          </Typography>
                    <Typography 
                    className="buttonText"
                    style={webStyles.editGroup} >
            <img
              src={location}
              alt={singleServiceableLocation.provider_state}
            />
          </Typography>
        </Box>
        <Box style={webStyles.creationDate}>
          {singleServiceableLocation.provider_services}
        </Box>
        <Box style={webStyles.creationDate}>
          {`Created on ${moment(
            new Date(singleServiceableLocation.created_at)
          ).format("MMMM D, YYYY")}`}
        </Box>
      </Box>
    );
  };

  
  
  DealershipsList = () => {
    const { dealershipsData, addUsersToDealershipsIds } = this.state;
    return (
      <DealershipsListContainer>
        <Typography className="tableTitle">Dealerships</Typography>
        <Box className="dealershipsContainer">
          {dealershipsData.map((dealership, i) => (
            <Box className="dealershipItem" key={dealership.id}>
              <Box className="leftSide">
                <Typography className="dealershipTitle">
                  {dealership.name}
                </Typography>
                <Typography className="dealershipUsers">
                  {dealership.users} Users
                </Typography>
              </Box>
              <Box className="rightSide">
                {addUsersToDealershipsIds.includes(dealership.id) ? (
                  <>
                    <button
                      onClick={(e) =>
                        this.handleAddUserToDealershipIds(e, dealership.id)
                      }
                      data-test-id={`user-added-button-${i}`}
                      type="button"
                      className="userAddedButton"
                    >
                      Added
                      <img src={circleCheckmark} alt="checkmark" className="icons"/>
                    </button>

                    <button
                      onClick={(e) =>
                        this.handleAddUserToDealershipIds(e, dealership.id)
                      }
                      data-test-id={`user-remove-button-${i}`}
                      type="button"
                      className="userAddedButtonSecondary"
                    >
                      Remove
                      <CloseRounded className="icons" />
                    </button>
                  </>
                ) : (
                  <button
                    onClick={(e) =>
                      this.handleAddUserToDealershipIds(e, dealership.id)
                    }
                    data-test-id={`add-to-dealership-${i}`}
                    type="button"
                    className="addUserButton"
                  >
                    Add to Dealership
                  </button>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </DealershipsListContainer>
    );
  };

  locationList = () => {
    const { providerDetails, addUsersToDealershipsIds } = this.state;
    return (
      <DealershipsListContainer>
        <Typography className="tableTitle">Locations</Typography>
        <Box className="dealershipsContainer">
          {providerDetails.serviceable_locations?.map((location, i) => (
            <Box className="dealershipItem" key={location.id}>
              <Box className="leftSide">
                <Typography className="dealershipTitle">
                  {location.provider_state}
                </Typography>
                <Typography className="dealershipUsers">
                  {location.users_count} Users
                </Typography>
              </Box>
              <Box className="rightSide">
                {addUsersToDealershipsIds.includes(location.id) ? (
                  <>
                    <button
                      onClick={(e) =>
                        this.handleAddUserToDealershipIds(e, location.id)
                      }
                      data-test-id={`user-added-button-${i}`}
                      type="button"
                      className="userAddedButton"
                    >
                      Added
                      <img src={circleCheckmark} alt="checkmark" />
                    </button>

                    <button
                      onClick={(e) =>
                        this.handleAddUserToDealershipIds(e, location.id)
                      }
                      data-test-id={`user-remove-button-${i}`}
                      type="button"
                      className="userAddedButtonSecondary"
                    >
                      Remove
                      <CloseRounded className="icons" />
                    </button>
                  </>
                ) : (
                  <button
                    onClick={(e) =>
                      this.handleAddUserToDealershipIds(e, location.id)
                    }
                    data-test-id={`add-to-dealership-${i}`}
                    type="button"
                    className="addUserButton"
                  >
                    Add to Location
                  </button>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </DealershipsListContainer>
    );
  };

  AddUserModal = () => {
    const { isDealershipsModalOpened, addUsersToDealershipsIds } = this.state;

    return (
      <AddUserBackdrop
        open={isDealershipsModalOpened}
        onClick={this.handleDealershipsModal}
      >
        <Box className="modalContainer" onClick={this.stopPropagation}>
          <CloseRounded
            onClick={this.handleDealershipsModal}
            width={18}
            style={{ position: "absolute", right: "60px", cursor: "pointer" }}
          />
          <Box className="modalHeader">
            <Typography className="modalTitle">Add Dealerships</Typography>
            <Typography className="modalDescription">
              Select the dealership you would like to add these user(s) to
            </Typography>
          </Box>

          {this.DealershipsList()}

          <Button
            onClick={this.handleAddUserToDealership}
            disabled={!addUsersToDealershipsIds.length}
            data-test-id="add-user-button"
            className="updateDealershipsUsers"
            style={{
              background: addUsersToDealershipsIds.length
                ? "#4FC8EC"
                : "#E8F8FD",
              color: addUsersToDealershipsIds.length ? "#FFFFFF" : "#75D3F0",
            }}
          >
            Update
          </Button>
        </Box>
      </AddUserBackdrop>
    );
  };

  DeleteUsersPrompt = () => {
    const { isDeletePromptOpened, usersToDelete, usersData } = this.state;

    return (
      <StyledDialog
        open={isDeletePromptOpened}
        onClose={this.setDeletePromptState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseRounded
          onClick={this.setDeletePromptState}
          width={18}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
        />
        <DialogTitle className="dialogTitle" id="alert-dialog-title">
          {usersToDelete.length > 1 ? "Delete Users" : "Delete User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {usersToDelete.length > 1
              ? `Are you sure you want to delete these ${usersToDelete.length} users? They will no longer have access to Vitu Marketplace.`
              : `Are you sure you want to delete ${
                  usersData.find((user) => user.id === usersToDelete[0])?.name
                }? They will no longer have access to Vitu Marketplace.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={this.setDeletePromptState}
            data-test-id="create-dealership"
            type="button"
            style={webStyles.cancelDeleteButton}
          >
            Cancel
          </button>

          <button
            onClick={this.handleDeleteUsers}
            data-test-id="invite-user"
            type="button"
            style={webStyles.deleteUserButton}
          >
            Delete
          </button>
        </DialogActions>
      </StyledDialog>
    );
  };
  InviteUserButton = ()=>{
    return(
      <Button
        variant="contained"
        onClick={this.inviteUsersToDealership}
        disabled={!this.isAllowedToInvite()}
        className="userUpdateBtn"
        style={{
          width: "100%",
          fontWeight: 700,
          lineHeight: "24px",
          textTransform: "none",
          borderRadius: "8px",
          background: this.isAllowedToInvite() ? "#4FC8EC" : "#E8F8FD",
          color: this.isAllowedToInvite() ? "#FFFFFF" : "#75D3F0",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        Invite User
      </Button>
    )
  }
  getName = (item: ServiceableLocation | DealershipNormalized) => {
    return item && ("name" in item ? item.name : item.provider_state);
  }
  getUserInfoDealerships = (user: any, isDealerView: any, dealershipsData: any, providerDetails: any) => {
    if(isDealerView){
      return (
        dealershipsData.filter((dealership: any) =>
            user.dealer_information_ids.includes(
              dealership.id
            )
          )
          .map((dealership: any) => this.getName(dealership))
          .join(", ")
      )
    }
    return (
      providerDetails.serviceable_locations?.filter((location: any) =>
          user.dealer_information_ids.includes(
            location.id
          )
        )
        .map((location: any) => this.getName(location))
        .join(", ")
    )
  }
  getInviteDealershipRenderValue = (isDealerView: any, inviteTo: any, dealershipsData: any, providerDetails: any) => {
    if(isDealerView){
      return(
        inviteTo
        .map(
          (dealershipId: any) =>
            this.getName(dealershipsData?.find(
              (dealershipToFind: any) =>
                dealershipToFind.id === dealershipId
            )!)
        )
        .join(", ")
      )
    }
    return(
      inviteTo
      .map(
        (dealershipId: any) =>
          this.getName(providerDetails.serviceable_locations?.find(
            (dealershipToFind: any) =>
              dealershipToFind.id === dealershipId
          )!)
      )
      .join(", ")
    )
  }
  renderSelectMenuItem =(userData: NormalizedUserData | null, singleDealershipInfo: SingleDealershipInfo | undefined, isDealerView: boolean | undefined, data: any, providerDetails: ProviderDetails, menuItems: JSX.Element[]) => {
    if(userData?.attributes.soloDealershipId){
      return <MenuItem value={userData.attributes.soloDealershipId}>
      {singleDealershipInfo?.name}
    </MenuItem>
    }
    else if(!isDealerView && data.length == 1){
      return <MenuItem value={providerDetails.serviceable_locations[0].id}>
      {providerDetails.serviceable_locations[0].provider_state}
    </MenuItem>
    }
    else return menuItems;
  }
  InviteUserModal = () => {
    const { showInviteUser, userData } = this.props;
    const {
      invitationSuccessScreen,
      roles,
      invitedUsers,
      dealershipsData,
      inviteToDealership,
      invitedUserRole,
      singleDealershipInfo,
      invitedUserEmail,
      inviteUserEmailError,
      providerDetails,
      inviteToServicableLocation
    } = this.state;
    const isDealerView = userData?.attributes.role.includes('dealer');
    const inviteTo = isDealerView ? inviteToDealership : inviteToServicableLocation;
    const data = isDealerView ? dealershipsData : (providerDetails.serviceable_locations || []);
    const menuItems = [
      <InviteUserMenuItemAll value="all">
        <Box>
          <Typography>{`${inviteTo.length} Selected`}</Typography>
          <Typography>
            Select All
            <StyledCheckbox
              checked={inviteTo.length === data.length}
              checkedIcon={
                <CheckedIcon>
                  <img src={checkmark} alt="checkmark" />
                </CheckedIcon>
              }
              icon={<CheckboxIcon />}
            />
          </Typography>
        </Box>
      </InviteUserMenuItemAll>,
    ];

    data.forEach((dealership: any) => {
      menuItems.push(
        <InviteUserMenuItem
          key={dealership.id}
          value={dealership.id}
          style={{ backgroundColor: "transparent" }}
        >
          <ListItemText primary={this.getName(dealership)} />
          <StyledCheckbox
            checked={inviteTo.includes(dealership.id)}
            checkedIcon={
              <CheckedIcon>
                <img src={checkmark} alt="checkmark" />
              </CheckedIcon>
            }
            icon={<CheckboxIcon />}
          />
        </InviteUserMenuItem>
      );
    });

    return (
      <InviteUserBackdrop
        open={showInviteUser}
        onClick={this.onShowInviteUserClose}
      >
        <Box className="inviteContainer" onClick={this.stopPropagation}>
          <CloseRounded
            onClick={this.onShowInviteUserClose}
            width={18}
            style={{ position: "absolute", right: "60px", cursor: "pointer" }}
          />
          {invitationSuccessScreen ? (
            <Box className="successInvite">
              <img
                className="successInviteImage"
                src={invitationSuccess}
                alt="invite success"
              />
              <Box className="successMessageBlock">
                <Typography className="successTitle">
                  Invitation Sent
                </Typography>
                <Typography className="successDescription">
                  The user will get an email with a link to join.
                </Typography>
              </Box>

              <Box className="successButtonsContainer">
                <button
                  className="successViewDealership"
                  onClick={this.onShowInviteUserClose}
                >
                  View {isDealerView ? ' Dealership' : ' Provider'}
                </button>
                <button
                  onClick={this.closeInvitationSuccess}
                  className="successInviteMore"
                >
                  Invite More Users
                </button>
              </Box>
            </Box>
          ) : (
            <>
              <Box className="inviteHeader">
                <Typography className="inviteTitle">Invite User</Typography>
                <Typography className="inviteDescription">
                  Please fill the information below to add this user.
                </Typography>
              </Box>

              {Boolean(invitedUsers.length) && (
                <Box className="inviteUsersContainer">
                  <Typography className="addedUserTitle">Added User</Typography>

                  <Box className="usersList">
                    {invitedUsers.map((user, i) => (
                      <Box className="userItem" key={user.email + i}>
                        <Box className="userBlankAvatar">
                          {user.email.slice(0, 2).toUpperCase()}
                        </Box>
                        <Box className="userInfoContainer">
                          <Typography className="userInfoEmail">
                            {user.email}
                          </Typography>
                          <Typography className="userInfoDealerships">
                            {this.getUserInfoDealerships(user, isDealerView, dealershipsData, providerDetails)}
                          </Typography>
                        </Box>
                        <Box className="userActionsContainer">
                          <Typography className="userRole">
                            {user.dealership_role_id === "1" ? "Admin" : "User"}
                          </Typography>
                          <img
                            onClick={() => this.editInvitedUser(user)}
                            className="editUser"
                            src={pencilGrey}
                            alt="edit user"
                          />
                          <img
                            onClick={() => this.deleteInvitedUser(user.email)}
                            className="deleteUser"
                            src={trashGrey}
                            alt="delete user"
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}

              <Box className="inviteFormContainer">
                {Boolean(invitedUsers.length) && (
                  <Typography className="formTitle">
                    Add Another User
                  </Typography>
                )}

                
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="dealership-select-label">
                    Choose{isDealerView ? ' Dealership' : ' State'}
                  </InputLabel>
                  <Select
                    labelId="dealership-select-label"
                    className="inviteDealershipSelect"
                    value={inviteTo}
                    renderValue={() =>
                      this.getInviteDealershipRenderValue(isDealerView, inviteTo, dealershipsData, providerDetails)
                    }
                    onChange={this.handleDealershipSelect}
                    disabled={data.length === 1}
                    label="Choose Dealership"
                    multiple
                    IconComponent={ExpandMoreRounded}
                    MenuProps={{
                      getContentAnchorEl: null,
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    {this.renderSelectMenuItem(userData, singleDealershipInfo, isDealerView, data, providerDetails, menuItems)}
                  </Select>
                </FormControl>

                <TextField
                  placeholder="Email Address"
                  value={invitedUserEmail}
                  onChange={this.setInviteUserEmail}
                  inputProps={{
                    maxLength: 80,
                  }}
                  variant="outlined"
                  label="Email Address"
                  error={Boolean(inviteUserEmailError)}
                  helperText={inviteUserEmailError}
                  onBlur={this.checkInviteEmail}
                  className="inviteEmailInput"
                  style={{
                    borderRadius: "8px",
                  }}
                />

                <InviteRadioGroupContainer>
                  <Typography className="radioLabel">Choose Role</Typography>
                  <FormControl>
                    <RadioGroup
                      value={invitedUserRole}
                      onChange={this.setInviteUserRole}
                      row
                    >
                      {roles.map((role) => (
                        <FormControlLabel
                          key={role.id}
                          value={role.id}
                          control={
                            <Radio
                              checkedIcon={<RadioIconChecked />}
                              icon={<RadioIcon />}
                            />
                          }
                          label={role.attributes.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </InviteRadioGroupContainer>

                {this.isAllowedToAddMore() && (
                  <Button
                    onClick={this.setInviteUsers}
                    className="addAnotherUserButton"
                    style={{
                      alignSelf: "flex-end",
                      width: "160px",
                      color: "#013D4F",
                      border: "1px solid #013D4F",
                      borderRadius: "8px",
                      fontWeight: 700,
                      backgroundColor: "transparent",
                      textTransform: "none",
                    }}
                  >
                    Add Another User
                  </Button>
                )}
              </Box>

              <this.InviteUserButton data-test-id="invite-user-button"/>
            </>
          )}
        </Box>
      </InviteUserBackdrop>
    );
  };

  EditUserModal = () => {
    const { userData } = this.props;
    const {
      roles,
      showEditUser,
      editedUserName,
      editedUserEmail,
      editedUserEmailError,
      editedUserRole,
      usersData
    } = this.state;
    const hasOnlyOneAdmin = usersData.filter(user => user.dealerRole === 'admin').length === 1;
    return (
      <EditUserBackdrop open={showEditUser} onClick={this.onShowEditUserClose}>
        <Box className="editContainer" onClick={this.stopPropagation}>
          <CloseRounded
            onClick={this.onShowEditUserClose}
            width={18}
            style={{ position: "absolute", right: "60px", cursor: "pointer" }}
          />
          <Box className="editHeader">
            <Typography className="editTitle">{`Edit ${editedUserName}`}</Typography>
            <Typography className="editDescription">
              Adjust the email, role, and {userData?.attributes.role.includes('dealer') ? ' Dealership(s) ' :  ' Provider(s) '} of this user
            </Typography>
          </Box>

          <Box className="editFormContainer">
            <Typography className="formTitle">Basic Info</Typography>

            <TextField
              placeholder="Email Address"
              value={editedUserEmail}
              onChange={this.setEditUserEmail}
              onBlur={this.checkEditEmail}
              error={Boolean(editedUserEmailError)}
              helperText={editedUserEmailError}
              variant="outlined"
              label="Email Address"
            />

            <InviteRadioGroupContainer>
              <Typography className="radioLabel">Choose Role</Typography>
              <FormControl>
                <RadioGroup
                  className="radioGroup"
                  value={editedUserRole}
                  onChange={this.setEditUserRole}
                  row
                >
                  {roles.map((role) => (
                    <FormControlLabel
                      key={role.id}
                      value={role.id}
                      disabled={hasOnlyOneAdmin && editedUserRole == '1'}
                      control={
                        <Radio
                          checkedIcon={<RadioIconChecked />}
                          icon={<RadioIcon />}
                        />
                      }
                      label={role.attributes.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </InviteRadioGroupContainer>

            {this.props.userData?.attributes.role===webConfigJSON.dealerAdmin
            ? this.DealershipsList() : this.locationList()}
          </Box>

          <Button
            variant="contained"
            onClick={this.editUser}
            data-test-id="edit-user-button"
            className="userUpdateBtn"
            disabled={!this.isAllowedToEdit()}
            style={{
              width: "100%",
              fontWeight: 700,
              lineHeight: "24px",
              textTransform: "none",
              borderRadius: "8px",
              background: this.isAllowedToEdit() ? "#4FC8EC" : "#E8F8FD",
              color: this.isAllowedToEdit() ? "#FFFFFF" : "#75D3F0",
              fontFamily: "Roboto, sans-serif",
            }}
          >
            Update
          </Button>
        </Box>
      </EditUserBackdrop>
    );
  };

  CustomSnackbar = () => {
    const { snackbarMessage } = this.state;
    return (
      <StyledSnackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(snackbarMessage)}
        autoHideDuration={3000}
        onClose={this.onSnackbarClose}
        style={{ backgroundColor: "#FFF" }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          {(snackbarMessage.includes("Deleted") && (
            <img src={trash} alt="delete icon" />
          )) ||
            (snackbarMessage.includes("added") && (
              <img src={group} alt="dealership icon" />
            )) ||
            (snackbarMessage.includes("Group Updated") && (
              <img src={dealershipGroup} alt="group icon" />
            )) ||
            (snackbarMessage.includes("User Updated") && (
              <img src={editUserSuccess} alt="user icon" />
            )) ||
            (snackbarMessage.includes("Dealership Updated") && (
              <img src={editDealership} alt="dealership icon" />
            )) || 
            (snackbarMessage.includes("Provider Updated") && (
              <img src={editDealership} alt="provider icon" />
            ))}

          <Typography className="snackbarMessage">{snackbarMessage}</Typography>
        </Box>
      </StyledSnackbar>
    );
  };

  getDescription = (userData: any) => {
    return !userData?.attributes.assignedDealershipIds?.length
      ? "Find your teammates and their contact info."
      : "Manage your Dealerships or invite more users.";
  };

  createAvatar = (logo: string, name: string) => {
    return (
      <SingleDealershipAvatar  style={!logo ? { border: "1px dashed #013D4F" } : {}} src={logo} alt={name} variant="rounded">
        {(!logo && name) &&
          name
            .split(" ")
            .slice(0, 2)
            .map((dl: any) => dl[0])
            .join("")
            .toUpperCase()}
      </SingleDealershipAvatar>
    );
  };

  TableHeaderUsersContentView = () => {
    const {
      usersData,
      dealershipsData,
      roles,
      currentTab,
      sort,
      search,
      page,
      role,
      totalUsersPages,
      itemsPerPage,
      isAllChecked,
      isDeletePromptOpened,
      dealershipsFilter,
      providerDetails,
      states
    } = this.state;

    return (
      <TableHead
        currentView={currentTab}
        sort={sort}
        search={search}
        page={page}
        totalPages={totalUsersPages}
        role={role}
        roles={roles}
        states={states}
        totalItems={usersData.length}
        itemsPerPage={itemsPerPage}
        setSort={this.setSort}
        setRole={this.setRole}
        setSearch={this.setSearch}
        setPage={this.setPage}
        setStates={this.setStates}
        checkedItems={usersData.filter((user) => user.checked).length}
        handleCheckAll={this.handleCheckAll}
        isAllChecked={isAllChecked}
        isDeletePromptOpened={isDeletePromptOpened}
        handleBulkDeleteUserPrompt={this.handleBulkDeleteUserPrompt}
        setDeletePromptState={this.setDeletePromptState}
        handleDealershipsModal={this.handleDealershipsModal}
        isAdmin={this.isAdmin}
        handleDealershipsFilterSelect={
          this.handleDealershipsFilterSelect
        }
        handleDealershipsFilterClose={
          this.handleDealershipsFilterClose
        }
        dealershipsFilter={dealershipsFilter}
        dealershipsData={dealershipsData}
        serviceableLocations={providerDetails.serviceable_locations}
      />
    )
  }

  renderDealerView = () => {
    const { userData } = this.props;
    const {
      usersData,
      dealershipsData,
      roles,
      singleDealershipInfo,
      currentTab,
      sort,
      search,
      page,
      role,
      totalDealershipsPages,
      totalUsersPages,
      itemsPerPage,
      isAllChecked,
      isDeletePromptOpened,
      isShowDealershipUsers,
      selectedDealership,
    } = this.state;

    return (
      userData?.attributes.role.includes("dealer") &&
      (userData?.attributes.isDealerGroup ||
      ((userData?.attributes.assignedDealershipIds?.length || 0) > 1 &&
        !isShowDealershipUsers) ? (
        <Container>
          <Box sx={webStyles.header}>
            {selectedDealership ? (
              <Box style={webStyles.singleDealershipHeader}>
                {this.createAvatar(
                  this.getDealershipById(selectedDealership).logo,
                  this.getDealershipById(selectedDealership).name
                )}
                <Box style={webStyles.singleDealershipContainer}>
                  <Box style={webStyles.dealershipNameContainer}>
                    <Typography style={webStyles.singleDealershipTitle}>
                      {this.getDealershipById(selectedDealership).name}
                    </Typography>
                    {this.isAdmin() && (
                      <EditOutlined
                        width={15}
                        height={15}
                        style={{ color: "#4B4C4B", cursor: "pointer" }}
                        onClick={() =>
                          this.handleDealershipEdit(
                            this.getDealershipById(selectedDealership).id
                          )
                        }
                      />
                    )}
                  </Box>
                  <Typography style={webStyles.singleDealershipDescription}>
                    Manage this Dealership or invite more users.
                  </Typography>
                </Box>
              </Box>
            ) : (
              <>
                <Box sx={webStyles.headerLeftSide}>
                  <Typography className="title">Dealerships & Users</Typography>
                  <Typography className="description">
                    {this.getDescription(userData)}
                  </Typography>
                </Box>
                {userData.attributes.role != 'dealer' &&
                  <>
                    {this.HeaderRightSide()}
                  </>

                }
              </>
            )}
          </Box>

          <Box sx={webStyles.content}>
            {!selectedDealership && (
              <CustomizedTabs value={currentTab} onChange={this.setTab}>
                <Tab
                  data-test-id="dealerships-tab"
                  label="Dealerships"
                  value={ContentView.DEALERSHIPS}
                />
                <Tab
                  data-test-id="users-tab"
                  label="Users"
                  value={ContentView.USERS}
                />
              </CustomizedTabs>
            )}

            {currentTab === ContentView.DEALERSHIPS ? (
              <>
                <TableHead
                  currentView={currentTab}
                  sort={sort}
                  search={search}
                  page={page}
                  totalItems={dealershipsData.length}
                  itemsPerPage={itemsPerPage}
                  totalPages={totalDealershipsPages}
                  setSort={this.setSort}
                  setSearch={this.setSearch}
                  setPage={this.setPage}
                  checkedItems={
                    dealershipsData.filter((dealership) => dealership.checked)
                      .length
                  }
                  handleCheckAll={this.handleCheckAll}
                  isAllChecked={isAllChecked}
                  isAdmin={this.isAdmin}
                />
                <DealershipsTable
                  data={dealershipsData}
                  itemsPerPage={itemsPerPage}
                  page={page}
                  handleCheck={this.handleCheck}
                  isAdmin={this.isAdmin}
                  view={ContentView.DEALERSHIPS}
                  handleDealershipEdit={this.handleDealershipEdit}
                  showDealershipUsers={this.showDealershipUsers}
                />
              </>
            ) : (
              <>
                <this.TableHeaderUsersContentView/>
                <UsersTable
                  userEmail={userData?.attributes.email!}
                  usersData={usersData}
                  itemsPerPage={itemsPerPage}
                  page={page}
                  handleCheck={this.handleCheck}
                  setDeletePromptState={this.setDeletePromptState}
                  handleEditUser={this.handleEditUser}
                  isAdmin={this.isAdmin}
                  handleDeleteUserPrompt={this.handleDeleteUserPrompt}
                  selectedServiceableLocation={this.state.selectedServiceableLocation}
                  resendInvite={this.resendInvite}
                />
              </>
            )}
          </Box>
        </Container>
      ) : (
        <Container>
          <Box style={webStyles.singleDealershipHeader}>
            {this.createAvatar(
              singleDealershipInfo?.logo!,
              singleDealershipInfo?.name!
            )}
            <Box style={webStyles.singleDealershipContainer}>
              <Box style={webStyles.dealershipNameContainer}>
                <Typography style={webStyles.singleDealershipTitle}>
                  {singleDealershipInfo?.name}
                </Typography>
                {this.isAdmin() && (
                  <EditOutlined
                    width={15}
                    height={15}
                    style={{ color: "#4B4C4B", cursor: "pointer" }}
                    onClick={this.editSingleDealership}
                  />
                )}
              </Box>
              <Typography component={'p'} className="description">
                Manage this Dealership or invite more users.
              </Typography>
            </Box>
          </Box>
          <TableHead
            currentView={ContentView.SINGLE_DEALERSHIP_USERS}
            sort={sort}
            search={search}
            page={page}
            totalPages={totalUsersPages}
            role={role}
            roles={roles}
            totalItems={usersData.length}
            itemsPerPage={itemsPerPage}
            isAdmin={this.isAdmin}
            setSort={this.setSort}
            setRole={this.setRole}
            setSearch={this.setSearch}
            setPage={this.setPage}
            checkedItems={usersData.filter((user) => user.checked).length}
            handleCheckAll={this.handleCheckAll}
            isAllChecked={isAllChecked}
            isDeletePromptOpened={isDeletePromptOpened}
            handleBulkDeleteUserPrompt={this.handleBulkDeleteUserPrompt}
            setDeletePromptState={this.setDeletePromptState}
            handleDealershipsModal={this.handleDealershipsModal}
          />
          <UsersTable
            userEmail={userData?.attributes.email!}
            usersData={usersData}
            itemsPerPage={itemsPerPage}
            page={page}
            handleCheck={this.handleCheck}
            setDeletePromptState={this.setDeletePromptState}
            handleEditUser={this.handleEditUser}
            isAdmin={this.isAdmin}
            handleDeleteUserPrompt={this.handleDeleteUserPrompt}
            selectedServiceableLocation={this.state.selectedServiceableLocation}
            resendInvite={this.resendInvite}
          />
        </Container>
      ))
    );
  };

  renderSPView = () => {
    const { userData } = this.props;
    const {
      usersData,
      currentTab,
      sort,
      search,
      page,
      services,
      totalDealershipsPages,
      totalStatesPages,
      itemsPerPage,
      isAllChecked,
      selectedServiceableLocation,
      providerDetails,
    } = this.state;
    const isSingleServiceableLocation =
      providerDetails.serviceable_locations?.length === 1 && this.state.totalStates === 1;
    let singleServiceableLocationData = {} as ServiceableLocation;
    if (isSingleServiceableLocation) {
      singleServiceableLocationData = providerDetails.serviceable_locations[0];
    }
    return (
      userData?.attributes.role.includes("service_provider") && (
        <Container>
          <Box sx={webStyles.header}>
            {selectedServiceableLocation ? (
              <Box style={webStyles.singleDealershipHeader}>
                <Box style={webStyles.singleDealershipContainer}>
                  <Box style={webStyles.dealershipNameContainer}>
                    <Typography style={webStyles.singleDealershipTitle}>
                      {
                        providerDetails.serviceable_locations?.find(
                          (location) =>
                            location.id === selectedServiceableLocation
                        )?.provider_state
                      }
                    </Typography>
                    {this.isAdmin() && (
                      <EditOutlined
                        width={15}
                        height={15}
                        style={{ color: "#4B4C4B", cursor: "pointer" }}
                        data-test-id="edit-service-provider-icon"
                        onClick={() => {this.props.navigateToEditServiceableLocation(providerDetails.serviceable_locations?.find(x=>x.id === selectedServiceableLocation)?.id!)}}
                      />
                    )}
                  </Box>
                  <Typography style={webStyles.singleDealershipDescription}>
                    {
                      providerDetails.serviceable_locations?.find(
                        (location) =>
                          location.id === selectedServiceableLocation
                      )?.provider_services
                    }
                  </Typography>
                </Box>
              </Box>
            ) : (
              <>
                <Box style={webStyles.singleDealershipHeader}>
                  {this.createAvatar(
                    providerDetails.service_provider_logo,
                    providerDetails.business_name
                  )}
                  <Box style={webStyles.singleDealershipContainer}>
                    <Box style={webStyles.dealershipNameContainer}>
                      <Typography className="title" component={'h3'}>
                        {providerDetails?.business_name}
                      </Typography>
                      {this.isAdmin() && (
                        <EditOutlined
                          width={15}
                          height={15}
                          style={{ color: "#4B4C4B", cursor: "pointer" }}
                          onClick={() => {this.props.navigateToEditServiceProvider()}}
                        />
                      )}
                    </Box>
                    <Typography className="description" component={'h6'}>
                      {!isSingleServiceableLocation
                        ? "Manage your locations or invite more users."
                        : "Find your teammates and their contact info."}
                    </Typography>
                  </Box>
                </Box>

                {isSingleServiceableLocation &&
                  this.HeaderRightSideSP(singleServiceableLocationData)}
              </>
            )}
          </Box>

          <Box sx={webStyles.content}>
            {!selectedServiceableLocation && !isSingleServiceableLocation && (
              <CustomizedTabs value={currentTab} onChange={this.setTab}>
                <Tab
                  data-test-id="serviceable-locations-tab"
                  label="Serviceable Locations"
                  value={ContentView.SERVICEABLE_LOCATIONS}
                />
                <Tab
                  data-test-id="users-tab"
                  label="Users"
                  value={ContentView.USERS}
                />
              </CustomizedTabs>
            )}

            {currentTab === ContentView.SERVICEABLE_LOCATIONS ? (
              <>
                <TableHead
                  services={services}
                  currentView={currentTab}
                  sort={sort}
                  search={search}
                  page={page}
                  totalItems={providerDetails.serviceable_locations?.length}
                  itemsPerPage={itemsPerPage}
                  totalPages={totalStatesPages}
                  setSort={this.setSort}
                  setSearch={this.setSearch}
                  setPage={this.setPage}
                  setServices={this.setServices}
                  checkedItems={
                    providerDetails.serviceable_locations?.filter(
                      (location) => location.checked
                    ).length
                  }
                  handleCheckAll={this.handleCheckAll}
                  isAllChecked={isAllChecked}
                  isAdmin={this.isAdmin}
                />
                <DealershipsTable
                  data={providerDetails.serviceable_locations}
                  itemsPerPage={itemsPerPage}
                  page={page}
                  handleCheck={this.handleCheck}
                  isAdmin={this.isAdmin}
                  view={ContentView.SERVICEABLE_LOCATIONS}
                  showLocationUsers={this.showServiceableLocationUsers}
                  handleServiceableLocationEdit={
                    this.handleServiceableLocationEdit
                  }
                  navigation={this.props.navigation}
                />
              </>
            ) : (
              <>
                <this.TableHeaderUsersContentView/>
                <UsersTable
                  userEmail={userData?.attributes.email}
                  usersData={usersData}
                  serviceableLocations={providerDetails.serviceable_locations}
                  itemsPerPage={itemsPerPage}
                  page={page}
                  handleCheck={this.handleCheck}
                  setDeletePromptState={this.setDeletePromptState}
                  handleEditUser={this.handleEditUser}
                  isAdmin={this.isAdmin}
                  handleDeleteUserPrompt={this.handleDeleteUserPrompt}
                  selectedServiceableLocation={this.state.selectedServiceableLocation}
                  resendInvite={this.resendInvite}
                />
              </>
            )}
          </Box>
        </Container>
      )
    );
  };
  
  render() {

    return (
      <ThemeProvider theme={theme}>
        {this.renderDealerView()}
        {this.renderSPView()}
        {this.AddUserModal()}
        {this.CustomSnackbar()}
        {this.DeleteUsersPrompt()}
        {this.InviteUserModal()}
        {this.EditUserModal()}
      </ThemeProvider>
    );
  }
}

const theme = createTheme({
  overrides: {
    MuiMenu: {
      list:{
        overflow: 'scroll'
      }
    },
  }
});

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  boxSizing: "border-box",
  padding: "36px 24px 36px 32px",

  [theme.breakpoints.down("sm")]: {
    padding: "24px 10px",
  },

  "& .title": {
    fontFamily: "Gotham, sans-serif",
    fontSize: "20px",
    lineHeight: "40px",
    fontWeight: 300,
    color: "#013D4F",

    [theme.breakpoints.up(1920)]: {
      fontSize: "30px",
      lineHeight: "24px",
    },
  },

  "& .description": {
    fontFamily: "Gotham, sans-serif",
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 300,
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: "18px",
      lineHeight: "24px",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
  "& .nameTxt":{
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: "18px",
    },
  },
  "& .tabText":{
   fontSize:'14px',
    [theme.breakpoints.up(1920)]: {
      fontSize: "16px",
    },
  },
  "& .buttonText":{
   fontSize:'12px',
    [theme.breakpoints.up(1920)]: {
      fontSize: "14px",
    },
  }
}));

const StyledCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#4FC8EC",
    width: "24px",
    height: "24px",
    borderRadius: "6px",
  },

  "&:hover": {
    backgroundColor: "transparent",
  },

});

const CheckboxIcon = styled("div")({
  boxSizing: "border-box",
  width: "24px",
  height: "24px",
  border: "1px solid #C5CBC9",
  borderRadius: "6px",
});

const CheckedIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "6px",
  backgroundColor: "#4FC8EC",
});

const InviteRadioGroupContainer = styled(Box)({
  display: "flex",
  position: "relative",
  alignItems: "center",
  border: "1px solid #C5CBC9",
  borderRadius: "8px",
  padding: "0 24px",
  height: "56px",

  "& .MuiFormControlLabel-root": {
    marginRight: "48px",
  },

  "& p.radioLabel": {
    position: "absolute",
    left: "6px",
    top: "-9px",
    fontFamily: "Roboto, sans-serif",
    padding: "1px 8px",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#FFF",
    lineHeight: "18px",
    color: "#4B4C4B",
  },

  "& .MuiTypography-body1": {
    fontWeight: 500,
    fontFamily: "Roboto, sans-serif",
    lineHeight: "24px",
    fontSize: "16px",
  },

  "& .MuiSvgIcon-root": {
    fontSize: "23px",
  },

  "& .MuiRadio-colorSecondary.Mui-checked": {
    color: "#4FC8EC",
  },

  "& .MuiRadio-colorSecondary.Mui-checked:hover": {
    backgroundColor: "transparent",
  },

  "& .MuiIconButton-colorSecondary:hover": {
    backgroundColor: "transparent",
  },
});

const RadioIcon = styled("span")({
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  boxShadow: "inset 0 0 0 1px gray, inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#fff",
});

const RadioIconChecked = styled(RadioIcon)({
  backgroundColor: "#4FC8EC",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  boxShadow: "inset 0 0 0 2px #4FC8EC, inset 0 -2px 0 rgba(16,22,26,.1)",
  "&::before": {
    display: "block",
    width: "20px",
    height: "20px",
    backgroundImage: "radial-gradient(#FFFFFF,#FFFFFF 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#4FC8EC",
  },
});

const Label = styled(Typography)({
  position: "absolute",
  top: "-9px",
  left: "7px",
  padding: "0 3px",
  fontFamily: "Roboto, sans-serif",
  fontSize: "12px",
  lineHeight: "18px",
  fontWeight: 500,
  color: "#4B4C4B",
  backgroundColor: "#FFF",
});

const CustomizedTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: "1px solid #F0FAFA",

  "& span": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#C5CBC9",
    [theme.breakpoints.up(1920)]:{
      fontSize:'16px'
    }
  },

  "& .Mui-selected span": {
    color: "#0F172A",
  },

  "& .MuiTabs-fixed > span": {
    backgroundColor: "#0F172A",
  },
}));

const StyledDialog = styled(Dialog)({
  position: "relative",

  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(1, 61, 79, 0.4)",
  },

  "& .MuiDialog-paper": {
    borderRadius: "8px",
    boxSizing: "border-box",
    minWidth: "600px",
    minHeight: "295px",
    padding: "56px 24px 24px 40px",
  },

  "& .MuiDialogTitle-root": {
    padding: 0,

    "& .MuiTypography-root": {
      fontFamily: "Roboto, sans-serif",
      fontSize: "24px",
      fontWeight: 500,
      color: "#0F172A",
    },
  },

  "& .MuiDialogContent-root": {
    padding: "11px 0px",

    "& .MuiTypography-root": {
      fontFamily: "Gotham, sans-serif",
      fontSize: "18px",
      fontWeight: 300,
      color: "#013D4F",
    },
  },

  "& .MuiDialogActions-root": {
    padding: 0,
  },

  "& .MuiDialogActions-spacing > :not(:first-child)": {
    paddingLeft: "16px",
  },
});

const StyledTextField = styled(TextField)({
  "& input": {
    color: "#C5CBC9",
    fontFamily: "Roboto, sans-serif",
    fontSize: "20px",
    fontWeight: 500,
  },

  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
});

const DealershipsListContainer = styled(Box)({
  "& .dealershipsContainer": {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    gap: "10px",

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "39px",
    },

    "&::-webkit-scrollbar-track": {
      visibility: "hidden",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
    },
  },

  "& .dealershipItem": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 24px",
    border: "1px solid #F0FAFA",
    borderRadius: "12px",

    "&:hover": {
      backgroundColor: "#013D4F",

      "& .dealershipTitle": {
        color: "#FFF",
      },

      "& .dealershipUsers": {
        color: "#ABBFC5",
      },

      "& .addUserButton": {
        backgroundColor: "#4FC8EC",
        color: "#FFF",
      },

      "& .userAddedButtonSecondary": {
        visibility: "visible",
      },
    },
  },
  "& .icons": {
    width: 20,
    height: 20,
    color: '#fff',
    [theme.breakpoints.up(1920)]: {
      width: 24,
      height: 24,
    }
  },

  "& .leftSide": {
    display: "flex",
    flexDirection: "column",
  },

  "& .dealershipTitle": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: "20px",
    }
  },

  "& .dealershipUsers": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: 400,
    color: "#868786",
    [theme.breakpoints.up(1920)]: {
      fontSize: "14px",
    }
  },

  "& .rightSide": {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignSelf: "flex-start",
  },

  "& .userAddedButton, & .userAddedButtonSecondary": {
    position: "absolute",
    display: "flex",
    padding: "0 16px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "#26BBB3",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    [theme.breakpoints.up(1920)]: {
      fontSize: '12px',
      height: 44
    }
  },

  "& .userAddedButtonSecondary": {
    backgroundColor: "#C43937",
    visibility: "hidden",
  },

  "& .addUserButton": {
    display: "flex",
    width: "161px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #013D4F",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: "#013D4F",
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    [theme.breakpoints.up(1920)]: {
      fontSize: "16px",
      height: "44px",
    }
  },
});

const EditUserBackdrop = styled(Backdrop)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "rgba(1, 61, 79, 0.4)",
  height: "100vh",
  zIndex: 1,
  alignItems: "unset",

  "& .editContainer": {
    overflow: "auto",
    backgroundColor: "#FFF",
    gap: "48px",
    padding: "48px 64px",
    flexShrink: 1,
    width: "50%",
    height: "100vh",
    flexDirection: "column",
    boxSizing: "border-box",
    display: "flex",
    position: "relative",

    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
    },

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "39px",
    },

    "&::-webkit-scrollbar-track": {
      visibility: "hidden",
    },
    [theme.breakpoints.up(1920)]: {
      flexBasis: "768px",
    },
  },

  "& .editDescription": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: "14px",
    },
  },

  "& .editTitle": {
    fontFamily: "Gotham, sans-serif",
    lineHeight: "32px",
    fontSize: "20px",
    color: "#013D4F",
    fontWeight: 300,
    [theme.breakpoints.up(1920)]: {
      fontSize: "24px",
    },
  },

  "& .formTitle": {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "16px",
    color: "#013D4F",
    [theme.breakpoints.up(1920)]: {
      fontSize: "20px",
    },
  },
'& .userUpdateBtn': {
    minHeight: "40px",
    fontSize: "12px",
    [theme.breakpoints.up(1920)]: {
      minHeight: "56px",
      fontSize: "16px",
    },
  },
  "& .editFormContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },

    "& label": {
      fontFamily: "Roboto, sans-serif",

      "&.MuiInputLabel-shrink": {
        color: "#4B4C4B",
        fontWeight: 500,
      },
    },

    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#4FC8EC",
    },

    "& ul > li .MuiIconButton-colorSecondary:hover": {
      backgroundColor: "transparent",
    },
  },

  "& .tableTitle": {
    marginTop: "32px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    lineHeight: "28px",
    fontWeight: 500,
    color: "#013D4F",
    [theme.breakpoints.up(1920)]: {
      fontSize: "20px",
    },
  },
});

const AddUserBackdrop = styled(Backdrop)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "rgba(1, 61, 79, 0.4)",
  height: "100vh",
  zIndex: 1,
  alignItems: "unset",

  "& .modalContainer": {
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "50%",
    flexShrink: 1,
    padding: "48px 64px 113px",
    gap: "48px",
    backgroundColor: "#FFF",
    [theme.breakpoints.up(1920)]: {
      flexBasis: "768px",
    },

    "& .updateDealershipsUsers": {
      position: "absolute",
      bottom: "48px",
      display: "flex",
      flexGrow: 1,
      maxWidth: "640px",
      maxHeight: "56px",
      minHeight: "56px",
      width: "100%",
      fontWeight: 700,
      fontSize: "16px",
      textTransform: "none",
      lineHeight: "24px",
      borderRadius: "8px",
      color: "#FFFFFF",
      background: "#4FC8EC",
      fontFamily: "Roboto, sans-serif",
    },
  },

  "& .modalTitle": {
    fontFamily: "Gotham, sans-serif",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 300,
    color: "#013D4F",
  },

  "& .modalDescription": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  "& .tableTitle": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    lineHeight: "28px",
    fontWeight: 500,
    color: "#013D4F",
    [theme.breakpoints.up(1920)]: {
      fontSize: "20px",
    },
  },
});

const InviteUserMenuItemAll = styled(MenuItem)({
  "& > div": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },

  "& p:first-child": {
    color: "#4B4C4B",
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
  },
});

const InviteUserMenuItem = styled(MenuItem)({
  "&:hover": {
    backgroundColor: "#F0FAFA",
  },
});

const InviteUserBackdrop = styled(Backdrop)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "rgba(1, 61, 79, 0.4)",
  height: "100vh",
  zIndex: 1,
  alignItems: "unset",

  "& .successInvite": {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    display: "flex",
    alignSelf: "center",
    justifySelf: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },

  "& .successInviteImage": {
    width: "180px",
    height: "180px",
    marginBottom: "50px",
    [theme.breakpoints.up(1920)]: {
      width: "180px",
      height: "227px",
      marginBottom: "76px",
    }
  },

  "& .successMessageBlock": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "48px",
  },

  "& .successTitle": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Gotham, sans-serif",
    fontSize: "20px",
    lineHeight: "32px",
    fontWeight: 300,
    color: "#013D4F",
    [theme.breakpoints.up(1920)]: {
      fontSize: "24px",
      lineHeight: "24px",
    }
  },

  "& .successDescription": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: "14px",
      lineHeight: "22px",
    }
  },

  "& .successButtonsContainer": {
    display: "flex",
    flexWrap: "nowrap",
    gap: "24px",
    width: "512px",
  },

  "& .successViewDealership": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    padding: "0 10px",
    flexGrow: 1,
    backgroundColor: "transparent",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
    cursor: "pointer",
    color: "#4B4C4B",
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: 500,
    [theme.breakpoints.up(1920)]: {
      height: "56px",
      fontSize: "16px",
    }
  },

  "& .successInviteMore": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    padding: "0 10px",
    flexGrow: 1,
    backgroundColor: "#4FC8EC",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: 500,
    [theme.breakpoints.up(1920)]: {
      height: "56px",
      fontSize: "16px",
    }
  },

  "& .inviteContainer": {
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: '50%',
    flexShrink: 1,
    padding: "48px 64px",
    gap: "48px",
    backgroundColor: "#FFF",
    overflow: "auto",

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "39px",
    },

    "&::-webkit-scrollbar-track": {
      visibility: "hidden",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
    },
    [theme.breakpoints.up(1920)]: {
      flexBasis: "768px",
    },
  },

  "& .inviteTitle": {
    fontFamily: "Gotham, sans-serif",
    lineHeight: "32px",
    fontSize: "24px",
    color: "#013D4F",
    fontWeight: 300,
  },

  "& .inviteDescription": {
    fontFamily: "Roboto, sans-serif",
    lineHeight: "22px",
    fontSize: "14px",
    color: "#4B4C4B",
    fontWeight: 500,
  },

  "& .formTitle": {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "16px",
    color: "#013D4F",
    [theme.breakpoints.up(1920)]: {
      fontSize: "20px",
    },
  },

  "& .inviteUsersContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
  },

  "& .addedUserTitle": {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "20px",
    fontWeight: 500,
  },

  "& .usersList": {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
  },

  "& .userItem": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px",
  },

  "& .userBlankAvatar": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    color: "#013D4F",
    backgroundColor: "#F0FAFA",
    borderRadius: "12px",
  },

  "& .userInfoContainer": {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  "& .userInfoEmail": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  "& .userInfoDealerships": {
    fontFamily: "Roboto, sans-serif",
    color: "#4B4C4B",
    fontWeight: 400,
    fontSize: "14px",
  },

  "& .userActionsContainer": {
    display: "flex",
    height: "100%",
    flexWrap: "nowrap",
    alignSelf: "flex-start",
    gap: "24px",
  },

  "& .userRole": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "41px",
    height: "24px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 600,
    fontSize: "12px",
    color: "013D4F",
    borderRadius: "26px",
    backgroundColor: "#F0FAFA",
  },

  "& .editUser": {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },

  "& .deleteUser": {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },

  "& .inviteFormContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    "& .MuiInputBase-root.Mui-disabled": {
      color: "#4B4C4B",
    },

    "& .MuiSelect-iconOutlined": {
      right: "20px",
    },

    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#4FC8EC",
    },

    "& ul > li .MuiIconButton-colorSecondary:hover": {
      backgroundColor: "transparent",
    },

    "& .MuiListItem-button:hover": {
      backgroundColor: "#F0FAFA",
    },

    "& ul > li.MuiListItem-root.Mui-selected": {
      backgroundColor: "transparent",
    },
  },

  "& .inviteDealershipSelect .inviteSelectAll": {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },

  "& .MuiInputLabel-formControl": {
    transform: "translate(16px, 21px) scale(1)",
  },

  "& .MuiInputLabel-shrink": {
    transform: "translate(12px, -6px) scale(0.75)",
  },

  "& label": {
    fontFamily: "Roboto, sans-serif",

    "&.MuiInputLabel-shrink": {
      color: "#4B4C4B",
      fontWeight: 500,
    },
  },

  "& .MuiOutlinedInput-root ": {
    borderRadius: "8px",
  },
  '& .userUpdateBtn': {
    minHeight: "40px",
    fontSize: "14px",
    [theme.breakpoints.up(1920)]: {
      minHeight: "56px",
      fontSize: "16px",
    },
  },
  '& .addAnotherUserButton': {
    minHeight: "40px",
    fontSize: "12px",
    [theme.breakpoints.up(1920)]: {
      minHeight: "44px",
      fontSize: "14px",
    },
  },
});

const StyledSnackbar = styled(Snackbar)({
  height: "72px",
  width: "420px",
  padding: "0 24px",
  justifyContent: "flex-start",
  border: "1px solid #013D4F",
  borderRadius: "12px",
  boxShadow: "0px 16px 48px 0px rgba(1, 61, 79, 0.22)",

  "& .MuiBox-root": {
    gap: "10px",
  },

  "& .snackbarMessage": {
    fontSize: "16px",
    color: "#013D4F",
  },
});

const SingleDealershipAvatar = styled(Avatar)({
  width: "70px",
  height: "70px",
  color: "#013D4F",
  backgroundColor: "rgba(240, 250, 250, 0.3)",
  borderRadius: "17px",
  fontWeight: 500,
  fontFamily: "Roboto, sans-serif",
  fontSize: "23px",
});

interface Styles {
  [Key: string]: CSSProperties;
}

const webStyles: Styles = {
  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
  },

  headerLeftSide: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },

  headerRightSide: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "272px",
    height: "70px",
    gap: "8 px",
    padding: "5px 10px",
    border: "1px solid #F0FAFA",
    borderRadius: "12px",
  },

  dealerGroupUpperBox: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
  },

  groupName: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  editGroup: {
    fontFamily: "Roboto, sans-serif",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",
    cursor: "pointer",
  },

  creationDate: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 500,
    color: "#C5CBC9",
  },

  content: {
    padding: "48px 24px 24px 0",
  },

  cancelDeleteButton: {
    display: "flex",
    width: "156px",
    height: "56px",
    paddingLeft: 0,
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #013D4F",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: "#013D4F",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
  },

  deleteUserButton: {
    display: "flex",
    width: "156px",
    height: "56px",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "#4FC8EC",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
  },

  singleDealershipHeader: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "12px",
    marginBottom: "48px",
  },

  singleDealershipContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },

  dealershipNameContainer: {
    display: "flex",
    flexWrap: "nowrap",
    gap: "19px",
    alignItems: "center",
  },

  singleDealershipTitle: {
    fontFamily: "Gotham, sans-serif",
    fontSize: "30px",
    lineHeight: "40px",
    fontWeight: 300,
    color: "#013D4F",
  },

  singleDealershipDescription: {
    fontFamily: "Gotham, sans-serif",
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: 300,
    color: "#4B4C4B",
  },
};
// Customizable Area End