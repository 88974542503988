import React from "react";
// Customizable Area Start
import ConfirmFeesProviderController, {
  Props,
} from "./ConfirmFeesProviderController.web";
import {
  Box,
  Avatar,
  withStyles,
  createStyles,
  Button,
  Badge,
  ThemeProvider,
  createTheme,
  Typography,
  Card,
  TextField,
} from "@material-ui/core";
import { vitu, notifications, menu } from "./assets";
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import clsx from "clsx";
import { Edit } from "@material-ui/icons";
import { theme } from "./ConfirmFeesDealer.web";
import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";
import Pushnotifications from "../../pushnotifications/src/Pushnotifications.web";

export class ConfirmFeesProvider extends ConfirmFeesProviderController {
  constructor(props: Props) {
    super(props);

  }
  MAX_LENGTH = 100;

  render() {
    const { classes } = this.props;
    const { dealInfo, isEditing, isEditingRegistrationFee, oldFee, oldComment } = this.state;

    const notChanged = oldFee == dealInfo.registrationFee && oldComment == dealInfo.comment;

    let cardPanelInfos = {
      ['ID: ' + dealInfo.id]: dealInfo.serviceProviderName,
      "CUSTOMER'S LAST NAME": dealInfo.customerLastName,
      STATES: dealInfo.fromState + " > " + dealInfo.toState,
      APPLICATION: dealInfo.application,
      'SERVICES': dealInfo.service,
      'VEHICLE TYPE': dealInfo.vehicleType
    } as { [key: string]: string };

    return <ThemeProvider theme={theme}>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Box className={classes.headerLeftSide}>
            <Box className={classes.menuLogo}>
              <Button className={classes.hamburgerButton}>
                <img src={menu} alt="menu button" />
              </Button>

              <Box className={classes.logo}>
                <img src={vitu} alt="vitu logo" />
              </Box>
            </Box>
          </Box>

          <Box className={classes.headerRightSide}>
            <Box
              data-test-id="notifications"
              className={classes.notificationIcon}
            >
              <Pushnotifications navigation={undefined} id={""} />
            </Box>

            <Box className={classes.avatarContainer}>
              <ProfileDropdown
                data-test-id="profileDropdownTestId"
                navigation={this.props.navigation}
                id={""}
                 />
            </Box>
          </Box>
        </Box>
        <Box className={classes.main}>
          <Box className={classes.formFee}>
            <Box className={classes.formFeeHeader}>
              <Typography variant="h1">Confirm Fees</Typography>
              <Box sx={{ marginTop: "12px", marginBottom: "60px" }}>
                <Typography variant="h2">
                  Please review the fee estimate. If necessary, adjust the fee before submitting it to the dealer
                </Typography>
              </Box>
            </Box>
            <Box className={classes.cardContainer}>
              <Box className={classes.cardPanelContainer}>
                {Object.entries(cardPanelInfos).map(([key, value]) => (
                  <Card className={classes.cardPanel} key={key}>
                    <Typography variant="body1">{key}</Typography>
                    <Typography variant="body2">{value}</Typography>
                  </Card>
                ))}
              </Box>
              <Box style={{ display: 'flex', backgroundColor: '#F0FAFA', borderRadius: '5px', width: '88px', height: '25px', justifyContent: 'space-around', alignItems: 'center', padding: '0px 10px', marginTop: '4px' }}>
                <ThumbUpAltOutlinedIcon style={{ color: '#4B4C4B', width: '15px', height: '15px' }} />
                <Box style={{ display: 'flex' }}>
                  <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight: 500 }}>86</Typography>
                  <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight: 500 }}>%</Typography>
                </Box>
                <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight: 500 }}>Positive</Typography>
              </Box>
            </Box>
            <Box className={classes.feeDetailsContainers}>
              <Box className={classes.feeDetailsHeader}>
                <Typography variant="h1">
                  Registration Fee
                </Typography>
                <Button
                  disabled={isEditing}
                  className={clsx(classes.editFeeBtn, {
                    [classes.disabledBorder]: isEditing
                  })}
                  onClick={this.handleChangeEditingStatus}
                  data-test-id="edit-fee-btn"
                >
                  Edit Fee
                </Button>
              </Box>
              <Box data-test-id="fee-items" sx={{ width: '100%' }}>
                <Box
                  className={classes.feeItem}
                  data-test-id="fee-item"
                >
                  <Typography
                    className={clsx(classes.feeName, {
                      [classes.selectedFeeTextColor]: isEditingRegistrationFee
                    })}
                  >
                    Registration Fee
                    {dealInfo.comment && !isEditingRegistrationFee && (
                      <span className={classes.changeReason}>
                        *Change because: "{dealInfo.comment}"
                      </span>
                    )}
                  </Typography>
                  <Typography
                    className={clsx(classes.feePrice, {
                      [classes.selectedFeeTextColor]: isEditingRegistrationFee
                    })}
                  >
                    {dealInfo.oldRegistrationFee && dealInfo.oldRegistrationFee !== dealInfo.registrationFee && (
                      <span className={classes.oldPrice}>${dealInfo.oldRegistrationFee}</span>
                    )}
                    $
                    {isEditingRegistrationFee ? (
                      <input
                        data-test-id="price-input"
                        style={{
                          width: (dealInfo.registrationFee.length + 1) + "ch"
                        }}
                        className={classes.feeInput}
                        value={dealInfo.registrationFee || ''}
                        onChange={this.handleFeeInputChange}
                        onKeyDown={this.handleFeeSave}
                        contentEditable={true}
                      />
                    ) : (
                      dealInfo.registrationFee
                    )}
                    {isEditing && (
                      <Edit
                        data-test-id="edit-price-icon"
                        onClick={this.handleSelectFeeChange}
                        className={clsx(classes.editIcon, {
                          [classes.selectedFeeTextColor]:
                            isEditingRegistrationFee
                        })}
                      />
                    )}
                  </Typography>
                </Box>
                {isEditingRegistrationFee && (
                  <TextField
                    data-test-id="reason-change-input"
                    className={classes.reasonChangeInput}
                    id="outlined-basic"
                    label="Why are you changing this fee?"
                    variant="outlined"
                    value={dealInfo.comment || ''}
                    onChange={this.handleInputReason}
                    onKeyDown={this.handleChangeReason}
                    inputProps={{
                      maxLength: this.MAX_LENGTH
                    }}
                    helperText={`${dealInfo.comment?.length || 0}/${this.MAX_LENGTH}`}
                  />
                )}
              </Box>
              <Box style={{gap: '10px'}} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingTop: '23px', borderTop: '1px solid #F1F4F3' }}>
                {isEditing ? <>
                  <Button className={classes.cancelBtn} onClick={this.cancelHandler}>Cancel</Button>
                  <Button className={clsx(classes.acceptBtn, {
                    [classes.disabledSaveBtn]: notChanged
                  })} onClick={this.saveHandler} disabled={notChanged}>Save</Button>
                </> : <Button className={classes.acceptBtn} style={{ width: '560px' }} onClick={this.confirmAndSendToDealer}>Confirm & Send to Dealer</Button>}
                <Box className={classes.totalFee}>
                  <Typography className={classes.totalFeeLabel}>
                    Total Fee
                  </Typography>
                  <Typography className={classes.totalFeeValue}>
                    ${dealInfo.registrationFee}
                  </Typography>
                  {(this.state.dealInfo.oldRegistrationFee &&
                    (this.state.dealInfo.oldRegistrationFee !==
                      this.state.dealInfo.registrationFee)) && (
                      <Typography style={{ textDecoration: 'line-through', fontSize: '20px' }}>${this.state.dealInfo.oldRegistrationFee}</Typography>
                    )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  }
}

const styles = createStyles({
  disabledSaveBtn: {
    background: "#A3E2F5 !important",
    color: '#FFFFFF',
    fontSize:'12px',
    [`@media (min-width:1920px)`]: {
      fontSize:'16px',
      height: '56px',
    },
    
  },

  container: {
    display: "flex",
    flexDirection: "column",
    width: '100%',
    height: '100%',
    overflow: 'scroll'
  },

  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 32px",
    borderBottom: "1px solid #F0FAFA"
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "48px"
  },

  menuLogo: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px"
  },

  hamburgerButton: {
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
    minWidth: "0",

    "& > img": {
      width: "100%",
      height: "100%"
    }
  },

  logo: {
    width: "52px",

    "& > img": {
      width: "100%"
    }
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px"
  },

  notificationIcon: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer"
  },

  avatarContainer: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",

    "& > img": {
      width: "100%"
    }
  },

  avatar: {
    width: "100%",
    height: "100%",
    borderRadius: "24px"
  },

  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    height: '100%'
  },

  formFee: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },

  formFeeHeader: {
    width: '900px',
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },

  cardContainer: {
    boxShadow: "0px 0px 4px 0px #8989895C",
    borderRadius: "10px",
    minWidth: "810px",
    padding: "30px 45px 30px 45px",
  },

  cardPanelContainer: {
    gap: '35px',
    display: "flex",
    justifyContent: "space-between",
  },

  cardPanel: {
    boxShadow: "none",
    overflow: 'visible'
  },

  progressMenu: {
    padding: "6px 2px 6px 2px",
    borderRadius: "26px"
  },

  inProgress: {
    color: "#346472",
    border: "1px solid #346472"
  },

  feeDetailsContainers: {
    padding: "40px 70px",
    gap: "40px",
    width: "760px",
    borderRadius: "10px",
    border: "1px solid #F1F4F3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px"
  },

  feeDetailsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },

  editFeeBtn: {
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    border: "1px solid #4C4D4C",
    height: '44px',
    [`@media (min-width:1920px)`]: {
      height: '56px',
    },
    "& span": {
      fontFamily: "Roboto",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "left",
      textTransform: "initial",
      fontSize: 12,
      [`@media (min-width:1920px)`]: {
        fontSize: 16,
      },
    }
  },

  feeDetailsFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingTop: "23px",
    borderTop: "1px solid #F1F4F3"
  },

  cancelBtn: {
    width: '83px',
    borderRadius: "8px",
    padding: '16px 0px',
    height: '44px',
    [`@media (min-width:1920px)`]: {
      height: '56px',
    },
    "& span": {
      fontFamily: "Roboto",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "initial",
      fontSize: 12,
      [`@media (min-width:1920px)`]: {
        fontSize: 16,
      },
    },
  },
  acceptBtn: {
    width: '464px',
    padding: '16px 0px',
    borderRadius: "8px",
    background: "#4FC8EC",
    height: '44px',
    [`@media (min-width:1920px)`]: {
      height: '56px',
    },
    "& span": {
      fontFamily: "Roboto",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#FFFFFF",
      textTransform: "initial",
      fontSize: 12,
      [`@media (min-width:1920px)`]: {
        fontSize: 16,
      },
    },
    "&:hover": {
      backgroundColor: "#4FC8EC",
      color: "#FFFFFF"
    }
  },
  totalFee: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px"
  },
  totalFeeLabel: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "18px",
    color: "#4B4C4B",
    [`@media (min-width:1920px)`]: {
      fontSize: 18,
    },
  },
  totalFeeValue: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    color: "#013D4F",
    [`@media (min-width:1920px)`]: {
      fontSize: 28,
    },
  },
  disabledBorder: {
    borderColor: "#C5CBC9"
  },

  dialog: {
    '& > div:nth-child(3) > div:nth-child(1)': {
      borderRadius: '8px',
      boxShadow: 'none',
    },
  },

  rejectDialog: {
    '& > div:nth-child(3) > div:nth-child(1)': {
      height: '45%'
    },
  },

  acceptDialog: {
    '& > div:nth-child(3) > div:nth-child(1)': {
      height: 'auto'
    },
  },

  dialogHeader: {
    padding: '18px 16px 8px 40px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '544px',
    height: '32px',
    borderBottom: '1px solid rgba(241, 244, 243, 1)',
  },

  dialogTitle: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    textAlign: 'left',
    color: '#013D4F',
  },
  feeItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'baseline',
    width: '100%'
  },

  feeName: {
    fontFamily: "Gotham",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#4B4C4B",
    fontSize: 16,
    [`@media (min-width:1920px)`]: {
      fontSize: "20px",
    },
  },

  feePrice: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#4B4C4B",
    [`@media (min-width:1920px)`]: {
      fontSize: "20px",
    },
  },

  selectedFeeTextColor: {
    color: "#1492B8 !important"
  },

  editIcon: {
    color: "#013D4F",
    marginLeft: "10px"
  },
  changeReason: {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#C5CBC9",
    marginTop: "5px",
    display: "block",
    [`@media (min-width:1920px)`]: {
      fontSize: "14px",
    },
  },
  oldPrice: {
    marginRight: "4px",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    color: "#C5CBC9",
    textDecoration: "line-through",
    [`@media (min-width:1920px)`]: {
      fontSize: "16px",
    },
  },
  feeInput: {
    border: "0px",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    color: "#1492B8",
    textAlign: "right",
    display: "inline-block",
    "&:focus": {
      border: "0px",
      outline: "0px"
    }
  },
  reasonChangeInput: {
    marginTop: "15px",
    borderRadius: "8px",
    width: "100%",
    "& .Mui-focused": {
      color: "#4B4C4B !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C5CBC9 !important"
    },
    "& input": {
      fontSize: "16px",
      fontFamily: "Roboto",
      color: "#4B4C4B"
    },
    "& label": {
      fontSize: "16px",
      fontFamily: "Roboto",
      color: "#C5CBC9",
      paddingLeft: '10px'
    },
    "& .MuiFormHelperText-root": {
      textAlign: "end"
    }
  }
});

export default withStyles(styles)(ConfirmFeesProvider);
// Customizable Area End
